import React, { memo } from 'react';

//Action
import { selectCurrency } from "@/redux/actions/globalActions";

//Libraries
import { useDispatch } from "react-redux";

//Import methods
import useDeviceDetect from "@/src/utils/customHooks/useDeviceDetect";
import { closePortal, closePortalMobile } from '../../utils/currencyMethods';
import { constants } from '@/src/utils/constants';


const CurrencyCard = ({info,selected,close = null, from}) => {

    //Check device
    const { isDesktop, isMobile } = useDeviceDetect();

    //Var
    const active = info.short === selected.short ? 'cn__currency__card--active' : '';

    //Store
    const dispatch = useDispatch();

    //Methods
    const currencyClick = (e) => {
        let parent = e.currentTarget;
        let active = document.querySelector('.cn__currency__card--active');
        //Action
        active && active.classList.remove('cn__currency__card--active');
        parent.classList.add('cn__currency__card--active');
        //Store Action
        dispatch(selectCurrency(info));
        //Close if exists
        if(close){
            if(isMobile){
                setTimeout(() => {
                    closePortalMobile(close.parent,close.state);
                },600);
            }else{
                setTimeout(() => {
                    closePortal(close.parent.current,close.child.current,close.state);
                },300)
            }
        }
    };

    const renderSubtitle = () => [
        info.short,
        info.symbol
    ].join(' - ');

    //Render
    const renderTitle = isDesktop ? (
        <p className='cn__currency__card__title'>{info.long} <span>- {info.short === constants.defaultCurrency.short ? constants.defaultCurrency.symbol : from === constants.currenceyType.fiatCurrency ? info.short : info.symbol}</span></p>
    ) : (
        <div className='cn__currency__card__titlemob'>
            <p className='cn__currency__card__titlemob__title'>{info.long}</p>
            <p className='cn__currency__card__titlemob__subtitle'>{renderSubtitle()}</p>
        </div>
    );

    return (
        <div className={`cn__currency__card ${active}`} onClick={currencyClick}>
            {renderTitle}
            <svg width="15" height="11" viewBox="0 0 15 11" fill="none">
                <path d="M1 5.5L5 9.5L14 1.5" stroke="#242526" strokeWidth="2"/>
            </svg>
        </div>
    )
};

export default memo(CurrencyCard);