import Box from "@mui/material/Box";
import PriceValue from "./PriceValue";

const FeesTaxes = props => (
    <Box
        sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            gap: .4,
            color: 'var(--color-dark-grey)',
            fontSize: 12
        }}
    >
        + <PriceValue
            sx={{
                fontWeight: 500
            }}
            value={props.tax_and_fee || props.total_stay_tax}
            currency={props.currency}
        />
        {' '}
        {props.tax_fee_label || 'taxes and fees'}
    </Box>
);

export default FeesTaxes;
