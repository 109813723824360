
const LogoBirds = () => {
    return (
        <svg width="65" height="39" viewBox="0 0 65 39" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M41.1242 16.3628C41.1242 16.3628 43.7621 19.0933 48.5229 20.1825C51.4055 15.6722 57.2994 13.1547 63.6219 12.2325C56.6203 11.7605 53.8445 12.6847 52.3918 12.841C50.7377 13.0188 46.0626 9.40217 41.1242 16.3628Z" fill="#FA368B" />
            <path d="M23.92 21.4165C23.92 21.4165 21.5558 24.3663 20.0004 27.5599C21.0881 25.9118 22.4185 24.3797 25.6501 22.7087L23.92 21.4165Z" fill="#4C35BC" />
            <path d="M17.5447 9.68322C18.5392 10.6353 19.1887 11.4585 19.7942 12.3416C16.9728 10.9261 12.0499 10.9962 8.91382 11.4204C10.6607 10.6069 14.6535 9.52962 17.5447 9.68322Z" fill="#4C35BC" />
            <path d="M35.2886 9.10101C35.2886 9.10101 36.9777 10.1959 37.7389 12.3307C39.1995 9.4325 44.7587 7.03887 48.516 6.09333C44.2631 6.23934 42.6457 6.96773 41.7787 7.15151C40.7921 7.36059 36.8587 5.968 35.2886 9.10101Z" fill="#4C35BC" />
            <path d="M15.0639 38.1537C15.0639 38.1537 23.4946 30.5048 32.6666 30.5048C42.9815 30.5048 45.9616 25.5471 48.335 20.7083C48.335 20.7083 40.3826 19.6469 36.7752 11.2615C36.7752 11.2615 28.1743 -0.715302 4.93388 0.0337233C4.93388 0.0337233 18.121 7.76181 21.3323 12.9594C24.6239 18.2868 33.1366 24.0784 32.7765 23.901C25.3218 20.2362 20.2515 13.2724 20.0798 12.825C19.9083 12.3776 12.8385 10.3159 0.481934 14.4686C0.481934 14.4686 13.759 11.9017 28.8992 24.5591C28.8992 24.5591 27.3684 25.2458 26.271 26.0032C19.4842 30.6867 15.0467 38.158 15.0639 38.1537Z" fill="#FA368B" />
        </svg>
    );
};

export default LogoBirds;
