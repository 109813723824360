import useCurrencyConverter from "@/utils/customHooks/useCurrencyConverter";
import useEnhanceUrlSearchParams, { STRATEGIES } from "@/utils/customHooks/useEnhanceUrlSearchParams";
import analyticsLabelProvider from "@/static/analyticsLabelProvider";
import { DestinationTypeEnum, EAType, MediaSrcSetEnum } from "@/static/contants";
import { config } from "@/utils/config";
import { useCallback } from "react";
import { sortByTypes } from "@/containers/searchResults/enum";
import useDeviceDetect from "@/src/utils/customHooks/useDeviceDetect";

export const trendingDataFn = () => {
    const linkGeneratorFn = useEnhanceUrlSearchParams(STRATEGIES.CHECKIN_CHECKOUT_NEXT_WEEKEND,sortByTypes.LOWEST_PRICE);
    const convertTo = useCurrencyConverter();
    const { isDesktop, isTablet } = useDeviceDetect();
    const generateLinkFor = useCallback((queryParams = []/*location, locationType = DestinationTypeEnum.CITY*/) => linkGeneratorFn({
        queryParams: [
            ...queryParams,
            ['adults', 2],
            ['rooms', 1]
        ]/*: [
            ['location', location],
            ['location_type', locationType]
        ]*/
    }), [linkGeneratorFn]);

    const trendingData = [
        {
            title: 'London',
            // img:'https://img.cuddlynest.com/images/locations/london-united-kingdom-sm.jpg',
            url: generateLinkFor([
                ['location', 'London, England, United Kingdom'],
                ['location_type', DestinationTypeEnum.CITY],
                ['lat', '51.50721779999999'],
                ['lon', '-0.1275862'],
                ['nwLat', '51.6723432'],
                ['nwLng', '0.148271'],
                ['seLat', '51.38494009999999'],
                ['seLng', '-0.3514683'],
                ['listing_id', 0],
                ['zoom_level', 13],
                ['mobile_zoom_level', 12],
                ['property_count',9700]
            ]),
            price: 77,
            src: `${config.resizedImagesUrl}/assets/${isDesktop ? 'desktop': isTablet ? 'tablet':'mobile'}/homepage/trending/london-united-kingdom.jpg`,
        },
        {
            title:'Barcelona',
            // img:'https://img.cuddlynest.com/images/locations/barcelona-spain-sm.jpg',
            url: generateLinkFor([
                ['location', 'Barcelona, Catalonia, Spain'],
                ['location_type', DestinationTypeEnum.CITY],
                ['lat', '41.3873974'],
                ['lon', '2.168568'],
                ['nwLat', '41.4682974'],
                ['nwLng', '2.2280449'],
                ['seLat', '41.3170385'],
                ['seLng', '2.0523333'],
                ['listing_id', 16],
                ['zoom_level', 13],
                ['mobile_zoom_level', 12],
                ['property_count', 3114]
            ]),
            price: 144,
            src: `${config.resizedImagesUrl}/assets/${isDesktop ? 'desktop': isTablet ? 'tablet':'mobile'}/homepage/trending/barcelona-spain.jpg`,
        },
        {
            title:'Miami',
            // img:'https://img.cuddlynest.com/images/locations/miami-united-states-sm.jpg',
            url: generateLinkFor([
                ['location', 'Miami, Florida, United States'],
                ['location_type', DestinationTypeEnum.CITY],
                ['lat', '25.7616798'],
                ['lon', '-80.1917902'],
                ['nwLat', '25.855773'],
                ['nwLng', '-80.139157'],
                ['seLat', '25.709042'],
                ['seLng', '-80.3197599'],
                ['listing_id', 483],
                ['zoom_level', 13],
                ['mobile_zoom_level', 12],
                ['property_count', 664]
            ]),
            price: 150,
            src: `${config.resizedImagesUrl}/assets/${isDesktop ? 'desktop': isTablet ? 'tablet':'mobile'}/homepage/trending/miami-united-states.jpg`,
        },
        {
            title:'Paris',
            // img:'https://img.cuddlynest.com/images/locations/paris-france-sm.jpg',
            url: generateLinkFor([
                ['location', 'Paris, Ile-De-France, France'],
                ['location_type', DestinationTypeEnum.CITY],
                ['lat', '48.856614'],
                ['lon', '2.3522218999999995'],
                ['nwLat', '48.9021475'],
                ['nwLng', '2.4698509'],
                ['seLat', '48.8155622'],
                ['seLng', '2.2242191'],
                ['listing_id', 17],
                ['zoom_level', 13],
                ['mobile_zoom_level', 12],
                ['property_count', 4054]
            ]),
            price: 93,
            src: `${config.resizedImagesUrl}/assets/${isDesktop ? 'desktop': isTablet ? 'tablet':'mobile'}/homepage/trending/paris-france.jpg`,
        },
        {
            title:'Dubai',
            // img:'https://img.cuddlynest.com/images/locations/dubai-united-arab-emirates-sm.jpg',
            url: generateLinkFor([
                ['location', 'Dubai, Dubai Emirate, United Arab Emirates'],
                ['location_type', DestinationTypeEnum.CITY],
                ['lat', '25.204849300000003'],
                ['lon', '55.2707828'],
                ['nwLat', '25.3585607'],
                ['nwLng', '55.5650393'],
                ['seLat', '24.7934842'],
                ['seLng', '54.89045429999999'],
                ['listing_id', 9],
                ['zoom_level', 13],
                ['mobile_zoom_level', 12],
                ['property_count', 5020]
            ]),
            price: 41,
            src: `${config.resizedImagesUrl}/assets/${isDesktop ? 'desktop': isTablet ? 'tablet':'mobile'}/homepage/trending/dubai-united-arab-emirates.jpg`,
        },
        {
            title:'Prague',
            // img:'https://img.cuddlynest.com/images/locations/prague-czech-republic-sm.jpg',
            url: generateLinkFor([
                ['location', 'Prague, Prague, Czech Republic'],
                ['location_type', DestinationTypeEnum.CITY],
                ['lat', '50.0755381'],
                ['lon', '14.4378005'],
                ['nwLat', '50.177403'],
                ['nwLng', '14.7067945'],
                ['seLat', '49.94193629999999'],
                ['seLng', '14.2244533'],
                ['listing_id', 18],
                ['zoom_level', 13],
                ['mobile_zoom_level', 12],
                ['property_count', 2063]            ]),
            price: 42,
            src: `${config.resizedImagesUrl}/assets/${isDesktop ? 'desktop': isTablet ? 'tablet':'mobile'}/homepage/trending/prague-czech-republic.jpg`,
        },
        {
            title:'Bali',
            // img:'https://img.cuddlynest.com/images/locations/bali-indonesia-sm.jpg',
            url: generateLinkFor([
                ['location', 'Bali, Indonesia'],
                ['location_type', DestinationTypeEnum.STATE],
                ['lat', '-8.609672208299639'],
                ['lon', '115.21710821765417'],
                ['nwLat', '-8.235751492557124'],
                ['nwLng', '115.36576696565896'],
                ['seLat', '-8.98322381379781'],
                ['seLng', '115.06844946964941'],
                ['listing_id', 10],
                ['zoom_level', 10],
                ['mobile_zoom_level', 10],
                ['property_count', 15000] 
            ]),
            price: 9,
            src: `${config.resizedImagesUrl}/assets/${isDesktop ? 'desktop': isTablet ? 'tablet':'mobile'}/homepage/trending/bali-indonesia.jpg`,
            sources: [
                {
                    media: MediaSrcSetEnum.MOBILE,
                    srcset: `${config.resizedImagesUrl}/images/locations/t/webp/bali-indonesia_610x423.jpg 1x, ${config.resizedImagesUrl}/images/locations/t/webp/bali-indonesia_1220x846.jpg 2x`,
                    type: 'image/webp'
                },
                {
                    media: MediaSrcSetEnum.TABLET,
                    srcset: `${config.resizedImagesUrl}/images/locations/t/webp/bali-indonesia_610x423.jpg 1x, ${config.resizedImagesUrl}/images/locations/t/webp/bali-indonesia_1220x846.jpg 2x`,
                    type: 'image/webp'
                },
                {
                    media: MediaSrcSetEnum.DESKTOP,
                    srcset: `${config.resizedImagesUrl}/images/locations/t/webp/bali-indonesia_610x423.jpg 1x, ${config.resizedImagesUrl}/images/locations/t/webp/bali-indonesia_1220x846.jpg 2x`,
                    type: 'image/webp'
                }
            ]
        },
        {
            title:'Delhi',
            // img:'https://img.cuddlynest.com/images/locations/india-sm.jpg',
            url: generateLinkFor([
                ['location', 'New Delhi, Delhi, India'],
                ['location_type', DestinationTypeEnum.CITY],
                ['lat', '28.6139391'],
                ['lon', '77.20902120000001'],
                ['nwLat', '28.6506044'],
                ['nwLng', '77.3449601'],
                ['seLat', '28.4041'],
                ['seLng', '77.07301009999999'],
                ['listing_id', 11],
                ['zoom_level', 13],
                ['mobile_zoom_level', 12],
                ['property_count', 3168] 
            ]),
            price: 20,
            src: `${config.resizedImagesUrl}/assets/${isDesktop ? 'desktop': isTablet ? 'tablet':'mobile'}/homepage/trending/india.jpg`,
        },
        {
            title:'Bangkok',
            // img:'https://img.cuddlynest.com/images/locations/bangkok-thailand-sm.jpg',
            url: generateLinkFor([
                ['location', 'Bangkok, Bangkok Province, Thailand'],
                ['location_type', DestinationTypeEnum.CITY],
                ['lat', '13.820310000000001'],
                ['lon', '100.6647101'],
                ['nwLat', '13.9551109'],
                ['nwLng', '100.9384077'],
                ['seLat', '13.4940881'],
                ['seLng', '100.3278136'],
                ['listing_id', 15],
                ['zoom_level', 13],
                ['mobile_zoom_level', 12],
                ['property_count', 4250] 
            ]),
            price: 10,
            src: `${config.resizedImagesUrl}/assets/${isDesktop ? 'desktop': isTablet ? 'tablet':'mobile'}/homepage/trending/bangkok-thailand.jpg`,
        },
        // {
        //     title:'Moscow',
        //     // img:'https://img.cuddlynest.com/images/locations/moscow-russia-sm.jpg',
        //     url: generateLinkFor('Moscow, Moscow, Russia'),
        //     price: 88,
        //     src: `${config.resizedImagesUrl}/images/locations/moscow-russia.jpg`,
        //     sources: [
        //         {
        //             media: MediaSrcSetEnum.MOBILE,
        //             srcset: `${config.resizedImagesUrl}/images/locations/t/webp/moscow-russia_610x423.jpg 1x, ${config.resizedImagesUrl}/images/locations/t/webp/moscow-russia_1220x846.jpg 2x`,
        //             type: 'image/webp'
        //         },
        //         {
        //             media: MediaSrcSetEnum.TABLET,
        //             srcset: `${config.resizedImagesUrl}/images/locations/t/webp/moscow-russia_610x423.jpg 1x, ${config.resizedImagesUrl}/images/locations/t/webp/moscow-russia_1220x846.jpg 2x`,
        //             type: 'image/webp'
        //         },
        //         {
        //             media: MediaSrcSetEnum.DESKTOP,
        //             srcset: `${config.resizedImagesUrl}/images/locations/t/webp/moscow-russia_610x423.jpg 1x, ${config.resizedImagesUrl}/images/locations/t/webp/moscow-russia_1220x846.jpg 2x`,
        //             type: 'image/webp'
        //         }
        //     ]
        // },
        {
            title:'Amsterdam',
            // img:'https://img.cuddlynest.com/images/locations/amsterdam-netherlands-sm.jpg',
            url: generateLinkFor([
                ['location', 'Amsterdam, North Holland, Netherlands'],
                ['location_type', DestinationTypeEnum.CITY],
                ['lat', '52.3675734'],
                ['lon', '4.9041389'],
                ['nwLat', '52.4311573'],
                ['nwLng', '5.0791619'],
                ['seLat', '52.278174'],
                ['seLng', '4.7287589'],
                ['listing_id', 103],
                ['zoom_level', 13],
                ['mobile_zoom_level', 12],
                ['property_count', 1302] 
            ]),
            price: 232,
            src: `${config.resizedImagesUrl}/assets/${isDesktop ? 'desktop': isTablet ? 'tablet':'mobile'}/homepage/trending/amsterdam-netherlands.jpg`,
        },
        {
            title:'Tokyo',
            // img:'https://img.cuddlynest.com/images/locations/tokyo-japan-sm.jpg',
            url: generateLinkFor([
                ['location', 'Tokyo, Tokyo, Japan'],
                ['location_type', DestinationTypeEnum.CITY],
                ['lat', '35.6803997'],
                ['lon', '139.76901740000002'],
                ['nwLat', '35.8174453'],
                ['nwLng', '139.9188743'],
                ['seLat', '35.519042'],
                ['seLng', '139.5628611'],
                ['listing_id', 40],
                ['zoom_level', 13],
                ['mobile_zoom_level', 12],
                ['property_count', 3104] 
            ]),
            price: 67,
            src: `${config.resizedImagesUrl}/assets/${isDesktop ? 'desktop': isTablet ? 'tablet':'mobile'}/homepage/trending/tokyo-japan.jpg`,
        },
        {
            title:'Berlin',
            // img:'https://img.cuddlynest.com/images/locations/berlin-germany-sm.jpg',
            url: generateLinkFor([
                ['location', 'Berlin, Berlin, Germany'],
                ['location_type', DestinationTypeEnum.CITY],
                ['lat', '52.5200066'],
                ['lon', '13.404954'],
                ['nwLat', '52.6754542'],
                ['nwLng', '13.7611175'],
                ['seLat', '52.338234'],
                ['seLng', '13.088346'],
                ['listing_id', 129],
                ['zoom_level', 13],
                ['mobile_zoom_level', 12],
                ['property_count', 1524] 
            ]),
            price: 77,
            src: `${config.resizedImagesUrl}/assets/${isDesktop ? 'desktop': isTablet ? 'tablet':'mobile'}/homepage/trending/berlin-germany.jpg`,
        },
        {
            title:'Lisbon',
            // img:'https://img.cuddlynest.com/images/locations/lisbon-portugal-sm.jpg',
            url: generateLinkFor([
                ['location', 'Lisbon, Lisbon Region, Portugal'],
                ['location_type', DestinationTypeEnum.CITY],
                ['lat', '38.722252399999995'],
                ['lon', '-9.1393366'],
                ['nwLat', '38.7958538'],
                ['nwLng', '-9.0905709'],
                ['seLat', '38.6913994'],
                ['seLng', '-9.2298356'],
                ['listing_id', 22],
                ['zoom_level', 13],
                ['mobile_zoom_level', 12],
                ['property_count', 3122] 
            ]),
            price: 44,
            src: `${config.resizedImagesUrl}/assets/${isDesktop ? 'desktop': isTablet ? 'tablet':'mobile'}/homepage/trending/lisbon-portugal.jpg`,
        },
        {
            title:'Madrid',
            // img:'https://img.cuddlynest.com/images/locations/madrid-spain-sm.jpg',
            url: generateLinkFor([
                ['location', 'Madrid, Madrid, Spain'],
                ['location_type', DestinationTypeEnum.CITY],
                ['lat', '40.4167754'],
                ['lon', '-3.7037902000000003'],
                ['nwLat', '40.5638447'],
                ['nwLng', '-3.5249115'],
                ['seLat', '40.3120639'],
                ['seLng', '-3.8341618'],
                ['listing_id', 13],
                ['zoom_level', 13],
                ['mobile_zoom_level', 12],
                ['property_count', 3041] 
            ]),
            price: 70,
            src: `${config.resizedImagesUrl}/assets/${isDesktop ? 'desktop': isTablet ? 'tablet':'mobile'}/homepage/trending/madrid-spain.jpg`,
        },
        {
            title:'Munich',
            // img:'https://img.cuddlynest.com/images/locations/munich-germany-sm.jpg',
            url: generateLinkFor([
                ['location', 'Munich, Bavaria, Germany'],
                ['location_type', DestinationTypeEnum.CITY],
                ['lat', '48.1351253'],
                ['lon', '11.5819806'],
                ['nwLat', '48.2482197'],
                ['nwLng', '11.7228755'],
                ['seLat', '48.0616018'],
                ['seLng', '11.360796'],
                ['listing_id', 423],
                ['zoom_level', 13],
                ['mobile_zoom_level', 12],
                ['property_count', 727] 
            ]),
            price: 71,
            src: `${config.resizedImagesUrl}/assets/${isDesktop ? 'desktop': isTablet ? 'tablet':'mobile'}/homepage/trending/munich-germany.jpg`,
        },
        {
            title:'Sydney',
            // img:'https://img.cuddlynest.com/images/locations/sydney-australia-sm.jpg',
            url: generateLinkFor([
                ['location', 'Sydney, New South Wales, Australia'],
                ['location_type', DestinationTypeEnum.CITY],
                ['lat', '-33.8688197'],
                ['lon', '151.2092955'],
                ['nwLat', '-33.5781409'],
                ['nwLng', '151.3430209'],
                ['seLat', '-34.118347'],
                ['seLng', '150.5209286'],
                ['listing_id', 37],
                ['zoom_level', 13],
                ['mobile_zoom_level', 12],
                ['property_count', 1944] 
            ]),
            price: 67,
            src: `${config.resizedImagesUrl}/assets/${isDesktop ? 'desktop': isTablet ? 'tablet':'mobile'}/homepage/trending/sydney-australia.jpg`,
        },
        {
            title:'Hong Kong',
            // img:'https://img.cuddlynest.com/images/locations/hong-kong-sm.jpg',
            url: generateLinkFor([
                ['location', 'Hong Kong, Hong Kong Sar, Hong Kong'],
                ['location_type', DestinationTypeEnum.CITY],
                ['lat', '22.319303899999998'],
                ['lon', '114.1693611'],
                ['nwLat', '22.3960009'],
                ['nwLng', '114.2240009'],
                ['seLat', '22.2426069'],
                ['seLng', '114.1147211'],
                ['listing_id', 137],
                ['zoom_level', 13],
                ['mobile_zoom_level', 12],
                ['property_count', 915] 
            ]),
            price: 32,
            src: `${config.resizedImagesUrl}/assets/${isDesktop ? 'desktop': isTablet ? 'tablet':'mobile'}/homepage/trending/hong-kong.jpg`,
        },
        {
            title:'Nice',
            // img:'https://img.cuddlynest.com/images/locations/nice-france-sm.jpg',
            url: generateLinkFor([
                ['location', `Nice, Provence-Alpes-Cote D'Azur, France`],
                ['location_type', DestinationTypeEnum.CITY],
                ['lat', '43.710172799999995'],
                ['lon', '7.261953200000001'],
                ['nwLat', '43.7837742'],
                ['nwLng', '7.2121875'],
                ['seLat', '43.6365714'],
                ['seLng', '7.3117189'],
                ['listing_id', 58],
                ['zoom_level', 13],
                ['mobile_zoom_level', 12],
                ['property_count', 1343] 
            ]),
            price: 78,
            src: `${config.resizedImagesUrl}/assets/${isDesktop ? 'desktop': isTablet ? 'tablet':'mobile'}/homepage/trending/nice-france.jpg`,
        },
        {
            title:'Seoul',
            // img:'https://img.cuddlynest.com/images/locations/seoul-south-korea-sm.jpg',
            url: generateLinkFor([
                ['location', 'Seoul, Gyeonggi Do, South Korea'],
                ['location_type', DestinationTypeEnum.CITY],
                ['lat', '37.4122124'],
                ['lon', '126.8680791'],
                ['nwLat', '37.9860922'],
                ['nwLng', '127.3761717'],
                ['seLat', '36.8713321'],
                ['seLng', '126.5183725'],
                ['listing_id', 13],
                ['zoom_level', 10],
                ['mobile_zoom_level', 12],
                ['property_count', 2226] 
            ]),
            price: 49,
            src: `${config.resizedImagesUrl}/assets/${isDesktop ? 'desktop': isTablet ? 'tablet':'mobile'}/homepage/trending/seoul-south-korea.jpg`,
        },
        {
            title:'Abu Dhabi',
            // img:'https://img.cuddlynest.com/images/locations/abu-dhabi-united-arab-emirates-sm.jpg',
            url: generateLinkFor([
                ['location', 'Abu Dhabi, Abu Dhabi Emirate, United Arab Emirates'],
                ['location_type', DestinationTypeEnum.CITY],
                ['lat', '24.453884'],
                ['lon', '54.3773438'],
                ['nwLat', '24.453884'],
                ['nwLng', '54.3773438'],
                ['seLat', '24.453884'],
                ['seLng', '54.3773438'],
                ['listing_id', 1033],
                ['zoom_level', 12],
                ['mobile_zoom_level', 12],
                ['property_count', 274] 
            ]),
            price: 44,
            src: `${config.resizedImagesUrl}/assets/${isDesktop ? 'desktop': isTablet ? 'tablet':'mobile'}/homepage/trending/abu-dhabi-united-arab-emirates.jpg`,
        }
    ].map(item => {
        const price = convertTo({ amount: item.price })
        return {
            ...item,
            dataCnGaClick: JSON.stringify({ el: analyticsLabelProvider.trending[EAType.CLICK](item.title) }),
            subtitle:`From ${price.currency_symbol}${price.amount} per night`,
        }
    });

    return trendingData;
}