import {useEffect, useRef} from 'react';
import PropTypes from 'prop-types';

export const FormHelpertext = props => {
    const textMessage = useRef(null);

    const { className, children } = props;
    return (
        <span className={`text-helper ${className}`} ref={textMessage}>{children}</span>
    )
}

FormHelpertext.propTypes = {
    className: PropTypes.string
}