import { EAType } from '@/static/contants';

export default {
    omnisearch: {
        [EAType.CLICK]: () => 'SearchBar'
    },

    how_well_slider: {
        [EAType.SCROLL]: () => 'Nearby',
        [EAType.CLICK]: name => `Nearby:${name}`
    },

    making_travel_slider: {
        [EAType.SCROLL]: () => 'BPC',
        [EAType.CLICK]: name => `BPC:${name}`
    },

    cities_section: {
        [EAType.SCROLL]: () => 'FC',
        [EAType.CLICK]: name => `FC:${name}`
    },

    part_section: {
        [EAType.SCROLL]: () => 'PF',
        [EAType.CLICK]: name => `PF:${name}`
    },

    subscribe: {
        [EAType.CLICK]: () => 'Subscribe'
    },

    weRecommend: {
        [EAType.SCROLL]: () => 'WR',
        'We recommend': {
            [EAType.CLICK]: () => 'WR Tab',
            city: {
                [EAType.CLICK]: name => `WR:${name}`
            }
        },

        'Top countries': {
            [EAType.CLICK]: () => 'TC Tab',
            city: {
                [EAType.CLICK]: name => `TC:${name}`
            }
        }
    },

    covid19Response: {
        [EAType.CLICK]: () => 'Covid-19 response'
    },

    footer: {
        [EAType.CLICK]: name => `Footer:${name}`
    },

    header: {
        [EAType.CLICK]: name => `Header:${name}`
    },

    // New Home Page

    recentSearches: {
        [EAType.CLICK]: () => 'RecentSearches'
    },

    stayTypes: {
        [EAType.SCROLL]: () => 'ST',
        [EAType.CLICK]: name => `ST:${name}`
    },

    nearby: {
        [EAType.SCROLL]: () => 'Nearby',
        [EAType.CLICK]: name => `Nearby:${name}`
    },

    deals: {
        [EAType.SCROLL]: () => 'Deals',
        [EAType.CLICK]: name => `DealsTab:${name}`,
        deal: {
            [EAType.CLICK]: (dealType, name) => `${dealType}=${name}`
        }
    },

    trending: {
        [EAType.SCROLL]: () => 'Trending',
        [EAType.CLICK]: name => `Trending=${name}`
    },

    listing: {
        [EAType.SCROLL]: () => 'Listing:signup',
        [EAType.CLICK]: () => 'Listing:signup'
    },

    featuredThisWeek: {
        [EAType.SCROLL]: () => 'FTW',
        [EAType.CLICK]: name => `FTWTab:${name}`,
        viewAll: {
            [EAType.CLICK]: () => 'FTW:ViewAll'
        }
    },

    tripTypes: {
        [EAType.SCROLL]: () => 'TT',
        [EAType.CLICK]: name => `TT:${name}`,
    },

    about: {
        [EAType.SCROLL]: () => 'About',
        [EAType.CLICK]: (name = 'Learn') => `About:${name}`,
    },

    seoLinks: {
        [EAType.SCROLL]: () => 'SEOLinks',
        tab: {
            [EAType.CLICK]: name => `${name} Tab`,
            city: {
                [EAType.CLICK]: (tab, name) => `${tab}:${name}`,
            }
        }
    }
};