import React, { memo } from 'react';

const PersonNew = ({ className }) => (

    <svg className={className} width="20" height="20" viewBox="0 0 20 20">
        <path d="M7.49368 10.8414C9.79694 10.8414 11.6641 8.97422 11.6641 6.67094C11.6641 4.36766 9.79694 2.50049 7.49368 2.50049C5.19041 2.50049 3.32324 4.36766 3.32324 6.67094C3.32324 8.97422 5.19041 10.8414 7.49368 10.8414Z" fill="#64748B" strokeWidth="0"/>
        <path d="M14.9809 16.6797C14.9809 14.4788 14.0394 12.4934 12.5131 11.1214C12.1618 10.8055 11.6321 10.7934 11.2729 11.1003C9.09469 12.9617 5.89333 12.9621 3.71456 11.1005C3.35592 10.794 2.82575 10.8035 2.47563 11.1197C0.957309 12.4911 0.00117305 14.4736 1.1224e-07 16.6757C-0.000234474 17.1295 0.367285 17.5008 0.821053 17.5008H14.1599C14.6121 17.5008 14.9809 17.1345 14.9809 16.6797Z" fill="#64748B" strokeWidth="0"/>
        <path d="M18.4356 12.3704C17.9812 12.7405 17.4467 13.016 16.8625 13.1663C16.4154 13.2814 16.1509 13.7412 16.2767 14.1854C16.6659 15.5598 16.6231 16.7163 16.6231 16.6782C16.6231 17.1317 16.9907 17.4993 17.4441 17.4993H19.1711C19.6245 17.4993 19.9921 17.1317 19.9921 16.6782C19.9921 14.1203 20.0721 13.6224 19.7224 12.7031C19.5234 12.1799 18.8696 12.0169 18.4356 12.3704Z" fill="#64748B" strokeWidth="0"/>
        <path d="M15.8345 11.6749C17.2165 11.6749 18.3368 10.5546 18.3368 9.17266C18.3368 7.7907 17.2165 6.67041 15.8345 6.67041C14.4526 6.67041 13.3323 7.7907 13.3323 9.17266C13.3323 10.5546 14.4526 11.6749 15.8345 11.6749Z" fill="#64748B" strokeWidth="0"/>
    </svg>
);

export default memo(PersonNew);