import React, { memo, useEffect, useRef, useState } from 'react';

//Import libraries
import pluralize from 'pluralize';

//Import methods
import useEnhanceUrlSearchParams, { STRATEGIES } from '@/utils/customHooks/useEnhanceUrlSearchParams';

//Components
import Heading from '../../components/Heading';
import Slider from '@/components/Slider';
import analyticsLabelProvider from '@/static/analyticsLabelProvider';
import { DestinationTypeEnum, EAType, MediaSrcSetEnum } from '@/static/contants';
import { formatNumber, getResizedImgUrl } from '@/utils/globalFunc';
import useApi from '@/containers/homePage/Omnisearch/useApi';


const GoNearBy = ({ onChange }) => {
    const { getStaycationByCountry } = useApi();

    const [d, setStaycations] = useState([]);

    const getStaycationByCountryRef = useRef(getStaycationByCountry);

    useEffect(() => {
        getStaycationByCountryRef.current = getStaycationByCountry;
    }, [getStaycationByCountry]);

    useEffect(() => {
        getStaycationByCountryRef.current(setStaycations);
    }, []);

    // const d = useSelector(state => state.nearByLoc.data);

    //Imported methods
    const linkGeneratorFn = useEnhanceUrlSearchParams(STRATEGIES.CHECKIN_CHECKOUT_NEXT_WEEKEND);

    // ======== DATA FORMATING ======== //

    let data = [];

    if (d && d.length > 3) {
        d.map(el => {
            let obj = {
                title: el.name,
                img: el.imageUrl,
                info: `${formatNumber(el.totalInventory)} properties`,
                url: linkGeneratorFn({
                    geoSeoTag: el.geoSeoTag,
                    queryParams: [
                        ['location', [el.name, el.address].join(', ')],
                        ['location_type', DestinationTypeEnum.CITY],
                        ['nwLat', `${el?.viewportBounds?.northeast.lat}`],
                        ['nwLng', `${el?.viewportBounds?.northeast.lng}`],
                        ['seLat', `${el?.viewportBounds?.southwest.lat}`],
                        ['seLng', `${el?.viewportBounds?.southwest.lng}`],
                        ['mobile_zoom_level', `${el?.mobileZoomLevel}`],
                        ['zoom_level', el?.zoomLevel],
                        ['lat', el?.lat],
                        ['lon', el?.long],
                    ]
                }),
                dataCnGaClick: JSON.stringify({ el: analyticsLabelProvider.stayTypes[EAType.CLICK](el.name) }),
                src: getResizedImgUrl(el.imageUrl, false),
                sources: [
                    {
                        media: MediaSrcSetEnum.MOBILE,
                        srcset: `${getResizedImgUrl(el.imageUrl, '305x423')} 1x, ${getResizedImgUrl(el.imageUrl, '610x846')} 2x`,
                        type: 'image/webp'
                    },
                    {
                        media: MediaSrcSetEnum.TABLET,
                        srcset: `${getResizedImgUrl(el.imageUrl, '305x423')} 1x, ${getResizedImgUrl(el.imageUrl, '610x846')} 2x`,
                        type: 'image/webp'
                    },
                    {
                        media: MediaSrcSetEnum.DESKTOP,
                        srcset: `${getResizedImgUrl(el.imageUrl, '305x423')} 1x, ${getResizedImgUrl(el.imageUrl, '610x846')} 2x`,
                        type: 'image/webp'
                    }
                ]
            };
            data.push(obj);
        });
    }

    // ======== DATA FORMATING ======== //

    const dataDnb = {
        data: data,
        count: 5,
        card: 5,
        cardMargin: 20,
        showBar: false,
        cardLink: {
            type: 'a',
            target: '_self'
        }
    }

    //Render Items

    let loaderR = (
        <section className="cn__hp__gonearby">
            <Heading align="left" tit="Discover nearby destinations " sub="Staying closer to home doesn’t mean less adventure!" />
            <div className="cn__hp__gonearby__slider1not">

            </div>
        </section>
    );

    const [loader, setLoader] = useState(loaderR);

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setLoader(null);
            onChange(5);
        }, 7000);
        return () => {
            clearTimeout(timeoutId);
        };
    }, []);

    return (d && d.length > 3) ? (
        <section
            className="cn__hp__gonearby"
            data-cn-ga-observe={JSON.stringify({ el: analyticsLabelProvider.nearby[EAType.SCROLL]() })}
        >
            <Heading align="left" tit="Staycations" sub="Keep it local and visit these destinations closer to home" />
            <Slider {...dataDnb} />
        </section>
    ) : null;
}

export default memo(GoNearBy);
