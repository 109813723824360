import { useState, memo } from 'react';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { useDispatch } from 'react-redux';
import { FbSignupSigninAction } from '@/redux/actions/authActions';
import { errorNotificationActions } from '@/redux/actions/globalActions';
import { config } from '@/utils/config';
import styles from './Authentication.module.scss';
import { FacebookSVG } from './AuthSVG';

function FacebookAuth() {
    const dispatch = useDispatch();

    const componentClicked = (e) => {
        return null;
    };
    const responseFacebook = (response) => {
        if (response.accessToken) {
            dispatch(FbSignupSigninAction({ "token": response.accessToken }));
        }
        else {
            // TODO : all the error messages should come from a translation file
            // dispatch(errorNotificationActions("Error", "Something went wrong! Please try again  later."));
        }
    };

    const onFailureHandler = (error) => {
        // dispatch(errorNotificationActions("Error", "Something went wrong! Please try again  later."));
    }

    return (
        <FacebookLogin
            appId={config.fb_app_id}
            fields="name,email,picture"
            onClick={componentClicked}
            callback={responseFacebook}
            onFailure={onFailureHandler}
            version="2.6"
            disableMobileRedirect={true}
            render={(renderProps) => (
                <div
                    id="facebook"
                    onClick={renderProps.onClick}
                    className={styles.auth_login_options_item}
                >
                    <div className={styles.auth_login_options_item_inner}>
                        <FacebookSVG />
                        <span className={styles.auth_login_options_item__text}>Facebook</span>
                    </div>
                </div>
            )}
        />
    );
}

export default memo(FacebookAuth);
