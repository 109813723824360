import React, { memo, useCallback, useEffect, useState } from 'react';

//Import libraries
import { shallowEqual, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

//Import methods
import useDeviceDetect from '@/src/utils/customHooks/useDeviceDetect';
import { handleJSClick } from '../../utils/link';

//Import components
import Button from '../../components/Button';
import analyticsLabelProvider from '@/static/analyticsLabelProvider';
import { EAType, MediaSrcSetEnum } from '@/static/contants';
import { config } from '@/utils/config';
import useKsa from '@/utils/customHooks/useKsa';
import { ksaRegions } from '@/static/ksa';
import useResponsiveResource from '@/utils/customHooks/useResponsiveResource';

const useStyles = makeStyles({
    bgImage: {
        backgroundImage: props => `url(${props.backgroundImage[MediaSrcSetEnum.MOBILE]})`
    },
    [`@media ${MediaSrcSetEnum.TABLET}`]: {
        bgImage: {
            backgroundImage: props => `url(${props.backgroundImage[MediaSrcSetEnum.TABLET]})`
        }
    },
    [`@media ${MediaSrcSetEnum.DESKTOP}`]: {
        bgImage: {
            backgroundImage: props => `url(${props.backgroundImage[MediaSrcSetEnum.DESKTOP]})`
        }
    }
});

const Join = ({handleLogIn}) => {
    const getImageUrl = useCallback(resize => `${config.resizedImagesUrl}/images/assets/homepage/t/webp/lyp-desktop-2_${resize}.jpg`, []);
    const classes = useStyles({
        backgroundImage: {
            [MediaSrcSetEnum.MOBILE]: getImageUrl('720x233'),
            [MediaSrcSetEnum.TABLET]: getImageUrl('720x233'),
            [MediaSrcSetEnum.DESKTOP]: getImageUrl('1920x622')
        }
    });
    const [imgState, setImageState] = useState();
    //Store
    const { data: authState } = useSelector(state => state.authReducer, shallowEqual);

    const getKsaRegion = useKsa(ksaRegions);
    const ksaRegion = getKsaRegion();

    //Check Device
    const {isMobile} = useDeviceDetect();

    const withBgImage = fn => {
        if (ksaRegions.has(ksaRegion)) {
            return `url('${fn(ksaRegion)}')`;
        }
    };

    const commonStyles = {
        color: 'var(--color-white)',
        fontFamily: 'var(--primary-font)'
    };

    const style = useResponsiveResource(new Map([
        [`(max-width: 760px)`, () => ({
            hero: {
                backgroundImage: withBgImage(ksaRegion => `/static/home/ksa/${ksaRegion}/lyp-mobile.jpeg`),
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                filter: 'brightness(.9)'
            },
            title: {
                ...commonStyles,
                fontSize: 18,
                lineHeight: '120%',
                fontWeight: 800
            },
            subtitle: {
                ...commonStyles,
                fontSize: 14,
                fontWeight: 400,
                lineHeight: '150%',
                letterSpacing: 0.14
            }
        })],
        [`(min-width: 761px)`, () => ({
            hero: {
                backgroundImage: withBgImage(ksaRegion => `/static/home/ksa/${ksaRegion}/lyp.jpg`)
            },
            title: commonStyles,
            subtitle: commonStyles
        })],
    ]), [ksaRegion]);

    //Vars
    const title = isMobile ? 'Share Your Nest with Millions of Travelers' : 'Share Your Nest with Millions of Travelers';

    //Methods
    const handleButtonClick = () => {
            handleJSClick("/list-your-property")
    }

    useEffect(() => {
        setImageState(classes.bgImage);
    }, [classes.bgImage]);

    return (
        <section
            className="cn__hp__join__wrapper"
            data-cn-ga-observe={JSON.stringify({ el: analyticsLabelProvider.listing[EAType.SCROLL]() })}
        >
            <div
                className={clsx('cn__hp__join', imgState)}
                style={style?.hero}
            >
                <div className="cn__hp__join__info">
                    <h4
                        className="cn__hp__join__info__title"
                        style={style?.title}
                    >
                        {title}
                    </h4>
                    <p
                        className="cn__hp__join__info__subtitle"
                        style={style?.subtitle}
                    >
                        Listing is easy with CuddlyNest
                    </p>
                    <Button type="house" title="List your property" click={handleButtonClick}/>
                </div>
            </div>
        </section>
    )
}

export default memo(Join);