import { useRouter } from "next/router";
import { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { config } from '@/src/utils/config';
import styles from './MobileHeader.module.scss';
import Link from 'next/link';
import useHeader from "./useHeader";
import * as menuSymbols from './MobileMenuSymbols';
import { constants } from '@/src/utils/constants';


export const GeneralMobileSwitcherRoutes = () => {
    
    const [
        authState,
        lists,
        pathFirst,
    ] = useHeader({ isMobile: true });

    const router = useRouter();
    const {dashboardSwitcher} = useSelector(state=>state.dashboardRoutesReducer)
    const propertyCount = useSelector(state=>state.dashboardProfileReducer.profile?.payload?.data?.properties_count);
    const currentURL = `${config.current_url}`;
    
    if(!authState) {
        return <></>
    }

    if(dashboardSwitcher === 'guest') {
        return ( <>
                {lists && <li className={styles.menu_item}>
                    <Link href={constants.wishlist_route}>
                        <a>
                            <span className={styles.menu_item_symb}><menuSymbols.ListsSymb /></span>
                            <span className={styles.menu_item_text}>Lists</span>
                            <span className={styles.menu_item_arrow}><menuSymbols.ArrowSymb /></span>
                        </a>
                    </Link>
                </li>}
                <li className={styles.menu_item}>
                    <Link href={('/'+pathFirst === constants.list_your_property_route)
                        ? constants.list_space_route
                        : constants.list_your_property_route}>
                        <a>
                            <span className={styles.menu_item_symb}><menuSymbols.LypSymb /></span>
                            <span className={styles.menu_item_text}>List your property</span>
                            <span className={styles.menu_item_arrow}><menuSymbols.ArrowSymb /></span>
                        </a>
                    </Link>
                </li>
                <li className={styles.menu_item}>
                    <Link href={constants.dashboard_route}>
                        <a>
                            <span className={styles.menu_item_symb}><menuSymbols.MyAccountSymb /></span>
                            <span className={styles.menu_item_text}>My account</span>
                            <span className={styles.menu_item_arrow}><menuSymbols.ArrowSymb /></span>
                        </a>
                    </Link>
                </li>
                <li className={styles.menu_item}>
                    <Link href={constants.listing_reservation_route}>
                        <a>
                            <span className={styles.menu_item_symb}><menuSymbols.MyBookingsSymb /></span>
                            <span className={styles.menu_item_text}>My bookings</span>
                            <span className={styles.menu_item_arrow}><menuSymbols.ArrowSymb /></span>
                        </a>
                    </Link>
                </li>
                <li className={styles.menu_item}>
                    <Link href={constants.inbox_route}>
                        <a>
                            <span className={styles.menu_item_symb}><menuSymbols.InboxSymb /></span>
                            <span className={styles.menu_item_text}>Inbox</span>
                            <span className={styles.menu_item_arrow}><menuSymbols.ArrowSymb /></span>
                        </a>
                    </Link>
                </li>
            </>
        )
    } else {
        return (
            <>
                <li className={styles.menu_item}>
                    <Link href={propertyCount ? currentURL + constants.manage_listings_route : currentURL + constants.list_space_route}>
                        <a>
                            <span className={styles.menu_item_symb}><menuSymbols.LypSymb /></span>
                            <span className={styles.menu_item_text}>{Boolean(propertyCount) ? "Manage listings" : "List your property"}</span>
                            <span className={styles.menu_item_arrow}><menuSymbols.ArrowSymb /></span>
                        </a>
                    </Link>
                </li>
                <li className={styles.menu_item}>
                    <Link href={constants.dashboard_route}>
                        <a>
                            <span className={styles.menu_item_symb}><menuSymbols.MyAccountSymb /></span>
                            <span className={styles.menu_item_text}>My account</span>
                            <span className={styles.menu_item_arrow}><menuSymbols.ArrowSymb /></span>
                        </a>
                    </Link>
                </li>
                <li className={styles.menu_item}>
                    <Link href={constants.booking_request_route}>
                        <a>
                            <span className={styles.menu_item_symb}><menuSymbols.BookingRequest /></span>
                            <span className={styles.menu_item_text}>Booking Requests</span>
                            <span className={styles.menu_item_arrow}><menuSymbols.ArrowSymb /></span>
                        </a>
                    </Link>
                </li>

                <li className={styles.menu_item}>
                    <Link href={constants.earnings_route}>
                        <a>
                            <span className={styles.menu_item_symb}><menuSymbols.Earnings /></span>
                            <span className={styles.menu_item_text}>Earnings</span>
                            <span className={styles.menu_item_arrow}><menuSymbols.ArrowSymb /></span>
                        </a>
                    </Link>
                </li>

                <li className={styles.menu_item}>
                    <Link href={constants.host_inbox_route}>
                        <a>
                            <span className={styles.menu_item_symb}><menuSymbols.InboxSymb /></span>
                            <span className={styles.menu_item_text}>Inbox</span>
                            <span className={styles.menu_item_arrow}><menuSymbols.ArrowSymb /></span>
                        </a>
                    </Link>
                </li>
            </>
        )
    }

}

 