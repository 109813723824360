import React, { memo } from 'react';

//Import libraries
import Tooltip from '@material-ui/core/Tooltip';
import clsx from 'clsx';

//Import methods
import { handleJSClick } from '@/components/Slider/utils';
import { config } from '@/src/utils/config';

//Import component
import Image from '@/containers/newHomePage/components/Image';


const Card9 = ({data,linkType}) => {

    //Var
    const mainUrl = `${config.current_url}${data.url}`;

    //Amenities
    let checkAmenities = 5;
    let amenities = data.amenities?.map((el, i) => {
        if (i < checkAmenities - 1) {
            const { iconComponent: IconComponent, iconName, name } = el;
            return Boolean(IconComponent) && (
                <Tooltip key={i} title={name} placement='top' classes={{ popper: 'cn__amenity_icon-popover', tooltip: 'cn__amenity_icon-tooltip', arrow: 'cn__amenity_icon-arrow' }} arrow>
                <section className="cn__gp__icon">
                    <IconComponent
                    className={clsx(
                        'cn__gp__icon-svg',
                        iconName
                    )}
                    />
                </section>
                </Tooltip>
            );
        } else {
          return;
        }
    });
    //Rating stars
    let acommodationStars = [];
    if (data.rating) {
        for (let i = 0; i < data.rating; i++) {
        acommodationStars.push({});
        }
    }
    let stars;
    if (acommodationStars.length > 0) {
        stars = acommodationStars.map((el, i) => {
        return <img src="/static/icon/hotel-star-black.svg" loading='lazy' alt={el} key={i} />;
        });
    }

    //Render
    const childRender = (
        <>
            <div className='cn__card9__img'>
                <Image src={data.img} alt={data.title}/>
                <div className='cn__card9__img__info'>
                    <div className='cn__card9__img__info__sec1'>
                        {data.urating.rate > 3 && (
                            <>
                                <div className='cn__card9__img__info__sec1__grs'>{data.urating.rate}</div>
                                <div className="cn__card9__img__info__sec1__rateinfo">
                                    <p className="cn__gp__card__image__sec1__rateinfo__status">{data.urating.status}</p>
                                    <p className="cn__gp__card__image__sec1__rateinfo__rates">{ data.urating.amount > 0 && `${data.urating.amount} ratings`}</p>
                                </div>
                            </>
                        )}
                    </div>
                    <div className='cn__card9__img__info__sec2'>{amenities}</div>
                </div>
            </div>
            <div className="cn__card9__info">
                <p className="cn__card9__info__title">{data.title}</p>
                {data.rating !== 0 ? (
                    <div className="cn__card9__info__rating">{stars}</div>
                ) : (
                    <div className="cn__card9__info__rating" style={{ width: 13, height: 19 }} />
                )}
                {data.price && (
                    <p className="cn__card9__info__price">
                    from <span>{data.price}</span>/night
                    </p>
                )}
            </div>
        </>
    );

    const renderEl = linkType.type === 'a' ? (
        <div className='cn__card cn__card9' data-cn-ga-click={data.dataCnGaClick}>
            <a href={mainUrl} target={linkType.target}>
                {childRender}
            </a>
        </div>
    ) : (
        <div className='cn__card cn__card9' data-cn-ga-click={data.dataCnGaClick} onClick={() => handleJSClick(mainUrl,linkType.target)}>
            {childRender}
        </div>
    );


    return renderEl;
}

export default memo(Card9);
