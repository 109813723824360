import React, { memo, useState } from 'react';

//Import Libraries
import clsx from 'clsx';

//Import styles
import styles from '@/styles/components/AboutUs/Cards/__au--AUCard1.module.scss';
import useDeviceDetect from '@/src/utils/customHooks/useDeviceDetect';

const AUCard1 = ({data}) => {
    const { isMobile } = useDeviceDetect()
    const [textLength, setTextLength] = useState(120)
    const [expanded,setExpanded] = useState(false)
    const expandMore = () => {
        if(expanded){
            setExpanded(false)
            setTextLength(120)
        }else {
            setExpanded(true)
            setTextLength(data.subtitle.length)
        }
    }
    return (
        <div className={clsx('cn__card',styles.cn__au__card1, expanded && styles.cn__card_expanded)}>
            <div className={styles.cn__au__card1__icon}>
                {data.icon}
            </div>
            <p className={styles.cn__au__card1__title}>{data.title}</p>
            <p className={styles.cn__au__card1__subtitle}>{isMobile ? data.subtitle.slice(0,textLength): data.subtitle}</p><br/><br/>
            {isMobile && <div className={styles.cn__au__card1__button} onClick={expandMore}>{expanded ? 'Learn less!' : 'Learn more!'}</div>}
        </div>
    );
};

export default memo(AUCard1);