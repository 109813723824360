import LocationNew from "@/components/common/icons/LocationNew";
import { memo } from "react";
import { ActivityErrorTypes } from "../enum";
import InterestsPopover from "./InterestsPopover";
import useInterestsPopover from "./useInterestsPopover";

const UnavailabilityInterestsPopover = () => {
    const { isOpen, destinationLocation, anchorElementRef } = useInterestsPopover({
        anchorElementCssSelector: '.js-omnisearch_content_input-area_input-icon-location',
        validationErrorType: ActivityErrorTypes.ActivitesNotAvailable
    });

    return (
        <InterestsPopover
            isOpen={isOpen}
            anchorElementRef={anchorElementRef}
            offset={{
                y: -85,
                x: -17
            }}
            icon={<LocationNew />}
        >
            Sorry, activities are currently unavailable in {destinationLocation}.
        </InterestsPopover>
    );
};

export default memo(UnavailabilityInterestsPopover);
