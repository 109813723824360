import Cookies from "js-cookie";

export const SwitchToAppCookies = 'SwitchToAppCookies';

export const isSwitchToAppCookieSet = () => Boolean(
    Cookies.get(SwitchToAppCookies)
);

export const setSwitchToAppCookie = () => (
    !isSwitchToAppCookieSet() &&
    Cookies.set(SwitchToAppCookies, 1, {
        expires: 3,
        secure: true
    })
);
