import { useOmnisearchState } from "../contexts/OmnisearchState";
import GuestModel from "./GuestModel";

export default function useGuestPicker() {
    const { guests } = useOmnisearchState();

    const [state, setState] = guests;

    const onChildAgeChange = (childOrder, age) => {
        setState(state => {
            const guestModel = new GuestModel(state);

            guestModel.setChildAge(childOrder, age);

            return guestModel;
        });
    };

    return {
        state,
        onChildAgeChange
    };
};
