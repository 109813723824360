import useDeviceDetect from '@/src/utils/customHooks/useDeviceDetect';
import React, { memo } from 'react';

const InTheMedia = () => {
    const {isDesktop, isTablet} = useDeviceDetect();
    return (
        <section className="cn__hp__media">
            <div className="cn__hp__media__wrapper">
                <div className="cn__hp__media__wrapper__item">
                    <img src={`https://img.cuddlynest.com/assets/${isDesktop ? 'desktop' : isTablet ? 'tablet' : 'mobile'}/homepage/media/m1.png`} alt=""/>
                </div> 
                <div className="cn__hp__media__wrapper__item">
                    <img src={`https://img.cuddlynest.com/assets/${isDesktop ? 'desktop' : isTablet ? 'tablet' : 'mobile'}/homepage/media/m5.png`} alt=""/>
                </div>
                <div className="cn__hp__media__wrapper__item">
                    <img src={`https://img.cuddlynest.com/assets/${isDesktop ? 'desktop' : isTablet ? 'tablet' : 'mobile'}/homepage/media/m3.png`} alt=""/>
                </div>
                <div className="cn__hp__media__wrapper__item">
                    <img src={`https://img.cuddlynest.com/assets/${isDesktop ? 'desktop' : isTablet ? 'tablet' : 'mobile'}/homepage/media/m2.png`} alt=""/>
                </div>
                <div className="cn__hp__media__wrapper__item">
                    <img src={`https://img.cuddlynest.com/assets/${isDesktop ? 'desktop' : isTablet ? 'tablet' : 'mobile'}/homepage/media/m4.png`} alt=""/>
                </div>
                <div className="cn__hp__media__wrapper__item">
                    <img src={`https://img.cuddlynest.com/assets/${isDesktop ? 'desktop' : isTablet ? 'tablet' : 'mobile'}/homepage/media/m6.png`} alt=""/>
                </div>
            </div>
        </section>
    )
}

export default memo(InTheMedia);