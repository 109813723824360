import { forwardRef, Fragment } from "react";
import Renderer from "../Renderer";

const Conditional = ({
    when,
    then,
    otherwise,
    children,
    Layout = Fragment,
    ...restProps
}, ref) => {
    const cond = () =>
        typeof when === 'function'
            ? when()
            : when;

    return (
        <Layout
            {...restProps}
            ref={ref}
        >
            {cond()
                ? (
                    <Renderer>
                        {then}
                    </Renderer>
                )
                : (
                    <Renderer>
                        {otherwise || children}
                    </Renderer>
                )}
        </Layout>
    );
};

export default forwardRef(Conditional);
