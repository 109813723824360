import PropTypes from 'prop-types';

export const InputGroup = (props) => {
    const { className = 'cn-input-group', children } = props;
    return (
        <div className={className}>
            {children}
        </div>
    )
}

InputGroup.propTypes = {
    className: PropTypes.string
}