import React, { memo, useMemo } from 'react';
import { DestinationTypeEnum } from '@/static/contants';

const Icon = ({type}) => {
    const IconEntity = useMemo(() => {
        const defaultEntity = {
            iconName: 'location',
            alt: 'city'
        };

        return ({
            [DestinationTypeEnum.AIRPORT]: {
                iconName: 'airport'
            },
            [DestinationTypeEnum.HOTEL]: {
                iconName: 'hotel'
            },
            [DestinationTypeEnum.VR]: {
                iconName: 'home'
            },
            [DestinationTypeEnum.POI]: {
                iconName: 'landmark',
                alt: 'poi'
            }
        })[type] ?? defaultEntity
    }, [type]);

    return (
        <img
            src={`/static/staticpages/home/recentsearches/${IconEntity.iconName}.svg`}
            alt={IconEntity.alt ?? IconEntity.iconName}
        />
    );
}

export default memo(Icon);