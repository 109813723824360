import settings, { SettingsContext } from "./SettingsContext";

const SettingsProvider = ({ children }) => (
    <SettingsContext.Provider
        value={settings}
    >
        {children}
    </SettingsContext.Provider>
);

export default SettingsProvider;
