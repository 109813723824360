
const CheckSymb = () => (
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_b_2922_41039)">
            <ellipse cx="13.0178" cy="13" rx="12.6603" ry="13" fill="#F35597" />
        </g>
        <path d="M8.14844 14.125L11.5914 17L17.8872 10" stroke="white" strokeWidth="2.25" strokeLinecap="round" strokeLinejoin="round" />
        <defs>
            <filter id="filter0_b_2922_41039" x="-3.64258" y="-4" width="33.3203" height="34" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feGaussianBlur in="BackgroundImageFix" stdDeviation="2" />
                <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_2922_41039" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_2922_41039" result="shape" />
            </filter>
        </defs>
    </svg>
);

export default CheckSymb;
