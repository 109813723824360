import { memo } from "react";
import clsx from "clsx";

const MobileFooter = ({
    className,
    activeSlot,
    passiveSlot,
    onCancel
}) => (
    <footer
        className={clsx(
            'omnisearch_input-wrapper_mobile_screen_footer',
            className
        )}
    >
        {passiveSlot || (
            <button
                className="omnisearch_input-wrapper_mobile_screen_footer_clear-btn"
                type='button'
                onClick={onCancel}
            >
                Clear
            </button>
        )}
        {activeSlot}
    </footer>
);

export default memo(MobileFooter);
