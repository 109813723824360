import clsx from 'clsx';
import { useEffect, useRef } from 'react';
import styles from './EmailConfPopup.module.scss';

function EmailConfPopup({ isMobile, name, setShowConf }) {
    const boxRef = useRef(null);
    const stopClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };
    const handleClose = (e) => {
        setShowConf(false);
        e.preventDefault();
        e.stopPropagation();
    };
    useEffect(() => {
        if (boxRef.current) {
            const timeoutID = setTimeout(() => {
                boxRef.current.classList.add(styles.visible);
            }, 0);
            return () => {
                boxRef.current?.classList.remove(styles.visible);
                clearTimeout(timeoutID);
            };
        }
    }, []);
    return (
        <div className={clsx(styles.container, isMobile && styles.mobile)} ref={boxRef} onClick={stopClick}>
            <div className={clsx("triangle", styles.triangle)}></div>
            <div className={styles.close} onClick={handleClose}>
                <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.6665 7.6665L15.7982 15.7982" stroke="#242526" strokeWidth="2.25" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M15.7983 7.6665L7.66661 15.7982" stroke="#242526" strokeWidth="2.25" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
            </div>
            <div className={styles.header}>
                <div className={styles.header_symb}>
                    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="12" cy="12" r="12" fill="#6FCF97" />
                        <path d="M6.74963 12.054C6.28635 11.64 5.57516 11.6799 5.16115 12.1432C4.74714 12.6065 4.78708 13.3177 5.25037 13.7317L6.74963 12.054ZM10.596 17L9.84633 17.8389C10.0732 18.0416 10.3726 18.1438 10.6761 18.1221C10.9796 18.1005 11.2614 17.9568 11.4572 17.7238L10.596 17ZM19.8612 7.7238C20.261 7.24814 20.1994 6.53849 19.7238 6.13875C19.2481 5.73901 18.5385 5.80055 18.1388 6.2762L19.8612 7.7238ZM5.25037 13.7317L9.84633 17.8389L11.3456 16.1611L6.74963 12.054L5.25037 13.7317ZM11.4572 17.7238L19.8612 7.7238L18.1388 6.2762L9.73471 16.2762L11.4572 17.7238Z" fill="white" />
                    </svg>
                </div>
                <div className={styles.header_title}>Email verified!</div>
            </div>
            <div className={styles.body}>
                {name && <><span className={styles.body_name}>{name}</span> we’re so excited to have you join our community!</>}
                {!name && <>You can now log in and make full use of our ever-growing community.</>}
            </div>
        </div>
    );
}

export default EmailConfPopup;


/**
 *this is just for testing (to delete account) 
*/

/*
curl -X 'POST' \
'https://staging.cuddlynest.com/api/v1/account/delete' \
 -H 'accept: /' \
 -H 'Content-Type: application/json' \
 -H 'x-api-key: QfTjWnZr4u7x!A%D*G-KaNdRgUkXp2s5' \
 -H 'X-Authorization: Bearer ...' \
 -d '{
    'appleLogin': false
 }'
*/
