import React, { memo, useMemo } from 'react';
import pluralize from 'pluralize';
import CaretIcon from '@/components/common/icons/Caret';
import useDateFormatter from '../useDateFormatter';

const SlideChip = ({ location, startDate, endDate, totalGuests, rooms, onClick, icon: Icon }) => {
    const dateFormatter = useDateFormatter('DD MMM');

    const historySearch = useMemo(() => {
        return Boolean(startDate && endDate && totalGuests) && (
            <div className="omnisearch_content_action-area_initial-step_slide-chip_history-search">
                {startDate.isValid() && endDate.isValid()
                    ? `${dateFormatter(startDate)} - ${dateFormatter(endDate)} • `
                    : ''}{pluralize('guest', totalGuests || 1, true)}{(rooms > 1) ? ` • ${pluralize('room', rooms || 1, true)}` : ''}
            </div>
        );
    }, [startDate, endDate, totalGuests, dateFormatter]);

    return (
        <div
            className="omnisearch_content_action-area_initial-step_slide-chip"
            onClick={onClick}
        >
            <div className="omnisearch_content_action-area_initial-step_slide-chip_content">
                {Icon && (
                    <div className='omnisearch_content_action-area_initial-step_slide-chip_content_icon'>
                        {Icon}
                    </div>
                )}
                <div className="omnisearch_content_action-area_initial-step_slide-chip_content-location-wrapper">
                    <div className="omnisearch_content_action-area_initial-step_slide-chip_content-location">{location}</div>
                    {historySearch}
                </div>
            </div>
            <CaretIcon className="omnisearch_content_action-area_initial-step_slide-chip_content-icon" />
        </div>
    );
};

export default memo(SlideChip);
