import { Fragment } from "react";
import clsx from "clsx";
import pluralize from "pluralize";
import Box from "@mui/material/Box";

const Itinerary = ({ nights, guests, rooms }) => (
        <Box
            sx={{
                color: 'var(--color-dark-grey)',
                fontSize: 12,
                textAlign: 'right'
            }}
        >
            {[
                [nights, 'night', true, true],
                [guests, 'guest', true, rooms > 1 || false],
                [rooms, 'room', rooms > 1]
            ]
            .map(([count, word, isShown, isCommaShown], index, array) => isShown && (
                <Fragment
                    key={index}
                >
                    {pluralize(word, count, true)}{clsx(isCommaShown && ', ')}
                </Fragment>
            ))}
        </Box>
    );


export default Itinerary;
