import useKsa from '@/utils/customHooks/useKsa';
import Image from './Image';

const KsaImage = ({
    regionsMap = new Map(),
    srcRegionMap = new Map(),
    component: Component = Image,
    ...rest
}) => {
    const getKsaRegion = useKsa(regionsMap);

    return (
        <Component
            {...rest}
            src={srcRegionMap.get(getKsaRegion()) || rest.src}
        />
    );
};

export default KsaImage;
