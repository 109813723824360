import React, { memo } from 'react';

//Import methods
import useCurrencyConverter from '@/src/utils/customHooks/useCurrencyConverter';
import { handleJSClick } from '@/components/Slider/utils';

//Components
import Image from '@/containers/newHomePage/components/Image';


const Card1 = ({data,linkType}) => {

    //Price converter
    const convertTo = useCurrencyConverter();
    const price = typeof window === 'undefined' ? data.price : convertTo({
        amount: data.price,
    });
    const finalPrice = `${price.currency_symbol}${price.amount}`;
    const inventory = `${data.count} {acc_types}`;

    //Render
    const childRender = (
        <>
            <div className='cn__card1__img'>
                <Image src={data.img} alt={`${data.title} background image`}/>
                <p className='cn__card1__img__title'>
                    {data.title}
                </p>
            </div>
            <div className='cn__card1__info'>
                <p className='cn__card1__info__price'>
                    Starting from {finalPrice}
                </p>
                <p className='cn__card1__info__inventory'>
                    {inventory}
                </p>
            </div>
        </>
    );

    const renderEl = linkType.type === 'a' ? (
        <div className='cn__card cn__card1'>
            <a href={data.url} target={linkType.target}>
                {childRender}
            </a>
        </div>
    ) : (
        <div className='cn__card cn__card1' onClick={() => handleJSClick(data.url,linkType.target)}>
            {childRender}
        </div>
    );

    return renderEl;
};

export default memo(Card1);
