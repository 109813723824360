import { useCallback } from "react";

export default function useBlogArticles({
    baseUrl = 'https://www.cuddlynest.com/blog/wp-json/wp/v2/posts?categories[]=47',
    imagesOnly: imgOnly = true,
    // motherfucking fix for SEO & BE Maricons
    ignoreFeaturedMedia = [9962]
} = {}) {
    return useCallback(async ({
        endpoint = baseUrl,
        params = {},
        imagesOnly = imgOnly,
    } = {}) => {
        const endpointUrlInstance = new URL(endpoint);
        for (const [key, value] of Object.entries(params)) {
            endpointUrlInstance.searchParams.set(key, value);
        }

        try {
            const response = await fetch(endpointUrlInstance.href);
            const { headers } = response;
            const data = await response.json();
            const posts = {};

            if (Array.isArray(data)) {
                for await (const mediaResource of data.map(({ title, date, link, _links, id, featured_media: featuredMediaId }) => {
                    // suck my dick SEO & BE
                    if (!ignoreFeaturedMedia.includes(featuredMediaId)) {
                        const featuredMediaUrl = _links['wp:featuredmedia'];
                        posts[id] = {
                            id,
                            featuredMediaId,
                            title: title.rendered,
                            date: date,
                            url: link
                        };
                        if (featuredMediaUrl?.length > 0) {
                            return fetch(featuredMediaUrl[0].href);
                        }
                    }
                    return Promise.resolve(null);
                })) {
                    if (mediaResource?.ok) {
                        const data = await mediaResource.json();
                        if (data instanceof Object) {
                            const { post: postId, id, guid, media_details } = data;

                            const currentPost = Object.values(posts).find(({ featuredMediaId }) => (featuredMediaId === id));
                            const renderedImg = guid?.rendered;
                            if (currentPost) {
                                currentPost.img = renderedImg;
                                currentPost.desktopImage = media_details?.sizes?.medium_large?.source_url
                                currentPost.mobileImage = media_details?.sizes?.medium?.source_url
                                continue;
                            }else if ((postId != null) && posts[postId]) {
                                posts[postId].img = renderedImg;
                                continue;
                            }
                            delete posts[postId];
                        }
                    }
                }
            }
            return {
                totalPages: Number.parseFloat(headers.get('x-wp-totalpages')),
                total: Number.parseFloat(headers.get('x-wp-total')),
                posts: Object.values(posts)
                    .filter(post => imagesOnly ? post.img : true)
                    .reverse()
            };
        }catch (err) {
            // TODO: handle error
            console.error(err);
            throw err;
        }
    }, []);
}