import React, { memo } from 'react';

//Import Libraries
import clsx from 'clsx';

//Import styles
import styles from '@/styles/components/AboutUs/__au--AU5.module.scss';

const AUCard2 = ({data}) => {

    return (
        <div className={clsx('cn__aboutus__au5__slider__card',styles.cn__aboutus__au5__slider__card)}>
                <p className={styles.cn__aboutus__au5__slider__card__title}>{data.title}</p>
                <p className={styles.cn__aboutus__au5__slider__card__subtitle}>{data.subtitle}</p>
        </div>
    );
};

export default memo(AUCard2);