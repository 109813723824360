import React, { memo, useState, useCallback, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import CloseIcon from '@/components/common/icons/Close';

const FloatingInput = ({
    className,
    containerProps,
    inputRef,
    label,
    validatorFn,
    onFocus,
    onBlur,
    onClick,
    onChange,
    onClear,
    value,
    prependContent,
    appendContent,
    clearable,
    ...restProps
}) => {
    const [focus, setFocus] = useState(false);
    const [validationOutput, setValidationOutput] = useState({ isValid: true, payload: '' });

    const onChangeHandler = useCallback(e => {
        onChange?.(e);
    }, [onChange]);

    const onFocusHandler = useCallback(e => {
        setFocus(true);
        onFocus?.(e);
    }, [onFocus]);

    const onBlurHandler = useCallback(e => {
        setFocus(false);
        onBlur?.(e);
    }, [onBlur]);

    const onClearHandler = useCallback(() => {
        onChangeHandler('');
        onClear?.('');
    }, [onChangeHandler, onClear]);

    const isValidationRequired = useMemo(() => typeof validatorFn === 'function', [validatorFn]);

    useEffect(() => {
        if (isValidationRequired) {
            setValidationOutput(validatorFn(value));
        }
    }, [isValidationRequired, value]);

    return (
        <div
            {...containerProps}
            className={clsx(
                'floating-input', {
                'floating-input__with-appendContent': !!appendContent,
                'floating-input__with-prependContent': !!prependContent,
                'floating-input__with-validation': isValidationRequired,
            },
                className
            )}
            onClick={onClick}
        >
            {prependContent && prependContent}
            <input 
                test-id='hp_whereTo'
                ref={inputRef}
                className={clsx(
                    'floating-input_input',
                    focus && 'floating-input_input__focused',
                    value && 'floating-input_input__dirty'
                )}
                {...restProps}
                onFocus={onFocusHandler}
                onBlur={onBlurHandler}
                onChange={onChangeHandler}
                value={value}
            />
            {label && <label className="floating-input_label">{label}</label>}
            {isValidationRequired && <div className="floating-input_validation-payload">{validationOutput.payload}</div>}
            {Boolean(clearable && value) && (
                <button
                    className="floating-input_input_clear-btn"
                    onClick={onClearHandler}
                    type='button'
                >
                    <CloseIcon className="floating-input_input_clear-btn_icon" />
                </button>
            )}
            {appendContent && appendContent}
        </div>
    );
};

FloatingInput.defaultProps = {
    containerProps: {}
};

FloatingInput.propTypes = {
    label: PropTypes.string,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    className: PropTypes.string,
    validatorFn: PropTypes.func // value => ({ isValid: boolean, payload: string })
};

export default memo(FloatingInput);
