import PropTypes from 'prop-types';

export const InputLabelWrapper = ({children, labelText, errorLabel}) => {
    
    return (
        <div className="input-form">
            {children}
            <label>{labelText}</label>
            {(errorLabel)?(<span className="error-span">{errorLabel}</span>):null}
        </div>
    )
}

InputLabelWrapper.propTypes = {
    children: PropTypes.element.isRequired,
    labelText : PropTypes.element.isRequired
}