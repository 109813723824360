import { EntityRequestProcessingStatus } from "@/src/utils/entityRequestAdapter";
import { useRef, useState, useEffect } from "react";
import { useAttractionsNearby } from "./contexts/AttractionsNearby";
import { useOmnisearchState } from "./contexts/OmnisearchState";
import useInterests from "./Interests/useInterests";

export default function useInitOmnisearch(onInit, { withAttractionsNearby }) {
    const {
        step,
        destination,
        dates,
        guests
    } = useOmnisearchState();

    const { destinationProcessingStatus } = useInterests();

    const { fetchAtractionNearby } = useAttractionsNearby();

    const destinationServiceReadyRef = useRef(false);

    const handleOnInit = () => {
        onInit?.({
            setDestinationState: destination[1],
            setDatesState: dates[1],
            setGuestsState: guests[1],
            setStepState: step[1],
            fetchAtractionNearby
        });

        if (!onInit && withAttractionsNearby) {
            fetchAtractionNearby();
        }
    };

    const initHandlerRef = useRef(handleOnInit);

    const [, setUpdateOnResize] = useState();

    useEffect(() => {
        initHandlerRef.current = handleOnInit;
    });

    useEffect(() => {
        window.addEventListener('resize', setUpdateOnResize);
        return () => {
            window.removeEventListener('resize', setUpdateOnResize);
        };
    }, []);

    useEffect(() => {
        const isInterestsDestinationServiceReady = () => {
            const isReady = ![
                EntityRequestProcessingStatus.UNINITIALIZED,
                EntityRequestProcessingStatus.IN_PROGRESS
            ].includes(destinationProcessingStatus) &&
                !destinationServiceReadyRef.current;

            if (isReady) {
                destinationServiceReadyRef.current = true;
            }

            return isReady;
        };

        const isServicesReady = () =>
            [isInterestsDestinationServiceReady].every(service => service());

        if (isServicesReady()) {
            initHandlerRef.current();
        }
    }, [destinationProcessingStatus]);

    return {
        step,
        destination,
        dates,
        guests
    };
};
