export default class RecommendedEntity {
    action = '';
    values = [];
    model = null;
    onChange = null;

    constructor({ action, values, model, onChange }) {
        this.action = action;
        this.model = model;
        this.values = values;
        this.onChange = onChange;
    }

    getClearValue() {
        return this._getComputedValuesToClear(this.model, this.values);
    }

    getValue(v) {
        return v;
    }

    hasValue(value) {
        return this.values.includes(value);
    }

    isModelContainsValue(value) {
        const computedValue = this.getValue(value);
        const model = this.model;

        if (model instanceof Object) {
            return computedValue in model;
        }
        return model === computedValue;
    }

    _getComputedValuesToClear() {
        return Object.entries(this.model).reduce((acc, [key, value]) => {
            if (this.hasValue(value)) {
                return acc;
            }
            return {
                ...acc,
                [key]: value
            };
        }, {});
    }
}
