import React from 'react';

import styles from './Authentication.module.scss';
import { AuthExclamationSVG } from './AuthSVG';


const ErrorMsg = ({ children }) => {
    return (
        <div className={styles.auth_form_error}>
            <div className={styles.auth_form_error_inner}>
                <span className={styles.auth_form_error_svg}>
                    <AuthExclamationSVG />
                </span>
                <span className={styles.auth_form_error_text}>
                    {children}
                </span>
            </div>
        </div>
    );
};

export default ErrorMsg;
