import React, { memo } from 'react';

//Import methods
import useCurrencyConverter from '@/src/utils/customHooks/useCurrencyConverter';
import { handleJSClick } from '@/components/Slider/utils';

//Components
import Image from '@/containers/newHomePage/components/Image';
import { config } from '@/src/utils/config';


const Card2 = ({data,linkType}) => {

    //Price converter
    const convertTo = useCurrencyConverter();

    //Change Price
    const changePrice = (pricedata) => {
        let price = typeof window === 'undefined' ? pricedata : convertTo({
            amount: pricedata,
        });
        return `${price.currency_symbol}${price.amount}`;
    }

    const price = changePrice(data.price);
    const oldPrice = changePrice(data.oldprice);

    //Url
    const url = config.current_url + '/' + data.url;

    let acc;
    if(data.type === 'card2-1'){
        acc = <p>{data.acc.type}</p>;
        if(data.acc.type.toLowerCase() === 'hotel'){  
            let acommodationStars = [];
            for (let i = 0; i < data.acc.rating; i++) {
                acommodationStars.push({});
            }
            acc = (
                <>
                    <p>{data.acc.type}</p>
                    {acommodationStars.map((el,i) => (
                        <svg width="15" height="15" viewBox="0 0 15 15" fill="none" key={i}>
                            <path d="M6.83623 1.98196C7.04955 1.34502 7.95045 1.34502 8.16376 1.98196L9.0608 4.66044C9.15563 4.9436 9.41966 5.13543 9.71826 5.13812L12.5429 5.16355C13.2145 5.1696 13.4929 6.02641 12.9531 6.42611L10.6829 8.10694C10.4429 8.28463 10.342 8.59501 10.4318 8.87983L11.2804 11.574C11.4822 12.2147 10.7534 12.7443 10.2064 12.3543L7.90633 10.7147C7.66318 10.5413 7.33683 10.5413 7.09367 10.7147L4.79357 12.3543C4.24662 12.7443 3.51777 12.2147 3.71958 11.574L4.56824 8.87983C4.65795 8.59501 4.55711 8.28463 4.31711 8.10694L2.04691 6.42611C1.50707 6.02641 1.78546 5.1696 2.45714 5.16355L5.28174 5.13812C5.58034 5.13543 5.84437 4.94361 5.9392 4.66044L6.83623 1.98196Z" fill="#ACABB4" fillOpacity="0.7"/>
                        </svg>
                    ))}
                </>
            );
        }
    }

    //Render
    const childRender = (
        <>
           <div className='cn__card2__img'>
                <Image src={data.img} alt={`${data.title} background image`}/>
                <p className='cn__card2__img__discount'>
                    <span>
                        <svg width="17" height="16" viewBox="0 0 17 16" fill="none">
                            <path fillRule="evenodd" clipRule="evenodd" d="M13.8536 9.54027L9.61095 13.7829C9.22043 14.1734 8.58726 14.1734 8.19674 13.7829L3.36964 8.95581C3.20101 8.78719 3.0982 8.56385 3.07974 8.32609L2.85835 5.47376C2.83574 5.18247 2.94165 4.89586 3.14824 4.68927L4.75995 3.07757C4.96654 2.87097 5.25315 2.76506 5.54444 2.78767L8.39677 3.00906C8.63453 3.02752 8.85787 3.13033 9.02649 3.29896L13.8536 8.12606C14.2441 8.51658 14.2441 9.14975 13.8536 9.54027ZM6.7825 6.71186C6.39198 7.10239 5.75881 7.10239 5.36829 6.71186C4.97776 6.32134 4.97776 5.68817 5.36829 5.29765C5.75881 4.90713 6.39198 4.90713 6.7825 5.29765C7.17302 5.68817 7.17302 6.32134 6.7825 6.71186Z" fill="white"/>
                        </svg>
                    </span>
                    {`UP TO ${data.discount}% off`}
                </p>
                {data.type === 'card2-2' && (
                    <p className='cn__card2__img__dates'>{data.dates}</p>
                )}
            </div>
            <div className='cn__card2__info'>
                <p className='cn__card2__info__expires'>
                    {data.type === 'card2-1' && (
                        data.dates
                    )}
                    {data.type === 'card2-2' && (
                        `Expire in ${data.expires} days!`
                    )}
                </p>
                <p className='cn__card2__info__title'>
                    {data.title}
                </p>
                {data.type === 'card2-1' && (
                    <div className='cn__card2__info__type'>
                        {acc}
                    </div>
                )}
                {data.type === 'card2-1' && (
                    <div className='cn__card2__info__location'>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M12.3333 4C9.39259 4 7 6.39259 7 9.33333C7 10.2161 7.22072 11.0915 7.64031 11.8678L12.0417 19.8281C12.1003 19.9342 12.2119 20 12.3333 20C12.4547 20 12.5664 19.9342 12.625 19.8281L17.028 11.8652C17.4459 11.0915 17.6667 10.2161 17.6667 9.3333C17.6667 6.39259 15.2741 4 12.3333 4ZM12.3333 12C10.863 12 9.66668 10.8037 9.66668 9.33333C9.66668 7.86296 10.863 6.66668 12.3333 6.66668C13.8037 6.66668 15 7.86296 15 9.33333C15 10.8037 13.8037 12 12.3333 12Z" fill="#ACABB4"/>
                        </svg>
                        <p><span>{data.location.country}</span> |{data.location.city}</p>
                    </div>
                )}
                <div className='cn__card2__info__rest'>
                    <p className='cn__card2__info__rest__view'>
                        View deal
                        <span>
                            <svg width="6" height="11" viewBox="0 0 6 11">
                                <path d="M1.33398 8.76343L4.66732 5.24455L1.33398 1.72567" stroke="#555759" strokeLinecap="round" strokeLinejoin="round" fill='none'/>
                            </svg>
                        </span>
                    </p>
                    <div className='cn__card2__info__rest__prices'>
                        <p className='cn__card2__info__rest__prices__old'>{oldPrice}</p>
                        <p className='cn__card2__info__rest__prices__new'>{price}<span>/night</span></p>
                    </div>
                </div>
            </div>
        </>
    );

    const renderEl = linkType.type === 'a' ? (
        <div className='cn__card cn__card2'>
            <a href={url} target={linkType.target}>
                {childRender}
            </a>
        </div>
    ) : (
        <div className='cn__card cn__card2' onClick={() => handleJSClick(url,linkType.target)}>
            {childRender}
        </div>
    );

    return renderEl;
};

export default memo(Card2);
