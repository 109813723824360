import { useEffect } from "react";

export default function useResize(lambda) {
    useEffect(() => {
        window.addEventListener('resize', lambda);
        return () => {
            window.removeEventListener('resize', lambda);
        };
    }, [lambda]);
}
