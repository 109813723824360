import { constants } from "@/src/utils/constants";
import { schemaBreadcrumb, schemaContext } from "@/static/contants";
import Head from "next/head";
import React from 'react';
import Parser from 'html-react-parser';

const SeoMetaData = ({
    meta_data,
    schema_data,
    schema_breadcrumb,
    schema_context,
    unavailable_score
}) => (
    <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
        {
            unavailable_score < 99 ? (
                <meta name="robots" content="noindex, nofollow" />
            ) : (
                <meta name="robots" content={meta_data.robots || 'index, follow'} />
            )
        }
        {!!meta_data.title && (
            <title>{(meta_data && meta_data.title) || 'Cuddlynest'}</title>
        )}
        {!!meta_data.description && (
            <meta
                name="description"
                content={meta_data && meta_data.description || "Cuddlynest - Find hotels and homes for your stay"}
            />)
        }
        {
            !!meta_data.canonical && (
                <link rel="canonical" href={meta_data.canonical} />
            )
        }
        {
            !!meta_data.google && (
                <meta property="google" content={meta_data.google} />
            )
        }
        {
            meta_data.ogImage && (
                <meta property="og:image" content={meta_data.ogImage} />
            )
        }
        {
            meta_data.ogImageWidth && (
                <meta property="og:image:width" content={meta_data.ogImageWidth} />
            )
        }
        {
            meta_data.ogImageHeight && (
                <meta property="og:image:heigth" content={meta_data.ogImageHeight} />
            )
        }
        {
            meta_data.ogImageAlt && (
                <meta property="og:image:alt" content={meta_data.ogImageAlt} />
            )
        }
        {
            meta_data.ogType ? (
                <meta property="og:type" content={meta_data.ogType} />
            ) : (
                <meta property="og:type" content="website" />
            )
        }
        {
            meta_data.ogTitle && (
                <meta property="og:title" content={meta_data.ogTitle} />
            )
        }
        {
            meta_data.ogUrl && (
                <meta property="og:url" content={meta_data.ogUrl} />
            )
        }
        {
            meta_data.ogSiteName && (
                <meta property="og:site_name" content={meta_data.ogSiteName} />
            )
        }
        {
            meta_data.ogDescription && (
                <meta property="og:description" content={meta_data.ogDescription} />
            )
        }
        {
            meta_data.fbPages && (
                <meta property="fb:app_id" content={meta_data.fbPages} />
            )
        }
        {
            meta_data.fbAppId && (
                <meta property="fb:app_id" content={meta_data.fbAppId} />
            )
        }
        {
            meta_data.twitterCard && (
                <meta name="twitter:card" content={meta_data.twitterCard} />
            )
        }
        {
            meta_data.twitterSite && (
                <meta name="twitter:site" content={meta_data.twitterSite} />
            )
        }
        {
            meta_data.twitterCreator && (
                <meta name="twitter:creator" content={meta_data.twitterCreator} />
            )
        }
        {
            meta_data.twitterTitle && (
                <meta name="twitter:title" content={meta_data.twitterTitle} />
            )
        }
        {
            meta_data.twitterDescription && (
                <meta name="twitter:description" content={meta_data.twitterDescription} />
            )
        }
        {
            meta_data.twitterUrl && (
                <meta name="twitter:url" content={meta_data.twitterUrl} />
            )
        }
        {
            meta_data.twitterImageSrc & (
                <meta name="twitter:image:src" content={meta_data.twitterImageSrc} />
            )
        }
        {
            meta_data.twitterImage & (
                <meta name="twitter:image" content={meta_data.twitterImage} />
            )
        }
        <link rel="icon" href="/static/img/site_icon/cuddlynest.ico"></link>
        {
            schema_breadcrumb && (
                <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(schema_breadcrumb, null, 2) }} />
            )
        }
        {
            schema_context && (
                <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(schema_context, null, 2) }} />
            )
        }
        { (!!schema_data && typeof schema_data === 'string') && Parser(schema_data || '')}
    </Head>
);

SeoMetaData.defaultProps = {
    meta_data: {},
    schema_breadcrumb: schemaBreadcrumb,
    schema_context: schemaContext
};

export default SeoMetaData;