import { memo, useEffect } from "react";
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { range } from "@/utils/globalFunc";
import useGuestPicker from "./useGuestPicker";

const AgeInput = ({ index, onChange, value = '2' }) => {
    const handleChange = event => {
        onChange(index, event.target.value);
    };
    const { state } = useGuestPicker();

    useEffect(() => {
        if(!state.getChildAge(index)){
            onChange(index, 2);
        }
    },[])
    return (
        <FormControl
            fullWidth
            sx={{
                animation: 'appear .3s ease-in-out forwards',
                opacity: 0,
                '& fieldset.MuiOutlinedInput-notchedOutline': {
                    border: 'none'//'1px solid var(--color-subtle-grey)'
                },
                '& .Mui-focused': {
                    '& fieldset.MuiOutlinedInput-notchedOutline': {
                        border: 'none'//'1px solid var(--color-subtle-grey)'
                    }
                }
            }}
        >
            <InputLabel
                id="child-age-select-label"
                sx={{
                    display: 'grid',
                    gridTemplateColumns: '1fr auto',
                    alignItems: 'center',
                    top: '50%',
                    fontFamily: 'var(--primary-font)',
                    fontWeight: 600,
                    width: 'calc(100% - 47px)',
                    maxWidth: 'initial',
                    fontSize: '14px',
                    lineHeight: '150%',
                    transform: 'translate(14px, -50%) scale(1)',
                    zIndex: 0,
                    '&.Mui-focused': {
                        color: 'var(--color-black)',
                        transform: 'translate(14px, -50%) scale(1)'
                    }
                }}
            >
                <Box>Child {index + 1} Age</Box>
                <Box
                    sx={{
                        borderRadius: '50%',
                        backgroundColor: 'var(--color-shade-gray)',
                        width: '40px',
                        height: '40px',
                        color: 'var(--color-black)',
                        display: 'grid',
                        placeContent: 'center'
                    }}
                >
                    {value}
                </Box>
            </InputLabel>
            <Select
                labelId="child-age-select-label"
                id="child-age-select"
                value={value}
                // label="Age"
                onChange={handleChange}
                MenuProps={{
                    className: 'js-portal-popover',
                    PaperProps: {
                        sx: {
                            minWidth: '50px !important',
                            '& .MuiMenuItem-root': {
                                display: 'block',
                                textAlign: 'center',
                                fontFamily: 'var(--primary-font)',
                                fontWeight: 600,
                                '&.Mui-selected': {
                                    color: 'var(--color-cn-pink)'
                                }
                            }
                        }
                    }
                }}
                sx={{
                    border: `1px solid var(${
                        value
                            ? '--color-subtle-grey'
                            : '--color-error-red'
                    })`,
                    borderRadius: '15px',
                    py: 0,
                    '& .MuiSelect-select': {
                       color: 'transparent'
                    }
                }}
            >
                {range(2, 17).map(age => (
                    <MenuItem
                        key={age}
                        value={age}
                    >
                        {age}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default memo(AgeInput);
