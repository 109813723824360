import styles from './DotsAnimation.module.scss';
import clsx from "clsx";

const Dots = ({ container, one, two, three }) => {
    return (
        <div className={clsx(styles.dot_container, container)}>
            <div className={clsx(styles.dot_one, one)}></div>
            <div className={clsx(styles.dot_two, two)}></div>
            <div className={clsx(styles.dot_three, three)}></div>
        </div>
    );
}

export default Dots;
