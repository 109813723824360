import useDeviceDetect from "@/src/utils/customHooks/useDeviceDetect";
import DesktopHeader from "./DesktopHeader";
import MobileHeader from "./MobileHeader";


const Header = (props) => {
    const {isMobile, isTablet, isDesktop} = useDeviceDetect();

    return (
        <>
            {(isDesktop || isTablet) && <DesktopHeader {...props} isTablet={isTablet} />}
            {isMobile && <MobileHeader {...props} />}
        </>
    );
};

export default Header;
