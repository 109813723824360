import React, { memo } from 'react';
import { DateRangePicker } from './DateRange';
import { DestinationPicker } from './Destination';
import { GuestPicker } from './GuestCount';
import InitialStep from './InitialStep';
import StepEnum from './enum';
import { aggregateLocationByType, normalizeLocation } from '@/utils/locationNormalizers';
import DestinationModel from './Destination/DestinationModel';
import InterestsPicker from './Interests/InterestsPicker';

const ActionArea = ({
    destination,
    dates,
    guests,
    step,
    // withRecentSearches,
    // attractionsNearbyData
}) => {
    const [currentStep, setCurrentStep] = step;

    const [destinationState, setDestinationState] = destination;
    const [datesState, setDatesState] = dates;
    const [guestsState, setGuestsState] = guests;

    return !!currentStep && (
        <div className="omnisearch_content_action-area">
            {{
                [StepEnum.INITIAL]: (
                    <InitialStep
                        // withRecentSearches={withRecentSearches}
                        // attractionsNearbyData={attractionsNearbyData}
                    />
                ),
                [StepEnum.LOCATION]: (
                    <DestinationPicker
                        value={destinationState}
                        onChange={setDestinationState}
                        setStep={setCurrentStep}
                    />
                ),
                [StepEnum.DATES]: (
                    <DateRangePicker
                        value={datesState}
                        onChange={setDatesState}
                        setStep={setCurrentStep}
                    />
                ),
                [StepEnum.GUESTS]: (
                    <GuestPicker
                        value={guestsState}
                        onChange={setGuestsState}
                    />
                ),
                [StepEnum.INTERESTS]: (
                    <InterestsPicker />
                )
            }[currentStep]}
        </div>
    );
};

export default memo(ActionArea);
