import React, { memo,  useState, useMemo } from 'react';
import styles from './UserDropDown.module.scss';
import useEntity from "@/containers/Dashboard/hooks/useEntity";
import ProfileAvatar from '@/containers/Dashboard/dashboard-components/widgets/Avatar/ProfileAvatar';
import CalculateProgress from '@/containers/Dashboard/dashboard-components/widgets/Avatar/CalculateProgress';
import { PROFILE } from "@/redux/reducers/dashboardReducer";
import useAccountWidget from "@/containers/Dashboard/hooks/useAccountWidget";
import { useSelector, shallowEqual } from 'react-redux';
import SwitcherComponent from '@/containers/Dashboard/dashboard-components/SideNav/SwitcherComponent';
import { GeneralSwitcherRoutes } from './GeneralSwitcherRoutes';
import { Environments } from '@/static/contants';
import { useEnvironment } from '@/providers/Environment';


const UserDropDown = ({ onLogout }) => {
    const { data: authState } = useSelector(state => state.authReducer, shallowEqual);
    const cnEnvironment = useEnvironment();
    
    useAccountWidget();
    const { profile, isLoaded } = useEntity(PROFILE);
    
    if(!profile) {
        return <></>
    }
   

    return (
        <div className={styles.container}>

            <header className={styles.header}>
                <span className={styles.header__pic}>
                    <CalculateProgress
                        component={ProfileAvatar}
                        src={profile.image_path}
                        width={41}
                        height={41}
                        circleSize={49}
                    />
                </span>
                <span className={styles.header__name}>{(isLoaded ? (profile?.firstname || authState?.name) : '...')  }</span>
                <span className={styles.header__email}>{profile?.email || authState?.email || profile?.country_code + profile?.phone_no}</span>
                {authState?.email?.includes('privaterelay.appleid.com') &&
                    <div className={styles.tooltip_wrapper}>
                        <div className={styles.tooltip}>
                            <div className={styles.arrow_up}></div>
                            <div className={styles.tooltip__symbol}>
                                <TooltipSVG />
                            </div>
                            <span className={styles.tooltip__text}>
                                This will be your CuddlyNest display address,
                                and any emails from our team will be rerouted to your private address.
                            </span>
                        </div>
                    </div>}
            </header> 
            

            { <SwitcherComponent className="mt-14 mb-14 userDropDownSwitcher" noRedirect />}

            <GeneralSwitcherRoutes onLogout={onLogout}/>
            
        </div>
    );
};

export default memo(UserDropDown);



const TooltipSVG = () => {
    return (
        <>
            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.33337 7.66668C6.33337 7.48987 6.40361 7.3203 6.52864 7.19527C6.65366 7.07025 6.82323 7.00001 7.00004 7.00001C7.17685 7.00001 7.34642 7.07025 7.47145 7.19527C7.59647 7.3203 7.66671 7.48987 7.66671 7.66668V9.66668C7.66671 9.84349 7.59647 10.0131 7.47145 10.1381C7.34642 10.2631 7.17685 10.3333 7.00004 10.3333C6.82323 10.3333 6.65366 10.2631 6.52864 10.1381C6.40361 10.0131 6.33337 9.84349 6.33337 9.66668V7.66668ZM7.00004 4.00001C6.73482 4.00001 6.48047 4.10537 6.29293 4.2929C6.1054 4.48044 6.00004 4.73479 6.00004 5.00001C6.00004 5.26523 6.1054 5.51958 6.29293 5.70712C6.48047 5.89465 6.73482 6.00001 7.00004 6.00001C7.26526 6.00001 7.51961 5.89465 7.70715 5.70712C7.89468 5.51958 8.00004 5.26523 8.00004 5.00001C8.00004 4.73479 7.89468 4.48044 7.70715 4.2929C7.51961 4.10537 7.26526 4.00001 7.00004 4.00001ZM0.333374 7.00001C0.333374 5.2319 1.03575 3.53621 2.286 2.28596C3.53624 1.03572 5.23193 0.333344 7.00004 0.333344C8.76815 0.333344 10.4638 1.03572 11.7141 2.28596C12.9643 3.53621 13.6667 5.2319 13.6667 7.00001C13.6667 8.76812 12.9643 10.4638 11.7141 11.7141C10.4638 12.9643 8.76815 13.6667 7.00004 13.6667C5.23193 13.6667 3.53624 12.9643 2.286 11.7141C1.03575 10.4638 0.333374 8.76812 0.333374 7.00001ZM7.00004 1.66668C5.58555 1.66668 4.229 2.22858 3.2288 3.22877C2.22861 4.22897 1.66671 5.58552 1.66671 7.00001C1.66671 8.4145 2.22861 9.77105 3.2288 10.7712C4.229 11.7714 5.58555 12.3333 7.00004 12.3333C8.41453 12.3333 9.77108 11.7714 10.7713 10.7712C11.7715 9.77105 12.3334 8.4145 12.3334 7.00001C12.3334 5.58552 11.7715 4.22897 10.7713 3.22877C9.77108 2.22858 8.41453 1.66668 7.00004 1.66668Z" fill="#E86993" />
            </svg>
        </>
    );
};
