import React, { memo } from 'react';

const LYPCard1 = ({data}) => {
    return (
        <div className='cn__lyp__card1'>
            <p className='cn__lyp__card1__title'>{data.title}</p>
            <p className='cn__lyp__card1__subtitle'>{data.subtitle}</p>
        </div>
    );
};

export default memo(LYPCard1);