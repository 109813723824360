import { Modal } from "../common/modal"
import { ModalContainer } from "../common/modal/modalCotainer"
import { ModalBody } from "../common/modal/modalBody"
import { Fragment, useState, useEffect, useCallback } from "react"
import { Button } from "../formComponents/button"
import { Input } from "../formComponents/input"
import { _setFocus, _validateEmail, _validatePassword } from "@/src/utils/globalValidations"
import { inValidEmail, emptyEmail, passwordCheck } from "@/static/errorMessages"
import { InputGroup } from "../formComponents/inputGroup"
import { FormHelpertext } from "../formComponents/formHelperText"
import { signupAction } from "@/redux/actions/authActions"
import { connect } from "react-redux"
import {
    SIGNUP_SUCCESS,
    SIGNUP_LOAD,
    SIGNUP_FAIL,
    GOOGLE_SIGNUP_SIGNIN_SUCCESS,
    FB_SIGNUP_SIGNIN_SUCCESS,
    GOOGLE_SIGNUP_SIGNIN_FAIL,
    FB_SIGNUP_SIGNIN_FAIL
} from "@/redux/types"
import { errorNotificationActions, successNotificationActions } from "@/redux/actions/globalActions"
import FbSignupLogin from "./fbSignupLogin"
import { constants } from "@/src/utils/constants"
import GoogleSignupLogin from "./googleSignupLogin"
import { add_remove_class_to_body } from "@/src/utils/globalFunc"
import { scroll_none } from "@/src/utils/dynamicClasses"
import { useRouter } from "next/router"

const Signup = props => {
    const [ email, setEmail ] = useState()
    const [ password, setPassword ] = useState();
    const [isRememberMe, setRememberMe] = useState(false);
    const [ errEmail, setErrEmail ] = useState();
    const [ errPass, setErrPass ] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const router = useRouter()
    const _checkFormValidity = () => {
        let isValid = true;
        if (!!email) {
            if (_validateEmail(email)) {
                setErrEmail();
            }else{
                _setFocus('email');
                isValid = false;
                setErrEmail(inValidEmail);
            }
        }else{
            _setFocus('email');
            isValid = false;
            setErrEmail(emptyEmail);
        }
        if (_validatePassword(password)) {
            setErrPass();
        }else{
            _setFocus('password');
            isValid = false;
            setErrPass(passwordCheck);
        }
        return isValid;
    }
    const _formSubmit = e => {
        e.preventDefault();
        if (_checkFormValidity()) {
            setIsLoading(true);
            props.signupAction({ email, password, remember: Number(isRememberMe) })
        }else{
            setIsLoading(false);
            return false;
        }
    }
    useEffect(() => {
        if ([SIGNUP_SUCCESS, GOOGLE_SIGNUP_SIGNIN_SUCCESS, FB_SIGNUP_SIGNIN_SUCCESS].includes(props.auth?.type)) {
            props.toggle();
            setIsLoading(false);
            if(router?.asPath?.includes("redirect_url")) {
                const queryRedirect = router?.asPath?.replace('?redirect_url=', '');
                window.location.href = `${config.current_url}${queryRedirect}`
            }
            //props.successNotificationActions('Register', props.auth.message)
            // setTimeout(() => {
            //     add_remove_class_to_body(scroll_none,true)
            // },600);
        }

        if([SIGNUP_FAIL, GOOGLE_SIGNUP_SIGNIN_FAIL, FB_SIGNUP_SIGNIN_FAIL].includes(props.auth?.type))
        {
            setIsLoading(false);
            if(props.auth?.message?.indexOf('email-')>-1)
            {
                setErrEmail(props.auth.message.replace('email-',''));
                _setFocus('email');
            }
            else if(props.auth?.message?.indexOf('password-')>-1)
            {
                setErrPass(props.auth.message.replace('password-',''));
                _setFocus('password');
            }
            setIsLoading(false);
        }
    }, [props.auth])


    useEffect(()=>{
        if(errEmail)
        {
            setTimeout(()=>{
                setErrEmail(false)
            },4000)
        }

        if(errPass)
        {
            setTimeout(()=>{
                setErrPass(false)
            },4000)
        }

    },[errEmail, errPass])

    const enterPressHandler = (e) => {
        let key = e.keyCode || e.which;
        if(key===13)
        {
            _formSubmit(e)
        }
    }

    const rememberMeHandler = useCallback(() => {
        setRememberMe(state => !state);
    }, []);

    return (
        <Modal className="register-popup" toggle={props.toggle}>
            <ModalContainer>
                <ModalBody>
                    <Fragment>
                        <div className="register-heading">Register</div>
                        <FbSignupLogin type={constants.fb_google_signup_type} />
                        <GoogleSignupLogin type={constants.fb_google_signup_type} />
                        <div className="middle-border-text"><span>or, register with an email</span></div>
                        <InputGroup>
                            <Input
                                type="email"
                                className="primary-input"
                                value={email}
                                id="email"
                                onChange={e => setEmail(e.target.value)}
                                placeholder="Email address"
                                onKeyUp={enterPressHandler}
                            />
                            {
                                !!errEmail && (
                                    <FormHelpertext className="err">
                                        { errEmail }
                                    </FormHelpertext>
                                )
                            }
                        </InputGroup>
                        <InputGroup>
                            <Input
                                type="password"
                                className="primary-input"
                                value={password}
                                id="password"
                                onChange={e => setPassword(e.target.value)}
                                placeholder="Password"
                                onKeyUp={enterPressHandler}
                            />
                            {
                                !!errPass && (
                                    <FormHelpertext className="err">
                                        { errPass }
                                    </FormHelpertext>
                                )
                            }
                            <FormHelpertext className="err">{props.auth && props.auth.type && props.auth.type.includes('SIGNUP') && props.auth.message}</FormHelpertext>
                        </InputGroup>
                        <div className="remember-checkbox">
                            <label className="cn_slct_box">
                                <input type="checkbox" checked={isRememberMe} onChange={rememberMeHandler} />
                                <span className="label">Remember me</span>
                                <span className="cn_checkmark"></span>
                            </label>
                        </div>
                        <Button type="button" className="btn-primary"
                            onClick={_formSubmit}
                            disabled={!!props.auth && props.auth.type === SIGNUP_LOAD}
                            status={isLoading}
                        >
                            Register
                        </Button>
                        <div className="register-login" >
                            <a onClick={e => props.toggleScreen(1)} className="model-ref" href="javascript: void(0);" rel="#login-popup">Log in</a>
                        </div>
                        <div className="popup-mobile-back close" onClick={props.toggle}>
                            <img src="/static/img/left-arrow-black.svg" alt="left arrow" />
                            Back
                        </div>
                    </Fragment>
                </ModalBody>
            </ModalContainer>
        </Modal>
    )
}
const mapStateToProps = ({ authReducer }) => {
    return {
        auth: authReducer
    }
}
export default connect(mapStateToProps, { signupAction, successNotificationActions, errorNotificationActions })(Signup)