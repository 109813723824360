import { memo } from "react";
import Box from '@mui/material/Box';
import useInterests from "./useInterests";
import InterestCard from "./InterestCard";

const InterestTilesMobile = () => {
    const { data } = useInterests();

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                rowGap: '8px',
                mt: '16px',
                mb: '90px',
            }}
        >
            {data.map((interest, index) => (
                <InterestCard
                    key={String(index)}
                    imgSize={'mobile'}
                    // imgSize={'small'}
                    {...interest}
                    isMobile
                />
            ))}
        </Box>
    );
};

export default memo(InterestTilesMobile);
