import Box from "@mui/material/Box";
import { memo } from "react";
import InterestTiles from './InterestTiles';
import MotherfuckingButton from './MotherfuckingButton';

const InterestsPicker = () => (
    <Box
        sx={{
            width: '100%',
            padding: '12px 20px 8px 20px',
            boxShadow: '0 4px 20px rgba(0, 0, 0, .15)',
            borderRadius: '20px',
            position: 'relative',
            userSelect: 'none'
        }}
    >
        <Box
            sx={{
                ml: '6px',
                fontFamily: 'var(--primary-font)',
                fontWeight: 700,
                fontSize: '23px',
                color: 'var(--color-pure-black)'
            }}
        >
            Stay near interests
            <Box
                component='span'
                sx={{
                    color: 'var(--color-cn-pink)'
                }}
            >.</Box>
        </Box>
        <MotherfuckingButton />
        <InterestTiles />
    </Box>
);

export default memo(InterestsPicker);
