import React, { memo } from "react";

// Libraries
import Link from "next/link";
import SimpleBar from "simplebar-react";

// Import methods
import useDeviceDetect from "@/src/utils/customHooks/useDeviceDetect";
import { handleJSClick } from "../../utils/link";
import { transformData } from "../../utils/transformData";
import { ntData } from "./data";

// Components
import Heading from "../../components/Heading";
import Image from "../../components/Image";
import analyticsLabelProvider from "@/static/analyticsLabelProvider";
import { EAType } from "@/static/contants";
import General from "@/components/map/helpers/General";

// Local Component
const SliderSection = ({ data }) => {
  const { isDesktop, isTablet} = useDeviceDetect();
  return (
  <div className="cn__hp__nexttrip__slider__wrapper">
    {data.map((el, i) => (
      <div
        key={i}
        className="cn__hp__nexttrip__slider__card"
        data-cn-ga-click={JSON.stringify({
          el: analyticsLabelProvider.tripTypes[EAType.CLICK](el.gaLabel),
        })}
      >
        {/* the following link was producing a crash - switching to anchor element fixed the crash */}
        {/* <Link
          href={el.url.replace(
            "https://dev.cuddlynest.com",
            "http://localhost:3000"
          )}
        > */}
        <a href={el.url}>
          <Image src={isDesktop ? el.desktopSrc : isTablet ? el.tabletSrc : el.mobileSrc} alt={el.title} />
          <h3 className="cn__hp__nexttrip__slider__card__title">{el.title}</h3>
        </a>
        {/* </Link> */}
      </div>
    ))}
  </div>
)};

const NextTrip = ({ location }) => {
  // Check Device
  const { isDesktop } = useDeviceDetect();

  // Data
  const data = transformData(
    ntData,
    General.getGeolocationString([location.city, location.country], ", ")
  );

  return (
    <section
      className="cn__hp__nexttrip"
      data-cn-ga-observe={JSON.stringify({
        el: analyticsLabelProvider.tripTypes[EAType.SCROLL](),
      })}
    >
      <Heading
        align={isDesktop ? "center" : "left"}
        tit="Explore by Trip Type"
        sub="Your ideal stay is just a click away!"
      />
      <div className="cn__hp__nexttrip__slider">
        {isDesktop ? (
          <SliderSection data={data} />
        ) : (
          <SimpleBar>
            <SliderSection data={data} />
          </SimpleBar>
        )}
      </div>
    </section>
  );
};

export default memo(NextTrip);
