import { makeStyles } from "@material-ui/core/styles";

export const useLabelStyles = makeStyles(() => ({
    root: {
        userSelect: 'none',
        paddingLeft: 10,
        transform: 'none',
        '&.Mui-focused:not(.Mui-error)': {
            color: 'var(--color-dark-grey)'
        },
        '& + div': {
            marginTop: 22
        },
        // Material UI motherfuckers using !important
        '&[data-shrink=false] + .MuiInputBase-formControl .MuiInputBase-input::-webkit-input-placeholder': {
            opacity: '.42 !important'
        }
    }
}));

export const useInputStyles = makeStyles(() => ({
    root: {
        userSelect: 'none',
        borderRadius: 100,
        boxShadow: '0 5px 30px rgba(231, 228, 228, .5)',
        borderColor: 'var(--color-field-grey)',
        borderWidth: 1,
        borderStyle: 'solid',
        backgroundColor: 'var(--color-white)'
    },
    input: {
        borderRadius: 100,
        padding: '12.5px 15px',
        fontSize: 16,
        lineHeight: '150%',
        letterSpacing: '.01em',
        '& + div': {
            marginRight: 8
        }
    },
    underline: {
        '&:before': {
            border: 'none'
        },
        '&:after': {
            border: 'none'
        },
        '&:hover:not(.Mui-disabled):before': {
            border: 'none'
        }
    }
}));

export const useHelperTextStyles = makeStyles(() => ({
    root: {
        paddingLeft: 10
    }
}));

export const useRadioGroup = makeStyles(() => ({
    root: {
        display: 'flex',
        alignItems: 'center'
    },
    label: {
        userSelect: 'none',
        fontSize: 16,
        lineHeight: '150%',
        letterSpacing: '.01em',
        color: 'var(--color-dark-grey)'
    }
}));

export const useBoolean = makeStyles(() => ({
    root: {
        width: 'min-content'
    },
    input: {
        display: 'none',
        '&:checked + label': {
            color: 'var(--color-dark-grey)',
            backgroundColor: 'rgba(255, 255, 255, .3)',
            borderRadius: 5,
            '& > div:first-child': {
                backgroundColor: 'var(--color-vibrant-pink)'
            }
        }
    },
    label: {
        padding: 9,
        userSelect: 'none',
        display: 'grid',
        gridTemplateColumns: 'repeat(2, min-content)',
        alignItems: 'center',
        columnGap: 14,
        fontSize: 16,
        lineHeight: '150%',
        letterSpacing: '.01em',
        color: 'var(--color-subtle-grey)',
        cursor: 'pointer'
    },
    checkbox: {
        display: 'grid',
        placeContent: 'center',
        width: 16,
        height: 16,
        borderRadius: '50%',
        border: '1px solid #E0E0E0',
        backgroundColor: 'var(--color-white)',
        transition: '.3s'
    }
}));

export const useSubmitStyles = makeStyles(() => ({
    root: {
        '&.MuiButton-text': {
            // backgroundImage: 'linear-gradient(92.14deg, #F35597 1.79%, #F53959 99.81%)',
            backgroundImage: 'linear-gradient(92.37deg, #F35597 1.81%, #7868C7 148.68%)',
            borderRadius: 30,
            textTransform: 'initial',
            color: 'var(--color-white)',
            fontWeight: 600,
            fontSize: 16,
            lineHeight: '150%',
            paddingTop: 11,
            paddingBottom: 11,
            textTransform: 'none'
        },
        '&.Mui-disabled': {
            background: 'var(--color-field-grey)',
            color: 'var(--color-white)'
        }
    }
}));
