import React, { memo } from 'react';
import Link from 'next/link';

const LinkWrapper = ({ className, children, href }) => {
    const Tag = href.includes('/listings') ? 'span' : 'a';
    return (
        <Link href={href}>
            <Tag
                className={className}
                {...Tag === 'a' ? { target: '_blank' } : {}}
            >
                {children}
            </Tag>
        </Link>
    );
};

export default memo(LinkWrapper);
