import { useEffect, useRef, useState } from "react";
import clsx from "clsx";
import { useRouter } from "next/router";
import { Formik, Form, useField } from 'formik';
import * as Yup from 'yup';
import Portal from "@/components/common/Portal";
import { useOutsideClick } from '@/utils/customHooks/clickOutSide';
import styles from './PasswordReset.module.scss';
import TextInput from "./TextInput";
import { PasswordEyeSVG } from "../AuthSVG";
import { Submit } from "@/components/formComponents/MaterialFormInputs";
import Dots from "../DotsAnimation";
import { resetPasswordCheck, resetPassword } from '@/redux/services/authApi';
import PasswordResetSuccess from "./PasswordResetSuccess";
import TinyTooltip from "@/containers/Dashboard/dashboard-components/widgets/DashboardPropertyCard/TinyTooltip";

export default function PasswordReset({ tokenParam }) {
    const router = useRouter();
    const [token, setToken] = useState(null);
    const bodyRef = useRef(null);
    const rootEl = useRef(null);
    const modalRef = useRef(null);
    let closeTimerId = useRef(null);
    const [showPassword, setShowPassword] = useState({
        password: false,
        password2: false
    });
    const [passwordStatus, setPasswordStatus] = useState({
        length: false,
        case: false,
        symbol: false
    });
    const [isPasswordVerified, setPasswordVerified] = useState(false);
    const isOutside = useOutsideClick(modalRef);

    const updatePasswordStatus = password => {
        const status = { ...passwordStatus };
        status.length = password.length >= 8;
        status.case = /[A-Z]/.test(password);
        status.symbol = !/^[a-z]+$/i.test(password) && password.trim().length !== 0;

        if (status.length && status.case && status.symbol) {
            setTimeout(() => {
                setPasswordVerified(true);
            }, 500);
        } else {
            setPasswordVerified(false);
        }
        setPasswordStatus(status);
    }

    const resetSuccess = (router.query['reset-password-success'] == 1);

    const cleanUp = () => {
        // rootEl.current?.classList.add(styles.fadeout);
        bodyRef.current.classList.remove(styles.overflow_hidden);
        router.replace('/', undefined, { shallow: true });
    };
    const handleClose = () => {
        rootEl.current?.classList.add(styles.fadeout);
        closeTimerId.current = setTimeout(() => {
            cleanUp();
            clearTimeout(closeTimerId.current);
        }, 300);
    };

    const checkToken = async code => {
        try {
            const resp = await resetPasswordCheck({ code });
            if (resp?.statusCode == 200) {
                setToken(code);
            }
        } catch (err) {
            router.replace('/', undefined, { shallow: true });
            console.log(err);
        }
    };

    const onSubmit = (values, { setSubmitting }) => {
        const password = values['password'];

        resetPassword({
            code: token,
            password,
        })
            .finally(() => {
                setSubmitting(false);
            })
            .then(resp => {
                if (resp?.statusCode == 200) {
                    router.replace('/?reset-password-success=1', undefined, { shallow: true });
                }
            })
            .catch(err => {
                console.log(err);
                cleanUp();
            });
    };

    useEffect(() => {
        if (tokenParam && !token) {
            checkToken(tokenParam);
        }
    }, [tokenParam, token]);

    useEffect(() => {
        if (!rootEl.current) {
            rootEl.current = document.createElement('div');
        }
        bodyRef.current = document.querySelector("body");
    }, [token]);

    useEffect(() => {
        if (token && rootEl.current && bodyRef.current) {
            rootEl.current.classList.add(styles.wrapper);
            bodyRef.current.classList.add(styles.overflow_hidden);
        }
    }, [token]);

    useEffect(() => {
        if (isOutside) {
            handleClose();
        }
    }, [isOutside]);

    if ((!token && !resetSuccess) || !rootEl.current) {
        return null;
    }

    return (
        <Portal
            rootElement={rootEl.current}
            className={styles.inner}
        >
            <div
                className={clsx(styles.card, (resetSuccess && styles.reset_success))}
                ref={modalRef}
            >

                {resetSuccess && <PasswordResetSuccess onClose={cleanUp} />}

                {(!!token && !resetSuccess) &&
                    (<>
                        <div className={styles.card_close} onClick={handleClose} >
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M8 8L16.4853 16.4853" stroke="#242526" strokeWidth="2.25" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M16.4844 8L7.99909 16.4853" stroke="#242526" strokeWidth="2.25" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </div>
                        <div className={styles.card_title}>Reset Password</div>
                        <div className={styles.content}>
                            <Formik
                                initialValues={{
                                    password: '',
                                    password2: ''
                                }}
                                validationSchema={Yup.object({
                                    password: Yup.string()
                                        .min(8, 'Must be at least 8 characters long')
                                        .max(20, 'Must not exceed 30 characters')
                                        .required('Required')
                                        .test('case', 'Password should contain at least 1 upper case letter', value => /[A-Z]/.test(value))
                                        .test('symbol', 'Password should contain a number or symbol', value => !/^[a-z]+$/i.test(value) && value.trim().length !== 0),
                                    password2: Yup.string()
                                        .required('Required')
                                        .oneOf([Yup.ref('password'), null], 'Passwords do not match')
                                })}
                                onSubmit={onSubmit}
                                validateOnMount={true}
                            >
                                {({ isSubmitting, isValid }) => (<Form>
                                    <ul className={styles.card_form_ul}>
                                        <li className={styles.card_form_item}>
                                            <TinyTooltip
                                                parent={bodyRef.current}
                                                containerClassName={'tiny-tooltip-container-reset-password'}
                                                arrowSize={8}
                                                isHover={true}
                                                isOpen={(document.activeElement.name === 'password')}
                                                content={(
                                                    <>
                                                        <ul style={{
                                                            fontSize: '12px',
                                                            lineHeight: '18px',
                                                            listStyleType: 'disc'
                                                        }}>
                                                            <li style={passwordStatus.length ? { color: "#2B8A4B" } : null}>At least 8 characters</li>
                                                            <li style={passwordStatus.case ? { color: "#2B8A4B" } : null}>At least 1 upper case letter (A-Z)</li>
                                                            <li style={passwordStatus.symbol ? { color: "#2B8A4B" } : null}>Contains a number or symbol (1,2, !?$…)</li>
                                                        </ul>
                                                    </>
                                                )}
                                            >
                                                <TextInput
                                                    label={'New password'}
                                                    name={'password'}
                                                    type={showPassword.password ? 'text' : 'password'}
                                                    placeholder={'Enter new password'}
                                                    handleChange={updatePasswordStatus}
                                                    handleBlur={updatePasswordStatus}
                                                />
                                            </TinyTooltip>
                                            <span
                                                className={styles.card_form_item__symbol}
                                                onClick={() => setShowPassword(currState => ({
                                                    ...currState,
                                                    password: !currState.password
                                                }))}
                                            >
                                                <PasswordEyeSVG show={showPassword.password} />
                                            </span>
                                        </li>
                                        <li className={styles.card_form_item}>
                                            <TextInput
                                                label={'Confirm password'}
                                                name={'password2'}
                                                type={showPassword.password2 ? 'text' : 'password'}
                                                placeholder={'Confirm new password'}
                                            />
                                            <span
                                                className={styles.card_form_item__symbol}
                                                onClick={() => setShowPassword(currState => ({
                                                    ...currState,
                                                    password2: !currState.password2
                                                }))}
                                            >
                                                <PasswordEyeSVG show={showPassword.password2} />
                                            </span>
                                        </li>
                                        <li className={styles.card_form_submit_wrapper}>
                                            <Submit
                                                type="submit"
                                                className={styles.card_form_submit}
                                                disabled={!isValid}
                                            >
                                                {isSubmitting
                                                    ? <Dots container={styles.loading} />
                                                    : 'Change Password'}
                                            </Submit>
                                        </li>
                                    </ul>
                                </Form>)}
                            </Formik>
                        </div>
                    </>)
                }
            </div>
        </Portal>
    );
}
