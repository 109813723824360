
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import { Button } from "../formComponents/button"
import { useDispatch } from 'react-redux'
import { FbSignupSigninAction } from '@/redux/actions/authActions';
import { errorNotificationActions } from '@/redux/actions/globalActions';
import {config} from '@/utils/config';
import { constants } from '@/src/utils/constants';

export default function FbSignupLogin (props){


    const dispatch = useDispatch();

    const componentClicked = (e) => {

    }
    const responseFacebook = (response) => {
        if(response.accessToken)
        {
            dispatch(FbSignupSigninAction({"token":response.accessToken}))
        }
        else
        {
            // TODO : all the error messages should come from a translation file
            // dispatch(errorNotificationActions("Error", "Something went wrong! Please try again  later."));
        }
    }

    const onFailureHandler = (error) => {
        // dispatch(errorNotificationActions("Error", "Something went wrong! Please try again  later."));
    }

    return (<FacebookLogin
    appId={config.fb_app_id}
    fields="name,email,picture"
    onClick={componentClicked}
    callback={responseFacebook}
    onFailure={onFailureHandler}
    version="2.6"
    disableMobileRedirect={true}
    cssClass="fb-btn"
    render = {(renderProps) => (
            <Button type="button" onClick={renderProps.onClick} className="fb-btn">

                    <span>
                        <img src="/static/img/icon-fb.svg" alt="icon fb" />
                    </span>
                    {(props.type===constants.fb_google_signup_type)?'Register with Facebook':'Sign in with Facebook'}

            </Button>
    )}
    />)
}