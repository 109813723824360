import { isValidElement } from "react";
import { isFunction } from "formik";

const Renderer = ({ children }) => {
    if (!children) {
        return null;
    }

    if (isFunction(children)) {
        return children();
    }

    const ComponentOrSlot = children;

    return (
        isValidElement(ComponentOrSlot)
            ? ComponentOrSlot
            : <ComponentOrSlot />
    );
};

export default Renderer;
