import { memo, useEffect, useState, useRef } from "react";
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import Box from "@mui/material/Box";
import Cookies from "js-cookie";

export const defaultCookieSettings = {
    key: '',
    value: 1,
    options: {
        expires: 7,
        secure: true
    }
};

const Cta = ({
    closeRef,
    TooltipProps,
    displayTimeout,
    closingTimeout,
    cookieSettings,
    headerSlot,
    subtitleSlot,
    iconSlot,
    actionSlot,
    onClick,
    children
}) => {
    const { key, value, options } = {
        ...defaultCookieSettings,
        ...cookieSettings
    };

    const [isOpen, setOpenState] = useState(false);

    const closeHandlerRef = useRef(() => {
        setOpenState(false);

        Cookies.set(key, value, options);
    });

    const handleActionClick = (...arg) => {
        onClick(...arg);
        closeHandlerRef.current();
    };

    useEffect(() => {
        closeRef.current = closeHandlerRef.current;
        const isCookieSet = Boolean(Cookies.get(key));

        if (isCookieSet) {
            return;
        }

        let closeTimerId;

        const showPopup = setInterval(() => {
            if(Cookies.get('cn-login-popup') === '1'){
                closeTimerId = setTimeout(() => {
                    setOpenState(true)
                }, displayTimeout);
                clearInterval(showPopup)
            }
        }, closingTimeout);
         return () => {
                clearInterval(showPopup);
                clearTimeout(closeTimerId)
            };
    }, [key]);

    const renderActionSlot = slot => {
        if (typeof slot === 'string') {
            return (
                <Button
                    onClick={handleActionClick}
                    variant='contained'
                    sx={{
                        backgroundImage: 'linear-gradient(92.37deg,#f35597 1.81%,#7868c7 148.68%)',
                        borderRadius: '100px',
                        boxShadow: 'none',
                        fontFamily: 'var(--primary-font)',
                        textTransform: 'none',
                        ':hover': {
                            boxShadow: 'none'
                        }
                    }}
                >
                    {slot}
                </Button>
            );
        }

        return slot;
    };

    return (
        <Tooltip
            onClose={closeHandlerRef.current}
            open={isOpen}
            PopperProps={{
                sx: {
                    transform: 'translate3d(0px, 107.2px, 0px) !important',
                    inset: '0 0 auto auto !important',
                    '&[data-popper-placement*="bottom"] .MuiTooltip-arrow': {
                        top: '5px'
                    },
                    '&[data-popper-placement*="top"] .MuiTooltip-arrow': {
                        // bottom: '5px',
                        bottom: '0 !important',
                        top: '5px !important'
                    },
                    '& .MuiTooltip-tooltip': {
                        bgcolor: 'transparent'
                    },
                    '& .MuiTooltip-arrow': {
                        color: 'var(--color-white)',
                        fontSize: '24px',
                        // marginTop: '-12px !important',
                        transform: 'translate3d(251.2px, 0px, 0px) !important'
                    }
                },
                popperOptions: {
                    placement: 'bottom-end'
                },
                disablePortal: true
            }}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            arrow
            title={
                <Box
                    sx={{
                        bgcolor: 'var(--color-white)',
                        color: 'var(--color-black)',
                        borderRadius: '15px',
                        maxWidth: '295px',
                        width: '100%',
                        p: '15px 30px 13px',
                        boxShadow: `0px 8px 28px -6px rgba(24, 39, 75, 0.12),
                                    0px 18px 88px -4px rgba(24, 39, 75, 0.14)`
                    }}
                >
                    <IconButton
                        onClick={closeHandlerRef.current}
                        sx={{
                            position: 'absolute',
                            right: '10px',
                            top: '5px'
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <Box
                        sx={{
                            display: 'grid',
                            gridTemplateColumns: 'repeat(2, auto)',
                            gap: '10px',
                            alignItems: 'center'
                        }}
                    >
                        <Box>{iconSlot}</Box>
                        <Box
                            sx={{
                                fontFamily: 'var(--primary-font)',
                                fontWeight: 600,
                                fontSize: '18px',
                                lineHeight: '120%',
                                color: 'var(--color-black)',
                                textAlign: 'center'
                            }}
                        >
                            {headerSlot}
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            mt: '10px',
                            fontSize: '14px',
                            lineHeight: '150%',
                            textAlign: 'center',
                            letterSpacing: '.01em',
                            color: 'var(--color-dark-grey)'
                        }}
                    >
                        {subtitleSlot}
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            mt: '10px'
                        }}
                    >
                        {renderActionSlot(actionSlot)}
                    </Box>
                </Box >
            }
            {...TooltipProps}
        >
            {children}
        </Tooltip >
    );
};

Cta.defaultProps = {
    TooltipProps: {},
    cookieSettings: {},
    displayTimeout: 3000,
    closingTimeout: 500,
    closeRef: {
        current: null
    }
};

export default memo(Cta);
