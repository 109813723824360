import { useCallback } from "react";

export default function useGenerateLink() {
    return useCallback(({
        baseUrl = '',
        queryParams = []
    }) => {
        const url = new URL(baseUrl);
        queryParams.forEach(([key, value, strategy = 'append']) => {
            url.searchParams[strategy](key, value);
        });

        return url.href;
    }, []);
}
