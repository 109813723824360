import { useCallback, useRef } from "react";
import debounce from 'lodash.debounce';
import DestinationModel from "./DestinationModel";
import useApi from "../useApi";
import StepEnum from '../enum';
import useInterests from "../Interests/useInterests";

export default function useDestinationIput({ onDestinationInputChange, setStep }) {
    const inputRef = useRef(null);
    const { getSuggestions } = useApi();
    const { fetchActivitiesFromUserLocation, isDataAvailable } = useInterests();

    const getSuggestionHandler = useCallback(debounce(getSuggestions, 500, { trailing: true }), []);

    const onChangeHandler = useCallback(e => {
        if (e instanceof Object) {
            const { value: inputValue } = e.target;
            setStep(StepEnum.LOCATION);
            onDestinationInputChange(state => new DestinationModel({
                ...state,
                inputValue,
                isLoading: true
            }));
            getSuggestionHandler(inputValue);
        }
    }, [onDestinationInputChange, getSuggestionHandler, setStep]);

    const onClear = () => {
        onDestinationInputChange(new DestinationModel);

        if (!isDataAvailable()) {
            fetchActivitiesFromUserLocation(/*() => Promise.resolve({
                data: {
                    result: store.getState().currentLoc.data
                }
            })*/);
        }
    };

    // useEffect(() => {
    //     setTimeout(() => {
    //         inputRef.current?.focus();
    //     }, 400);
    // }, []);

    return {
        inputRef,
        onChange: onChangeHandler,
        onClear
    };
}
