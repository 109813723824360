import Popover from "@/components/common/Popover";
import { memo } from "react";

const InfoPopover = ({
    anchorElementRef,
    className,
    offset,
    children
}) => (typeof window !== 'undefined') && (
    <Popover
        className={className}
        anchorElementRef={anchorElementRef}
        appendToElement={document.body}
        offset={offset}
    >
        {children}
    </Popover>
);

export default memo(InfoPopover);
