import { useCallback, useRef, useEffect } from "react";
import retryHelper from './retryHelper';

export default function useRetry({
    retryCount,
    retryTimeout,
    ref: rootRef,
    isRetry,
    fallbackSrc,
    image: {
        width,
        height,
        src,
        onLoad,
        onError
    }
}) {
    const imageRef = useRef();
    const timeoutRef = useRef();
    const retryCountRef = useRef(retryCount);

    const callbackRef = useCallback(el => {
        imageRef.current = el;
        if (rootRef) {
            rootRef.current = el;
        }
    }, [rootRef?.current]);

    const setSrc = useCallback(src => {
        const { current: imgHtmlElem } = imageRef;
        if (imgHtmlElem) {
            imgHtmlElem.src = src;
        }
    }, []);

    const onImageLoadError = useCallback(err => {
        setSrc(fallbackSrc);
        onError?.(err);
    }, [
        setSrc,
        onError,
        fallbackSrc
    ]);

    const onImageLoadSuccess = useCallback(ev => {
        onLoad?.(ev);
    }, [onLoad]);

    const onErrorHandler = useCallback(() => {
        if (isRetry) {
            retryHelper({
                timeout: retryTimeout,
                counterRef: retryCountRef,
                timerRef: timeoutRef,
                image: {
                    width,
                    height,
                    src,
                    onLoad: ev => {
                        const { target } = ev;
                        setSrc(target.src);
                        onImageLoadSuccess(ev);
                    },
                    onError: onImageLoadError
                }
            });
            return;
        }

        onImageLoadError();
    }, [
        onImageLoadSuccess,
        onImageLoadError,
        setSrc,
        isRetry,
        width,
        height,
        src,
        retryTimeout
    ]);

    /** On component will unmount clearTimeout */
    useEffect(() => () => clearTimeout(timeoutRef.current), []);

    return {
        ref: callbackRef,
        onLoad: onImageLoadSuccess,
        onError: onErrorHandler
    };
}
