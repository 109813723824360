import clsx from "clsx";
import { useEffect, useCallback, useState, useRef } from "react";
// import { shallowEqual, useSelector } from "react-redux";
import useCompleteBooking from "@/utils/customHooks/useCompleteBooking";
import { useDates } from "@/containers/searchResults/hooks";
export class PDFilterSchema {

    static checkin = 'checkin';
    static checkout = 'checkout';
    static rooms = 'rooms';
    static adults = 'adults';
    static children = 'children';
    static infants = 'infants';
    static childrenAges = 'childrenAges';

    constructor(dateFormater) {
        this[PDFilterSchema.checkout] = this[PDFilterSchema.checkin] = dateFormater;
    }
}

export default function usePDCommonState(initialFilters, onFilterChange) {
    // const currencies = useSelector(state => state.currencies, shallowEqual);
    const getDates = useDates();

    const setFiltersState = useCallback(initialFilters => {
        const { checkin, checkout } = initialFilters;
        const { startDate, endDate } = getDates({ checkin, checkout, isVR: false });

        return {
            [PDFilterSchema.checkin]: startDate,//initialFilters.checkin ? moment(initialFilters.checkin) : moment(),
            [PDFilterSchema.checkout]: endDate,//initialFilters.checkout ? moment(initialFilters.checkout) : moment().add(1, 'day'),
            [PDFilterSchema.rooms]: initialFilters.rooms || 1,
            [PDFilterSchema.adults]: initialFilters.adults || 1,
            [PDFilterSchema.children]: initialFilters.children || 0,
            // multiChildren: initialFilters.multichildren || [],
            [PDFilterSchema.infants]: initialFilters.infants || 0,
            ['currency']: initialFilters.currency || 'USD',
            ['childrenAges'] : initialFilters.childrenAges || '',
        };
    }, [getDates]);

    const setSectionMarginCss = useCallback((title, menuItems, separatorClass = 'listings-section-separator') => {
        const menuItem = menuItems.find(menuItem => menuItem.title === title);

        return clsx(separatorClass, menuItem?.isNoMargin && `${separatorClass}--no-margin`);
    }, []);

    const overviewRenderThreshold = useRef(3);
    const [overviewTileCount, setOverviewTileCount] = useState(0);

    const [filter, setFilter] = useState(() => setFiltersState(initialFilters));

    // const currency = useMemo(() => currencies?.currency.short, [currencies?.currency.short]);

    const { setData } = useCompleteBooking();

    /**
     * Events
     */
    // useEffect(() => {
    //     setFilter(setFiltersState(initialFilters));
    // }, [initialFilters]);

    useEffect(() => {
        onFilterChange({ ...filter });
        setData(filter);
    }, [filter, setData]);

    return {
        filter, setFilter,
        setSectionMarginCss,
        overviewTileCount, setOverviewTileCount,
        overviewRenderThreshold: overviewRenderThreshold.current
    };
}
