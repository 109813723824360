import Cta from "@/components/CTA/Cta";
import { memo } from "react";
import { useSettings } from "../contexts/Settings";

const InterestsCta = ({ children, onClick, closeCtaRef }) => {
    const { ctaCookieName } = useSettings();

    return (
        <Cta
            closeRef={closeCtaRef}
            headerSlot='Base your search on your interests'
            iconSlot={(
                <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="18" cy="18" r="18" fill="#F35597" fillOpacity="0.11" />
                    <path d="M25.3752 14.5211C25.3752 14.5211 25.4663 18.0536 23.3437 20.1762C21.2211 22.2988 17.6886 22.2077 17.6886 22.2077C17.6886 22.2077 17.5974 18.6752 19.72 16.5526C21.8426 14.4299 25.3752 14.5211 25.3752 14.5211Z" fill="#F35597" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M15.4453 15.366C15.8847 12.8762 17.6864 11 17.6864 11C17.6864 11 19.4936 12.882 19.9295 15.3774C19.6522 15.5872 19.3847 15.8268 19.135 16.1006C18.4445 16.8578 17.9922 17.7643 17.695 18.6349C17.3977 17.7643 16.9455 16.8578 16.2549 16.1006C16.0007 15.8218 15.728 15.5785 15.4453 15.366Z" fill="#F35597" />
                    <path d="M10.0008 14.5211C10.0008 14.5211 9.90969 18.0536 12.0323 20.1762C14.1549 22.2988 17.6874 22.2077 17.6874 22.2077C17.6874 22.2077 17.7786 18.6752 15.656 16.5526C13.5334 14.4299 10.0008 14.5211 10.0008 14.5211Z" fill="#F35597" />
                    <path d="M10 22.7578H25.3755C25.3755 23.9962 24.3716 25.0001 23.1332 25.0001H12.2423C11.0039 25.0001 10 23.9962 10 22.7578Z" fill="#F35597" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M15.6224 12.892C14.7289 12.0774 13.9223 11.6387 13.9223 11.6387C13.9223 11.6387 13.2431 12.7491 12.8828 14.2705C13.512 14.4459 14.1936 14.703 14.8474 15.0812C15.0109 14.2685 15.3018 13.5265 15.6224 12.892ZM20.5421 15.1617C21.2959 14.7031 22.0946 14.4113 22.8129 14.2245C22.45 12.7268 21.7844 11.6387 21.7844 11.6387C21.7844 11.6387 20.8358 12.1547 19.8565 13.1063C20.145 13.7131 20.3992 14.4078 20.5421 15.1617Z" fill="#F35597" />
                </svg>
            )}
            actionSlot='Try me'
            subtitleSlot='Now you can find stays based on your interests!'
            cookieSettings={{
                key: ctaCookieName
            }}
            closingTimeout={100000}
            displayTimeout={100}
            onClick={onClick}
        >
            {children}
        </Cta>
    );
};

export default memo(InterestsCta);
