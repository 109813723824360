import React, { memo, useRef } from 'react';

//Import Methods
import useDeviceDetect from '@/src/utils/customHooks/useDeviceDetect';

//Import local data
import { trendingDataFn } from './data';

//Import components
import Heading from '../../components/Heading';
import Slider from '@/components/Slider';
import analyticsLabelProvider from '@/static/analyticsLabelProvider';
import { EAType } from '@/static/contants';


const Trending = () => {

    //data
    let data = trendingDataFn();

    // Detection
    const { isDesktop } = useDeviceDetect();

    //Refs
    const pcountriesRef = useRef(null);


    const dataTt = {
        data: data,
        count: 5,
        card: 4,
        cardWidth: true,
        cardMargin: 20,
        showBar: false,
        cardLink: {
            type:'a',
            target:'_self'
        }
    }

    return (
        <section
            className="cn__hp__trending"
            ref={pcountriesRef}
            data-cn-ga-observe={JSON.stringify({ el: analyticsLabelProvider.trending[EAType.SCROLL]() })}
        >
            <Heading align="left" tit="Trending Destinations" sub="Places that travelers are adding to their bucket lists"/>
            <Slider {...dataTt}/>
        </section>
    )
}

export default memo(Trending);
