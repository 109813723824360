export default class InterestsModel {
    displayName = '';
    subCategories = [];

    constructor({
        display_name,
        sub_category_data
    }) {
        this.displayName = display_name;
        this.subCategories = sub_category_data.map(subCategory => subCategory.display_name);
    }
}
