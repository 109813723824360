
const LogoAuth = ({state}) => {
    return (
        <svg  test-id='hp_profileIcon' width="44" height="34" viewBox="0 0 44 34" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="44" height="34" rx="15" fill="white" />
            <path fillRule="evenodd" clipRule="evenodd" d="M34 17C34 23.6274 28.6274 29 22 29C15.3726 29 10 23.6274 10 17C10 10.3726 15.3726 5 22 5C28.6274 5 34 10.3726 34 17ZM25 12.5C25 14.433 23.6569 16 22 16C20.3431 16 19 14.433 19 12.5C19 10.567 20.3431 9 22 9C23.6569 9 25 10.567 25 12.5ZM16.9709 19.9343C16.3497 20.3178 16 21.0044 16 21.7344C16 22.9857 17.0143 24 18.2656 24H25.7344C26.9857 24 28 22.9857 28 21.7344C28 21.0044 27.6503 20.3178 27.0291 19.9343C25.7955 19.1727 23.6311 18 22 18C20.3689 18 18.2045 19.1727 16.9709 19.9343Z" fill="black" />
            {state && (
                <>
                    <circle cx="22" cy="17" r="12.5" stroke="url(#paint0_linear)" className="logged-circle"/>
                    <defs>
                        <linearGradient id="paint0_linear" x1="1.3871" y1="2.57894" x2="25.7572" y2="3.49037" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#F35597"/>
                            <stop offset="1" stopColor="#F53959"/>
                        </linearGradient>
                    </defs>
                </>
            )}
        </svg>
    );
};

export default LogoAuth;
