import React, { memo, useEffect } from 'react';
import Skeleton from 'react-loading-skeleton';
import SimpleBar from 'simplebar-react';
import Highlighter from "react-highlight-words";
import { useSelector } from 'react-redux';
import DestinationModel from './DestinationModel';
import clsx from 'clsx';
import useApi from '../useApi';
import Icon from '@/components/common/AutosuggestIcon';
import { aggregateLocationByType, normalizeLocation } from '@/utils/locationNormalizers';
import useDestinationTransition from '../useDestinationTransition';
import OmniAside from '@/components/common/OmniAside/OmniAside';
import { DestinationTypeEnum } from '@/static/contants';

const DestinationPicker = ({ value, onChange, searchActionAreaSlot }) => {
    const { getSuggestions } = useApi();
    const handleNextStepTransition = useDestinationTransition(DestinationPicker);

    const suggestionResult = useSelector(state => state.geo_location);

    const onSelectSuggestion = suggestion => async () => {
        const destinationModel = new DestinationModel({
            ...value,
            ...suggestion,
            inputValue: aggregateLocationByType({
                type: suggestion.type,
                action: normalizeLocation
            })(suggestion.searchTerm)
        });

        handleNextStepTransition(destinationModel);

        getSuggestions(suggestion.name);
    };

    const suggestionRenderer = ({ id, isLoading, name, address, type, grs, property_count, starRating, grs_adjective, ...rest }, index) =>
        isLoading ? (
            <div
                key={String(index)}
                className={clsx(
                    'omnisearch_content_action-area_destination-picker_search-suggestion',
                    'omnisearch_content_action-area_destination-picker_search-suggestion--loading'
                )}
            >
                <Skeleton circle height={40} width={40} />
                <Skeleton height={57} />
            </div>
        ) : (
            <div
                key={String(index)}
                className='omnisearch_content_action-area_destination-picker_search-suggestion'
                onClick={onSelectSuggestion(new DestinationModel({ id, name, address, type, ...rest }))}
            >
                <Icon
                    className="somnisearch_content_action-area_destination-picker_search-suggestion_icon"
                    type={Boolean(name !== address) ? type : DestinationTypeEnum.COUNTRY}
                />
                <div
                    className={clsx(
                        'omnisearch_content_action-area_destination-picker_search-suggestion_desc', {
                        'omnisearch_content_action-area_destination-picker_search-suggestion_desc--single': !address
                    })}
                >
                    <Highlighter
                        test-id='firstResult'
                        highlightClassName="omnisearch_content_action-area_destination-picker_search-suggestion_highlight"
                        autoEscape
                        searchWords={value.inputValue.split(',')}
                        textToHighlight={name}

                    />
                    {Boolean(address) && Boolean(name !== address) &&
                        <div className="omnisearch_content_action-area_destination-picker_search-suggestion_secondary">
                            {address}
                        </div>
                    }
                    {Boolean(property_count) &&
                        <div className={clsx('omnisearch_content_action-area_destination-picker_search-suggestion_secondary_total_count',!!!address && 'no_adddres_sugesstion' )}>
                            {property_count} properties
                        </div>
                    }
                </div>
                <OmniAside
                    type={Boolean(name !== address) ? type : DestinationTypeEnum.COUNTRY}
                    stars={starRating}
                    grs={grs}
                    grs_adjective={grs_adjective}
                />
                {/* {window.innerWidth <= MOBILE_THRESHOLD && <CaretIcon />} */}
            </div >
        );

    useEffect(() => {
        if (['GEO_CODE_SUCCESS', 'GEO_CODE_ERROR'].includes(suggestionResult.type) && value.isLoading) {
            onChange(state => new DestinationModel({
                ...state,
                isLoading: false
            }));
        }
    }, [value.isLoading, suggestionResult.type]);

    useEffect(() => {
        const wrapper = document.querySelector('.simplebar-wrapper .simplebar-content-wrapper');
        if (wrapper) wrapper.scrollTop = 0;
    }, [suggestionResult.data]);

    if (!Array.isArray(suggestionResult.data)) {
        return null;
    }

    const isLoading = value.isLoading || ('GEO_CODE_LOAD' === suggestionResult.type);

    return (
        <>
            <SimpleBar
                className={clsx(
                    'omnisearch_content_action-area_destination-picker', {
                    'omnisearch_content_action-area_destination-picker--loading': isLoading
                })}
                forceVisible="y"
            >
                {(isLoading ? Array(10).fill({ isLoading: true }) : suggestionResult.data).map(suggestionRenderer)}
            </SimpleBar>
            {!!value.inputValue && ('GEO_CODE_SUCCESS' === suggestionResult.type) && searchActionAreaSlot}
        </>
    );
};

export default memo(DestinationPicker);
