
const LypSymb = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M14.3333 17C14.3333 15.4499 14.3333 15.7019 14.3333 14.9293C14.3333 14.3771 13.8856 13.9286 13.3333 13.9286H10.6667C10.1144 13.9286 9.66667 14.3763 9.66667 14.9286V19C9.66667 19.5523 9.21895 20 8.66667 20H6C5.44771 20 5 19.5523 5 19V10.1574C5 9.58837 5.24236 9.04631 5.66641 8.6669L10.6664 4.19321C11.4257 3.51385 12.5743 3.51386 13.3336 4.19321L18.3336 8.6669C18.7576 9.04631 19 9.58837 19 10.1574V13" stroke="#242526" strokeWidth="2"/>
            <path d="M19 19V22.5V15.5V19ZM19 19H15.5H22.5H19Z" stroke="#242526" strokeWidth="2"/>
        </svg>
    );
};
export default LypSymb;
