import React, { memo } from 'react';
import PropTypes from 'prop-types';

const HouseOutlined = ({ className }) => (
    <svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path d="M6 9.40328V17C6 17.5523 6.44772 18 7 18H9C9.55228 18 10 17.5523 10 17V13.6429C10 13.0906 10.4477 12.6429 11 12.6429H13C13.5523 12.6429 14 13.0906 14 13.6429V17C14 17.5523 14.4477 18 15 18H17C17.5523 18 18 17.5523 18 17V9.40328C18 8.84432 17.7661 8.31087 17.355 7.93219L13.355 4.24798C12.5893 3.54277 11.4107 3.54277 10.645 4.24798L6.64505 7.93219C6.23391 8.31087 6 8.84432 6 9.40328Z" stroke="#797882" strokeWidth="2" />
    </svg>
);

HouseOutlined.propTypes = {
    className: PropTypes.string
};

export default memo(HouseOutlined);
