import Conditional from "@/components/Conditional";
import UndefinedPrice from "./UndefinedPrice";
import PriceBreakdown from "./PriceBreakdown";
import PricePlaceholder from "./PricePlaceholder";

const ListingPrice = ({
    originalPriceUSD,
    priceUSD,
    currency,
    dataSource
}) => (
    <Conditional
        when={!priceUSD?.amount}
        then={<PricePlaceholder />}
        // then={(
        //     <UndefinedPrice
        //         priceUSD={priceUSD}
        //         dataSource={dataSource}
        //     />
        // )}
        otherwise={(
            <PriceBreakdown
                currency={currency}
                originalPriceUSD={originalPriceUSD}
                priceUSD={priceUSD}
            />
        )}
    />
);

export default ListingPrice;
