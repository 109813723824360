import moment from 'moment';

export const _getStartOf = (date, scope = 'day') => {
    return _momentUtc(date).startOf(scope);
}

export const _getEndOf = (date, scope = 'day') => {
    return _momentUtc(date).startOf(scope);
}

export const _getDiffDate = (from, to = new Date(), scope = "days") => {
    return _getStartOf(_formatDate(from)).diff(_getStartOf(_formatDate(to)), scope)
}

export const _addDate = (date, no = 1, scope = 'days') => {
    return _getStartOf(date).add(no, scope)
}

export const _subDate = (date, no = 1, scope = 'days') => {
    return _getStartOf(date).subtract(no, scope)
}
export const _momentUtc = (date = new Date()) => {
    return moment.utc(date);
}

export const _momentDate = (date = new Date()) => {
    return moment(date);
}

export const _formatDate = (date = new Date(), format = "YYYY-MM-DD") => {
    return _momentDate(date).format(format)
}

export const _displayDate = (date = new Date(), format = "YYYY-MM-DD") => {
    return _momentUtc(date).format(format)
}

export const _toDate = (date = new Date()) => {
    return _momentUtc(date).toDate()
}

export const calcWeeksInMonth = (date) => {
    const dateFirst = moment(date).date(1);
    const dateLast = moment(date).date(date.daysInMonth());
    const startWeek = dateFirst.week();
    const endWeek = dateLast.week();
    if (endWeek < startWeek) {
      return dateFirst.weeksInYear() - startWeek + 1 + endWeek;
    } else {
      return endWeek - startWeek + 1;
    }
  }

/** Array<{ startDate: Moment, endDate: Moment }> */
export const areDatesEqual = (...args) => {
    if (args.length < 2) {
      return true;
    }

    // Allocate dates by category
    const categorizedDatesByKeyMap = args.reduce((categoriesMap, dateEntity) => {
      for (const categoryProperty of Object.keys(dateEntity)) {
        const dateOfCategory = dateEntity[categoryProperty];
        if (categoriesMap.has(categoryProperty)) {
          categoriesMap.get(categoryProperty).push(dateOfCategory);
          continue;
        }
        categoriesMap.set(categoryProperty, [dateOfCategory]);
      }
      return categoriesMap;
    }, new Map);

    // Compare dates
    for (const [, dates] of categorizedDatesByKeyMap) {
      if (dates.some(date =>
          dates[0].startOf('day').valueOf() !== date.startOf('day').valueOf())) {
        return false;
      }
    }

    return true;
};

export const getDuration = ({ startDate, endDate }) =>
  moment
    .duration(
      moment(endDate)
        .startOf('day')
        .diff(moment(startDate).startOf('day'))
    )
    .asDays();
