import React, { memo } from 'react';

//Import methods
import useDeviceDetect from "@/src/utils/customHooks/useDeviceDetect";

//Import components
import CurrencyMobile from './components/CurrencyMobile';
import CurrencyDesktop from './components/CurrencyDesktop';


const Currency = ({info, theme}) => {

    //Check device
    const { isMobile } = useDeviceDetect();

    if(!info.data){
        return null;
    }

    const renderEl = isMobile ? <CurrencyMobile info={info}/> : <CurrencyDesktop info={info} theme={theme} />;

    return renderEl;

};

export default memo(Currency);

export {
    default as CurrencyProvider,
    useCurrencyContext
} from './CurrencyProvider';
