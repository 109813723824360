import React, { memo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import FloatingInput from '@/components/formComponents/FloatingInput';
import LocationOutlinedIcon from '@/components/common/icons/LocationOutlined';
import LabelledInputWrapper from '../LabelledInputWrapper';
import InputSlot from '../InputSlot';
import StepEnum from '../enum';
import useDestinationIput from './useDestinationInput';
import LocationNew from '@/components/common/icons/LocationNew';

const DestinationInput = ({ onChange, value, isActive, setCurrentStep }) => {
    const suggestionResult = useSelector(state => state.geo_location);
    const { onChange: onChangeHandler, inputRef, onClear } = useDestinationIput({ onDestinationInputChange: onChange, setStep: setCurrentStep });

    const isEmpty = value.inputValue.length <= 0;

    const onActivateStep = useCallback(() => {
        setCurrentStep(() => {
            if (Array.isArray(suggestionResult.data) && suggestionResult.data.length > 0) {
                return StepEnum.LOCATION;
            }
            return StepEnum.INITIAL;
        });
    }, [setCurrentStep, suggestionResult.data]);

    // useEffect(() => {
    //     if (isEmpty) {
    //         onActivateStep();
    //     }
    // }, [
    //     onActivateStep,
    //     suggestionResult.data?.length,
    //     isEmpty
    // ]);

    return (
        <InputSlot
            className="omnisearch_content_input-area_destination-input"
            isActive={isActive}
            prependIcon={(
                <LocationNew
                    className="omnisearch_content_input-area_input-icon js-omnisearch_content_input-area_input-icon-location"
                />
            )}
            onClear={onClear}
            onChangeStep={onActivateStep}
            isEmpty={isEmpty}
        >
            <LabelledInputWrapper
                label='Destination'
            >
                <FloatingInput
                    placeholder="Where to?"
                    onChange={onChangeHandler}
                    value={value.inputValue}
                    inputRef={inputRef}
                />
            </LabelledInputWrapper>
        </InputSlot>
    );
};

export default memo(DestinationInput);
