import { useCallback, useEffect, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import currencyRateConverter from '@/utils/currencyRateConverter';
import { getCurrenciesActions } from "@/redux/actions/globalActions";
import { formatNumber } from "../globalFunc";

export default function useCurrencyConverter() {
    const dispatch = useDispatch();
    const { currency, data } = useSelector(state => state.currencies);

    const isCurrenciesAvailable = useMemo(() =>
        [data?.top_currencies, data?.normal_currencies].every(cur => Array.isArray(cur) && (cur.length > 0))
    , [data]);

    useEffect(() => {
        if (!isCurrenciesAvailable) {
            dispatch(getCurrenciesActions());
        }
    }, [dispatch, isCurrenciesAvailable]);

    return useCallback((
        {
            amount = 0,
            currency_symbol: currencySymbol = currency.symbol,
            rate = 1 /* current rate of USD */,
            strategy = ''
        } = {}
    ) => {
        let amountValue = Math.round(currencyRateConverter({
            amount,
            rate
        }, currency));

        if (strategy) {
            const amountLength = String(amountValue).length;
            const coefficient = Number.parseInt([1, ...Array(amountLength - 1).fill(0)].join(''));
            amountValue = Math[strategy](amountValue / coefficient) * coefficient;
        }

        return {
            amountNum: amountValue,
            amount: formatNumber(amountValue),
            currency_symbol: currencySymbol,
            get currencyFormattedAmount() {
                return `${currencySymbol}${formatNumber(amountValue)}`;
            }
        };
    }, [currency]);
}