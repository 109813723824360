
const BlogSymb = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="6" y="5" width="12" height="14" rx="2" className="rect1" stroke="#242526" strokeWidth="2" />
            <path d="M10 10L14 10" className="blog1" stroke="#242526" strokeWidth="2" strokeLinecap="round" />
            <path d="M10 14H12" className="blog2" stroke="#242526" strokeWidth="2" strokeLinecap="round" />
        </svg>
    );
};

export default BlogSymb;
