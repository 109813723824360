import React, { memo } from 'react';

const BedOutlined = ({ className }) => (
    <svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none">
        <rect x="4" y="13" width="16" height="4" rx="2" stroke="#797882" strokeWidth="2" />
        <mask id="bed-outlined-mask" fill="white">
            <path fillRule="evenodd" clipRule="evenodd" d="M5 9C5 7.89543 5.89543 7 7 7H17C18.1046 7 19 7.89543 19 9V11H17.25V10C17.25 9.44772 16.8023 9 16.25 9H13.875C13.3227 9 12.875 9.44772 12.875 10V11H11.125V10C11.125 9.44772 10.6773 9 10.125 9H7.75C7.19772 9 6.75 9.44772 6.75 10V11H5V9Z" />
        </mask>
        <path fillRule="evenodd" clipRule="evenodd" d="M5 9C5 7.89543 5.89543 7 7 7H17C18.1046 7 19 7.89543 19 9V11H17.25V10C17.25 9.44772 16.8023 9 16.25 9H13.875C13.3227 9 12.875 9.44772 12.875 10V11H11.125V10C11.125 9.44772 10.6773 9 10.125 9H7.75C7.19772 9 6.75 9.44772 6.75 10V11H5V9Z" fill="#797882" />
        <path d="M19 11V12H20V11H19ZM17.25 11H16.25V12H17.25V11ZM12.875 11V12H13.875V11H12.875ZM11.125 11H10.125V12H11.125V11ZM6.75 11V12H7.75V11H6.75ZM5 11H4V12H5V11ZM7 6C5.34315 6 4 7.34315 4 9H6C6 8.44772 6.44772 8 7 8V6ZM17 6H7V8H17V6ZM20 9C20 7.34315 18.6569 6 17 6V8C17.5523 8 18 8.44772 18 9H20ZM20 11V9H18V11H20ZM17.25 12H19V10H17.25V12ZM16.25 10V11H18.25V10H16.25ZM16.25 10H18.25C18.25 8.89543 17.3546 8 16.25 8V10ZM13.875 10H16.25V8H13.875V10ZM13.875 10V8C12.7704 8 11.875 8.89543 11.875 10H13.875ZM13.875 11V10H11.875V11H13.875ZM11.125 12H12.875V10H11.125V12ZM10.125 10V11H12.125V10H10.125ZM10.125 10H12.125C12.125 8.89543 11.2296 8 10.125 8V10ZM7.75 10H10.125V8H7.75V10ZM7.75 10V8C6.64543 8 5.75 8.89543 5.75 10H7.75ZM7.75 11V10H5.75V11H7.75ZM5 12H6.75V10H5V12ZM4 9V11H6V9H4Z" fill="#797882" mask="url(#bed-outlined-mask)" />
        <rect x="3" y="14" width="2" height="6" rx="1" fill="#797882" />
        <rect x="19" y="14" width="2" height="6" rx="1" fill="#797882" />
    </svg>
);

export default memo(BedOutlined);
