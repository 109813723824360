import { memo } from 'react';
import { Box, ClickAwayListener, Fade, Popper } from "@mui/material";

import LosNotificationContent from './LosNotificationContent';

const LosNotification = ({ text, offset = [0, 0], ...popperConfig }) => {
    return (
        <Popper
            {...popperConfig}
            sx={{
                zIndex: 99,
            }}
            modifiers={[
                {
                    name: 'offset',
                    options: {
                        offset: offset,
                    },
                },
            ]}
            transition
        >
            {({ TransitionProps }) => (
                <Fade {...TransitionProps} timeout={350}>
                    <Box>
                        <LosNotificationContent
                            text={text}
                        />
                    </Box>
                </Fade>
            )}
        </Popper >
    );
};

export default memo(LosNotification);
