import { memo } from "react";
import Box from '@mui/material/Box';
import { Itinerary, ListingPrice } from "../ScrollablePropertyContainer";
import { getDuration } from "@/utils/globalMomentFunc";

const PriceWithItinerary = ({
    startDate,
    endDate,
    guests,
    rooms,
    originalPriceUSD,
    priceUSD,
    currency,
    dataSource
}) => (
    <Box
        sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            width: '100%'
        }}
    >
        <Box>
            <Itinerary
                nights={getDuration({ startDate, endDate })}
                guests={guests}
                rooms={rooms}
            />
            <ListingPrice
                originalPriceUSD={originalPriceUSD}
                priceUSD={priceUSD}
                dataSource={dataSource}
                currency={currency}
            />
        </Box>
    </Box>
);

export default memo(PriceWithItinerary);
