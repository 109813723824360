import React, { memo } from 'react';

const NextArrow = (props) => {
    return (
        <div className='cn__slider__arrows__arrow cn__slider__arrows__disabled cn__slider__arrows__prevarrow' {...props}>
            <svg width="6" height="12" viewBox="0 0 6 12" fill="none">
                <path d="M1.3916 10.261L4.43508 6.00012L1.3916 1.73924" stroke="#797882" strokeOpacity="1" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        </div>
    )
};

export default memo(NextArrow);
