import Box from "@mui/material/Box";
import { forwardRef, memo, useState } from "react";
import Skeleton from "react-loading-skeleton";
import useRetry from './useRetry';

const ImageComponent = ({
    isRetry,
    isLoadable,
    retryConfig,
    fallbackSrc,
    loaderProps = {},
    ...imageSpecificProps
}, ref) => {
    const [isLoaded, setLoadedState] = useState(!isLoadable);

    const handleOnLoad = () => {
        setLoadedState(true);
        imageSpecificProps.onLoad?.();
    };

    const {
        ref: rootRef,
        onLoad,
        onError
    } = useRetry({
        ...retryConfig,
        image: {
            ...imageSpecificProps,
            onLoad: handleOnLoad
        },
        ref,
        fallbackSrc,
        isRetry
    });

    return (
        <>
            <img
                {...imageSpecificProps}
                ref={rootRef}
                onLoad={onLoad}
                onError={onError}
            />
            {!isLoaded && (
                <Box
                    {...loaderProps}
                    sx={{
                        position: 'absolute',
                        width: '100%',
                        height: '100%',
                        top: 0,
                        left: 0,
                        '& *': {
                            display: 'block',
                            width: 'inherit',
                            height: 'inherit'
                        },
                        ...loaderProps.sx
                    }}
                >
                    <Skeleton />
                </Box>
            )}
        </>
    );
};

const ImageWithRef = forwardRef(ImageComponent);

ImageWithRef.defaultProps = {
    isRetry: false,
    isLoadable: false,
    retryConfig: {
        retryCount: 3,
        retryTimeout: 1000
    },
    fallbackSrc: '/static/staticpages/newsroom/noimage.jpg'
};

export default memo(ImageWithRef);
