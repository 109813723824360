
const AuthSymb = () => {
    return (
        <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="18" cy="18" r="18" fill="#F35597" fillOpacity="0.11" />
            <g clipPath="url(#clip0_243_21346)">
                <path d="M18 6C14.14 6 11 9.14 11 13C11 16.86 14.14 20 18 20C21.86 20 25 16.86 25 13C25 9.14 21.86 6 18 6ZM21.787 12.757L20.426 14.152L20.749 16.13C20.795 16.413 20.676 16.698 20.44 16.864C20.312 16.954 20.16 17 20.009 17C19.884 17 19.759 16.969 19.646 16.906L18 15.997L16.354 16.907C16.103 17.046 15.793 17.03 15.559 16.864C15.324 16.698 15.205 16.413 15.25 16.13L15.573 14.152L14.213 12.757C14.017 12.556 13.949 12.261 14.039 11.995C14.128 11.728 14.358 11.535 14.637 11.492L16.488 11.209L17.322 9.432C17.568 8.905 18.433 8.905 18.679 9.432L19.513 11.209L21.364 11.492C21.642 11.534 21.873 11.728 21.962 11.995C22.051 12.261 21.983 12.556 21.787 12.757Z" fill="#E86993" />
                <path d="M11.9599 18.98L8.08993 25.93C7.95993 26.17 7.96993 26.47 8.11993 26.7C8.26993 26.93 8.52993 27.07 8.80993 27.04L11.8899 26.81L13.2499 29.58C13.3799 29.83 13.6299 29.99 13.8999 30H13.9199C14.1899 30 14.4499 29.85 14.5799 29.62L17.0999 25.14L14.6499 20.81C13.6399 20.38 12.7299 19.75 11.9599 18.98Z" fill="#E86993" />
                <path d="M27.9 25.93L24 19.02C22.73 20.29 21.06 21.15 19.2 21.41C18.81 21.47 18.41 21.5 18 21.5C17.56 21.5 17.13 21.47 16.71 21.4L17.96 23.61L21.35 29.62C21.48 29.85 21.73 30 22 30H22.02C22.3 29.99 22.55 29.83 22.68 29.58L24.05 26.81L27.19 27.05C27.47 27.07 27.73 26.94 27.88 26.7C28.03 26.47 28.04 26.17 27.9 25.93Z" fill="#E86993" />
            </g>
            <defs>
                <clipPath id="clip0_243_21346">
                    <rect width="24" height="24" fill="white" transform="translate(6 6)" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default AuthSymb;
