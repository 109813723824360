import { memo } from "react";
import Box from '@mui/material/Box';

const InterestsChip = ({ count, onClick }) => {
    return (
        <Box
            sx={{
                width: '180px',
                position: 'relative',
                display: 'flex',
                borderRadius: '30px',
                background: '#FDE9E0',
                padding: '11px 40px 11px 16px',
                justifyContent: 'space-between',
                fontFamily: 'var(--primary-font)',
                fontSize: '14px',
                fontWeight: 600,
                lineHeight: '150%',
                letterSpacing: '0.01em',
                color: '#000',
            }}
        >
            <Box
                sx={{
                    flex: '0 0 auto',
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                    }}
                >
                    <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15.3747 4.0201C15.3747 4.0201 15.4658 7.55266 13.3432 9.67527C11.2206 11.7979 7.68807 11.7067 7.68807 11.7067C7.68807 11.7067 7.59693 8.17418 9.71953 6.05157C11.8421 3.92896 15.3747 4.0201 15.3747 4.0201Z" fill="black" />
                        <path fillRule="evenodd" clipRule="evenodd" d="M5.44531 4.866C5.88467 2.37624 7.68639 0.5 7.68639 0.5C7.68639 0.5 9.49364 2.38198 9.92948 4.87744C9.65219 5.08721 9.38471 5.32683 9.13503 5.6006C8.44448 6.3578 7.99221 7.2643 7.69498 8.13492C7.39775 7.2643 6.94548 6.3578 6.25492 5.6006C6.0007 5.32184 5.72802 5.07849 5.44531 4.866Z" fill="black" />
                        <path d="M0.000317097 4.0201C0.000317097 4.0201 -0.0908022 7.55266 2.03179 9.67527C4.15439 11.7979 7.68693 11.7067 7.68693 11.7067C7.68693 11.7067 7.77807 8.17418 5.65547 6.05157C3.53288 3.92896 0.000317097 4.0201 0.000317097 4.0201Z" fill="black" />
                        <path d="M0 12.2578H15.3755C15.3755 13.4962 14.3716 14.5001 13.1332 14.5001H2.24226C1.00389 14.5001 0 13.4962 0 12.2578Z" fill="black" />
                        <path fillRule="evenodd" clipRule="evenodd" d="M5.62236 2.39196C4.72888 1.57744 3.92231 1.13867 3.92231 1.13867C3.92231 1.13867 3.24307 2.24914 2.88281 3.77046C3.51199 3.94593 4.19356 4.203 4.84738 4.58124C5.01086 3.7685 5.3018 3.02651 5.62236 2.39196ZM10.5421 4.66175C11.2959 4.20309 12.0946 3.91129 12.8129 3.72454C12.45 2.22685 11.7844 1.13867 11.7844 1.13867C11.7844 1.13867 10.8358 1.65467 9.85653 2.60631C10.145 3.21308 10.3992 3.90782 10.5421 4.66175Z" fill="black" />
                    </svg>
                </Box>
                <Box
                    sx={{
                        marginLeft: '14px',
                        // fontSize: 'inherit',
                        // fontWeight: 'inherit',
                        // lineHeight: 'inherit',
                        // letterSpacing: 'inherit',
                        // color: 'inherit',
                    }}
                >
                    Interests
                </Box>
            </Box>
            <Box
                sx={{
                    flex: '0 0 auto',
                }}
            >{count}</Box>
            <Box
                onClick={onClick}
                sx={{
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    top: 0,
                    right: '12px',
                    height: '100%',
                    padding: '6px',
                }}
            >
                <svg width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.54785 1.67188L7.20471 7.32873" stroke="#242526" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M7.2041 1.67188L1.54725 7.32873" stroke="#242526" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
            </Box>
        </Box>
    );
};

export default memo(InterestsChip);
