import analyticsLabelProvider from "@/static/analyticsLabelProvider";
import { EAType } from "@/static/contants";
import Link from 'next/link';
import { useRouter } from "next/router";
import Image from "./image";

const LogoBirds = (props) => {
  const { pathname } = useRouter();
  const removeFromBody = () => {
    // const body = document.getElementsByTagName('body')[0];
    // body.style.cssText = `overflow-y: auto; overflow-x:hidden`;
  }

  const LinkComponent = pathname === '/' ? Link : 'a';

  return (
    <div
      className={props.className}
      onClick={removeFromBody}
      data-cn-ga-click={JSON.stringify({ el: analyticsLabelProvider.header[EAType.CLICK]('Logo') })}
    >
      <LinkComponent href='/'>
        <svg className="logo-birds_icon" width="43" height="30" viewBox="0 0 43 30" fill="none">
          <path d="M27.6836 11.8715C27.6836 11.8715 29.4379 13.6873 32.6038 14.4116C34.5207 11.4123 38.4402 9.73814 42.6446 9.12488C37.9886 8.811 36.1427 9.42561 35.1766 9.5295C34.0766 9.6478 30.9677 7.24272 27.6836 11.8715Z" fill="#FA368B" />
          <path d="M16.2433 15.2324C16.2433 15.2324 14.6711 17.194 13.6367 19.3178C14.3601 18.2218 15.2448 17.2029 17.3938 16.0917L16.2433 15.2324Z" fill="#4C35BC" />
          <path d="M12.0013 7.42974C12.6626 8.06288 13.0945 8.61032 13.4972 9.19758C11.6209 8.25628 8.34722 8.3029 6.26172 8.58497C7.4234 8.04396 10.0786 7.3276 12.0013 7.42974Z" fill="#4C35BC" />
          <path d="M23.8037 7.04238C23.8037 7.04238 24.927 7.77045 25.4331 9.19014C26.4045 7.26282 30.1014 5.67105 32.5999 5.04227C29.7717 5.13937 28.6962 5.62374 28.1196 5.74596C27.4635 5.885 24.8479 4.95892 23.8037 7.04238Z" fill="#4C35BC" />
          <path d="M10.3523 26.3624C10.3523 26.3624 15.9587 21.2759 22.0581 21.2759C28.9175 21.2759 30.8992 17.979 32.4776 14.7613C32.4776 14.7613 27.1892 14.0554 24.7903 8.47912C24.7903 8.47912 19.0707 0.514558 3.61582 1.01266C3.61582 1.01266 12.3853 6.15184 14.5208 9.60826C16.7097 13.151 22.3706 17.0024 22.1312 16.8844C17.1738 14.4473 13.802 9.81638 13.6879 9.51885C13.5738 9.22132 8.87236 7.85031 0.655273 10.6119C0.655273 10.6119 9.48453 8.90488 19.5527 17.322C19.5527 17.322 18.5348 17.7787 17.805 18.2824C13.2918 21.3969 10.3409 26.3653 10.3523 26.3624Z" fill="#FA368B" />
        </svg>
      </LinkComponent>
    </div>
  );
};

export default LogoBirds;
