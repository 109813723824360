import { useState, useEffect } from 'react';

const useForm = (initialValues, yupSchema) => {
    const [form, setForm] = useState(() => initializeForm(initialValues));

    function initializeForm(initValues) {
        const values = {};
        const touched = {};
        const errors = {};
        for (const key in initValues) {
            values[key] = initValues[key];
            touched[key] = false;
            errors[key] = '';
        }
        return {
            values,
            touched,
            errors,
        };
    };

    const createValidator = schema => async (path, value) => {
        try {
            await schema.validateAt(path, value, { abortEarly: false });
            return {
                isValid: true,
                errors: [],
            };
        } catch (err) {
            return {
                isValid: false,
                errors: err.errors,
            };
        }
    };
    const validate = createValidator(yupSchema);

    const handleChange = (evt, customId = null, customValue = null, error = null) => {
        const id = customId || evt.target.id;
        const val = (customValue !== null) ? customValue : evt.target.value;
        validate(id, { [id]: val })
            .then(result => {
                const err = (form.touched[id] && (!result.isValid || error) && (error !== 'ignore'))
                    ? (error || result.errors[result.errors.length - 1])
                    : '';

                setForm((state) => {
                    return ({
                        ...state,
                        errors: {
                            ...state.errors,
                            [id]: err
                        }
                    });
                });
            });
        setForm((state) => {
            return ({
                ...state,
                values: {
                    ...state.values,
                    [id]: val
                },
            });
        });
    };

    const handleBlur = async (evt, customId = null, customValue = null, error = null) => {
        const id = customId || evt.target.id;
        const val = customValue || evt.target.value;
        const result = await validate(id, { [id]: val });
        const err = ((!result.isValid || error) && (error !== 'ignore'))
            ? (error || result.errors[result.errors.length - 1])
            : '';
        setForm((state) => {
            return ({
                ...state,
                touched: {
                    ...state.touched,
                    [id]: true
                },
                errors: {
                    ...state.errors,
                    [id]: err
                }
            });
        });
    };

    return {
        ...form,
        handleChange,
        handleBlur,
    };
};

export default useForm;
