import { useState } from 'react';
import { parsePhoneNumber } from 'awesome-phonenumber'
const usePhoneInputDecorator = (handleChange, handleBlur) => {
    const [phoneNumb, setPhoneNumb] = useState(['', '']);
    const [phoneInputKey, setPhoneInputKey] = useState(1);

    const countryCodeError = (!phoneNumb[0] && phoneNumb[1])
        ? 'Incorrect country code'
        : null;

    const handleBlurDecorated = async evt => {
        return handleBlur(evt, 'phone', phoneNumb.join(''), countryCodeError);
    };

    const handleChangeDecorated = async (phoneNumber, { dialCode }, evt) => {
        const phonetemp = parsePhoneNumber("+"+phoneNumber)
        const err = (!dialCode && phoneNumber) ? 'Incorrect country code' : null;
        if (!phoneNumber && dialCode) {
            setPhoneInputKey(prev => prev*(-1));
        }
        const phone = (dialCode && (!countryCodeError ||  dialCode === phoneNumber))
            ? phoneNumber.toString().substr(dialCode.toString().length)
            : phoneNumber;
        const code = (dialCode && phoneNumber)
            ? (dialCode !== phonetemp.countryCode) && Boolean(phonetemp.countryCode) ?  phonetemp.countryCode : dialCode
            : null;
        setPhoneNumb([
            code,
            phone
        ]);
        return handleChange(evt, 'phone', phoneNumber, err);
    };

    return [phoneNumb, phoneInputKey, handleChangeDecorated, handleBlurDecorated];
};

export default usePhoneInputDecorator;
