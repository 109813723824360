
const CloseSymb = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8 8L16.4853 16.4853" stroke="#242526" strokeWidth="2.25" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M16.4854 8L8.00007 16.4853" stroke="#242526" strokeWidth="2.25" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
};

export default CloseSymb;
