import { memo } from "react";
import Typography from "@mui/material/Typography";
import { constants } from "@/utils/constants";

const CareProgramUntilDate = ({ sx, date = constants.careProgramDeadlineDate }) => (
    <Typography
        sx={{
            fontFamily: 'inherit',
            fontSize: 'inherit',
            fontWeight: 'inherit',
            fontStyle: 'inherit',
            lineHeight: 'inherit',
            letterSpacing: 'inherit',
            color: 'inherit',
            display: 'inline-block',
            ...sx
        }}
    >
        {date}
    </Typography>
);

export default memo(CareProgramUntilDate);
