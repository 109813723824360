import React, { memo } from "react";

const CameraPhoto = ({ className }) => (
    <svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path d="M18.087 8.22609H16.4174L15.4957 6.83478C15.1478 6.31304 14.5391 6 13.913 6H10.087C9.46087 6 8.85217 6.31304 8.50435 6.83478L7.58261 8.22609H5.91304C4.85217 8.22609 4 9.07826 4 10.1391V16.887C4 17.9478 4.85217 18.8 5.91304 18.8H18.087C19.1478 18.8 20 17.9478 20 16.887V10.1391C20 9.07826 19.1478 8.22609 18.087 8.22609ZM12 17.2348C9.61739 17.2348 7.68696 15.3043 7.68696 12.9217C7.68696 10.5391 9.61739 8.62609 12 8.62609C14.3826 8.62609 16.313 10.5565 16.313 12.9391C16.313 15.3043 14.3826 17.2348 12 17.2348ZM17.8435 10.9217C17.8261 10.9217 17.8087 10.9217 17.7739 10.9217H17.0783C16.7652 10.9043 16.5217 10.6435 16.5391 10.3304C16.5565 10.0348 16.7826 9.8087 17.0783 9.7913H17.7739C18.087 9.77391 18.3478 10.0174 18.3652 10.3304C18.3826 10.6435 18.1565 10.9043 17.8435 10.9217Z" fill="#64748B" />
        <path d="M12.0001 10.5391C10.6784 10.5391 9.6001 11.6173 9.6001 12.9391C9.6001 14.2608 10.6784 15.3217 12.0001 15.3217C13.3218 15.3217 14.4001 14.2434 14.4001 12.9217C14.4001 11.5999 13.3218 10.5391 12.0001 10.5391Z" fill="#64748B" />
    </svg>
)

export default memo(CameraPhoto);
