import { useEffect, useRef, useState } from "react";

export const concentCookieName = 'cuddlynest_allconsent';
export const concentLocationCookie = 'location_cookie';

export default function useGetUserLocation(
    setValue,
    promptCbRef,
    denyCbRef =  { current: null },
    cacheInterval,
    customOptions) {
    // cacheInterval is in milliseconds, e.g. 1 week = 7 * 24 * 60 * 60 * 1000

    useEffect(() => {
        if (navigator?.geolocation) {
            const options = (customOptions || {
                enableHighAccuracy: true,
                timeout: 5000,
                maximumAge: 0,
            });

            const onSuccess = geoPos => {
                const coords = {
                    latitude: geoPos.coords.latitude,
                    longitude: geoPos.coords.longitude
                }
                setValue({
                    status: 'coords',
                    coords
                });
            }
            const onSuccessCached = geoPos => {
                const coords = {
                    latitude: geoPos.coords.latitude,
                    longitude: geoPos.coords.longitude
                }
                const now = new Date();
                localStorage.setItem('userAddress', JSON.stringify({
                    status: 'coords',
                    timestamp: now.getTime(),
                    coords,
                }));
                setValue({
                    status: 'coords',
                    coords
                });
            };
            const onError = err => {
                setValue({
                    status: 'denied',
                    error: err
                });
            };
            const onErrorCached = err => {
                const now = new Date();
                localStorage.setItem('userAddress', JSON.stringify({
                    status: 'denied',
                    timestamp: now.getTime()
                }));

                setValue({
                    status: 'denied',
                    error: err
                });
            };
            const getCachedResult = () => {
                let userAddress = localStorage.getItem('userAddress');
                if (userAddress) {
                    userAddress = JSON.parse(userAddress);
                    if (userAddress?.timestamp && !isNaN(userAddress.timestamp)) {
                        const now = new Date();
                        const timeElapsed = now.getTime() - userAddress.timestamp;
                        if (timeElapsed < cacheInterval) {
                            return {
                                status: userAddress.status,
                                coords: userAddress.coords
                            };
                        }
                        localStorage.removeItem('userAddress');
                        return {
                            status: 'prompt',
                        };
                    }
                }
                return {
                    status: 'prompt',
                };
            }
            const askPosition = (onSuccess, onError, options) => {
                // alert("navigator.geolocation.getCurrentPosition");
                navigator.geolocation.getCurrentPosition(onSuccess, onError, options);
            };

            try {
                if (!navigator?.permissions) {
                    // Fuck you Safary!!!!!
                    return;
                }

                const result = getCachedResult();
                if (result.status === 'prompt') {

                    navigator.permissions
                    ?.query({ name: 'geolocation' })
                    .then(result => {
                        if (result.state === 'granted') {
                            // askPosition(onSuccessCached, onError, options);
                            promptCbRef.current = () => askPosition(onSuccess, onError, options);
                        } else if (result.state === 'prompt') {
                            if (!cacheInterval) {
                                setValue({
                                    status: 'prompt'
                                });
                                promptCbRef.current = () => askPosition(onSuccess, onError, options);
                                denyCbRef.current = () => onError();
                                return;
                            }
                            // const result = getCachedResult();
                            setValue(result);
                            if (result.status === 'prompt') {
                                // askPosition(onSuccessCached, onErrorCached, options);
                                promptCbRef.current = () => askPosition(onSuccessCached, onErrorCached, options);
                                denyCbRef.current = () => onErrorCached();
                            }
                        } else if (result.state === 'denied') {
                            onError();
                            onErrorCached();
                        }
                    });
                } else if (result.status === 'coords') {
                    promptCbRef.current = () => askPosition(onSuccess, onError, options);
                }
            }catch(err) {
                console.error(err);
            }
        }
    }, []);
}
