export class KsaRegion {
    static MiddleEast = 'middle-east';
};

export const ksaRegions = new Map([
    [KsaRegion.MiddleEast, [
        'Bahrain',
        'Egypt',
        'Iran',
        'Iraq',
        'Jordan',
        'Kuwait',
        'Lebanon',
        'Oman',
        'Qatar',
        'Saudi Arabia',
        'Syria',
        'United Arab Emirates',
        'Yemen'
    ]]
]);
