import React, { memo } from "react";

//Libraries
import Link from "next/link";

//Import methods
import useDeviceDetect from "@/src/utils/customHooks/useDeviceDetect";

const DownloadApp = () => {
  const { isDesktop, isTablet } = useDeviceDetect();

  const renderImage = isDesktop ? (
    <img
      src="https://img.cuddlynest.com/assets/desktop/homepage/banners/bannerapp-min.png"
      alt="Phones images"
      className="cn__hp__download__mobile"
    />
  ) : isTablet ? (
    <img
      src="https://img.cuddlynest.com/assets/tablet/homepage/banners/bannerapp.png"
      alt="Phone image"
      className="cn__hp__download__mobile"
    />
  ) : (
    <img
      src="https://img.cuddlynest.com/assets/mobile/homepage/banners/bannerapp.png"
      alt="Phone image"
      className="cn__hp__download__mobile"
    />
  );

  return (
    <section className="cn__hp__download__wrapper">
      <div className="cn__hp__download">
        <div className="cn__hp__download__info">
          <div>
            <h3 className="cn__hp__download__info__title">
              Millions of places to stay, one app.
            </h3>
            <p className="cn__hp__download__info__subtitle">
              Don’t miss out on mobile-only deals and one-of-a-kind offers.
            </p>
          </div>
          <div className="cn__hp__download__info__buttons">
            <img
              src="/static/staticpages/home/download/qr1.svg"
              alt="QR image"
              className="cn__hp__download__info__buttons__qr"
            />
            <p className="cn__hp__download__info__buttons__get">
              Get the app now.
            </p>
            <Link href="https://apps.apple.com/app/cuddlynest-hotels-and-homes/id1504837568?uo=2">
              <a
                target="_blank"
                className="cn__hp__download__info__buttons__apple"
              >
                <img
                  src="/static/staticpages/home/download/apple.svg"
                  alt="Download from Apple Store button"
                />
              </a>
            </Link>
            <Link href="https://play.google.com/store/apps/details?id=com.cuddlynest">
              <a
                target="_blank"
                className="cn__hp__download__info__buttons__android"
              >
                <img
                  src="/static/staticpages/home/download/google.svg"
                  alt="Download from Google Store button"
                />
              </a>
            </Link>
          </div>
        </div>
        {renderImage}
      </div>
    </section>
  );
};

export default memo(DownloadApp);
