import { Fragment, useState, useEffect } from "react"
import { Input } from "../formComponents/input"
import { Button } from "../formComponents/button"
import { Modal } from "../common/modal"
import { ModalContainer } from "../common/modal/modalCotainer"
import { ModalBody } from "../common/modal/modalBody"
import { _setFocus, _validateEmail } from "@/src/utils/globalValidations"
import { inValidEmail } from "@/static/errorMessages"
import { InputGroup } from "../formComponents/inputGroup"
import { FormHelpertext } from "../formComponents/formHelperText"
import { forgetPassword } from "@/redux/services/authApi"
import { successNotificationActions, errorNotificationActions } from "@/redux/actions/globalActions"
import { connect } from "react-redux"

const ResetPass = props => {
    const [ email, setEmail ] = useState();
    const [ errEmail, setErrEmail ] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const _formSubmit = async e => {
        e.preventDefault();
        if (_validateEmail(email)) {
            setErrEmail(false);
            setIsLoading(true);
            try {
                const res = await forgetPassword({email})
                if(res.statusCode === 200) {
                    props.toggle();
                    // props.successNotificationActions("Success", res.message)
                }else {
                    _setFocus('email');
                    setErrEmail(res.message);
                    setIsLoading(false);
                    // props.errorNotificationActions("Error", res.message);
                }
            }catch(err) {
                _setFocus('email');
                setErrEmail(err.message);
                setIsLoading(false);

                // props.errorNotificationActions("Error", err.message);
            }
        }else{
            _setFocus('email');
            setErrEmail(true);
            setIsLoading(false);
        }
    }


    useEffect(()=>{
        if(errEmail)
        {
            const time = setTimeout(()=>{
                setErrEmail(false)
            },4000)
        }

    },[errEmail])

    const enterPressHandler = (e) => {
        let key = e.keyCode || e.which;
        if(key===13)
        {
            _formSubmit(e)
        }
    }

    return (
        <Modal className="forgot-popup" toggle={props.toggle}>
            <ModalContainer>
                <ModalBody>
                    <Fragment>
                        <div className="forgot-heading">Forgot your password?</div>
                        <div className="forgot-text">
                            Enter your email address below and we’ll send you password reset instructions
                        </div>
                        <InputGroup>
                            <Input
                                type="email"
                                className="primary-input"
                                value={email}
                                id="email"
                                onChange={e => setEmail(e.target.value)}
                                placeholder="Email address"
                                onKeyUp={enterPressHandler}
                            />
                            {
                                errEmail && (
                                    <FormHelpertext className="err">
                                        {errEmail!==true?errEmail:inValidEmail}
                                    </FormHelpertext>
                                )
                            }
                        </InputGroup>
                        <Button
                            type="button"
                            className="btn-primary"
                            onClick={_formSubmit}
                            status={isLoading}
                        >
                            Send me reset instructions
                        </Button>
                        <div className="forgot-never-mind">
                            <a href="javascript: void(0);" onClick={e => props.toggleScreen(1)}>Never mind, go back</a>
                        </div>
                        <div className="reset-text">
                            If you don’t see reset email please check your spam filter for an email from CuddlyNest.
                        </div>
                        <div className="popup-mobile-back close" onClick={props.toggle}>
                            <img src="/static/img/left-arrow-black.svg" alt="left arrow" />
                            Back
                        </div>
                    </Fragment>
                </ModalBody>
            </ModalContainer>
        </Modal>
    )
}

export default connect(null, { successNotificationActions, errorNotificationActions })(ResetPass);