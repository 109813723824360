import { useCallback } from "react";

export function useFilterAggregator(onChange, valueObject) {
    return useCallback(valueToSet => () => {
        onChange(getValueOnChange(valueToSet, valueObject));
    }, [onChange, valueObject]);
}

export function useSelectSpecificFilterValues(data, currentValue, initialValue = {}) {
    return data.reduce((acc, filterOrCategory) => {
        if (Array.isArray(filterOrCategory.items)) {
            return { ...acc, ...useSelectSpecificFilterValues(filterOrCategory.items, currentValue) };
        }
        const key = filterOrCategory.id ?? filterOrCategory.name ?? filterOrCategory;
        if (key in currentValue) {
            return { ...acc, [key]: currentValue[key] };
        }
        return acc;
    }, initialValue);
}

export function useClearSpecificFilter(onClear, data, currentValue) {
    const clearHandler = useClear();

    return useCallback(() => {
        onClear(
            clearHandler(data, currentValue)
        );
    }, [onClear, clearHandler, data, currentValue]);
}

export function useClear() {
    return useCallback(function clearHandler(data, currentValue) {
        return {
            ...data.reduce((acc, filterOrCategory) => {
                if (Array.isArray(filterOrCategory.items)) {
                    return clearHandler(filterOrCategory.items, currentValue);
                }
                const key = filterOrCategory.id ?? filterOrCategory;
                if (key in acc) {
                    delete acc[key];
                }
                return acc;
            }, currentValue)
        };
    }, []);
}

function getValueOnChange(valueToSet, valueObject) {
    if (!(valueObject instanceof Object)) {
        return valueToSet;
    }

    if (valueToSet instanceof Object && Object.keys(valueToSet).length === 0) {
        return {};
    }

    const key = valueToSet.id ?? valueToSet;

    if (key in valueObject) {
        delete valueObject[key];
    } else {
        valueObject[key] = valueToSet;
    }

    return { ...valueObject };
}
