import Box from '@mui/material/Box';
import Badge from '@mui/material/Badge';
import ButtonBase from '@mui/material/ButtonBase';
import Interests from "@/components/common/icons/Interests";
import { memo } from "react";
import useInterests from './useInterests';
import InterestsCta from './InterestsCta';
import ActivityInvariantException from '../exceptions/ActivityInvariantException';

const InterestsTrigger = () => {
    const { onOpen, state, ctaRef, isDataAvailable, validationError } = useInterests();

    const getBadgeCount = () => {
        if (validationError instanceof ActivityInvariantException) {
            return validationError.context.selectedCount;
        }

        return state.length;
    };

    return isDataAvailable() && (
        <InterestsCta
            closeCtaRef={ctaRef}
            onClick={onOpen}
        >
            <Box
                component={ButtonBase}
                onClick={onOpen}
                className='omnisearch_content_interests-trigger js-omnisearch_content_interests-trigger'
                sx={{
                    position: 'absolute !important',
                    transform: 'translateY(calc(100% + 11px)) !important',
                    bottom: 0,
                    right: 0,
                    backgroundColor: 'var(--color-white) !important',
                    borderRadius: '100px !important',
                    padding: '7px 11px !important',
                    fontFamily: 'var(--primary-font) !important',
                    color: 'var(--color-black)',
                    letterSpacing: '.01em',
                    fontWeight: 600,
                    fontSize: '12px !important',
                    lineHeight: '150%',
                    display: 'grid',
                    gridTemplateColumns: 'repeat(2, min-content)',
                    whiteSpace: 'nowrap !important',
                    alignItems: 'center !important',
                    gap: '15px !important',
                    cursor: 'pointer !important',
                    border: 'none !important',
                    zIndex: -1,
                    '& + .MuiTooltip-popper': {
                        zIndex: -1
                    }
                }}
            >
                <Interests />
                <Badge
                    badgeContent={getBadgeCount()}
                    sx={{
                        '& .MuiBadge-badge': {
                            color: 'var(--color-white)',
                            fontFamily: 'var(--primary-font)',
                            backgroundColor: validationError
                                ? 'var(--color-gray-tollfree)'
                                : 'var(--color-cn-pink)',
                            top: '-8px'
                        }
                    }}
                >
                    <span>Advanced Filter</span>
                </Badge>
            </Box>
        </InterestsCta>
    );
};

export default memo(InterestsTrigger);
