import { memo } from "react";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import Avatar from "@material-ui/core/Avatar";
import useProfileAvatar from "./useStyles";

const ProfileAvatar = ({ src, progress, width=60, height=60, circleSize=72 }) => {
    const classes = useProfileAvatar({
        width: width,
        height: height
    });

    return (
        <Box
            position='relative'
            display='flex'
            justifyContent='center'
            alignItems='center'
            marginRight={'20px'}
            marginLeft={'5px'}
        >
            <CircularProgress
                className={classes.progress}
                variant="determinate"
                thickness={2}
                value={progress}
                size={circleSize}
            />
            <Avatar
                className={classes.avatar}
                src={src}
            />
        </Box>
    );
};

export default memo(ProfileAvatar);
