import { useDispatch } from 'react-redux'
import { GoogleSignupSigninAction } from '@/redux/actions/authActions';
import styles from './Authentication.module.scss';
import { GoogleSVG } from './AuthSVG';
import { memo } from 'react';
import { useGoogleLogin } from '@react-oauth/google';


const GoogleAuth = () => {
    const dispatch = useDispatch();
    const login = useGoogleLogin({
        onSuccess: codeResponse => dispatch(GoogleSignupSigninAction(codeResponse)),
        flow: 'auth-code',
    });

    return (
        <div
            id="google"
            onClick={login}
            className={styles.auth_login_options_item}
        >
            <div className={styles.auth_login_options_item_inner}>
                <GoogleSVG />
                <span className={styles.auth_login_options_item__text}>Google</span>
            </div>
        </div>
    );
};

export default memo(GoogleAuth);
