import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import moment from 'moment';
import { Screen } from '../Mobile';
import DateRangePicker from './DateRangePicker';
import StepEnum from '../enum';
import SearchActionArea from '../SearchActionArea';
import useDateFormatter from '../useDateFormatter';
import DateRangeModel from './DateRangeModel';
import LengthOfStayNotification from '@/containers/newHomePage/components/Extra/LengthOfStayNotification';
import { constants } from '@/src/utils/constants';
import { useSelector } from 'react-redux';
import LosNotification from '@/components/LosNotification/LosNotification';

const DateRangePickerMobile = ({ value, step, onClose }) => {
    const [datesState, setDatesState] = value;
    const [startDate, endDate] = [moment(datesState.startDate), moment(datesState.endDate)]
    const [stepState, setStepState] = step;
    const formatDate = useDateFormatter('DD MMM');
    const nextMonthsClicks = useRef(Math.round((constants.calendarMonths + 1) / constants.calendarMonthsMobile));
    const suggestionResult = useSelector(state => state.geo_location);
    const headerRef = useRef(null);


    const [showLosNotification, setShowLosNotification] = useState(false);

    const lenghtOfStayIsValid = dates => ((dates.endDate - dates.startDate <= constants.lengthOfStay)
        || (!dates.endDate.isValid() || !dates.startDate.isValid()));

    const onCancel = useCallback(() => {
        setDatesState(new DateRangeModel);
    }, []);

    const onClick = useCallback((e) => {
        e.stopPropagation();
        if (!lenghtOfStayIsValid({ startDate, endDate })) {
            setShowLosNotification(true);
            const node = document.querySelector('.DayPicker_transitionContainer') || window;
            node.addEventListener('click', () => setShowLosNotification(false), { once: true });
            node.addEventListener('scroll', () => setShowLosNotification(false), { once: true });
        } else {
            setStepState(StepEnum.GUESTS);
        }
    }, [startDate, endDate, showLosNotification]);

    useEffect(() => {
        const verticalScrollableCalendar = document.querySelector('.DayPicker_transitionContainer__verticalScrollable');
        const nextCalendarBar = document.querySelector('.DayPickerNavigation:not(.DayPickerNavigation__verticalScrollable_prevNav)');

        const calendarScrollHandler = ({ target }) => {
            const { scrollHeight, scrollTop, clientHeight } = target;
            if (((scrollHeight - scrollTop - 300) <= clientHeight) && nextCalendarBar && nextMonthsClicks.current > 1) {
                nextCalendarBar.firstElementChild.click();
                nextMonthsClicks.current -= 1;
            }
        };
        verticalScrollableCalendar?.addEventListener('scroll', calendarScrollHandler);
        return () => {
            verticalScrollableCalendar?.removeEventListener('scroll', calendarScrollHandler);
        };
    }, [stepState]);

    return (
        <Screen
            ref={headerRef}
            className="omnisearch_input-wrapper_mobile_screen_dates-picker"
            headerContentSlot={<div></div>}
            proceedToNextSlot={
                <SearchActionArea
                    className="omnisearch_input-wrapper_mobile_screen_dates-picker_next-btn"
                    isMobile
                    onClick={onClick}
                >
                    Next
                </SearchActionArea>
            }
            priority={4}
            isOpen={[StepEnum.DATES, StepEnum.GUESTS, StepEnum.INTERESTS].includes(stepState)}
            onGoBack={() => setStepState(suggestionResult.data.length ? StepEnum.LOCATION : StepEnum.INITIAL)}
            onCancel={onCancel}
            onClose={onClose}
        >
            <LosNotification
                anchorEl={headerRef.current}
                open={showLosNotification}
                text={`You can only book stays for ${Math.round(constants.lengthOfStay / 86400000)} days or less.`}
                offset={[0, -8]}
            />
            <DateRangePicker
                value={datesState}
                onChange={setDatesState}
                setStep={setStepState}
            />
        </Screen>
    );
};

export default memo(DateRangePickerMobile);
