import React, { forwardRef, memo, useCallback, useEffect, useRef } from 'react';
import CaretIcon from '@/components/common/icons/Caret';
import CloseIcon from '@/components/common/icons/Close';
import clsx from 'clsx';
import MobileFooter from './MobileFooter';

const Screen = ({
    className,
    priority,
    isOpen,
    onGoBack,
    headerContentSlot,
    onCancel,
    onClose,
    proceedToNextSlot,
    clearSlot,
    onScroll,
    children
}, ref) => {
    const rootRef = useRef(null);

    const goBackHandler = useCallback(() => {
        onGoBack?.();
    }, [onGoBack]);

    const onTransitionEnd = useCallback(() => {
        const { current: rootEl } = rootRef;
        if (!rootEl) {
            return;
        }
        if (!rootEl.classList.contains('omnisearch_input-wrapper_mobile_screen--open')) {
            rootEl.style.zIndex = '';
        }
    }, []);

    useEffect(() => {
        const { current: rootEl } = rootRef;
        if (isOpen && rootEl) {
            rootEl.style.zIndex = priority;
        }
    }, [isOpen, priority]);

    return (
        <div
            ref={rootRef}
            className={clsx('omnisearch_input-wrapper_mobile_screen', {
                'omnisearch_input-wrapper_mobile_screen--open': isOpen
            }, className)}
            onTransitionEnd={onTransitionEnd}
        >
            <div className="omnisearch_input-wrapper_mobile_screen_main">
                <header className="omnisearch_input-wrapper_mobile_screen_header" ref={ref}>
                    <button
                        className="omnisearch_input-wrapper_mobile_screen_header_goback-btn"
                        type='button'
                        onClick={goBackHandler}
                    >
                        <CaretIcon />
                    </button>
                    {headerContentSlot}
                    <button
                        className="omnisearch_input-wrapper_mobile_screen_header_close-btn"
                        type='button'
                        onClick={onClose}
                    >
                        <CloseIcon />
                    </button>
                </header>
                <div
                    className="omnisearch_input-wrapper_mobile_screen_content"
                    onScroll={onScroll}
                >
                    {children}
                </div>
                <MobileFooter
                    onCancel={onCancel}
                    passiveSlot={clearSlot}
                    activeSlot={proceedToNextSlot}
                />
                {/* <footer className="omnisearch_input-wrapper_mobile_screen_footer">
                    {clearSlot || (
                        <button
                            className="omnisearch_input-wrapper_mobile_screen_footer_clear-btn"
                            type='button'
                            onClick={onCancel}
                        >
                            Clear
                        </button>
                    )}
                    {proceedToNextSlot}
                </footer> */}
            </div>
        </div>
    );
};

export default memo(forwardRef(Screen));
