import React, { memo, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { DateRangeInput } from './DateRange';
import { DestinationInput } from './Destination';
import { GuestInput } from './GuestCount';
import SearchActionArea from './SearchActionArea';
import StepEnum, { Entities } from './enum';
import useProceedToSearch from './useProceedToSearch';
import useValidationGate from './useValidationGate';
import LosNotification from '@/components/LosNotification/LosNotification';
import { constants } from '@/src/utils/constants';
import InvalidSearchCriteriaException from './exceptions/InvalidSearchCriteriaException';


const InputGroupArea = ({ destination, dates, guests, step }) => {

    const [currentStep, setCurrentStep] = step;

    const [destinationState, setDestinationState] = destination;
    const [datesState, setDatesState] = dates;
    const [guestsState, setGuestsState] = guests;
    const [showLosNotification, setShowLosNotification] = useState(false);
    const datesRef = useRef(null);

    const [invalidEntity, setInvalidEntity] = useState(null);

    const { isEntitiesValid } = useValidationGate();

    const onProceedToSearch = useProceedToSearch();

    const onClick = (e) => {
        e.stopPropagation();
        if(currentStep === StepEnum.INTERESTS) {
            setCurrentStep(StepEnum.GUESTS)
            return;
        }

        if (!datesState.isLenghtOfStayValid()) {
            setShowLosNotification(true);
            window.addEventListener('click', () => setShowLosNotification(false), { once: true });
            window.addEventListener('scroll', () => setShowLosNotification(false), { once: true });
        }

        onProceedToSearch(
            destinationState,
            datesState,
            guestsState
        ).catch(error => {
            if (error instanceof InvalidSearchCriteriaException) {
                setInvalidEntity(error.context);
            }
        });

        if (isEntitiesValid()) {
            setCurrentStep();
        }
    };

    const resetInvalidEntity = () => setInvalidEntity(null);

    return (
        <div
            className={clsx(
                'omnisearch_content_input-group-area',
                isEntitiesValid() && 'omnisearch_content_input-group-area--valid'
            )}
        >
            <DestinationInput
                value={destinationState}
                onChange={setDestinationState}
                isActive={[StepEnum.INITIAL, StepEnum.LOCATION].includes(currentStep)}
                setCurrentStep={setCurrentStep}
            />
            <DateRangeInput
                ref={datesRef}
                value={datesState}
                onChange={setDatesState}
                isActive={currentStep === StepEnum.DATES}
                setCurrentStep={setCurrentStep}
            />
            <GuestInput
                value={guestsState}
                onChange={setGuestsState}
                isActive={currentStep === StepEnum.GUESTS}
                setCurrentStep={setCurrentStep}
            />
            <SearchActionArea
                onClick={onClick}
                // disabled={!canProceedToSearch}
                invalidEntity={invalidEntity}
                resetInvalidEntity={resetInvalidEntity}
            />
            <LosNotification
                anchorEl={datesRef.current}
                open={showLosNotification}
                text={`You can only book stays for ${Math.round(constants.lengthOfStay / 86400000)} days or less.`}
                offset={[10, 14]}
            />
        </div>
    );
};

export default memo(InputGroupArea);
