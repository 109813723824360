import React, { memo, useEffect, useState } from 'react';

//Libraries
import SimpleBar from 'simplebar-react';
import pluralize from 'pluralize';
import moment from 'moment';

//Import methods
import useDeviceDetect from '@/src/utils/customHooks/useDeviceDetect';
import { DestinationModel } from '@/containers/homePage/Omnisearch/Destination';
import { DateRangeModel } from '@/containers/homePage/Omnisearch/DateRange';
import { GuestModel } from '@/containers/homePage/Omnisearch/GuestCount';
import useProceedToSearch from '@/containers/homePage/Omnisearch/useProceedToSearch';
import { deepEqual } from './utils';

//Components
import Heading from '../../components/Heading';
import Icon from './components/Icon';
import analyticsLabelProvider from '@/static/analyticsLabelProvider';
import { EAType } from '@/static/contants';
import { getHistorySearches } from '@/utils/searchHistory';

const Card = ({info, onClick,state,parentState}) => {

    //Card Info
    const transformDate = (infoDate) => {
        let date  = new Date(infoDate);
        date = date.toDateString("yyyy-MM-dd").split(" ");
        return `${date[2]} ${date[1]}`;
    }

    let titleInfoRest = info.addrData.formattedAddress.split(",");
    titleInfoRest = titleInfoRest[titleInfoRest.length - 1];
    titleInfoRest = titleInfoRest.trim();

    let date1 = transformDate(info.startDate);
    let date2 = transformDate(info.endDate);
    let userInfo = info.guests.adults + info.guests.childrenVr;

    let renderTitle = info.addrData.name !== titleInfoRest ? (
        <>
            <span>{info.addrData.name},</span> {titleInfoRest.replace( /\s/g, ' ')}
        </>
    ) : (
        <span>{info.addrData.name}</span>
    );

    let type = info.addrData.type ?? null;

    //Remove Card
    const handleDelete = (event,info) => {
        //Stop parent click
        event.stopPropagation();

        //Get local storage info
        const localStorageData = localStorage.getItem("recentSearch");
        const data = JSON.parse(localStorageData);

        //Update data and local storage
        const newLocal = data.filter(el => deepEqual(el,info) !== true);
        localStorage.setItem("recentSearch", JSON.stringify(newLocal));

        if(newLocal.length === 0){
            parentState(null);
        }else{
            state(newLocal);
        }
    }

    return (
        <div
            className="cn__hp__recentsearches__card"
            data-cn-ga-click={JSON.stringify({ el: analyticsLabelProvider.recentSearches[EAType.CLICK]() })}
            onClick={() => onClick(
                new DestinationModel({
                    id: info.addrData?.id,
                    name: info.addrData?.name,
                    address: info.addrData?.address,
                    inputValue: info.addrData?.name,
                    type: info.addrData?.type,
                    slug: info.addrData?.slug,
                    viewportBounds: info.addrData?.viewportBounds,
                    location: info.addrData?.location,
                    mobileZoomLevel: info.addrData?.mobileZoomLevel,
                    zoomLevel: info.addrData?.zoomLevel,
                    propertyCount: info.addrData?.propertyCount
                }),
                new DateRangeModel({
                    startDate: moment(info.startDate),
                    endDate: moment(info.endDate)
                }),
                new GuestModel({
                    adultCount: info.guests?.adults,
                    childCount: info.guests?.childrenVr,
                    infantCount: info.guests?.infants,
                    roomCount: info.guests?.rooms,
                    childAges: info.guests?.children
                }),
                info.interests
            )}
        >
            <Icon type={type}/>
            <div>
                <p className="cn__hp__recentsearches__card__title">
                    {renderTitle}
                </p>
                <p className="cn__hp__recentsearches__card__subtitle">
                    {date1} - {date2} • {userInfo} {pluralize('guest', userInfo)}
                </p>
            </div>
            <svg className="cn__hp__recentsearches__card__cancel" width="8" height="7" viewBox="0 0 8 7" fill="none" onClick={(e) => handleDelete(e,info)}>
                <path d="M1 0.5L7 6.5" stroke="#797882"></path><path d="M7 0.5L1 6.5" stroke="#797882"></path>
            </svg>
        </div>
    )
}

const RecentSearches = ({ onChange }) => {

    // TODO: refactor this trash line 111 - 119 moved from parent component
    //---------- Recent Searches

    const [parentData, setParentState] = useState(null);

    useEffect(() => {
        const resentSearch = getHistorySearches();
        setParentState(resentSearch);
        setDataInfo(resentSearch);
    }, []);
    //---------- Recent Searches

    useEffect(() => {
        onChange(parentData?.length ?? 0);
    }, [onChange, parentData?.length]);

    //State
    const [dataInfo, setDataInfo] = useState(parentData);

    //Check Device
    const {isMobile} = useDeviceDetect();
    const onClick = useProceedToSearch();

    return (parentData?.length > 0) && (
        <section className="cn__hp__recentsearches">
            <Heading align="left" tit="Recent searches" sub="Continue where you left off"/>
            <SimpleBar>
                <div className="slidermobile__wrapper">
                    {dataInfo.map((el,i) => (
                        <Card
                            key={i}
                            info={el}
                            onClick={onClick}
                            state={setDataInfo}
                            parentState={setParentState}
                        />
                    ))}
                </div>
            </SimpleBar>
        </section>
    )
}

export default memo(RecentSearches);