import { useCallback } from "react";

export default function useRemoveDuplicatedIcons() {
    return useCallback(({ amenities, facilities }) => Object.values([
        ...amenities,
        ...Object.values(facilities).flat()
    ].reduce((acc, { iconComponent, ...restProperties }) => ({
        ...acc,
        [iconComponent.type.name]: {
            ...restProperties,
            iconComponent
        }
    }), {})), []);
}