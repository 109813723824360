import { useRouter } from "next/router";
import { Submit } from "@/components/formComponents/MaterialFormInputs";
import styles from './Authentication.module.scss';
import { TickMarkSVG } from "./AuthSVG";

const Final = ({ message, setAuthOpen, redirectUrl, redirectText, linkText }) => {
    const router = useRouter();

    return (
        <>
            <div className={styles.complete_profile_symb}>
                <TickMarkSVG />
            </div>
            <div className={styles.complete_profile_text}>
                {message}
            </div>
            {redirectUrl && (
                <Submit
                    type='button'
                    onClick={() => {
                        setAuthOpen(false);
                        router.push(redirectUrl);
                    }}
                    className={styles.auth_form_submit}
                >
                    {redirectText}
                </Submit>
            )}
            <div
                className={styles.complete_profile_skip}
            >
                <a
                    className={styles.complete_profile_skip_inner}
                    onClick={() => setAuthOpen(false)}
                >
                    {linkText}
                </a>
            </div>
        </>
    );
};

export default Final;
