import React, { memo } from 'react';

//Import methods
import { handleJSClick } from '@/components/Slider/utils';

//Components
import Image from '@/containers/newHomePage/components/Image';

const Card3 = ({data,linkType}) => {

    //Render
    const childRender = (
        <div className='cn__card3__img'>
            <Image src={data.img} alt={`${data.title} background image`}/>
            <p className='cn__card3__img__title'>
                {data.title}
            </p>
        </div>
    );

    const renderEl = linkType.type === 'a' ? (
        <div className='cn__card cn__card3'>
            <a href={data.url} target={linkType.target}>
                {childRender}
            </a>
        </div>
    ) : (
        <div className='cn__card cn__card3' onClick={() => handleJSClick(data.url,linkType.target)}>
            {childRender}
        </div>
    );

    return renderEl;

};

export default memo(Card3);
