import clsx from 'clsx';
import React, { memo } from 'react';

const Search = ({ className }) => (
    <svg
        className={clsx('search-icon', className)}
        width="25"
        height="26"
        viewBox="0 0 25 26"
        fill="none"
       
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14.7678 8.19697C16.7204 10.1496 16.7204 13.3154 14.7678 15.268C12.8152 17.2207 9.64933
                17.2207 7.6967 15.268C5.74408 13.3154 5.74408 10.1496 7.6967 8.19697C9.64933 6.24435
                12.8152 6.24435 14.7678 8.19697ZM16.839 15.925C18.8946 13.1832 18.6756 9.27635 16.182
                6.78276C13.4483 4.04909 9.01616 4.04908 6.28249 6.78276C3.54882 9.51643 3.54882 13.9486
                6.28249 16.6822C8.77608 19.1758 12.6829 19.3948 15.4248 17.3392L18.3033 20.2178C18.6938
                20.6083 19.327 20.6083 19.7175 20.2178C20.108 19.8273 20.108 19.1941 19.7175
                18.8036L16.839 15.925Z"
            fill="#242526"
        />
    </svg>
);

export default memo(Search);
