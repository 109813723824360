import React, { memo } from 'react';

//Import methods
import useCurrencyConverter from '@/src/utils/customHooks/useCurrencyConverter';
import { handleJSClick } from '@/components/Slider/utils';

//Components
import Image from '@/containers/newHomePage/components/Image';

const Card4 = ({ data, linkType, index, getparent }) => {

    //Price converter
    const convertTo = useCurrencyConverter();

    let finalPrice = null;
    if (data?.price) {
        const price = typeof window === 'undefined' ? data.price : convertTo({
            amount: data.price,
        });
        finalPrice = `${price.currency_symbol}${price.amount}`;
    }

    //Methods
    const hoveCheck = (e, status, getparent) => {

        const parent = getparent();
        let el = e.currentTarget;
        if (status) {
            const current = parent.querySelector('.cnt__card4-current');
            if (el !== current) {
                current?.classList.remove('cnt__card4-current');
                el.classList.add('cnt__card4-current');
            }
        }

    }

    //Render
    const childRender = (
        <div className='cn__card4__img'>
            <Image
                src={data.src}
                alt={`${data.title} background image`}
                sources={data.sources}
                loading={data?.loading}
            />
            <div className='cn__card4__img__info'>
                <p className='cn__card4__img__info__title'>{data.title}</p>
                {finalPrice &&
                    <p className='cn__card4__img__info__subtitle'>
                        From <span className='cn__card4__img__info__subtitle__price'>{finalPrice}</span> per night
                    </p>}
            </div>
        </div>
    );

    const renderEl = linkType.type === 'a' ? (
        <div
            className={`cn__card cn__card4 ${index === 0 ? 'cnt__card4-current' : ''}`}
            onMouseEnter={(e) => hoveCheck(e, true, getparent)}
            onMouseLeave={(e) => hoveCheck(e, false, getparent)}
        >
            <a href={data.url} target={linkType.target}>
                {childRender}
            </a>
        </div>
    ) : (
        <div
            className={`cn__card cn__card4 ${index === 0 ? 'cnt__card4-current' : ''}`}
            onClick={() => handleJSClick(data.url, linkType.target)}
            onMouseEnter={(e) => hoveCheck(e, true, getparent)}
            onMouseLeave={(e) => hoveCheck(e, false, getparent)}
        >
            {childRender}
        </div>
    );

    return renderEl;

};

export default memo(Card4);
