import { getItem, setItem } from "./localStorageService";

export const validateHistorySearches = historySearches => [historySearches]
    .flat()
    .filter(Boolean)
    .filter(({ addrData: { name, address, formattedAddress } }) => {
        if (typeof address !== 'string') {
            return false;
        }

        const str = [
            name,
            formattedAddress
        ].join();

        if (str.includes('undefined') || str.includes('null')) {
            return false;
        }

        return true;
    });

export const getHistorySearches = (key = 'recentSearch') => validateHistorySearches(
    getItem(key, { defaultValue: [] })
);

export const persistHistorySearches = (historySearches = [], key = 'recentSearch') => {
    const data = validateHistorySearches(historySearches);

    setItem(key, data);

    return data;
};
