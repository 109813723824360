export const handleArrowClick = (getparent,margin,varWidth, index) => {

    const parent = getparent();
    //Cards
    let scrollContent = parent.querySelector('.simplebar-content-wrapper');
    let cardWidth = !varWidth ? parent.querySelector('.cn__card').getBoundingClientRect().width: parent.querySelector('.cn__card:not(.cnt__card4-current)').getBoundingClientRect().width;
    let totalMove = cardWidth + margin;
    if(varWidth){
        let getCards = [...parent.querySelectorAll('.cn__card')];
        let cardCurrent = parent.querySelector('.cnt__card4-current');
        cardCurrent.classList.remove('cnt__card4-current');
        getCards[index].classList.add('cnt__card4-current');
    }
    scrollContent.scroll({ left: totalMove*index, behavior: 'smooth' });
}

export const setCard = (parent, margin, count) => {
    if(window.innerWidth > 1136){
        if(count > 0){
            const cardSum = margin / count;
            const cardWidth = (parent.offsetWidth / count) + cardSum;
            parent.style.setProperty('--card-width', `${cardWidth}px`);
        }
        parent.style.setProperty('--card-gap', `${margin}px`);
    }

};

export const setArrows = (parent,margin,cardsWrapper,arrowState) => {

    let scrollContent = parent.querySelector('.simplebar-content-wrapper').offsetWidth + margin;
    let cardWidth = parseInt(getComputedStyle(parent).getPropertyValue('--card-width'));
    let cardsTotal = cardsWrapper.querySelectorAll('.cn__card').length;
    let cardsContent = cardWidth * cardsTotal;

    if(cardsContent <= scrollContent){
        arrowState(true);
    }

}

export const sliderScrolling = (parent) => {
    // 5 is approx value for forcing it to be true bracuse in some resolution the scrollLeft is producing float values of different 2 or sometime 1.
    const approx = 5
    
    let scrollContent = parent.querySelector('.simplebar-content-wrapper');
    //Arrows
    let arrowLeft = parent.querySelector('.cn__slider__arrows__prevarrow');
    let arrowRight = parent.querySelector('.cn__slider__arrows__nextarrow');
    if(scrollContent.scrollLeft === 0){
        arrowLeft?.classList.add('cn__slider__arrows__disabled')
    }else{
        arrowLeft?.classList.remove('cn__slider__arrows__disabled');
    }

    // console.log(Math.abs(scrollContent.scrollLeft), scrollContent.scrollWidth - scrollContent.clientWidth); 
    
    if(Math.abs(scrollContent.scrollLeft) >= (scrollContent.scrollWidth - scrollContent.clientWidth - approx)){
        arrowRight?.classList.add('cn__slider__arrows__disabled');
    }else{
        arrowRight?.classList.remove('cn__slider__arrows__disabled');
    }

}

export const handleJSClick = (url,link = '_self') => {
    window.open(url,link);
}
