import { createContext } from "react";

export const settings = {
    styles: {
        container: {
            overflow: 'hidden',
            position: 'relative',
            width: '100%',
            px: '20px',
            my: '50px',
            mx: 'auto',
            py: 0,
            borderRadius: '1rem',

            '@media (min-width: 1137px)': {
                my: '100px',
                px: '100px'
            }
        },
        wrapper: {
            backgroundRepeat: 'no-repeat',
            width: 'inherit',
            maxWidth: 1920,
            borderRadius: 'inherit',
            mx: 'auto'
        }
    }
};

export const SettingsContext = createContext(settings);

export default settings;
