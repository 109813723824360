import { gsap } from "gsap/dist/gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import { CustomEase } from "gsap/dist/CustomEase";

//--------------- Sections Animation ---------------//

const appear = (el,el2) => {
    let elV = el2 ? el2 : el;
    const startPoint = window.innerWidth > 600 ? "90%" : "90%";
    gsap.to(elV,{
        scrollTrigger:{
            id: "learn2",
            trigger:el,
            start: `top ${startPoint}`,
            end:'top center',
            scrub: 1,
        },
        opacity:1,
        ease:'none'
    });
}

export const secsAnim = ({trigger,reference1,reference2,el1,el2,el3,el4,el5}) => {
    gsap.registerPlugin(ScrollTrigger,CustomEase);
    appear(reference1,reference2);
    if(el1){
        appear(el1)
    };
    appear(el2);
    appear(el3);
    appear(el4);
    if(el5){
        appear(el5);
    }
}


