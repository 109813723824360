import useDeviceDetect from '@/src/utils/customHooks/useDeviceDetect';
import clsx from 'clsx';
import { useState, useEffect, useRef } from 'react';
import { Popover, ArrowContainer } from 'react-tiny-popover'


const TinyTooltip = ({
    parent,
    content,
    children,
    containerClassName,
    arrowSize = 12,
    closeDelay,
    isHover,
    isOpen
}) => {
    const [isPopoverOpen, setIsPopoverOpen] = useState(false);
    const { isDesktop } = useDeviceDetect();

    // const handleMouseEnter = () => setIsPopoverOpen(true);
    // const handleMouseLeave = () => setIsPopoverOpen(false);

    const handleMouseEnter = isDesktop ? () => setIsPopoverOpen(true) : null;
    const handleMouseLeave = (isHover && isDesktop) ? () => setIsPopoverOpen(false) : null;;
    const handleOpen = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsPopoverOpen(true);
    };
    const handleClick = isDesktop ? null : handleOpen;
    const handleClose = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsPopoverOpen(false);
    };
    const handleInnerClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };

    // uncomment when they change their mind
    useEffect(() => {
        if (!!closeDelay && isPopoverOpen) {
            const timeoutID = setTimeout(() => setIsPopoverOpen(false), closeDelay);
            return () => clearTimeout(timeoutID);
        }
    }, [isPopoverOpen]);

    return (
        <Popover
            containerParent={parent}
            isOpen={(isOpen === undefined) ? isPopoverOpen : isOpen}
            positions={['top', 'right', 'left', 'bottom']}
            boundaryInset={10}
            padding={4}
            // onClickOutside={() => setIsPopoverOpen(false)}
            content={({ position, childRect, popoverRect }) => (
                <ArrowContainer
                    position={position}
                    childRect={childRect}
                    popoverRect={popoverRect}
                    arrowColor={'#0F1A2A'}
                    arrowSize={arrowSize}
                    arrowStyle={{ bottom: (arrowSize - 1) * (-1) }}
                    // arrowSize={12}
                    // arrowStyle={{ bottom: -11 }}
                    className={clsx('tiny-tooltip-container', containerClassName)}
                    arrowClassName='tiny-tooltip-arrow'
                >
                    <>
                        <div className='tiny-tooltip-close' onClick={handleClose}>
                            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.292893 0.292893C0.683417 -0.0976311 1.31658 -0.0976311 1.70711 0.292893L7 5.58579L12.2929 0.292893C12.6834 -0.0976311 13.3166 -0.0976311 13.7071 0.292893C14.0976 0.683417 14.0976 1.31658 13.7071 1.70711L8.41421 7L13.7071 12.2929C14.0976 12.6834 14.0976 13.3166 13.7071 13.7071C13.3166 14.0976 12.6834 14.0976 12.2929 13.7071L7 8.41421L1.70711 13.7071C1.31658 14.0976 0.683417 14.0976 0.292893 13.7071C-0.0976311 13.3166 -0.0976311 12.6834 0.292893 12.2929L5.58579 7L0.292893 1.70711C-0.0976311 1.31658 -0.0976311 0.683417 0.292893 0.292893Z" fill="white" />
                            </svg>
                            {/* <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <line x1="1.70711" y1="1" x2="11.6066" y2="10.8995" stroke="white" strokeLinecap="round" />
                                <line x1="1" y1="10.9003" x2="10.8995" y2="1.00082" stroke="white" strokeLinecap="round" />
                            </svg> */}
                        </div>
                        <p
                            className='tiny-tooltip-text'
                            onClick={handleInnerClick}
                        >
                            {content}
                        </p>
                    </>
                </ArrowContainer>
            )}
        >
            <div
                onClick={handleClick}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            >
                {children}
            </div>
        </Popover>
    );
};

export default TinyTooltip;
