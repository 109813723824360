
const AddSymb = () => (
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g opacity="0.5" filter="url(#filter0_b_2922_41042)">
            <ellipse cx="12.7931" cy="13" rx="12.6603" ry="13" fill="#797882" />
        </g>
        <path d="M8.05371 13H18.0537" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M13.0537 8V18" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <defs>
            <filter id="filter0_b_2922_41042" x="-98.8672" y="-99" width="223.32" height="224" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feGaussianBlur in="BackgroundImageFix" stdDeviation="49.5" />
                <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_2922_41042" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_2922_41042" result="shape" />
            </filter>
        </defs>
    </svg>
);

export default AddSymb;
