import React, { memo } from 'react';

//Import Libraries
import clsx from 'clsx';
import pluralize from 'pluralize';

//Import styles
import styles from '@/styles/components/GeoPages/__gp--GCS.module.scss';

//Import Component
import LinkWrapper from '../Card/LinkWrapper';

const GeoCardStays = ({data}) => {

    //---- Render
    return (
        <div className={clsx('cn__card cn__gp__geocardstays',styles.cn__gp__geocardstays)}>
            <LinkWrapper href={data.url ? data.url : ''}>
                {/* -------------------- TOP -------------------- */}
                <div className={styles.cn__gp__geocardstays__top}>
                    {/* -------------------- TOP - image -------------------- */}
                    <div className={styles.cn__gp__geocardstays__top__img}>
                        <img src={data.img} alt={``}/>
                    </div>
                </div>
                {/* -------------------- BOTTOM -------------------- */}
                <div className={styles.cn__gp__geocardstays__bottom}>
                    {/* -------------------- BOTTOM - title -------------------- */}
                    <p className={clsx('cn__gp__geocardstays__bottom__title',styles.cn__gp__geocardstays__bottom__title)}>
                        {data.type}
                    </p>
                    {/* -------------------- BOTTOM - subtitle -------------------- */}
                    <p className={styles.cn__gp__geocardstays__bottom__subtitle}>
                        Explore {data.count} {pluralize('place',data.count)} to stay
                    </p>
                </div>
            </LinkWrapper>
        </div>
    )
};

export default memo(GeoCardStays);

