import React, { memo } from 'react';
import PropTypes from 'prop-types';

const CheckMark = ({ className }) => (
    <svg className={className} width="15" height="11" viewBox="0 0 15 11" fill="none">
        <path d="M1 5L5 9L14 1" stroke="#4C35BC" strokeWidth="2" />
    </svg>
);

CheckMark.propTypes = {
    className: PropTypes.string
};

export default memo(CheckMark);
