import React, { memo } from 'react';

//Libraries
import Link from 'next/link';
import SimpleBar from 'simplebar-react';

//Import methods
import useDeviceDetect from '@/src/utils/customHooks/useDeviceDetect';

//Components
import Heading from '../../components/Heading';
import Image from '@/containers/newHomePage/components/Image';
import analyticsLabelProvider from '@/static/analyticsLabelProvider';
import { EAType } from '@/static/contants';
import pluralize from 'pluralize';
import { formatDigit } from '@/utils/globalFunc';
import clsx from 'clsx';


//Local Component
const SliderSection = ({data}) => {
    const { isDesktop, isTablet } = useDeviceDetect()
    return (
        <div
            className={clsx(
                'cn__hp__nexttrip__slider__wrapper',
                'cn__hp__nexttrip__slider__wrapper--captioned'
            )}
        >
            {data.map((el,i) => {
                return (
                    <div
                        key={i}
                        className={clsx(
                            'cn__hp__nexttrip__slider__card',
                            'cn__hp__nexttrip__slider__card--captioned'
                        )}
                        data-cn-ga-click={JSON.stringify({ el: analyticsLabelProvider.stayTypes[EAType.CLICK](el.name) })}
                    >
                        <Link href={el.url}>
                            <a className='cn__hp__nexttrip__slider__card__link'>
                                <div className='cn__hp__nexttrip__slider__card__link__top'>
                                    <Image src={isDesktop ? el.desktop_image_url : isTablet ? el.tablet_image_url : el.mobile_image_url} alt={el.name} />
                                </div>
                                <footer className='cn__hp__nexttrip__slider__card__caption'>
                                    <h3 className="cn__hp__nexttrip__slider__card__title">{el.name === 'B&Bs' ? <span>{el.name}</span>:el.name}</h3>
                                    <div className="cn__hp__nexttrip__slider__card__description">
                                        {`${formatDigit(+el.count)} ${pluralize('property', el.count)}`}
                                    </div>
                                </footer>
                            </a>
                        </Link>
                    </div>
                )
            })}
        </div>
    )
}

const ExploreStays = ({ propertyTypes }) => {

    //Check Device
    const { isDesktop } = useDeviceDetect();

    return (
        <section
            className="cn__hp__nexttrip cn__hp__nexttripfirst"
            data-cn-ga-observe={JSON.stringify({ el: analyticsLabelProvider.stayTypes[EAType.SCROLL]() })}
        >
            <Heading align={isDesktop ? "center" : "left"} tit="Explore by Property Type" sub="So many Nests, so little time!"/>
            <div className="cn__hp__nexttrip__slider">
                {isDesktop ?
                    <SliderSection data={propertyTypes}/>
                 : (
                    <SimpleBar>
                        <SliderSection data={propertyTypes}/>
                    </SimpleBar>
                )}
            </div>
        </section>
    )
}

export default memo(ExploreStays);