import { useField } from 'formik';
import ErrorMsg from '../Error';
import styles from './PasswordReset.module.scss';

const TextInput = ({
    label,
    handleChange,
    handleBlur,
    ...props }) => {
    const [field, meta] = useField(props);

    // console.log('field => ', field);
    // console.log('meta => ', meta);
    return (
        <>
            <label htmlFor={props.id || props.name} className={styles.card_form_label}>
                {label}
            </label>
            <input
                className={styles.card_form_input}
                {...field}
                {...props}
                onChange={(...args) => {
                    (!!handleChange && handleChange(args[0].target.value));
                    field.onChange(...args);
                }}
                onBlur={(...args) => {
                    (!!handleBlur && handleBlur(args[0].target.value));
                    field.onBlur(...args);
                }}
            />
            {meta.touched && meta.error ? (
                <ErrorMsg>{meta.error}</ErrorMsg>
            ) : null}
        </>
    );
};

export default TextInput;
