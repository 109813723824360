import { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { object, string } from 'yup';
import clsx from "clsx";
import { Submit, PhoneInput } from "@/components/formComponents/MaterialFormInputs";
import { signupAction } from "@/redux/actions/authActions";
import Tooltip from '@/components/common/tooltip';
import {
    SIGNUP_LOAD,
    SIGNUP_SUCCESS,
    SIGNUP_FAIL,
} from "@/redux/types";
import Checkbox from '@/components/formComponents/Checkbox/Checkbox';
import styles from './Authentication.module.scss';
import ErrorMsg from './Error';
import { PasswordEyeSVG } from './AuthSVG';
import Dots from './DotsAnimation';
import useForm from './useForm';
import usePhoneInputDecorator from './usePhoneInputDecorator';
import Final from './Final';
import PhoneVerification from './PhoneVerification';
import { useInputSelection } from '@/src/utils/customHooks/useInputSelection';
import { constants } from '@/src/utils/constants';
import ReCAPTCHA from 'react-google-recaptcha';
import { parsePhoneNumber } from 'awesome-phonenumber'


const Signup = ({ signupOption, setAuthOpen }) => {
    const [showPassword, setShowPassword] = useState({
        password: false,
        passwordRepeat: false
    });
    const isEmail = signupOption === 'email';
    const authStore = useSelector(state => state.authReducer);
    const dispatch = useDispatch();
    // screen enum: 'main', 'verification', 'final';
    const [screen, setScreen] = useState('main');
    const [captchaCode, setCaptcaCode] = useState();
    const captchaRef = useRef(null);
    const [submitCount, setSubmitCount] = useState(0);
    const [backendErr, setBackendErr] = useState({
        name: null,
        phone: null,
        email: null,
        password: null
    });
    const [passwordStatus, setPasswordStatus] = useState({
        length: false,
        case: false,
        symbol: false
    });
    const [isPasswordVerified, setPasswordVerified] = useState(false);
    const [rememberMe, setRememberMe] = useState(0);
    const phoneInputHelper = useRef(false);

    const initVals = {
        name: '',
        phone: '',
        email: '',
        password: '',
        passwordRepeat: '',
    };

    const signupSchema = object({
        name: string()
            .min(4, 'Must be at least 4 characters long')
            .max(20, 'Must not exceed 20 characters')
            .required('Required')
            .test('case', 'Must contain one character', value => /[a-zA-Z]/.test(value)),
        phone: string()
            .required('Required')
            .test('valid-phone', 'Invalid phone number', value => parsePhoneNumber("+"+value).valid),
        email: string()
            .email('Must be valid email address')
            .max(100, 'Must not exceed 100 characters')
            .required('Required'),
        password: string()
            .min(8, 'Must be at least 8 characters long')
            .max(20, 'Must not exceed 20 characters')
            .required('Required')
            .test('case', 'Password should contain at least 1 upper case letter', value => /[A-Z]/.test(value))
            .test('symbol', 'Password should contain a number or symbol', value => !/^[a-z]+$/i.test(value) && value.trim().length !== 0),
        passwordRepeat: string()
            .test('match', 'Passwords do not match', value => value === myForm.values.password)
    });

    const myForm = useForm(initVals, signupSchema);
    const onChangeReCaptcha = (e) => {
        setCaptcaCode(e)
    }
    const [
        phoneNumber,
        phoneInputKey,
        handleChangePhoneDecorated,
        handleBlurPhoneDecorated
    ] = usePhoneInputDecorator(myForm.handleChange, myForm.handleBlur);

    const updatePasswordStatus = password => {
        const status = {...passwordStatus};
        status.length = password.length >= 8;
        status.case = /[A-Z]/.test(password);
        status.symbol = !/^[a-z]+$/i.test(password) && password.trim().length !== 0;

        if (status.length && status.case && status.symbol) {
            setTimeout(() => {
                setPasswordVerified(true);
            }, 500);
        } else {
            setPasswordVerified(false);
        }
        setPasswordStatus(status);
    }

    const handleChangePasswordDecorated = (...args) => {
        updatePasswordStatus(args[0].target.value);
        const error = myForm.values.passwordRepeat ? null : 'ignore';
        return myForm.handleChange(...args, null, null, error);
    };
    const handleBlurPasswordDecorated = (...args) => {
        updatePasswordStatus(args[0].target.value);
        return myForm.handleBlur(...args, 'password', myForm.values.password);
    };


    const handleSubmit = async evt => {
        evt.preventDefault();
        setSubmitCount((count) => count + 1);
        const phoneData = isEmail ? null : {
            country_calling_code: `+${  phoneNumber[0]}`,
            phone: phoneNumber[1],
        };
        const values = {
            remember: rememberMe,
            name: myForm.values.name,
            password: myForm.values.password,
            ...(isEmail ? { email: myForm.values.email } : phoneData),
            ...(!isEmail && { "g-recaptcha-response": captchaCode }),
        };
        dispatch(signupAction(values));
        captchaRef?.current?.reset();
    };

    const updateBackendError = (field) => {
        setBackendErr((prev) => ({
                ...prev,
                [field]: authStore.message
            }));
    };

    // const Input = useInputSelection();

    useEffect(() => {
        // TODO: talk to backend - it's difficult to differentiate which field the error belongs to
        if(authStore.statusCode === 202) {
            setScreen('verification')
        }
        if ([SIGNUP_SUCCESS].includes(authStore.type)) {
            if (signupOption === 'email') {
                setScreen('final');
            } else if (signupOption === 'phone') {
                setScreen('verification');
            }
        } else if ([SIGNUP_FAIL].includes(authStore.type)) {
            if (authStore.message?.includes('hone')) {
                updateBackendError('phone');
            } else if (authStore.message?.includes('mail')) {
                updateBackendError('email');
            } else if (authStore.message?.includes('assword')) {
                updateBackendError('password');
            }
        }
    }, [authStore.type]);

    useEffect(() => {
        if (Object.values(backendErr).some(el => el) && (screen === 'main')) {
            const timeoutID = setTimeout(() => setBackendErr({
                name: null,
                phone: null,
                email: null,
                password: null
            }), 4000);
            return () => {
                clearTimeout(timeoutID);
            };
        }
    }, [backendErr]);

    const isSubmitDisabled = () => {
        const values = { ...myForm.values };
        const errors = { ...myForm.errors };
        const token = Boolean(!captchaRef.current?.getValue()) && !isEmail;
        if (isEmail) {
            delete values.phone;
            delete errors.phone;
        } else {
            delete values.email;
            delete errors.email;
        }
        const haveEmpty = Object.values(values).some(value => !value);
        const haveError = Object.values(errors).some(error => error);
        return (haveEmpty || haveError || token);
    };

    useEffect(() => {
        if (phoneInputHelper.current) {
            document.querySelector('.phone-input-component input')?.focus();
        } else {
            phoneInputHelper.current = true;
        }
    }, [phoneInputKey]);

    useEffect(() => {
        // this is a hack for passwordRepeat error
        if (myForm?.touched.passwordRepeat) {
            myForm.handleBlur(null, 'passwordRepeat', (myForm.values['passwordRepeat'] || '.'));
        }
    }, [myForm?.values.password]);

    return (
        <>
            {(screen === 'final') &&
                <Final
                    message={
                        isEmail
                            ? `A verification email has been sent to ${authStore.data.email}`
                            : 'Your mobile number has been successfully verified!'
                    }
                    linkText='Skip for now'
                    redirectUrl='/d'
                    redirectText='Complete your profile'
                    setAuthOpen={setAuthOpen}
                />}

            {(screen === 'verification') &&
                <PhoneVerification
                    phoneNumber={authStore?.payload || phoneNumber}
                    setScreen={setScreen}
                />}

            {(screen === 'main') && (<>
                <div className={styles.auth_title}>
                    Sign up with {isEmail ? 'email' : 'mobile number'}
                </div >
                <form className={styles.auth_form} onSubmit={handleSubmit}>
                    <ul className={styles.auth_form_ul}>
                        <li className={styles.auth_form_item}>
                            <input
                                id='name'
                                name='name'
                                type='text'
                                placeholder='Name'
                                className={clsx(styles.auth_form_input)}
                                onChange={myForm.handleChange}
                                onBlur={myForm.handleBlur}
                                value={myForm.values.name}
                            />
                            {myForm.errors.name &&
                                <ErrorMsg>{myForm.errors.name}</ErrorMsg>}
                            {backendErr.name && (submitCount > 0) && <ErrorMsg>{backendErr.name}</ErrorMsg>}
                        </li>
                        {isEmail
                            ? (
                                <li className={styles.auth_form_item}>
                                    <input
                                        id='email'
                                        name='email'
                                        type='email'
                                        placeholder='Email address'
                                        className={clsx(styles.auth_form_input)}
                                        onChange={myForm.handleChange}
                                        onBlur={myForm.handleBlur}
                                        value={myForm.values.email}
                                    />
                                    {myForm.errors.email &&
                                        <ErrorMsg>{myForm.errors.email}</ErrorMsg>}
                                    {backendErr.email && (submitCount > 0) && <ErrorMsg>{backendErr.email}</ErrorMsg>}
                                </li>
                            )
                            : (
                                <li className={styles.auth_form_item}>
                                    <PhoneInput
                                        containerClass={clsx(styles.auth_form_item__phone_container,
                                            (myForm.values.phone && styles.auth_form_item__phone_container_selected))}
                                        placeholder='country code+phone number'
                                        value={phoneNumber[0] ? phoneNumber.join('') : `${phoneNumber[1]}`}
                                        onChange={handleChangePhoneDecorated}
                                        onBlur={handleBlurPhoneDecorated}
                                        key={phoneInputKey}
                                    />
                                    {myForm.errors.phone &&
                                        <ErrorMsg>{myForm.errors.phone}</ErrorMsg>}
                                    {backendErr.phone && (submitCount > 0) && <ErrorMsg>{backendErr.phone}</ErrorMsg>}
                                </li>
                            )}
                        <li className={styles.auth_form_item}>
                            { !isPasswordVerified && <Tooltip
                                place="top"
                                type="dark"
                                html={false}
                                globalEventOff='click'
                                className={styles.tooltipPassword}
                            >
                                <ul style={{ 
                                        fontSize: '12px',
                                        lineHeight: '18px',
                                        listStyleType: 'disc' 
                                }}>
                                    <li style={passwordStatus.length? {color: "#2B8A4B"} : null}>At least 8 characters</li>
                                    <li style={passwordStatus.case? {color: "#2B8A4B"} : null}>At least 1 upper case letter (A-Z)</li>
                                    <li style={passwordStatus.symbol? {color: "#2B8A4B"} : null}>Contains a number or symbol (1,2, !?$…)</li>
                                </ul>
                            </Tooltip> }
                            <input
                                id='password'
                                name='password'
                                type={showPassword.password ? 'text' : 'password'}
                                data-tip
                                data-event='click'
                                placeholder='Password'
                                className={clsx(styles.auth_form_input,
                                    styles.auth_form_input_password)}
                                onChange={handleChangePasswordDecorated}
                                onBlur={handleBlurPasswordDecorated}
                                value={myForm.values.password}
                            />
                            <span
                                className={styles.auth_form_item__symbol}
                                onClick={() => setShowPassword(currState => ({
                                        ...currState,
                                        password: !currState.password
                                    }))}
                            >
                                <PasswordEyeSVG show={showPassword.password} />
                            </span>
                            {myForm.errors.password &&
                                <ErrorMsg>{myForm.errors.password}</ErrorMsg>}
                            {backendErr.password && (submitCount > 0) && <ErrorMsg>{backendErr.password}</ErrorMsg>}
                        </li>
                        <li className={styles.auth_form_item}>
                            <input
                                id='passwordRepeat'
                                name='passwordRepeat'
                                type={showPassword.passwordRepeat ? 'text' : 'password'}
                                placeholder='Confirm password'
                                className={clsx(styles.auth_form_input,
                                    styles.auth_form_input_password)}
                                onChange={myForm.handleChange}
                                onBlur={myForm.handleBlur}
                                value={myForm.values.passwordRepeat}
                            />
                            <span
                                className={styles.auth_form_item__symbol}
                                onClick={() => setShowPassword(currState => ({
                                        ...currState,
                                        passwordRepeat: !currState.passwordRepeat
                                    }))}
                            >
                                <PasswordEyeSVG show={showPassword.passwordRepeat} />
                            </span>
                            {myForm.errors.passwordRepeat &&
                                <ErrorMsg>{myForm.errors.passwordRepeat}</ErrorMsg>}
                        </li>
                        <li className={styles.auth_form_item}>
                            <Checkbox
                                id='checkbox'
                                className={styles.auth_checkbox}
                                boxClass={styles.auth_checkbox_box}
                                markClass={styles.auth_checkbox_mark}
                                textClass={styles.auth_checkbox_text}
                                checked={rememberMe}
                                onChange={() => setRememberMe(state => !state)}
                            >
                                Remember me
                            </Checkbox>
                        </li>
                        {authStore?.message?.includes('aptcha') && <ErrorMsg>{authStore?.message}</ErrorMsg>}
                        {!isEmail && 
                            <ReCAPTCHA
                                sitekey={constants.dashboardResendTokenRecaptchaKey}
                                onChange={onChangeReCaptcha}
                                className={styles.recaptcha}
                                ref={captchaRef}
                            />}
                        <li className={styles.auth_form_item}>
                            <Submit
                                type="submit"
                                className={styles.auth_form_submit}
                                disabled={isSubmitDisabled()}
                            >
                                {authStore.type === SIGNUP_LOAD
                                    ? <Dots container={styles.loading} />
                                    : 'Sign up'}
                            </Submit>
                        </li>
                    </ul>
                </form>
            </>)}
        </>
    );
};

export default Signup;
