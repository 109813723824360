import { memo } from "react";
import Box from '@mui/material/Box';
import { Screen } from "../Mobile";
import StepEnum from '../enum';
import { useOmnisearchState } from "../contexts/OmnisearchState";
import SearchActionArea from "../SearchActionArea";
import useProceedToSearch from '../useProceedToSearch';
import InterestTilesMobile from "./InterestTilesMobile";
import useInterests from "./useInterests";
import InterestsChip from "./InterestsChip";

const InterestsPickerMobile = ({ onClose }) => {
    const { step, destination, dates, guests } = useOmnisearchState();

    const [stepState, setStepState] = step;

    const [destinationState] = destination;
    const [datesState] = dates;
    const [guestsState] = guests;

    const { state, reset } = useInterests();

    const onProceedToSearch = useProceedToSearch();

    const onGoBack = () =>
        setStepState(StepEnum.DATES);

    const handleProceedToSearch = () =>
        onProceedToSearch(destinationState, datesState, guestsState);
    const handleSkip = () => {
        if(state?.length){
            reset()
        } else {
            handleProceedToSearch()
        }
    }
    return (
        <Screen
            className='omnisearch_input-wrapper_mobile_screen_interests-picker'
            headerContentSlot={
                <div className="omnisearch_input-wrapper_mobile_screen_interests-picker_head">
                    <div className="omnisearch_input-wrapper_mobile_screen_interests-picker_head_title">
                        Your Trip
                    </div>
                </div>
            }
            clearSlot={(
                <Box
                    component='button'
                    className="omnisearch_input-wrapper_mobile_screen_footer_clear-btn"
                    type='button'
                    onClick={handleSkip}
                    sx={{
                        fontWeight: 600
                    }}
                >
                    {Boolean(state?.length) ? 'Clear' : 'Skip'}
                </Box>
            )}
            proceedToNextSlot={
                <SearchActionArea
                    isMobile
                    onClick={handleProceedToSearch}
                />
            }
            priority={6}
            isOpen={[StepEnum.INTERESTS].includes(stepState)}
            onGoBack={onGoBack}
            onCancel={handleProceedToSearch}
            onClose={onClose}
        >
            <Box>
                <Box
                    sx={{
                        position: 'relative',
                        fontSize: '18px',
                        lineHeight: '21px',
                        fontWeight: 600,
                        fontFamily: 'var(--primary-font)',
                        pb: '19px',
                        px: '47px',
                        height: !!state?.length ? '130px' : '70px'
                    }}
                >
                    <Box
                        sx={{
                            color: 'var(--color-pure-black)',
                            span: {
                                color: 'var(--color-cn-pink)'
                            },
                            borderBottom: '0.5px solid #E9E9EB',
                            pb: '20px'
                        }}
                    >
                        Select top interests or activities closest to your stay<span>.</span>
                    </Box>
                    <Box
                        sx={{
                            position: 'absolute',
                            top: '60px',
                            left: 0,
                            height: '100%',
                            width: '100%',
                            px: '47px',
                            pt: '15px',
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        {!!state?.length &&
                            <InterestsChip
                                count={state?.length}
                                onClick={() => reset()}
                            />}
                    </Box>
                </Box>
                <Box
                    sx={{
                        px: '33px',
                        maxHeight: `calc(100vh - 238px)`,
                        overflow: 'auto'
                    }}
                >
                    <InterestTilesMobile />
                </Box>
            </Box>
        </Screen>
    );
};

export default memo(InterestsPickerMobile);
