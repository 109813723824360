import React, { memo } from 'react';

//Import Libraries
import clsx from 'clsx';
import { EAType } from '@/static/contants';
import analyticsLabelProvider from '@/static/analyticsLabelProvider';
import Dots from '@/components/Authentication/DotsAnimation';

const gaLabelMapper = {
    house: JSON.stringify({ el: analyticsLabelProvider.listing[EAType.CLICK]() }),
    blog: JSON.stringify({ el: analyticsLabelProvider.featuredThisWeek.viewAll[EAType.CLICK]() })
};

const Button = ({htmlType = "button", type = "",title,click, className, disabled = false, isLoading= false}) => {

    let source = type === 'house' ? '':'';
    let namedClass;
    switch(type) {
        case "tag":
            namedClass = 'cn__button__grey';
            source = '/static/staticpages/home/button/tag.svg';
            break;
        case "house":
            namedClass = 'cn__button__linear';
            source = '/static/staticpages/home/button/house.svg';
            break;
        case "envelope":
            namedClass = 'cn__button__purple';
            source = '/static/staticpages/home/button/envelope.svg';
            break;
        case "blog":
            namedClass = 'cn__button__grey';
            source = '/static/staticpages/home/button/blog.svg';
            break;
        default:
            namedClass = 'cn__button__linearnew';
            source = null;
    };

    return (
        <button
            className={clsx("cn__button", namedClass, className)}
            onClick={click}
            data-cn-ga-click={gaLabelMapper[type]}
            disabled={disabled}
            type={htmlType}
        >    
            {source && <img src={source} alt="button icon"/>}
            {isLoading ? <Dots /> : <p className="cn__button__title">{title}</p>}
        </button>
    )
}

export default memo(Button);