import { useSelector, shallowEqual } from "react-redux";
import { isServer } from "@/providers/Environment";
import { getItem } from "@/utils/localStorageService";

export default function useUser() {
    return useSelector(selectUser, shallowEqual);
};

function selectUser(state) {
    const userData = state.authReducer.data;

    if (isServer()) {
        return userData;
    }

    return userData ?? getItem('userData');
}
