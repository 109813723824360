import React, { memo } from 'react';

//Import components
import Portal from '@/components/common/Portal';
import CurrencyBox from './components/CurrencyBox';
import { useCurrencyContext } from '../../CurrencyProvider';


const CurrencyMobile = ({info}) => {
    const {
        isOpen,
        open,
        setOpen
    } = useCurrencyContext();

    return (
        <div
            className='cn__currency__mobile'
            onClick={open}
        >
            <div className='cn__currency__mobile__info'>
                <img src="/static/home/currency2.svg" className='cn__currency__mobile__info__icon' alt='currency icon'/>
                <div className='cn__currency__mobile__info__info'>
                    <p className='cn__currency__mobile__info__info__title'>{info.currency.long}</p>
                    <p className='cn__currency__mobile__info__info__subtitle'>{info.currency.short} - {info.currency.symbol}</p>
                </div>
            </div>
            <svg width="8" height="14" viewBox="0 0 8 14" fill="none" className='cn__currency__mobile__arrow'>
                <path d="M1 13L6 7L1 1" stroke="#242526" strokeWidth="2"/>
            </svg>
            {isOpen && (
                <Portal>
                    <CurrencyBox
                        state={setOpen}
                        info={info}
                    />
                </Portal>
            )}
        </div>
    )
};

export default memo(CurrencyMobile);