import { memo } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import GradientCircularProgress from '@/components/common/CircularProgress/GradientCircularProgress';


function PricePlaceholder({ spinnerProps = {}, textProps = {} }) {
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                gap: '8px',
            }}
        >
            <GradientCircularProgress
                size={'32px'}
                {...spinnerProps}
            />
            <Typography
                maxWidth={120}
                textAlign={'left'}
                fontFamily={'inherit'}
                fontSize={'14px'}
                fontWeight={600}
                lineHeight={1.2}
                color={'var(--color-cn-pink)'}
                {...textProps}
            >
                {"Searching lowest price..."}
            </Typography>
        </Box>
    );
}

export default memo(PricePlaceholder);
