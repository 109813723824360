import { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { PromoInclusions } from "@/static/contants";
import { filterTypes } from "@/containers/searchResults/enum";
import {
    SEARCH_RESULTS_FILTERS_AMENITIES,
    SEARCH_RESULTS_FILTERS_REVIEW_SCORE,
    SEARCH_RESULTS_FILTERS_RECOMMENDED
} from "@/redux/types";
import { useFilterAggregator } from "@/containers/searchResults/hooks";
import RecommendedEntity from "./RecommendedEntity";
import { BusinessLogicException } from "@/exceptions/BusinessLogicException";

export const recommendedWiredKeys = {
    ...PromoInclusions,
    PayLater: 'Pay Later',
    LessThan5KmFromCenter: 'Less than 5km from center',
    LessThan5MilesFromCenter: 'Less than 5miles from center'
};
class PopularFiltersError extends BusinessLogicException {
    constructor(message) {
        super(message);
        this.name = 'PopularFiltersError';
    }
}

export default function useRecommended(onSave) {
    const popular = useSelector(state => state.searchResults.filtersModel.popular);

    const reviewScore = useSelector(state => state.searchResults[filterTypes.review_score]);
    const recommended = useSelector(state => state.searchResults[filterTypes.recommended]);
    const amenities = useSelector(state => state.searchResults[filterTypes.amenities]);

    const saveSettings = useMemo(() => ({
        requestOptions: {
            withSession: true
        }
    }), []);

    const recommendedMapping = [
        new RecommendedEntity({
            action: SEARCH_RESULTS_FILTERS_REVIEW_SCORE,
            model: reviewScore,
            values: ['Excellent: 9+'],
            onChange: useFilterAggregator(
                onSave(SEARCH_RESULTS_FILTERS_REVIEW_SCORE, saveSettings),
                reviewScore
            )
        }),
        new RecommendedEntity({
            action: SEARCH_RESULTS_FILTERS_RECOMMENDED,
            model: recommended,
            values: [
                recommendedWiredKeys.LessThan5KmFromCenter,
                recommendedWiredKeys.LessThan5MilesFromCenter,
                recommendedWiredKeys.BREAKFAST_INCLUDED,
                recommendedWiredKeys.FREE_CANCELLATION,
                recommendedWiredKeys.PayLater
            ],
            onChange: useFilterAggregator(
                onSave(SEARCH_RESULTS_FILTERS_RECOMMENDED, saveSettings),
                recommended
            )
        }),
        new RecommendedEntity({
            action: SEARCH_RESULTS_FILTERS_AMENITIES,
            model: amenities,
            values: ['Pets allowed', 'Free parking', 'Scenic view'],
            onChange: useFilterAggregator(
                onSave(SEARCH_RESULTS_FILTERS_AMENITIES, saveSettings),
                amenities
            )
        })
    ];

    const getEntityByValue = useCallback(value => {
        for (const entity of recommendedMapping) {
            if (entity.hasValue(value)) {
                return entity;
            }
        }
        throw new PopularFiltersError(`${value} doesn't have corresponding entity`);
    }, [recommendedMapping]);

    const onClear = useCallback(() => {
        const actions = [];
        const values = [];

        recommendedMapping.forEach(entity => {
            actions.push(entity.action);
            values.push(entity.getClearValue());
        });

        onSave(actions, saveSettings)(values);
    }, [
        recommendedMapping,
        onSave,
        saveSettings
    ]);

    const popularFilterModel = useMemo(() =>
        [popular]
            .flat()
            .filter(popularModelEntity =>
                Boolean(
                    popularModelEntity &&
                    getEntityByValue(popularModelEntity.name)
                )
            ), [popular, getEntityByValue]);

    const isPopularActive = useCallback(value => Boolean(
        popularFilterModel.find(({ name }) => name === value)
    ), [popularFilterModel]);

    const getActiveCount = useCallback(() =>
        recommendedMapping.reduce((totalCount, recommendedEntity) => {
            const { values } = recommendedEntity;

            return values.reduce((accTotalCount, value) =>
                accTotalCount + Number(
                    isPopularActive(value) && recommendedEntity.isModelContainsValue(value)
                ),
                totalCount
            )
        }, 0), [
        recommendedMapping,
        isPopularActive
    ]);

    return {
        popularFilterModel,
        getEntityByValue,
        onClear,
        isPopularActive,
        getActiveCount
    };
}
