import Interests from "@/components/common/icons/Interests";
import { memo } from "react";
import { ActivityErrorTypes } from "../enum";
import InterestsPopover from "./InterestsPopover";
import useInterestsPopover from "./useInterestsPopover";

const SelectionMismatchInterestsPopover = () => {
    const { isOpen, anchorElementRef } = useInterestsPopover({
        anchorElementCssSelector: '.js-omnisearch_content_interests-trigger',
        validationErrorType: ActivityErrorTypes.ActivitiesOutsideSelectedRange
    });

    return (
        <InterestsPopover
            className='omnisearch_activities-popover_selection-mismatch'
            isOpen={isOpen}
            anchorElementRef={anchorElementRef}
            offset={{
                y: 12,
                x: -5
            }}
            icon={<Interests />}
            triangleStyles={{
                bottom: '100%',
                borderWidth: '0 10px 13px 10px',
                borderColor: 'transparent transparent var(--color-pastel-red) transparent'
            }}
        >
            Please try different activities for this city.
        </InterestsPopover>
    );
};

export default memo(SelectionMismatchInterestsPopover);
