// ========== DESKTOP ==========

export const enterAnimation = (parent,child) => {
    setTimeout(() => {
        parent.classList.add('cn__currencydesktop--active');
        setTimeout(() => {
            child.classList.add('cn__currencydesktopchild--active');
        },300);
    },10);
};

export const leaveAnimation = (parent,child) => {
    child.classList.remove('cn__currencydesktopchild--active');
    setTimeout(() => {
        parent.classList.remove('cn__currencydesktop--active');
    },300);
};

export const closePortal = (parent,child,state) => {
    leaveAnimation(parent,child);
    setTimeout(() => {
        lockBody(false);
        state(false);
    },10)
};

// ========== MOBILE ==========

export const animationMobile = (parent,type = 'leave') => {
    if(type === 'enter'){
        setTimeout(() => {
            parent.classList.add('cn__currencymobile--active');
        },100);
    }else{
        parent.classList.remove('cn__currencymobile--active');
    }
};

export const closePortalMobile = (parent,state) => {
    animationMobile(parent);
    setTimeout(() => {
        state(false);
    },400)
};

// ========== GENERAL ==========

export const lockBody = (status) => {
    const body = document.getElementsByTagName('body')[0];
    if(status){
        body.style.cssText = 'overflow: hidden';
    }else{
        body.style.cssText = '';
    }
}
