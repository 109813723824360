import LogoBirds from "@/components/common/logoBirds";
import { constants } from "@/src/utils/constants";
import { useRouter } from "next/router";
import React, { memo, useEffect, useState } from "react";



const VerifySubscription = () => {

  const [openModal, setOpenModal] = useState(false)
  const router = useRouter();

  const closeModal = () => {
      setOpenModal(false)
  }
 
  useEffect(() => {
    if(router.query?.subscription === constants.trueString){
        setOpenModal(true)
    }
  },[router])
  
  if(!openModal){
      return <></>;
    }

  return (
    <div className="cn__hp__hero__verification">
        <div className="cn__hp__hero__verification__close">
            <span onClick={closeModal}>x</span>
        </div>
        <div className="cn__hp__hero__verification__logo">
            <svg width="85" height="59" viewBox="0 0 85 59" fill="none">
                <path d="M54.3673 22.7431C54.3673 22.7431 57.8757 26.3747 64.2076 27.8233C68.0414 21.8246 75.8804 18.4763 84.2893 17.2498C74.9771 16.622 71.2853 17.8512 69.3532 18.059C67.1532 18.2956 60.9354 13.4854 54.3673 22.7431Z" fill="#FA368B"/>
                <path d="M31.4865 29.4648C31.4865 29.4648 28.3422 33.3881 26.2734 37.6355C27.7202 35.4435 29.4895 33.4059 33.7876 31.1835L31.4865 29.4648Z" fill="#4C35BC"/>
                <path d="M23.0026 13.8595C24.3252 15.1258 25.189 16.2206 25.9943 17.3952C22.2418 15.5126 15.6944 15.6058 11.5234 16.1699C13.8468 15.0879 19.1572 13.6552 23.0026 13.8595Z" fill="#4C35BC"/>
                <path d="M46.6075 13.0848C46.6075 13.0848 48.854 14.5409 49.8663 17.3803C51.8089 13.5256 59.2027 10.3421 64.1998 9.08453C58.5435 9.27873 56.3923 10.2475 55.2393 10.4919C53.927 10.77 48.6957 8.91784 46.6075 13.0848Z" fill="#4C35BC"/>
                <path d="M19.705 51.7249C19.705 51.7249 30.9179 41.5519 43.1167 41.5519C56.8355 41.5519 60.799 34.9581 63.9557 28.5226C63.9557 28.5226 53.3789 27.1109 48.5811 15.9582C48.5811 15.9582 37.1419 0.0291169 6.23212 1.02532C6.23212 1.02532 23.771 11.3037 28.0421 18.2165C32.4199 25.302 43.7417 33.0047 43.2628 32.7688C33.348 27.8946 26.6045 18.6328 26.3762 18.0377C26.1481 17.4427 16.7452 14.7006 0.311035 20.2238C0.311035 20.2238 17.9695 16.8098 38.1059 33.6441C38.1059 33.6441 36.0701 34.5574 34.6105 35.5648C25.584 41.7938 19.6822 51.7306 19.705 51.7249Z" fill="#FA368B"/>
            </svg>
        </div>
        <div className="cn__hp__hero__verification__title">
            Welcome to the community!
        </div>
        <div className="cn__hp__hero__verification__subtilte">
            Expect endless travel tips, incentives and unmissable deals on accommodation 
            in over 80,000 cities.
        </div>
        <div className="cn__hp__hero__verification__action">
           <span onClick={closeModal}>Explore</span> 
        </div>
    </div>
  );
};

export default memo(VerifySubscription);
