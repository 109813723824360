import React, { memo, useEffect, useRef, useState } from "react";

// Import Libraries
import { gsap } from "gsap/dist/gsap";

// Import components
import Omnisearch from "@/containers/homePage/Omnisearch";
import useDeviceDetect from "@/src/utils/customHooks/useDeviceDetect";
import { setSwitchToAppCookie } from "../../../SwitchToApp";

// Local Vars
const words = [
  "solo trip",
  "adventure travel",
  "romantic getaway",
  "group trip",
  "family vacation",
  "remote work travel",
  "business trip",
];

const Words = () => {
  // state
  const { isMobile } = useDeviceDetect()
  // Word animation
  useEffect(() => {
    // Detecting wheter day or night
    const heroSubtileText = document.querySelector('.cn__hp__hero__words__subtitle');
    let time = new Date().getHours();
        if(time < 6 || time > 18){
          heroSubtileText.classList.add('cn__hp__hero__words__subtitle__night');
      }
  }, []);

  return (
    <div className="cn__hp__hero__words">
      <h1 className="cn__hp__hero__words__title">
        Find places to stay near your preferred
        <br />
        <span>
          <p>interests & activities</p>
        </span>
      </h1>
      <p className="cn__hp__hero__words__subtitle">
         {isMobile
          ? 'Browse, Book & Stay in over 180 countries'
          : 'Browse, Book & Stay in millions of listings, across more than 180 countries'}
      </p>
      <Omnisearch
        className="cn__hp__omnisearch"
        withRecentSearches={false}
        onMobileOmnisearchOpen={setSwitchToAppCookie}
      />
    </div>
  );
};

export default memo(Words);
