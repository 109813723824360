import ViewportMapBounds from '@/components/map/dto/ViewportMapBounds';
import General from '@/components/map/helpers/General';
import { useDates } from '@/containers/searchResults/hooks';
import { constants } from '@/utils/constants';
import useGenerateLink from '@/utils/customHooks/useGenerateLink';
import { currentPositionPromise } from '@/utils/currentPosition';
import { useCallback } from 'react';
import useInterests from './Interests/useInterests';
import { useQueryParam } from './contexts/QueryParam';
import { DateRangeModel } from './DateRange';
import useRecentSearch from './useRecentSearch';
import useValidationGate from './useValidationGate';
import { useStore } from 'react-redux';
import InvalidSearchCriteriaException from './exceptions/InvalidSearchCriteriaException';

export default function useProceedToSearch({
    getValidationValues = (...args) => args
} = {}) {
    const store = useStore();
    const { isInvalid, validateEntities } = useValidationGate();
    const generateLink = useGenerateLinkAdapter();
    const { updateRecentSearch } = useRecentSearch();
    const getDates = useDates();
    const { state: selectedInterests } = useInterests();
    const getRedirectLink = useCallback(async (destinationState, validatedDatesState, guestsState, recentInterest) => {
        const { startDate, endDate } = validatedDatesState.getValidDates('YYYY-MM-DD');
        const { adultCount, childCount, infantCount, roomCount } = guestsState;
        const response = await currentPositionPromise;
        const getIntersts = () => {
            if(recentInterest?.length > 0) {
                return recentInterest
            }
            return selectedInterests
        }
        const interestsParam =  getIntersts().flatMap(({ subCategories }) =>
        subCategories.map(subCategoryName =>
            ['activity[]', subCategoryName]))
        const queryParamsBase = [
            ['checkin', startDate],
            ['checkout', endDate],
            ['adults', adultCount],
            ['children', childCount],
            ['childrenAges', guestsState.children.join(',')],
            ['infants', infantCount],
            ['rooms', roomCount],
            ['user_country', response.data?.result.country || 'United States'],
            ...interestsParam,
        ]
            .filter(([, value]) => value !== '');

        return generateLink(queryParamsBase, destinationState);
    }, [generateLink, selectedInterests]);

    return useCallback(async (destinationState, datesState, guestsState, recentInterest) => {
        if (isInvalid(
            ...getValidationValues(
                destinationState,
                datesState,
                guestsState
            )
        )) {
            throw new InvalidSearchCriteriaException(
                'Invalid search parameters',
                validateEntities(destinationState, datesState)
            );
        }
        const getIntersts = () => {
            if(recentInterest?.length > 0) {
                return recentInterest
            }
            return selectedInterests
        }

        const validatedDatesState = new DateRangeModel(getDates({
            checkin: datesState.startDate,
            checkout: datesState.endDate,
            isVR: false
        }));
        updateRecentSearch(destinationState, validatedDatesState, guestsState, getIntersts());

        window.location.href = await getRedirectLink(destinationState, validatedDatesState, guestsState, recentInterest);
    }, [
        getDates,
        updateRecentSearch,
        getRedirectLink,
        isInvalid
    ]);
}

function useGenerateLinkAdapter() {
    const generateLink = useGenerateLink();

    const getSRPParams = useSRPStrategy();

    return (...args) => {
        let params;

        for (const strategy of [getSRPParams]) {
            params = strategy(...args);
            if (params) {
                break;
            }
        }

        return generateLink(params);
    }
}

/* function useLDPStrategy() {
    const urlSegmentTokenMapping = {
        [DestinationTypeEnum.VR]: 'listings',
        [DestinationTypeEnum.HOTEL]: 'hotel'
    };

    const getBaseUrl = (token, { slug, id }) =>
        [`${window.location.origin}/`, `/${slug.replace(/\s+/g, '-').toLowerCase().trim()}-${id}`].join(token);

    return (queryParamsBase, { type, ...rest }) => {
        for (const [urlSegmentType, urlSegmentToken] of Object.entries(urlSegmentTokenMapping)) {
            if (urlSegmentType === type) {
                return {
                    baseUrl: getBaseUrl(urlSegmentToken, rest),
                    queryParams: queryParamsBase
                };
            }
        }
    };
} */

function useSRPStrategy() {
    const contectQueryParam = useQueryParam();

    const getCoordinates = viewportMapBoundsEntity => {
        const validatedCoordinates = General.validateCoords(viewportMapBoundsEntity, []);
        if (!validatedCoordinates) {
            return [
                ['lat', viewportMapBoundsEntity.lat],
                ['lon', viewportMapBoundsEntity.lon]
            ]
            .filter(([, coordValue]) => Boolean(coordValue));
        }

        return validatedCoordinates;
    }

    return (queryParamsBase, destinationModel) => {
        const {
            rawSearchTerm,
            type,
            id,
            viewportBounds,
            location,
            zoomLevel,
            mobileZoomLevel,
            propertyCount
        } = destinationModel.toQueryParam();

        const viewportMapBoundsEntity = new ViewportMapBounds({
            ...viewportBounds,
            location: location ?? viewportBounds
        });

        return {
            baseUrl: `${window.location.origin}${constants.search_results_route}`,
            queryParams: [
                ...getCoordinates(viewportMapBoundsEntity),
                ['zoom_level', zoomLevel],
                ['mobile_zoom_level', mobileZoomLevel],
                ['location', rawSearchTerm],
                ['location_type', type],
                ['listing_id', id],
                ['property_count', propertyCount],
                ...queryParamsBase,
                ...contectQueryParam
            ]
        };
    };
}
