import React, { memo, useCallback, useRef, useState, useMemo } from 'react';
import clsx from 'clsx';
//Import Libraries
import SimpleBar from 'simplebar-react';

//Import methods
import useDeviceDetect from '@/utils/customHooks/useDeviceDetect';
import useDateFormatter from "@/containers/homePage/Omnisearch/useDateFormatter";
import { getWeekend } from '@/utils/globalFunc';
//Import components
import Heading from '../../components/Heading';
import Slider from '@/components/Slider';
import analyticsLabelProvider from '@/static/analyticsLabelProvider';
import { EAType, MediaSrcSetEnum } from '@/static/contants';
import { extractDigitFromString, getResizedImgUrl } from '@/utils/globalFunc';
import useGenerateLink from '@/utils/customHooks/useGenerateLink';
import useUserLocation from '@/utils/customHooks/useUserLocation';
import useSliderScroll from '@/components/Slider/utils/useSliderScroll';

export const DEAL_CATEGORIES = {
    ONGOING: 'od',
    WEEKLY_STAYS: 'wy',
    MONTHLY_STAYS: 'my'
};

const Deals = ({ className, d }) => {
    const generateLink = useGenerateLink();
    const currentLocation = useUserLocation();
    //Refs
    const menuRef = useRef(null);
    const sliderRef = useRef(null);
    const sRef = useRef(null);
    const dateFormater = useDateFormatter();
    const [checkin, checkout] = getWeekend(1).map(date => dateFormater(date));

    // ======== DATA FORMATING ======== //
    const enhanceLink = () => {
        let queryParams = new URLSearchParams();
        queryParams.set('checkin', checkin);
        queryParams.set('checkout', checkout);
        queryParams = '&' + queryParams.toString();
        return queryParams;
    };
    const queryParams = useMemo(() => enhanceLink(), []);

    let data = {
        current: [],
        weekly: [],
        monthly: []
    };

    function addInfo(arr,to,datesParams=''){
        arr.map(el => {
            let obj = {
                title: el.city,
                img: el.image_url,
                desktopImage: el.desktop_image_url,
                tabletImage: el.tablet_image_url,
                mobileImage: el.mobile_image_url,
                post_text: el.post_text,
                deal: extractDigitFromString(el.prices),
                url: el.url + datesParams,
                // url: Boolean(datesParams)  ? el.url + datesParams : el.url ,
                src: getResizedImgUrl(el.image_url, false),
                sources: [
                    {
                        media: MediaSrcSetEnum.MOBILE,
                        srcset: `${getResizedImgUrl(el.image_url, '385x551')} 1x, ${getResizedImgUrl(el.image_url, '770x1102')} 2x`,
                        type: 'image/webp'
                    },
                    {
                        media: MediaSrcSetEnum.TABLET,
                        srcset: `${getResizedImgUrl(el.image_url, '385x551')} 1x, ${getResizedImgUrl(el.image_url, '770x1102')} 2x`,
                        type: 'image/webp'
                    },
                    {
                        media: MediaSrcSetEnum.DESKTOP,
                        srcset: `${getResizedImgUrl(el.image_url, '385x551')} 1x, ${getResizedImgUrl(el.image_url, '770x1102')} 2x`,
                        type: 'image/webp'
                    },
                ]
            }
            to.push(obj)
        });
    }

    addInfo(d.current_celebrations,data.current);
    addInfo(d.monthly_discounts,data.monthly);
    addInfo(d.weekly_discounts,data.weekly);

    // ======== DATA FORMATING ======== //

    //Check Device
    const {isDesktop,isMobile} = useDeviceDetect();

    const dealsMapper = useCallback((dealCategory, deals) => deals.map(item => ({
        ...item,
        dataCnGaClick: JSON.stringify({ el: analyticsLabelProvider.deals.deal[EAType.CLICK](dealCategory, item.title) })
    })), []);

    //State
    const [mainData, setMainData] = useState(() => dealsMapper(DEAL_CATEGORIES.ONGOING, data.current));

    const { simpleBarRef, withScrollingProgress } = useSliderScroll();

    //Methods
    const handleOptionClick = withScrollingProgress((e) => {
        const element = e.target;
        const classList = element.classList;
        if(!element.classList.contains('cnhpdeals--active')){
            //Add active class
            const activeElement = document.querySelector('.cnhpdeals--active');
            activeElement.classList.remove('cnhpdeals--active');
            element.classList.add('cnhpdeals--active');

            //Set Element to the first
                //menu slider
                const menuWrapper = menuRef.current.querySelector('.simplebar-content-wrapper');
                const moveLeft = element.offsetLeft - (isMobile ? 20 : 30);
                menuWrapper.scrollTo(moveLeft, 0);
                //Cards slider
                const cardsWrapper = sliderRef.current.querySelector('.simplebar-content-wrapper');
                cardsWrapper.scrollTo(0, 0);

            //Change state
            switch(classList[1]) {
                case 'cnhpdeals--1':
                    setMainData(dealsMapper(DEAL_CATEGORIES.ONGOING, data.current));
                    break;
                case 'cnhpdeals--2':
                    setMainData(dealsMapper(DEAL_CATEGORIES.WEEKLY_STAYS, data.weekly));
                    break;
                case 'cnhpdeals--3':
                    setMainData(dealsMapper(DEAL_CATEGORIES.MONTHLY_STAYS, data.monthly));
                    break;
            }
        }
    });

    const dataDaw = {
        simpleBarProps: {
            ref: simpleBarRef
        },
        data: mainData
            .map(({ url, ...rest }) => {
                const generatedUrl = generateLink({
                    baseUrl: url,
                    queryParams: [
                        ['user_country', currentLocation?.country],
                    ]
                });

                return {
                    ...rest,
                    url: generatedUrl
                };
            }),
        count: 4,
        card: 7,
        cardMargin: 20,
        showBar: false,
        cardLink: {
            type:'js',
            target:'_self'
        }
    };

    return(
        <section
            className={clsx('cn__hp__deals', className)}
            data-cn-ga-observe={JSON.stringify({ el: analyticsLabelProvider.deals[EAType.SCROLL]() })}
        >
            <Heading align="left" tit="Deals of The Day" sub="Plan your next trip with these unmissable CuddlyNest deals"/>
            <div className="cn__hp__deals__titles" ref={menuRef}>
                <SimpleBar>
                    <div className="slidermobile__wrapper">
                        <h4
                            test-id='hp-opening_detal_tab' className="cn__hp__deals__titles__title cnhpdeals--1 cnhpdeals--active"
                            data-cn-ga-click={JSON.stringify({ el: analyticsLabelProvider.deals[EAType.CLICK](DEAL_CATEGORIES.ONGOING) })}
                            onClick={handleOptionClick}
                        >
                            Ongoing deals
                        </h4>
                        <h4
                            test-id='hp-discounted_weekly_tab' className="cn__hp__deals__titles__title cnhpdeals--2"
                            data-cn-ga-click={JSON.stringify({ el: analyticsLabelProvider.deals[EAType.CLICK](DEAL_CATEGORIES.WEEKLY_STAYS) })}
                            onClick={handleOptionClick}
                        >
                            Discounted weekly stays
                        </h4>
                        <h4
                            test-id='hp-discounted_monthly_tab' className="cn__hp__deals__titles__title cnhpdeals--3"
                            data-cn-ga-click={JSON.stringify({ el: analyticsLabelProvider.deals[EAType.CLICK](DEAL_CATEGORIES.MONTHLY_STAYS) })}
                            onClick={handleOptionClick}
                        >
                            Discounted monthly stays
                        </h4>
                        <div style={{width: isMobile ? 10 : 20, flexShrink:0, marginLeft: 10}}/>
                    </div>
                </SimpleBar>
            </div>
            <div className="cn__hp__deals__slider" ref={sliderRef}>
                <Slider {...dataDaw}/>
            </div>
        </section>
    )
}

export default memo(Deals);
