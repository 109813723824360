import { memo } from "react";
import Box from "@mui/material/Box";
import { constants } from "@/utils/constants";
import { priceCurrencyConverter } from "@/containers/searchResults/helpers";

const PriceValue = ({ sx, currency, value, baseRate }) => (
    <Box
        sx={{
            fontFamily: 'var(--primary-font)',
            ...sx,
        }}
    >
        {currency.symbol}{priceCurrencyConverter(
            value,
            baseRate,
            currency.rate
        )}
    </Box>
);

PriceValue.defaultProps = {
    baseRate: constants.defaultCurrency.rate
};

export default memo(PriceValue);
