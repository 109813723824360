
import GoogleLogin from 'react-google-login'
import { Button } from "../formComponents/button"
import { useDispatch } from 'react-redux'
import { FbSignupSigninAction, GoogleSignupSigninAction } from '@/redux/actions/authActions';
import { errorNotificationActions } from '@/redux/actions/globalActions';
import {config} from '@/utils/config';
import { constants } from '@/src/utils/constants';

export default function GoogleSignupLogin (props){


    const dispatch = useDispatch();

    const responseGoogle = (response) => {
        if(response.accessToken)
        {
            dispatch(GoogleSignupSigninAction(response.tokenObj))
        }
        else
        {
            // TODO : all the error messages should come from a translation file
            // dispatch(errorNotificationActions("Error", "Something went wrong! Please try again later."));
        }
    }

    const onFailureHandler = (error) => {
        // dispatch(errorNotificationActions("Error", "Something went wrong! Please try again later."));
    }

    return (<GoogleLogin
    clientId={config.google_client_id}
    onSuccess={responseGoogle}
    onFailure={onFailureHandler}
    cookiePolicy={'single_host_origin'}

    render = {(renderProps) => (
            <Button type="button" onClick={renderProps.onClick} className="google-btn">

                        <span>
                            <img src="/static/img/icon-google.svg" alt="icon google" />
                        </span>
                        {(props.type===constants.fb_google_signup_type)?'Register with Google':'Sign in with Google'}

            </Button>
    )}
    />)
}