import React, { memo, useMemo } from 'react';
import { useSelector } from 'react-redux';
//Import Libraries
import clsx from 'clsx';
import pluralize from 'pluralize';

//Import styles
import styles from '@/styles/components/GeoPages/__gp--GC.module.scss';

//Import Component
import LinkWrapper from '../Card/LinkWrapper';
import Tooltip from '@material-ui/core/Tooltip';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { PromoInclusions } from '@/static/contants';
import { capitalizeFirstLetter } from 'components/Organisms/ListProductDetail/Shared';
import { ActiveListingPriceWithItinerary } from '@/containers/searchResults';
import { PayLaterSticker } from '@/components/common/PropertyCard/Stickers';
import { useOmnisearchState } from '@/containers/homePage/Omnisearch/contexts/OmnisearchState';
import currencyRateConverter from '@/utils/currencyRateConverter';
import { constants } from '@/utils/constants';

const GeoCard = ({data}) => {
    const currency = useSelector(state => state.currencies.currency);

    const {
        dates: [dates],
        guests: [guests]
    } = useOmnisearchState();

    const priceUSD = useMemo(() =>
        Object.entries(data.priceFull)
            .reduce((acc, [property, amount]) => {
                if (typeof amount !== 'number') {
                    return acc;
                }

                return {
                    ...acc,
                    [property]: currencyRateConverter({
                        amount,
                        rate: data.priceFull.rate
                    }, { rate: constants.defaultCurrency.rate })
                };
            }, {})
    , [data.priceFull]);

    //---- Vars
    let amenities = data.amenities?.map((el, i) => {
        const { iconComponent: IconComponent, iconName, name } = el;
        return Boolean(IconComponent) && ({
            ...el,
            iconComponent: (
                <Tooltip key={i} title={name} placement='top' classes={{ popper: 'cn__amenity_icon-popover', tooltip: 'cn__amenity_icon-tooltip', arrow: 'cn__amenity_icon-arrow' }} arrow>
                    <section className="cn__gp__icon">
                        <IconComponent
                        className={clsx(
                            'cn__gp__icon-svg',
                            iconName
                        )}
                        />
                    </section>
                </Tooltip>
            )
        });
    }).filter(Boolean);
    amenities = amenities?.map(({ iconComponent }) => iconComponent);

    let stars  = [];
    for(let i = 0; i < data.rating; i++){
        stars.push(i);
    };


    //---- Render
    return (
        <div className={clsx('cn__card cn__gp__geocard',styles.cn__gp__geocard)}>
            <LinkWrapper href={data.url ? data.url : ''}>
                {/* -------------------- TOP -------------------- */}
                <div className={styles.cn__gp__geocard__top}>
                    {/* -------------------- TOP - image -------------------- */}
                    <div className={styles.cn__gp__geocard__top__img}>
                        <img src={data.img} alt={``}/>
                    </div>
                    {/* -------------------- TOP - amenities -------------------- */}
                    {/* {amenities.length > 0 && (
                        <div className={styles.cn__gp__geocard__top__amenities}>
                            {amenities}
                        </div>
                    )}  */}
                    {/* -------------------- TOP - rating -------------------- */}
                    {((data.urating.rate > 6) && (data.urating.amount > 4)) && (
                        <div className={styles.cn__gp__geocard__top__rating}>
                            <div className={styles.cn__gp__geocard__top__rating__rate}>
                                {/* {Number.parseFloat(data.urating.rate).toFixed(1)} */}
                                {data.urating.rate < 10
                                    ? Number.parseFloat(data.urating.rate).toFixed(1)
                                    : Math.round(data.urating.rate)}
                            </div>
                            <div className={styles.cn__gp__geocard__top__rating__rateinfo}>
                                <p className={styles.cn__gp__geocard__top__rating__rateinfo__title}>
                                    {data.urating.status}
                                </p>
                                <p className={styles.cn__gp__geocard__top__rating__rateinfo__subtitle}>
                                    {`${data.urating.amount} ${pluralize('rating', data.urating.amount)}`}
                                </p>

                            </div>
                        </div>
                    )}
                    {/* -------------------- TOP - tags -------------------- */}
                    <div className={styles.cn__gp__geocard__top__tags}>
                        {data.tags.breakfast && (
                            <div className={styles.cn__gp__geocard__top__tags__breakfast}>
                                <svg width="11" height="10">
                                    <path d="M8.2577 4.77425V4.25342H1.45215V5.5555C1.45215 6.76212 2.08876 7.83781 3.0417 8.43745H6.65079C6.88482 8.29017 7.11658 8.11463 7.30769 7.91661H7.99729C9.00234 7.91661 9.8202 7.09875 9.8202 6.0937V4.77425H8.2577ZM9.29937 6.0937C9.29937 6.81163 8.71522 7.39578 7.99729 7.39578H7.71385C8.05435 6.8689 8.2577 6.22847 8.2577 5.5555V5.29508H9.29937V6.0937Z" fill="#4C35BC"/>
                                    <path d="M0.931641 8.95825C0.931641 9.53355 1.39801 9.99992 1.97331 9.99992H7.7372C8.31249 9.99992 8.77886 9.53355 8.77886 8.95825H0.931641Z" fill="#4C35BC"/>
                                    <path d="M5.80844 3.88826C5.83577 3.15099 6.48698 2.8449 6.48698 2.06291C6.48698 1.28088 6.19388 0.947495 5.80844 0.554932C5.82031 1.30004 4.82031 1.88831 4.82031 2.54708C4.82031 3.38662 5.35627 3.62494 5.80844 3.88826V3.88826Z" fill="#4C35BC"/>
                                    <path d="M4.36746 2.22179C4.38567 1.73039 4.81966 1.52636 4.81966 1.00511C4.81966 0.483871 4.62431 0.26163 4.36746 0C4.37539 0.496622 3.70898 0.888745 3.70898 1.32782C3.70898 1.88743 4.06615 2.04626 4.36746 2.22179Z" fill="#4C35BC"/>
                                </svg>
                                <p className={styles.cn__gp__geocard__top__tags__breakfast__title}>
                                    Breakfast included
                                </p>
                            </div>
                        )}
                        {data.tags.cancellation && (
                            <div className={styles.cn__gp__geocard__top__tags__free}>
                                <svg width="11" height="10">
                                    <path d="M0.806641 1.99951C0.806641 1.72337 1.0305 1.49951 1.30664 1.49951H6.30664C6.58278 1.49951 6.80664 1.72337 6.80664 1.99951V2.49951C6.80664 2.77565 6.58278 2.99951 6.30664 2.99951H1.30664C1.0305 2.99951 0.806641 2.77565 0.806641 2.49951V1.99951Z" fill="#2B8A4B"/>
                                    <path d="M1.80664 0.5C1.80664 0.223858 2.0305 0 2.30664 0C2.58278 0 2.80664 0.223858 2.80664 0.5V1C2.80664 1.27614 2.58278 1.5 2.30664 1.5C2.0305 1.5 1.80664 1.27614 1.80664 1V0.5Z" fill="#2B8A4B"/>
                                    <path d="M4.80664 0.5C4.80664 0.223858 5.0305 0 5.30664 0C5.58278 0 5.80664 0.223858 5.80664 0.5V1C5.80664 1.27614 5.58278 1.5 5.30664 1.5C5.0305 1.5 4.80664 1.27614 4.80664 1V0.5Z" fill="#2B8A4B"/>
                                    <path fillRule="evenodd" clipRule="evenodd" d="M5.30664 2.00049H2.30664C1.75436 2.00049 1.30664 2.4482 1.30664 3.00049V6.00049C1.30664 6.55277 1.75436 7.00049 2.30664 7.00049H3.30664C3.30664 7.34579 3.35039 7.68087 3.43266 8.00049H2.30664C1.20207 8.00049 0.306641 7.10506 0.306641 6.00049V3.00049C0.306641 1.89592 1.20207 1.00049 2.30664 1.00049H5.30664C6.41121 1.00049 7.30664 1.89592 7.30664 3.00049C6.96134 3.00049 6.62626 3.04424 6.30664 3.12651V3.00049C6.30664 2.4482 5.85893 2.00049 5.30664 2.00049Z" fill="#2B8A4B"/>
                                    <path fillRule="evenodd" clipRule="evenodd" d="M7.30664 4.99951C6.20207 4.99951 5.30664 5.89494 5.30664 6.99951C5.30664 8.10408 6.20207 8.99951 7.30664 8.99951C8.41121 8.99951 9.30664 8.10408 9.30664 6.99951C9.30664 5.89494 8.41121 4.99951 7.30664 4.99951ZM4.30664 6.99951C4.30664 5.34266 5.64979 3.99951 7.30664 3.99951C8.96349 3.99951 10.3066 5.34266 10.3066 6.99951C10.3066 8.65637 8.96349 9.99951 7.30664 9.99951C5.64979 9.99951 4.30664 8.65637 4.30664 6.99951Z" fill="#2B8A4B"/>
                                    <path d="M6.24606 5.93949C6.44132 5.74423 6.7579 5.74423 6.95316 5.93949L8.36738 7.3537C8.56264 7.54897 8.56264 7.86555 8.36738 8.06081C8.17211 8.25607 7.85553 8.25607 7.66027 8.06081L6.24606 6.6466C6.05079 6.45134 6.05079 6.13475 6.24606 5.93949Z" fill="#2B8A4B"/>
                                    <path d="M6.24613 8.05961C6.05087 7.86435 6.05087 7.54776 6.24613 7.3525L7.66035 5.93829C7.85561 5.74303 8.17219 5.74303 8.36745 5.93829C8.56271 6.13355 8.56271 6.45013 8.36745 6.64539L6.95324 8.05961C6.75798 8.25487 6.44139 8.25487 6.24613 8.05961Z" fill="#2B8A4B"/>
                                </svg>
                                <p className={styles.cn__gp__geocard__top__tags__free__title}>
                                    Free Cancellation
                                </p>
                            </div>
                        )}
                    </div>
                </div>
                {/* -------------------- BOTTOM -------------------- */}
                <div className={styles.cn__gp__geocard__bottom}>
                    {/* -------------------- BOTTOM - title -------------------- */}
                    <p className={clsx('cn__gp__geocard__bottom__title',styles.cn__gp__geocard__bottom__title)}>
                        {data.title}
                    </p>
                    {/* -------------------- BOTTOM - type and rating -------------------- */}
                    <Stack
                        direction="row"
                       spacing={1.5}
                    >
                        <div className={styles.cn__gp__geocard__bottom__rating}>
                            <p className={styles.cn__gp__geocard__bottom__rating__type}>
                                {capitalizeFirstLetter(data.property) }
                            </p>
                            {data.rating > 0 && (
                                <div className={styles.cn__gp__geocard__bottom__rating__stars}>
                                    {stars.map((el,i) => {
                                        return (
                                            <svg width="15" height="15" key={i}>
                                                <path d="M6.83623 1.98196C7.04955 1.34502 7.95045 1.34502 8.16376 1.98196L9.0608 4.66044C9.15563 4.9436 9.41966 5.13543 9.71826 5.13812L12.5429 5.16355C13.2145 5.1696 13.4929 6.02641 12.9531 6.42611L10.6829 8.10694C10.4429 8.28463 10.342 8.59501 10.4318 8.87983L11.2804 11.574C11.4822 12.2147 10.7534 12.7443 10.2064 12.3543L7.90633 10.7147C7.66318 10.5413 7.33683 10.5413 7.09367 10.7147L4.79357 12.3543C4.24662 12.7443 3.51777 12.2147 3.71958 11.574L4.56824 8.87983C4.65795 8.59501 4.55711 8.28463 4.31711 8.10694L2.04691 6.42611C1.50707 6.02641 1.78546 5.1696 2.45714 5.16355L5.28174 5.13812C5.58034 5.13543 5.84437 4.94361 5.9392 4.66044L6.83623 1.98196Z" fill="#EFB008"/>
                                            </svg>
                                        )
                                    })}
                                </div>
                            )}
                        </div>
                        <Box
                            sx={{
                                alignSelf: 'flex-end',
                                maxHeight: '20px',
                                mb: '5px !important',
                            }}
                        >
                            <PayLaterSticker
                                cancellationPolicies={
                                    data.tags.cancellation
                                        ? PromoInclusions.FREE_CANCELLATION
                                        : null}
                            />
                        </Box>
                    </Stack>
                    {/* -------------------- BOTTOM - location -------------------- */}
                    <div className={styles.cn__gp__geocard__bottom__location}>
                    {data.location.loc1 &&   <svg width="9" height="13">
                            <path d="M4.16667 0.25C1.86921 0.25 0 2.11921 0 4.41667C0 5.10636 0.172436 5.7902 0.500243 6.39674L3.93881 12.6157C3.98459 12.6986 4.07182 12.75 4.16667 12.75C4.26152 12.75 4.34875 12.6986 4.39452 12.6157L7.83436 6.39469C8.1609 5.7902 8.33333 5.10634 8.33333 4.41664C8.33333 2.11921 6.46412 0.25 4.16667 0.25ZM4.16667 6.49999C3.01794 6.49999 2.08335 5.5654 2.08335 4.41667C2.08335 3.26794 3.01794 2.33335 4.16667 2.33335C5.3154 2.33335 6.24999 3.26794 6.24999 4.41667C6.24999 5.5654 5.3154 6.49999 4.16667 6.49999Z" fill="black"/>
                        </svg>}
                        <div className={styles.cn__gp__geocard__bottom__loc}>
                            <span className={styles.cn__gp__geocard__bottom__location__loc1}>{data.location.loc1}</span>
                            {data.location.loc1 && <span>&nbsp;|&nbsp;</span>}
                            <span className={styles.cn__gp__geocard__bottom__location__loc2}>{data.location.loc2}</span>
                        </div>
                    </div>
                    {/* -------------------- BOTTOM - facilities and price -------------------- */}
                    <div className={clsx('cn__gp__geocard__bottom__facilitiesprice',styles.cn__gp__geocard__bottom__facilitiesprice)}>
                        <div className={styles.cn__gp__geocard__bottom__facilitiesprice__facilities}>
                            {data.facilities.slice(0,3).map((el,i) => {
                                return (
                                    <div className={styles.cn__gp__geocard__bottom__facilitiesprice__facilities__facility} key={i}>
                                        {el.name}
                                    </div>
                                )
                            })}
                        </div>

                    </div>
                    <Box
                        className='property-card-price'
                        sx={{
                            justifyContent: 'flex-end',
                            flexGrow: 1
                        }}
                    >
                        <ActiveListingPriceWithItinerary
                            startDate={dates.startDate}
                            endDate={dates.endDate}
                            guests={guests.totalCount}
                            rooms={guests.roomCount}
                            originalPriceUSD={priceUSD}
                            priceUSD={priceUSD}
                            currency={currency}
                        />
                    </Box>
                    {/* <p className={styles.cn__gp__geocard__bottom__facilitiesprice__price}>
                        <span>{data.price.replace(/\s/gm, '')} / &nbsp;</span>night
                    </p> */}
                </div>
            </LinkWrapper>
        </div>
    )
};

export default memo(GeoCard);

