import React, { memo } from 'react';

//Import methods
import { handleJSClick } from '@/components/Slider/utils';

//Import component
import Image from '@/containers/newHomePage/components/Image';

const Card5 = ({data,linkType}) => {

    //Render
    const childRender = (
        <>
            <Image src={data.src} alt={`${data.title} background`} sources={data.sources}/>
            <div className="cn__card5__info">
                <h3 className="cn__card5__info__title">{data.title}</h3>
                {Boolean(data.info) && (
                    <p className="cn__card5__info__subtitle">{data.info}</p>
                )}
            </div>
        </>
    );

    const renderEl = linkType.type === 'a' ? (
        <div className='cn__card cn__card5' data-cn-ga-click={data.dataCnGaClick}>
            <a href={data.url} target={linkType.target}>
                {childRender}
            </a>
        </div>
    ) : (
        <div className='cn__card cn__card5' data-cn-ga-click={data.dataCnGaClick} onClick={() => handleJSClick(data.url,linkType.target)}>
            {childRender}
        </div>
    );


    return renderEl;
}

export default memo(Card5);
