import PropTypes from 'prop-types'
import clsx from 'clsx';

export const Button = (props) => {
    const status = props.status;
    const { type = "button", btnType = 'default', textType = 'default', onClick, text = 'Save', className, rounded = false, disabled = false, children, loaderClassName="cn__loader" } = props;
    const typeClass = btnType === 'primary' ? 'btn-primary' : btnType === 'danger' ? 'btn-danger' : btnType === 'secondary' ? 'btn-secondary' : 'btn-default';
    const textClass = textType === 'primary' ? 'btn-text-primary' : btnType === 'danger' ? 'btn-text-danger' : btnType === 'secondry' ? 'btn-text-secondry' : 'btn-text-default';
    return (
        <button
            className={`btn ${typeClass} ${textClass} ${!!rounded ? 'rounded' : ''} ${!!disabled ? 'disabled' : ""} ${className} ${status ? "loader-button" : ""}`}
            onClick={onClick}
            type={type}
            {...((disabled)?{disabled:"disabled"}:{})}
        >
            {status ? <img src='/static/img/loader.gif' alt='loading' className={loaderClassName}/> : children}
        </button>
    )
}

Button.propTypes = {
    type: PropTypes.string,
    btnType: PropTypes.string,
    textType: PropTypes.string,
    text: PropTypes.string,
    className: PropTypes.string,
    onClick: PropTypes.func
}

Button.defaultProps = {
    status: false,
};