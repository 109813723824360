import { MediaSrcSetEnum } from "@/static/contants";
import { config } from "@/utils/config";

export const ntData = [
    {
        title:'BUSINESS',
        url:'/t/business-travel/',
        gaLabel: 'Business',
        src: `${config.resizedImagesUrl}/images/trip_types/business-travel.jpg`,
        mobileSrc: `${config.resizedImagesUrl}/assets/mobile/homepage/trip-types/business-travel.jpg`,
        tabletSrc: `${config.resizedImagesUrl}/assets/tablet/homepage/trip-types/business-travel.jpg`,
        desktopSrc: `${config.resizedImagesUrl}/assets/desktop/homepage/trip-types/business-travel.jpg`,
        sources: [
            {
                media: MediaSrcSetEnum.MOBILE,
                srcset: `${config.resizedImagesUrl}/images/trip_types/t/webp/business-travel_409x222.jpg 1x, ${config.resizedImagesUrl}/images/trip_types/t/webp/business-travel_818x444.jpg 2x`,
                type: 'image/webp'
            },
            {
                media: MediaSrcSetEnum.TABLET,
                srcset: `${config.resizedImagesUrl}/images/trip_types/t/webp/business-travel_409x222.jpg 1x, ${config.resizedImagesUrl}/images/trip_types/t/webp/business-travel_818x444.jpg 2x`,
                type: 'image/webp'
            },
            {
                media: MediaSrcSetEnum.DESKTOP,
                srcset: `${config.resizedImagesUrl}/images/trip_types/t/webp/business-travel_409x222.jpg 1x, ${config.resizedImagesUrl}/images/trip_types/t/webp/business-travel_818x444.jpg 2x`,
                type: 'image/webp'
            }
        ]

    },
    {
        title:'FAMILY',
        url:'/t/family-vacations/',
        gaLabel: 'Family',
        src: `${config.resizedImagesUrl}/images/trip_types/family-vacation.jpg`,
        mobileSrc: `${config.resizedImagesUrl}/assets/mobile/homepage/trip-types/family-vacation.jpg`,
        tabletSrc: `${config.resizedImagesUrl}/assets/tablet/homepage/trip-types/family-vacation.jpg`,
        desktopSrc: `${config.resizedImagesUrl}/assets/desktop/homepage/trip-types/family-vacation.jpg`,
        sources: [
            {
                media: MediaSrcSetEnum.MOBILE,
                srcset: `${config.resizedImagesUrl}/images/trip_types/t/webp/family-vacation_409x222.jpg 1x, ${config.resizedImagesUrl}/images/trip_types/t/webp/family-vacation_818x444.jpg 2x`,
                type: 'image/webp'
            },
            {
                media: MediaSrcSetEnum.TABLET,
                srcset: `${config.resizedImagesUrl}/images/trip_types/t/webp/family-vacation_409x222.jpg 1x, ${config.resizedImagesUrl}/images/trip_types/t/webp/family-vacation_818x444.jpg 2x`,
                type: 'image/webp'
            },
            {
                media: MediaSrcSetEnum.DESKTOP,
                srcset: `${config.resizedImagesUrl}/images/trip_types/t/webp/family-vacation_409x222.jpg 1x, ${config.resizedImagesUrl}/images/trip_types/t/webp/family-vacation_818x444.jpg 2x`,
                type: 'image/webp'
            }
        ]
    },
    {
        title:'ROMANTIC GETAWAYS',
        url:'/t/romantic-getaways/',
        gaLabel: 'Romantic',
        src: `${config.resizedImagesUrl}/images/trip_types/romantic-getaway.jpg`,
        mobileSrc: `${config.resizedImagesUrl}/assets/mobile/homepage/trip-types/romantic-getaway.jpg`,
        tabletSrc: `${config.resizedImagesUrl}/assets/tablet/homepage/trip-types/romantic-getaway.jpg`,
        desktopSrc: `${config.resizedImagesUrl}/assets/desktop/homepage/trip-types/romantic-getaway.jpg`,
        sources: [
            {
                media: MediaSrcSetEnum.MOBILE,
                srcset: `${config.resizedImagesUrl}/images/trip_types/t/webp/romantic-getaway_409x222.jpg 1x, ${config.resizedImagesUrl}/images/trip_types/t/webp/romantic-getaway_818x444.jpg 2x`,
                type: 'image/webp'
            },
            {
                media: MediaSrcSetEnum.TABLET,
                srcset: `${config.resizedImagesUrl}/images/trip_types/t/webp/romantic-getaway_409x222.jpg 1x, ${config.resizedImagesUrl}/images/trip_types/t/webp/romantic-getaway_818x444.jpg 2x`,
                type: 'image/webp'
            },
            {
                media: MediaSrcSetEnum.DESKTOP,
                srcset: `${config.resizedImagesUrl}/images/trip_types/t/webp/romantic-getaway_409x222.jpg 1x, ${config.resizedImagesUrl}/images/trip_types/t/webp/romantic-getaway_818x444.jpg 2x`,
                type: 'image/webp'
            }
        ]
    },
    {
        title:'SOLO',
        url:'/t/solo-travel/',
        gaLabel: 'Solo',
        src: `${config.resizedImagesUrl}/images/trip_types/solo-trip.jpg`,
        mobileSrc: `${config.resizedImagesUrl}/assets/mobile/homepage/trip-types/solo-trip.jpg`,
        tabletSrc: `${config.resizedImagesUrl}/assets/tablet/homepage/trip-types/solo-trip.jpg`,
        desktopSrc: `${config.resizedImagesUrl}/assets/desktop/homepage/trip-types/solo-trip.jpg`,
        sources: [
            {
                media: MediaSrcSetEnum.MOBILE,
                srcset: `${config.resizedImagesUrl}/images/trip_types/t/webp/solo-trip_409x222.jpg 1x, ${config.resizedImagesUrl}/images/trip_types/t/webp/solo-trip_818x444.jpg 2x`,
                type: 'image/webp'
            },
            {
                media: MediaSrcSetEnum.TABLET,
                srcset: `${config.resizedImagesUrl}/images/trip_types/t/webp/solo-trip_409x222.jpg 1x, ${config.resizedImagesUrl}/images/trip_types/t/webp/solo-trip_818x444.jpg 2x`,
                type: 'image/webp'
            },
            {
                media: MediaSrcSetEnum.DESKTOP,
                srcset: `${config.resizedImagesUrl}/images/trip_types/t/webp/solo-trip_409x222.jpg 1x, ${config.resizedImagesUrl}/images/trip_types/t/webp/solo-trip_818x444.jpg 2x`,
                type: 'image/webp'
            }
        ]
    },
    {
        title:'ADVENTURE TRAVEL',
        url:'/t/adventure-travel/',
        gaLabel: 'Adventure',
        src: `${config.resizedImagesUrl}/images/trip_types/adventure-travel.jpg`,
        mobileSrc: `${config.resizedImagesUrl}/assets/mobile/homepage/trip-types/adventure-travel.jpg`,
        tabletSrc: `${config.resizedImagesUrl}/assets/tablet/homepage/trip-types/adventure-travel.jpg`,
        desktopSrc: `${config.resizedImagesUrl}/assets/desktop/homepage/trip-types/adventure-travel.jpg`,
        sources: [
            {
                media: MediaSrcSetEnum.MOBILE,
                srcset: `${config.resizedImagesUrl}/images/trip_types/t/webp/adventure-travel_409x222.jpg 1x, ${config.resizedImagesUrl}/images/trip_types/t/webp/adventure-travel_818x444.jpg 2x`,
                type: 'image/webp'
            },
            {
                media: MediaSrcSetEnum.TABLET,
                srcset: `${config.resizedImagesUrl}/images/trip_types/t/webp/adventure-travel_409x222.jpg 1x, ${config.resizedImagesUrl}/images/trip_types/t/webp/adventure-travel_818x444.jpg 2x`,
                type: 'image/webp'
            },
            {
                media: MediaSrcSetEnum.DESKTOP,
                srcset: `${config.resizedImagesUrl}/images/trip_types/t/webp/adventure-travel_409x222.jpg 1x, ${config.resizedImagesUrl}/images/trip_types/t/webp/adventure-travel_818x444.jpg 2x`,
                type: 'image/webp'
            }
        ]
    },
    {
        title:'GROUP TRIPS',
        url:'/t/group-travel/',
        gaLabel: 'Group',
        src: `${config.resizedImagesUrl}/images/trip_types/group-travel.jpg`,
        mobileSrc: `${config.resizedImagesUrl}/assets/mobile/homepage/trip-types/group-travel.jpg`,
        tabletSrc: `${config.resizedImagesUrl}/assets/tablet/homepage/trip-types/group-travel.jpg`,
        desktopSrc: `${config.resizedImagesUrl}/assets/desktop/homepage/trip-types/group-travel.jpg`,
        sources: [
            {
                media: MediaSrcSetEnum.MOBILE,
                srcset: `${config.resizedImagesUrl}/images/trip_types/t/webp/group-travel_409x222.jpg 1x, ${config.resizedImagesUrl}/images/trip_types/t/webp/group-travel_818x444.jpg 2x`,
                type: 'image/webp'
            },
            {
                media: MediaSrcSetEnum.TABLET,
                srcset: `${config.resizedImagesUrl}/images/trip_types/t/webp/group-travel_409x222.jpg 1x, ${config.resizedImagesUrl}/images/trip_types/t/webp/group-travel_818x444.jpg 2x`,
                type: 'image/webp'
            },
            {
                media: MediaSrcSetEnum.DESKTOP,
                srcset: `${config.resizedImagesUrl}/images/trip_types/t/webp/group-travel_409x222.jpg 1x, ${config.resizedImagesUrl}/images/trip_types/t/webp/group-travel_818x444.jpg 2x`,
                type: 'image/webp'
            }
        ]
    },
    {
        title:'LUXURY STAYS',
        url:'/a/luxury-stays/',
        gaLabel: 'Luxury',
        src: `${config.resizedImagesUrl}/images/trip_types/luxury-stays.jpg`,
        mobileSrc: `${config.resizedImagesUrl}/assets/mobile/homepage/trip-types/luxury-stays.jpg`,
        tabletSrc: `${config.resizedImagesUrl}/assets/tablet/homepage/trip-types/luxury-stays.jpg`,
        desktopSrc: `${config.resizedImagesUrl}/assets/desktop/homepage/trip-types/luxury-stays.jpg`,
        sources: [
            {
                media: MediaSrcSetEnum.MOBILE,
                srcset: `${config.resizedImagesUrl}/images/trip_types/t/webp/luxury-stays_409x222.jpg 1x, ${config.resizedImagesUrl}/images/trip_types/t/webp/luxury-stays_818x444.jpg 2x`,
                type: 'image/webp'
            },
            {
                media: MediaSrcSetEnum.TABLET,
                srcset: `${config.resizedImagesUrl}/images/trip_types/t/webp/luxury-stays_409x222.jpg 1x, ${config.resizedImagesUrl}/images/trip_types/t/webp/luxury-stays_818x444.jpg 2x`,
                type: 'image/webp'
            },
            {
                media: MediaSrcSetEnum.DESKTOP,
                srcset: `${config.resizedImagesUrl}/images/trip_types/t/webp/luxury-stays_409x222.jpg 1x, ${config.resizedImagesUrl}/images/trip_types/t/webp/luxury-stays_818x444.jpg 2x`,
                type: 'image/webp'
            }
        ]
    },
    {
        title:'DIGITAL NOMAD TRAVEL',
        url:'/t/digital-nomad/',
        gaLabel: 'DigitalNomad',
        src: `${config.resizedImagesUrl}/images/trip_types/digital-nomad.jpg`,
        mobileSrc: `${config.resizedImagesUrl}/assets/mobile/homepage/trip-types/digital-nomad.jpg`,
        tabletSrc: `${config.resizedImagesUrl}/assets/tablet/homepage/trip-types/digital-nomad.jpg`,
        desktopSrc: `${config.resizedImagesUrl}/assets/desktop/homepage/trip-types/digital-nomad.jpg`,
        sources: [
            {
                media: MediaSrcSetEnum.MOBILE,
                srcset: `${config.resizedImagesUrl}/images/trip_types/t/webp/digital-nomad_409x222.jpg 1x, ${config.resizedImagesUrl}/images/trip_types/t/webp/digital-nomad_818x444.jpg 2x`,
                type: 'image/webp'
            },
            {
                media: MediaSrcSetEnum.TABLET,
                srcset: `${config.resizedImagesUrl}/images/trip_types/t/webp/digital-nomad_409x222.jpg 1x, ${config.resizedImagesUrl}/images/trip_types/t/webp/digital-nomad_818x444.jpg 2x`,
                type: 'image/webp'
            },
            {
                media: MediaSrcSetEnum.DESKTOP,
                srcset: `${config.resizedImagesUrl}/images/trip_types/t/webp/digital-nomad_409x222.jpg 1x, ${config.resizedImagesUrl}/images/trip_types/t/webp/digital-nomad_818x444.jpg 2x`,
                type: 'image/webp'
            }
        ]
    }
];
// .map(resource => srcSetGenerator(resource.img, resource));
