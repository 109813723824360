import { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import { useRouter } from "next/router";
import clxs from 'clsx';
import styles from './Authentication.module.scss';
import {
    PhoneSVG,
    EmailSVG
} from './AuthSVG';
import FacebookAuth from './FacebookAuth';
import GoogleAuth from './GoogleAuth';
import AppleAuth from './AppleAuth';
import {
    SIGNUP_FAIL,
    GOOGLE_SIGNUP_SIGNIN_SUCCESS,
    GOOGLE_SIGNUP_SIGNIN_FAIL,
    FB_SIGNUP_SIGNIN_SUCCESS,
    FB_SIGNUP_SIGNIN_FAIL,
    APPLE_SIGNUP_SIGNIN_SUCCESS,
    APPLE_SIGNUP_SIGNIN_FAIL,
} from "@/redux/types";
import SaveBig from './SaveBig';
import useMyDeviceDetect from '@/src/utils/customHooks/useMyDeviceDetect';


const Options = ({ modalType, handleClick, excludeOption, setAuthOpen, setModalType }) => {
    const signUp = modalType === 'signup';
    const showPhone = (modalType === 'signup') || (modalType === 'login' && excludeOption === 'email');
    const showEmail = (modalType === 'signup') || (modalType === 'login' && excludeOption === 'phone');
    const { isMobile } = useMyDeviceDetect();
    const authStore = useSelector(state => state.authReducer);
    const router = useRouter();

    useEffect(() => {
        if (signUp) {
            if ([GOOGLE_SIGNUP_SIGNIN_SUCCESS, FB_SIGNUP_SIGNIN_SUCCESS, APPLE_SIGNUP_SIGNIN_SUCCESS].includes(authStore.type)) {
                setAuthOpen(false);
                if (router?.asPath?.includes("redirect_url")) {
                    router.push(router.query.redirect_url);
                }
            } else if ([SIGNUP_FAIL, GOOGLE_SIGNUP_SIGNIN_FAIL, FB_SIGNUP_SIGNIN_FAIL, APPLE_SIGNUP_SIGNIN_FAIL].includes(authStore.type)) {
                console.log(authStore.message);
            }
        }
    }, [authStore.type]);

    return (
        <>
            {(signUp && isMobile) && <SaveBig setModalType={setModalType} />}
            <div className={signUp ? styles.auth_signup_options : styles.auth_login_options}>
                <FacebookAuth />
                <GoogleAuth />
                <AppleAuth />
                {showPhone &&
                    <div
                        id="phone"
                        onClick={handleClick}
                        className={styles.auth_login_options_item}
                    >
                        <div className={styles.auth_login_options_item_inner}>
                            <PhoneSVG />
                            <span className={styles.auth_login_options_item__text}>Phone</span>
                        </div>
                    </div>}
                {showEmail &&
                    <div
                        id="email"
                        onClick={handleClick}
                        className={styles.auth_login_options_item}
                    >
                        <div className={styles.auth_login_options_item_inner}>
                            <EmailSVG />
                            <span className={styles.auth_login_options_item__text}>Email</span>
                        </div>
                    </div>}
            </div>
        </>
    );
};

export default Options;