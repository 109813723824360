import { useSelector } from 'react-redux';
import Link from 'next/link'
import styles from './PromiseInfo.module.scss';
import { Asset1, Asset1v2, Asset2, Asset2v2, Asset3, Asset3v2 } from './SvgAssets';

const PromiseBanner = () => {
    const { isMobile, isTablet, isDesktop } = useSelector(state => state.device);

    return (
        <div className={styles.banner}>
            <div className={styles.banner_inner}>
                <div className={styles.banner_left}>
                    <div className={styles.banner_title}>
                        {'The CuddlyNest Promise'}
                    </div>
                    <div className={styles.banner_subtitle}>
                        {'Free cancellation & waived service fees'}
                    </div>
                    <div className={styles.banner_btn}>
                        <Link href={'/promise'}>
                            {'See more'}
                        </Link>
                    </div>
                </div>
                <div className={styles.banner_right}>
                    <div className={styles.banner_pic}>
                        {isDesktop && <Asset1v2 />}
                        {isTablet && <Asset2v2 />}
                        {isMobile && <Asset3v2 />}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PromiseBanner;
