import React, { memo, useEffect } from 'react';
import FloatingInput from '@/components/formComponents/FloatingInput';
import useDestinationIput from './useDestinationInput';
import LocationNew from '@/components/common/icons/LocationNew';

const DestinationInputMobile = ({ destination, setStep, onClose }) => {
    const [destinationState, setDestinationState] = destination;
    const { inputRef, onChange, onClear } = useDestinationIput({ onDestinationInputChange: setDestinationState, setStep });

    // useEffect(() => {
    //     let intervalId;
    //     const scrollToTop = () => {
    //         window.scrollTo({
    //             top: 0,
    //             left: 0,
    //             behavior: 'smooth'
    //         });
    //     };

    //     const onFocusHandler = () => {
    //         intervalId = setInterval(() => {
    //             if (window.scrollY) {
    //                 scrollToTop();
    //             }else {
    //                 clearInterval(intervalId);
    //             }
    //         }, 400);
    //     };

    //     scrollToTop();
    //     inputRef.current?.addEventListener('focus', onFocusHandler);
    //     const focusTimeoutId = setTimeout(() => {
    //         inputRef.current?.focus();
    //     }, 500);
    //     // document.documentElement.style.overflow = document.body.style.overflow = 'hidden';
    //     return () => {
    //         clearTimeout(focusTimeoutId);
    //         scrollToTop();
    //         inputRef.current?.removeEventListener('focus', onFocusHandler);
    //         // document.documentElement.style.overflow = document.body.style.overflow = '';
    //     };
    // }, []);

    return (
        <div className="omnisearch_input-wrapper_mobile_input-container_main-input-wrapper">
            <button
                className="omnisearch_input-wrapper_mobile_input-container_main-input-wrapper_close-btn"
                type='button'
                onClick={onClose}
            >
                <svg width="7" height="16" viewBox="0 0 7 16" fill="none">
                    <path d="M6 15L1 8L6 1" stroke="#3E4041" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
            </button>
            <FloatingInput
                inputRef={inputRef}
                className="omnisearch_input-wrapper_mobile_input-container_main-input-wrapper_input"
                prependContent={<LocationNew className="omnisearch_input-wrapper_mobile_input-container_main-input-wrapper_input_prepend-icon" />}
                placeholder="Where to?"
                value={destinationState.inputValue}
                onChange={onChange}
                onClear={onClear}
                clearable
            />
        </div>
    );
};

export default memo(DestinationInputMobile);
