import React, { memo } from 'react';

//Import methods
import { handleJSClick } from '@/components/Slider/utils';

//Import component
import Image from '@/containers/newHomePage/components/Image';

const Card6 = ({data,linkType}) => {

    //Render
    const childRender = (
        <>
            <Image src={data.amenity_image} alt={`${data.amenity_name} background`}/>
            <div className="cn__card6__info">
                <h3 className="cn__card6__info__title">{data.amenity_text_card}</h3>
                <img src="/static/staticpages/home/placesfor/arrow.svg" alt="arrow"/>
            </div>
        </>
    );

    const renderEl = linkType.type === 'a' ? (
        <div className='cn__card cn__card6' data-cn-ga-click={data.dataCnGaClick}>
            <a href={data.url} target={linkType.target}>
                {childRender}
            </a>
        </div>
    ) : (
        <div className='cn__card cn__card6' data-cn-ga-click={data.dataCnGaClick} onClick={() => handleJSClick(data.url,linkType.target)}>
            {childRender}
        </div>
    );


    return renderEl;
}

export default memo(Card6);
