import React, { memo } from 'react';

//Components
import Card1 from '../Cards/Card1';
import Card2 from '../Cards/Card2';
import Card3 from '../Cards/Card3';
import Card4 from '../Cards/Card4';
import Card5 from '../Cards/Card5';
import Card6 from '../Cards/Card6';
import Card7 from '../Cards/Card7';
import Card9 from '../Cards/Card9';

//LYP Cards
import LYPCard1 from '@/containers/Static/list-your-property/components/Cards/LYPCard1';
import LYPCard2 from '@/containers/Static/list-your-property/components/Cards/LYPCard2';
import LYPMap from '@/containers/Static/list-your-property/components/Cards/LYPMap';

//AUP Cards
import AUCard1 from '@/containers/Static/about-us/components/Cards/AUCard1';
import AUCard2 from '@/containers/Static/about-us/components/Cards/AUCard2';
import AUCard3 from '@/containers/Static/about-us/components/Cards/AUCard3';

//GP Card
import GeoCard from '@/containers/newGeoPages/Sliders/GeoCard';
import GeoCardStays from '@/containers/newGeoPages/Sliders/GeoCardStays';


const Card = ({type,data,link,index,getparent}) => {

    let renderElement;

    switch(type) {
        case 1:
            renderElement = <Card1 data={data} linkType={link}/>
            break;
        case 2:
            renderElement = <Card2 data={data} linkType={link}/>;
            break;
        case 3:
            renderElement = <Card3 data={data} linkType={link}/>;
            break;
        case 4:
            renderElement = <Card4 data={data} linkType={link} getparent={getparent} index={index}/>;
            break;
        case 5:
            renderElement = <Card5 data={data} linkType={link}/>;
            break;
        case 6:
            renderElement = <Card6 data={data} linkType={link}/>;
            break;
        case 7:
            renderElement = <Card7 data={data} linkType={link}/>;
            break;
        case 9:
            renderElement = <Card9 data={data} linkType={link}/>;
            break;
        case 'LYP1':
            renderElement = <LYPCard1 data={data} linkType={link}/>;
            break;
        case 'LYP2':
            renderElement = <LYPCard2 data={data} linkType={link}/>;
            break;
        case 'LYPMAP':
            renderElement = <LYPMap data={data} linkType={link}/>;
            break;
        case 'AUP1':
            renderElement = <AUCard1 data={data} linkType={link}/>;
            break;
        case 'AUP2':
            renderElement = <AUCard2 data={data} linkType={link}/>;
            break;
        case 'AUP3':
            renderElement = <AUCard3 data={data} linkType={link}/>;
            break;
        case 'GP':
            renderElement = <GeoCard data={data}/>;
            break;
        case 'GPS':
            renderElement = <GeoCardStays data={data}/>;
            break;
    };

    return renderElement;
}

export default memo(Card);
