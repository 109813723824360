import { useEffect, useRef, useState } from "react";

export default function useSliderScroll({ resetScrollingProgressTimeoutMs = 50 } = {}) {
    const simpleBarRef = useRef(null);

    const [isScrollingProgress, setScrollingProgress] = useState(false);
    const isScrollingProgressRef = useRef(isScrollingProgress);
    isScrollingProgressRef.current = isScrollingProgress;

    useEffect(() => {
        let timerId;

        const whenComponentUnmount = () => {
            clearTimeout(timerId);
            contentWrapperEl.removeEventListener('scroll', handleScroll);
        };

        if (!simpleBarRef.current?.contentWrapperEl) {
            return whenComponentUnmount;
        }

        const { contentWrapperEl } = simpleBarRef.current;

        function handleScrollEnd() {
            clearTimeout(timerId);
            timerId = setTimeout(() => setScrollingProgress(false), resetScrollingProgressTimeoutMs);
        };


        function handleScroll() {
            handleScrollEnd();
            setScrollingProgress(true);
        };

        contentWrapperEl.addEventListener('scroll', handleScroll);

        return whenComponentUnmount;
    }, []);

    const withScrollingProgress = callback => (...args) => {
        if (isScrollingProgress) {
            return;
        }

        return callback(...args);
    };

    return {
        simpleBarRef,
        withScrollingProgress
    };
};
