import React from 'react';
import PropTypes from 'prop-types';
import { InputLabelWrapper } from './inputLabelWrapper';

export const Input = React.forwardRef((props,ref) => {
    const { type = "text", className = "primary-input",
    name, id, value, placeholder, onChange, onTouchStart, onBlur, onFocus, onClick, readOnly = false, labelText=false, errorLabel=false, autoComplete=true, onKeyUp=()=>{} } = props;


    const inputElement = () => (
            <input
                test-id='srp-geoSearch'
                type={type}
                className={`${className}`}
                name={name}
                id={id}
                value={value}
                placeholder={placeholder}
                onChange={onChange}
                onBlur={onBlur}
                onFocus={onFocus}
                readOnly={readOnly}
                ref={ref}
                autoComplete={autoComplete?.toString()}
                onKeyUp={onKeyUp}
                onClick={onClick}
                onTouchStart={onTouchStart}
                {...props}
            />
    );

    if(labelText )
    {

        return (
            <InputLabelWrapper labelText={labelText} errorLabel={errorLabel}>
                {props?.passedInput && props.passedInput() || inputElement()}
            </InputLabelWrapper>
        )
    }

    if(errorLabel)
    {
        return (
            <>
            {props?.passedInput && props.passedInput() || inputElement()}
            <span className="error-span">{errorLabel}</span>
            </>
        )
    }


    return inputElement()

});

Input.propTypes = {
    type: PropTypes.string,
    className: PropTypes.string,
    name: PropTypes.string,
    id: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    onTouchStart: PropTypes.func,
    onClick: PropTypes.func,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    readOnly: PropTypes.bool
}