import React, { memo } from 'react';

const GuestsProgressive = ({ className }) => (
    <svg className={className} width="30" height="30" viewBox="0 0 30 30" fill="none">
        <path d="M12.4937 15.8414C14.7969 15.8414 16.6641 13.9742 16.6641 11.6709C16.6641 9.36766 14.7969 7.50049 12.4937 7.50049C10.1904 7.50049 8.32324 9.36766 8.32324 11.6709C8.32324 13.9742 10.1904 15.8414 12.4937 15.8414Z" fill="#64748B" />
        <path d="M19.9809 21.6797C19.9809 19.4788 19.0394 17.4934 17.5131 16.1214C17.1618 15.8055 16.6321 15.7934 16.2729 16.1003C14.0947 17.9617 10.8933 17.9621 8.71456 16.1005C8.35592 15.794 7.82575 15.8035 7.47563 16.1197C5.95731 17.4911 5.00117 19.4736 5 21.6757C4.99977 22.1295 5.36728 22.5008 5.82105 22.5008H19.1599C19.6121 22.5008 19.9809 22.1345 19.9809 21.6797Z" fill="#64748B" />
        <path d="M23.4354 17.3704C22.9809 17.7405 22.4464 18.016 21.8623 18.1663C21.4152 18.2814 21.1507 18.7412 21.2764 19.1854C21.6656 20.5598 21.6228 21.7163 21.6228 21.6782C21.6228 22.1317 21.9904 22.4993 22.4439 22.4993H24.1708C24.6243 22.4993 24.9919 22.1317 24.9919 21.6782C24.9919 19.1203 25.0719 18.6224 24.7222 17.7031C24.5231 17.1799 23.8694 17.0169 23.4354 17.3704Z" fill="#64748B" />
        <path d="M20.8348 16.6749C22.2167 16.6749 23.337 15.5546 23.337 14.1727C23.337 12.7907 22.2167 11.6704 20.8348 11.6704C19.4528 11.6704 18.3325 12.7907 18.3325 14.1727C18.3325 15.5546 19.4528 16.6749 20.8348 16.6749Z" fill="#64748B" />
    </svg>
);

export default memo(GuestsProgressive);
