import React, { memo } from 'react';

//Import Libraries
import clsx from 'clsx';

//Import styles
import styles from '@/styles/components/AboutUs/__au--AU9.module.scss';
import Image from '@/containers/newHomePage/components/Image';

const AUCard3 = ({data}) => {

    let date  = new Date(data.date);
    date = date.toDateString("yyyy-MM-dd").split(" ");
    date = `${date[2]} ${date[1]} ${date[3]}`;

    return (
        <div className={clsx('cn__card cn__aboutus__au9__info__options__option',styles.cn__aboutus__au9__info__options__option)}>
            <a href={data.url}>
                <div className={clsx('cn__aboutus__au9__info__options__option__img', styles.cn__aboutus__au9__info__options__option__img)}>
                    <Image src={data.img} alt={data.title}/>
                </div>
                <p className={styles.cn__aboutus__au9__info__options__option__title}>{data.title}</p>
                <p className={styles.cn__aboutus__au9__info__options__option__subtitle}>{date}</p>
            </a>
        </div>
    );
};

export default memo(AUCard3);
