export const setItem = (key, value) => {
    localStorage.setItem(key, JSON.stringify(value));
};

export const getItem = (key, { defaultValue, shouldThrow = false } = {}) => {
    const serializedValue = localStorage.getItem(key);
    let value = defaultValue;
    try {
        value = JSON.parse(serializedValue);
    }catch(err) {
        if (shouldThrow) {
            throw err;
        }
    }
    return value;
};

export const removeItem = (key) => {
    localStorage.removeItem(key);
};
