import React, { memo, useEffect, useRef, useState } from 'react';

//Import methods
import { closePortalMobile, animationMobile } from '../../../../utils/currencyMethods';

//Import components
import CurrencyCard from '../../../CurrencyCard';
import CurrencyCategory from '../../../CurrencyCategory';

import { constants } from '@/src/utils/constants';
const CurrencyBox = ({state,info}) => {

    //Var
    const topC = info.data.top_currencies ?? [];
    const cryptoCurrencies = info.data.crypto_currencies ?? [];
    const bottomC = info.data.normal_currencies ?? [];

    //States
    const [searchInfo, setSearchInfo] = useState([]);
    const [inputValue, setInputValue] = useState('');

    //Refs
    const parentRef = useRef(null);

    //Methods
    useEffect(() => {
        animationMobile(parentRef.current,'enter');
    },[]);

    //Search
    const handleSearch = (e) => {
        let value = e.target.value;
        setInputValue(value);
    };
    useEffect(() => {
        let filter = bottomC.filter((el) => {
            if(
                el.long.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1 ||
                el.short.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1 ||
                el.symbol.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1
            ){
                return true;
            }
        });
        setSearchInfo(filter);
}   ,[inputValue]);

    //Render
    const renderAllCards = searchInfo.map((el,i) => <CurrencyCard selected={info.currency} info={el} close={{parent: parentRef.current, state}} from={constants.currenceyType.fiatCurrency} key={i}/>);
    const renderTopCards = topC.map((el,i) => <CurrencyCard selected={info.currency} info={el} key={i} close={{parent: parentRef.current, state}} from={constants.currenceyType.fiatCurrency}/>);
    const renderBottomCards = bottomC.map((el,i) => <CurrencyCard selected={info.currency} info={el} key={i} close={{parent: parentRef.current, state}}from={constants.currenceyType.fiatCurrency}/>);


    return(
        <div className='cn__currency__mobile__portal' ref={parentRef}>

            {/* ---------- HEADER ---------- */}
            <div className='cn__currency__mobile__portal__header'>
                <svg width="7" height="16" viewBox="0 0 7 16" fill="none" onClick={() => closePortalMobile(parentRef.current,state)}>
                    <path d="M6 15L1 8L6 1" stroke="#3E4041" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
                <p className='cn__currency__mobile__portal__header__title'>Select currency</p>
            </div>
            {/* ---------- SEARCH ---------- */}
            <div className='cn__currency__mobile__portal__search'>
                <div className='cn__currency__mobile__portal__search__wrapper'>
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
                        <path fillRule="evenodd" clipRule="evenodd" d="M10.9409 11.9608L11.0533 11.8485C12.5839 10.258 12.5654 7.72772 10.9976 6.15998C9.41112 4.57347 6.83888 4.57347 5.25238 6.15998C3.66587 7.74648 3.66587 10.3187 5.25238 11.9052C6.82012 13.473 9.35041 13.4915 10.9409 11.9608ZM11.5308 13.5886C9.30307 15.2582 6.12918 15.0801 4.10333 13.0543C1.88222 10.8332 1.88222 7.23203 4.10333 5.01093C6.32444 2.78982 9.92556 2.78982 12.1467 5.01093C14.1729 7.03717 14.3507 10.2119 12.68 12.4397L15.0182 14.7779C15.3355 15.0952 15.3355 15.6096 15.0182 15.9269C14.7009 16.2442 14.1864 16.2442 13.8691 15.9269L11.5308 13.5886Z" fill="#F35597"/>
                    </svg>
                    <input type='text' placeholder="Search" value={inputValue} onChange={handleSearch}/>
                </div>
            </div>

            {inputValue === '' ? (
                /* ---------- CURRENCIES ---------- */
                <div className='cn__currency__mobile__portal__currencies'>
                    <p className='cn__currency__mobile__portal__currencies__title'>
                        Popular currencies
                    </p>
                    {/* ---------- TOP CURRENCIES ---------- */}
                    <div className='cn__currency__mobile__portal__currencies__top'>
                        {renderTopCards}
                    </div>
                    {/* ---------- CRYPTO CURRENCIES ---------- */}
                    <CurrencyCategory
                        sx={{
                            mx: 2,
                            px: 2
                        }}
                        TitleProps={{
                            sx: {
                                fontSize: 14
                            }
                        }}
                        CardWrapperProps={{
                            sx: {
                                gridTemplateColumns: 'repeat(2, minmax(0, 1fr))'
                            }
                        }}
                        CardProps={{
                            selected: info.currency,
                            close: {
                                parent: parentRef.current,
                                state
                            }
                        }}
                        currencies={cryptoCurrencies}
                    >
                        Cryptocurrencies
                    </CurrencyCategory>

                    <p className='cn__currency__mobile__portal__currencies__title'>
                        All currencies
                    </p>
                    {/* ---------- ALL CURRENCIES ---------- */}
                    <div className='cn__currency__mobile__portal__currencies__bottom'>
                        {renderBottomCards}
                    </div>
                </div>
            ) : (
                searchInfo.length > 0 ? (
                    /* ---------- SEARCH CURRENCIES ---------- */
                    <div className='cn__currency__mobile__portal__searchcurrencies'>
                        {renderAllCards}
                    </div>
                ):(
                    /* ---------- SEARCH CURRENCIES EMPTY ---------- */
                    <div className='cn__currency__mobile__portal__searchcurrencies'>
                        <p className='cn__currency__mobile__portal__searchcurrencies__empty'>
                            Hmm, are you sure you typed that correctly?
                        </p>
                    </div>
                )
            )}

        </div>
    )
};

export default memo(CurrencyBox);