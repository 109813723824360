import clsx from 'clsx';
import React, { memo, useEffect, useRef, useState } from 'react';
/**
img.webp (default image as of exact size of div)
img.jpg (fallback image as of exact size of div).
img-2x.webp (2x image for retina display).
img-2x.jpg (fallback 2x image for retina display).
 */
const Image = ({
    src,
    alt,
    sources,
    onLoad,
    onError,
    loading = 'lazy',
    fallbackSrc = '/static/staticpages/newsroom/noimage.jpg',
    className,
    imgStyle
}) => {

    //States
    const [loaded, setLoaded] = useState(false);

    //Refs
    const image = useRef(null);

    //Methods

    //-----Error
    const handleError = e => {
        const { target } = e;
        target.src = fallbackSrc;
        onError?.(e);
    };

    //-----Onload
    const handleLoad = e => {
        setLoaded(true);
        onLoad?.(e);
    };
    useEffect(() => {
        image.current.complete && setLoaded(true);
    },[]);
    useEffect(() => {
        if(loaded){
            image.current.classList.remove('cn__image--notloaded');
        }
    },[loaded]);


    return (
        <picture className={clsx('cn__image-picture', className)}>
            {sources.map(({ media, srcset, type }, index) => (
                <source
                    key={String(index)}
                    media={media}
                    srcSet={srcset}
                    type={type}
                />
            ))}
            <img
                ref={image}
                style={imgStyle}
                className="cn__image-default cn__image--notloaded"
                src={src || ''}
                alt={alt}
                loading={loading}
                onError={handleError}
                onLoad={handleLoad}
            />
        </picture>
    )
};

Image.defaultProps = {
    sources: []
};

export default memo(Image);