export class ProcessingEnum {
    static PRISTINE = 'PRISTINE';
    static EDITED = 'EDITED';
    static COMPLETED = 'COMPLETED';
}

export class PropertyTypes {
    static HOTEL = 'hotel';
    static HOME = 'home';
    static VR = 'vr';
}
