import Skeleton from "@mui/material/Skeleton";
import Box from "@mui/material/Box";
import { memo } from "react";
import useInterests from "./useInterests";
import CheckSymb from "./CheckSymb";
import AddSymb from "./AddSymb";

const InterestCard = props => {
    const { onChange, isSelected, isLoading } = useInterests();

    const { isMobile } = props;

    const onClick = () => {
        if (!isLoading) {
            onChange(props);
        }
    };

    const getRootViewportStyles = () => {
        if (isMobile) {
            return {
                width: '300px',
                height: '124px',
            };
        }

        return {
            '@media (max-width: 600px)': {
                width: '300px',
                height: '124px',
            },
            '@media (min-width: 601px)': {
                width: props.widthUnset ? 'unset' : '160px',
                height: '254px',
            },
        };
    };

    const getTextViewportStyles = () => {
        return {
            fontFamily: 'var(--primary-font)',
            fontSize: '16px',
            fontWeight: 600,
            lineHeight: '120%',
            color: '#FFF',
            letterSpacing: '.01em',
            display: '-webkit-box',
            '-webkit-line-clamp': '2',
            'line-clamp': '2',
            '-webkit-box-orient': 'vertical',
            whiteSpace: 'wrap',
        };
    };

    return (
        <Box
            sx={{
                ...getRootViewportStyles(),
                display: 'block',
                borderRadius: '15px',
                border: '1px solid #E9E9EB',
                position: 'relative',
                transition: '.3s ease',
            }}
        >
            {isLoading
                ? (
                    <Skeleton
                        sx={{
                            transform: 'none',
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            borderRadius: 'inherit'
                        }}
                    />
                )
                : (
                    <>
                        <Box
                            sx={{
                                width: '100%',
                                height: '100%',
                                backgroundColor: 'rgba(0,0,0,.22)',
                                transition: '.5s ease',
                                borderRadius: '15px',
                                overflow: 'hidden',
                            }}
                        >
                            <Box
                                component='span'
                                sx={{
                                    transition: 'inherit',
                                    display: 'block',
                                    width: 'inherit',
                                    height: 'inherit',
                                    backgroundImage: isLoading
                                        ? 'initial'
                                        : `url('${props[`image_base_url_${props.imgSize}`]}')`,
                                    backgroundSize: 'cover',
                                    backgroundPosition: isMobile ? '50% 40%' : 'center',
                                    // backgroundPosition: 'center',
                                    backgroundRepeat: 'no-repeat',
                                    borderRadius: 'inherit'
                                }}
                            >
                                <Box
                                    sx={{
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        width: '100%',
                                        height: '100%',
                                        transition: 'all 0.2s',
                                        backgroundColor:
                                            `rgba(255, 255, 255, ${isSelected(props) ? '0.5' : '0'})`,
                                        pointerEvents: 'none',
                                    }}
                                />
                                <Box
                                    sx={{
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        width: '100%',
                                        height: '100%',
                                        transition: 'all 0.2s',
                                        background: 'linear-gradient(180deg, rgba(0, 0, 0, 0.00) 46.65%, rgba(0, 0, 0, 0.50) 83.07%)',
                                        pointerEvents: 'none',
                                        borderRadius: '15px',
                                    }}
                                />
                            </Box>
                            <Box
                                component='button'
                                onClick={onClick}
                                sx={{
                                    appearance: 'none',
                                    color: 'unset',
                                    backgroundColor: 'unset',
                                    border: 'none',
                                    cursor: 'pointer',
                                    position: 'absolute',
                                    display: 'flex',
                                    top: '6px',
                                    right: '6px',
                                }}
                            >
                                {isSelected(props)
                                    ? <CheckSymb />
                                    : <AddSymb />
                                }
                            </Box>
                            <Box
                                sx={{
                                    ...getTextViewportStyles(),
                                    position: 'absolute',
                                    bottom: 0,
                                    left: 0,
                                    width: '100%',
                                    padding: '16px',
                                }}
                            >
                                {props.display_name}
                            </Box>
                        </Box>
                    </>
                )}
        </Box>
    );
};

export default memo(InterestCard);
