import React, { memo } from 'react';

//Import components
import Portal from '@/components/common/Portal';
import CurrencyBox from './components/CurrencyBox';
import clsx from 'clsx';
import { ThemeType } from '@/static/contants';
import { useCurrencyContext } from '../../CurrencyProvider';


const CurrencyDesktop = ({info, theme }) => {
    const {
        isOpen,
        open,
        setOpen
    } = useCurrencyContext();

    return (
        <div
            test-id='hp_currency_desktop'
            className={clsx('cn__currency__desktop', {
                'cn__currency__desktop--dark': theme === ThemeType.DARK
            })}
        >
            <p
                className='cn__currency__desktop__title'
                onClick={open}
            >
                {info.currency.short}
            </p>
            <div className='cn__currency__desktop__tooltip'>
                <p className='cn__currency__desktop__tooltip__title'>
                    Select currency
                </p>
            </div>
            {isOpen && (
                <Portal>
                    <CurrencyBox
                        state={setOpen}
                        info={info}
                    />
                </Portal>
            )}
        </div>
    )
};

export default memo(CurrencyDesktop);