import { constants } from "@/utils/constants";

export const options1 = [
    {
        title: 'About us',
        url: '/about-us',
        gaLabel: 'Aboutus'
    },
    {
        title: 'Contact us',
        url: '/contact-us',
        gaLabel: 'ContactUs'
    },
    {
        title: 'List your property',
        url: '/list-your-property',
        gaLabel: 'ListYourProperty'
    },
    {
        title: 'CuddlyCare Program',
        url: '/care-program',
        gaLabel: 'CuddlyCareProgram'
    },
    {
        title: 'The CuddlyNest Promise',
        url: '/promise',
        gaLabel: 'CuddlyNestPromise'
    },
    {
        title: 'FAQ & Help Center',
        url: '/help',
        gaLabel: 'FAQ'
    },
    {
        title: 'Newsroom',
        url: '/newsroom',
        gaLabel: 'Newsroom'
    },
    {
        title: 'Blog',
        url: '/blog',
        gaLabel: 'Blog'
    },

];
export const options4 = [
    {
        title: 'Family vacations',
        url: '/t/family-vacations/',
        gaLabel: 'Family'
    },
    {
        title: 'Adventure travel',
        url: '/t/adventure-travel/',
        gaLabel: 'Adventure'
    },
    {
        title: 'Romantic getaways',
        url: '/t/romantic-getaways/',
        gaLabel: 'Romantic'
    },
    {
        title: 'Group travel',
        url: '/t/group-travel/',
        gaLabel: 'Group'
    },
    {
        title: 'Business travel',
        url: '/t/business-travel/',
        gaLabel: 'Business'
    },
    {
        title: 'Digital nomad travel',
        url: '/t/digital-nomad/',
        gaLabel: 'Digital'
    },
    {
        title: 'Solo travel',
        url: '/t/solo-travel/',
        gaLabel: 'Solo'
    },
    {
        title: 'Luxury stays',
        url: '/a/luxury-stays/',
        gaLabel: 'Luxury'
    }
];
export const options5 = [
    {
        title: 'Hotels',
        url: '/a/hotels/',
        gaLabel: 'Hotels'
    },
    {
        title: 'Vacation rentals',
        url: '/a/vacation-rentals/',
        gaLabel: 'VR'
    },
    {
        title: 'Hostels',
        url: '/a/hostels/',
        gaLabel: 'Hostels'
    },
    {
        title: 'Villas',
        url: '/a/villas/',
        gaLabel: 'Villas'
    },
    {
        title: 'B&Bs',
        url: '/a/bed-and-breakfast/',
        gaLabel: 'B&Bs'
    },
    // {
    //     title: 'Chalets',
    //     url: '/a/chalets/',
    //     gaLabel: 'Chalets'
    // },
    {
        title: 'Apartments',
        url: '/a/holiday-apartments',
        gaLabel: 'Apartments'
    },
    {
        title: 'Cabins',
        url: '/a/cabins/',
        gaLabel: 'Cabins'
    },
    {
        title: 'Bungalows',
        url: '/a/bungalows/',
        gaLabel: 'Bungalows'
    }
]