import React, { memo, useEffect, useState } from 'react'
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';
import { isServer } from '@/providers/Environment';
import { useRouter } from 'next/router';
import { isSwitchToAppCookieSet, setSwitchToAppCookie } from './cookie-helpers';

export {
    setSwitchToAppCookie,
    isSwitchToAppCookieSet
};

const SwitchToApp = ({ displayTimeout = 5000 }) => {
    const [isVisible, setIsVisible] = useState(false);
    const router = useRouter();

    const isSharedLDP = () => {
        return router.query.shared === 'ldp';
    };

    useEffect(() => {
        const isHomePage = router.pathname === '/';
        const resizeHandler = () =>
            setIsVisible(
                !isSwitchToAppCookieSet() &&
                getMobileOperatingSystem() &&
                (isHomePage || isSharedLDP())
            );


        const timeoutId = setTimeout(
            resizeHandler,
            displayTimeout
        );

        return () => {
            clearTimeout(timeoutId);
        };
    }, [displayTimeout]);

    function  getMobileOperatingSystem() {
        if (isServer()) {
            return;
        }

        let userAgent = navigator.userAgent || navigator.vendor || window.opera;
        if (/android/i.test(userAgent)) {
            return "Android";
        }
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            return "iOS";
        }
    }

    const checkAppInstalled = () => {
        const appScheme = 'cuddlynest://';
        const appSchemeAndroid = 'cuddlynest://user';
        const getOS = getMobileOperatingSystem();
        if(getOS === "Android") {
            const checkHandle = setTimeout(() => {
                clearTimeout(checkHandle);
                window.location.href = 'https://play.google.com/store/apps/details?id=com.cuddlynest';
            }, 2000);
            window.location.href = appSchemeAndroid;
            return;
        } else if (getOS === "iOS") {
            // const checkHandle = setTimeout(() => {
            //     clearTimeout(checkHandle);
                window.location.href = 'https://apps.apple.com/app/cuddlynest-hotels-and-homes/id1504837568?uo=2';
            // }, 2000);
            // window.location.href = appScheme;
            return;
        }
        window.location.href = '/'
    };

    const closePopup = () => {
        setIsVisible(false);

        setSwitchToAppCookie();

        const cookieBanner = document.querySelector('.cn__cookie-wrapper');
        if (cookieBanner) {
            const checkHandle = setTimeout(() => {
                clearTimeout(checkHandle);
                cookieBanner.classList.remove('hide');
            }, 1000)
        }
    };

    const switchToApp = async () => {
        closePopup();
        checkAppInstalled();
    };

    return (
        <Dialog
            open={isVisible}
            onClose={closePopup}
            sx={{
                bottom: '10%'
            }}
            PaperProps={{
                sx: {
                    borderRadius: '20px',
                    boxShadow: '0px 18px 88px -4px rgba(24, 39, 75, 0.14), 0px 8px 28px -6px rgba(24, 39, 75, 0.12)',
                    margin: '20px',
                }
            }}
        >
            <Box
                sx={{
                    bgcolor: 'var(--color-white)',
                    borderRadius: '20px',
                    p: '10px 18px 35px 18px',
                    fontWeight: 'bold',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    position: 'relative',
                    borderRadius: 'inherit'
                }}
            >
                <IconButton
                    sx={{
                        '&.MuiButtonBase-root': {
                            position: 'absolute',
                            top: 10,
                            right: 10
                        }
                    }}
                    onClick={closePopup}
                >
                    <CloseIcon color='disabled' />
                </IconButton>
                <Box
                    sx={{
                        mt: '32px'
                    }}
                >
                    <svg width="71" height="42" viewBox="0 0 71 42" fill="none">
                        <path d="M44.7434 18.0125C44.7434 18.0125 47.6473 21.0183 52.888 22.2173C56.0612 17.2524 62.5493 14.481 69.5091 13.4659C61.8017 12.9463 58.7461 13.9637 57.1469 14.1357C55.326 14.3315 50.1797 10.3502 44.7434 18.0125Z" fill="#F35597" />
                        <path d="M25.8019 23.5781C25.8019 23.5781 23.1995 26.8253 21.4872 30.3408C22.6846 28.5266 24.1491 26.84 27.7065 25.0006L25.8019 23.5781Z" fill="#F35597" />
                        <path d="M18.7839 10.6569C19.8786 11.7049 20.5935 12.6112 21.2601 13.5833C18.1542 12.0251 12.7351 12.1023 9.28284 12.5692C11.2058 11.6736 15.6011 10.4878 18.7839 10.6569Z" fill="#F35597" />
                        <path d="M38.3183 10.0217C38.3183 10.0217 40.1777 11.227 41.0156 13.5771C42.6235 10.3866 48.7431 7.75171 52.8791 6.71085C48.1975 6.87158 46.417 7.6734 45.4627 7.87571C44.3766 8.10587 40.0467 6.57288 38.3183 10.0217Z" fill="#F35597" />
                        <path d="M16.052 42C16.052 42 25.3326 33.58 35.4293 33.58C46.784 33.58 50.0645 28.1225 52.6772 22.796C52.6772 22.796 43.9231 21.6275 39.9521 12.3968C39.9521 12.3968 30.4841 -0.787412 4.90075 0.037123C4.90075 0.037123 19.4173 8.54429 22.9524 14.2659C26.5758 20.1304 35.9466 26.5058 35.5502 26.3105C27.344 22.2762 21.7625 14.6104 21.5735 14.1179C21.3848 13.6254 13.6022 11.3559 0 15.9272C0 15.9272 14.6155 13.1015 31.282 27.0349C31.282 27.0349 29.597 27.7909 28.3889 28.6246C20.9179 33.7802 16.0331 42.0047 16.052 42Z" fill="#F35597" />
                    </svg>
                </Box>
                <Box
                    sx={{
                        textAlign: 'center',
                        fontSize: 20,
                        letterSpacing: '0.2px',
                        lineHeight: '140.5%',
                        py: '10px',
                        fontWeight: 600
                    }}
                >
                    A more personalized experience? - See you on the other side...
                </Box>
                <ButtonBase
                    sx={{
                        '&.MuiButtonBase-root': {
                            backgroundImage: 'linear-gradient(92.14deg, #F35597 1.79%, #7868C7 99.81%)',
                            borderRadius: 30,
                            color: 'var(--color-white)',
                            fontFamily: 'var(--primary-font)',
                            fontWeight: 600,
                            fontSize: '1rem',
                            width: '100%',
                            p: '11px 20px',
                            width: 'fit-content',
                            mt: '15px'
                        }
                    }}
                    onClick={switchToApp}
                >
                    Switch to the app
                </ButtonBase>
            </Box>
        </Dialog>
    )
}

export default memo(SwitchToApp);