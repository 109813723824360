/* import React, { memo, useEffect, useRef } from 'react';

//Libraries
import { useSelector, useDispatch } from "react-redux";
import { successNotificationActions } from '@/redux/actions/globalActions';

//Import methods
import useDeviceDetect from '@/src/utils/customHooks/useDeviceDetect';

const Message = () => {

    //Check size
    const { isMobile } = useDeviceDetect();

    //Vars
    let set1, set2,set3;

    //Refs
    const ref = useRef(null);

    //Store
    const notification = useSelector((state) => state.notifications);
    const dispatch = useDispatch();

    // methods
    useEffect(() => {
        if(notification.successMsgDescription){
            set1 = setTimeout(() => {
                ref.current.style.cssText = isMobile ? 'right: 20px' : 'right: 30px';
            },100);
            set2 = setTimeout(() =>{
                ref.current.style.cssText = 'right:-100vw';
            },5000)
        };
        return () => {
            clearTimeout(set1);
            clearTimeout(set2);
        }
    },[notification.successMsgDescription, isMobile]);

    const handleClose = () => {
        ref.current.style.cssText = 'right:-100vw';
        clearTimeout(set2);
        set3 = setTimeout(() => {
            dispatch(successNotificationActions(null, null, null));
        },800);
    };

    //Element

    let title = 'Oops, something went wrong!';
    let subtitle = 'Please try again later';

    if(notification.successMsgDescription === 'You have already subscribed.'){
        title = `You're already subscribed!`;
        subtitle = 'As it turns out, you previously subscribed and should already be receiving our emails';
    }else if (notification.successMsgDescription === 'Subscriber added successfully.'){
        title = 'Thanks for subscribing, adventures await!';
        subtitle = 'To complete the subscription process, please click the link in the email that we just sent you';
    }

    const renderElement = notification.successMsgDescription && (
        <div className='cn__message' ref={ref}>
            <p className='cn__message__title'>{title}</p>
            <p className='cn__message__subtitle'>{subtitle}</p>
            <div className='cn__message__close' onClick={handleClose}>
                <img src='/static/home/x.svg' alt='close button'/>
            </div>
        </div>
    );
    

    return renderElement;
}

export default memo(Message); */



import React, { memo, useRef } from 'react';

//Libraries
import { useSelector } from "react-redux";

const Message = () => {

    //Refs
    const ref = useRef(null);

    //Store
    const notification = useSelector((state) => state.notifications);

    //Element

    let title = 'Oops, something went wrong!';
    let subtitle = 'Please try again later';

    if(notification.successMsgDescription === 'You have already subscribed.'){
        title = `You're already subscribed!`;
        subtitle = 'As it turns out, you previously subscribed and should already be receiving our emails';
    }else if (notification.successMsgDescription === 'Subscriber added successfully.'){
        title = 'Thanks for subscribing, adventures await!';
        subtitle = 'To complete the subscription process, please click the link in the email that we just sent you';
    }

    const renderElement = notification.successMsgDescription && (
        <div className='cn__message' ref={ref}>
            <p className='cn__message__title'>{title}</p>
            <p className='cn__message__subtitle'>{subtitle}</p>
        </div>
    );
    

    return renderElement;
}

export default memo(Message);