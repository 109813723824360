import React, { memo, useCallback, useEffect, useMemo } from 'react';
import CaretIcon from '@/components/common/icons/Caret';
import GuestModel from './GuestModel';
import SearchActionArea from '../SearchActionArea';
import { constants } from '@/src/utils/constants';
import AgeSelector from './AgeSelector';
import clsx from 'clsx';

const GuestPicker = ({
    value,
    onChange,
    searchActionAreaSlot,
    disableChildAge,
    disableRooms,
}) => {
    // const guestTypes = useMemo(() => ({
    //     ADULT: 'adultCount',
    //     CHILD: 'childCount',
    //     INFANT: 'infantCount',
    //     ROOM: 'roomCount'
    // }), []);

    const validationSchema = useMemo(() => {
        return {
            [constants.guestTypes.ADULT]: {
                MIN: constants.guestInput[constants.guestTypes.ADULT]['MIN'],
                MAX: constants.guestInput[constants.guestTypes.ADULT]['MAX']
            },
            [constants.guestTypes.CHILD]: {
                MIN: constants.guestInput[constants.guestTypes.CHILD]['MIN'],
                MAX: constants.guestInput[constants.guestTypes.CHILD]['MAX']
            },
            [constants.guestTypes.INFANT]: {
                MIN: constants.guestInput[constants.guestTypes.INFANT]['MIN'],
                MAX: constants.guestInput[constants.guestTypes.INFANT]['MAX']
            },
            [constants.guestTypes.ROOM]: {
                MIN: constants.guestInput[constants.guestTypes.ROOM]['MIN'],
                MAX: constants.guestInput[constants.guestTypes.ROOM]['MAX']
            }
        };
    }, [constants.guestTypes, constants.guestTypes]);

    const validator = useCallback(([guestCountType, count]) => {
        const validationRules = validationSchema[guestCountType];
        return Math.max(Math.min(count, validationRules.MAX), validationRules.MIN);
    }, [validationSchema]);

    const actions = useMemo(() => ({
        add: count => count + 1,
        sub: count => count - 1
    }), []);

    // const controlActionHandler = useCallback((action = actions.add, guestCountType = constants.guestTypes.ADULT) => () => {
    //     onChange(state => new GuestModel({
    //         ...state,
    //         [guestCountType]: validator([guestCountType, action(state[guestCountType])])
    //     }));
    // }, [onChange, actions, constants.guestTypes]);
    const controlActionHandler = useCallback((action = actions.add, guestCountType = constants.guestTypes.ADULT) => () => {
        onChange(state => {
            const updatedCount = validator([guestCountType, action(state[guestCountType])]);
            return (new GuestModel({
                ...state,
                [guestCountType]: updatedCount,
                ...((guestCountType === constants.guestTypes.ROOM && updatedCount > state[constants.guestTypes.ADULT])
                    && { [constants.guestTypes.ADULT]: updatedCount }),
                ...((guestCountType === constants.guestTypes.ADULT && updatedCount < state[constants.guestTypes.ROOM])
                    && { [constants.guestTypes.ROOM]: updatedCount }),
            }));
        });
    }, [onChange, actions, constants.guestTypes]);

    const isControlDisabled = useCallback((controlType, guestType) => ({
        add: (value[guestType] === validationSchema[guestType].MAX),
        sub: (value[guestType] === validationSchema[guestType].MIN)
    })[controlType], [value, validationSchema]);

    const getChildrenModifierClass = () => {
        if (!disableChildAge && (value.childCount > 5)) {
            return 'omnisearch_content_action-area_guest-picker_track--large';
        }
        return '';
    };

    useEffect(() => {
        onChange(state => new GuestModel({
            ...state,
            isVisited: true
        }));
    }, [onChange]);

    return (
        <>
            <div className="omnisearch_content_action-area_guest-picker">
                <div
                    className={clsx(
                        "omnisearch_content_action-area_guest-picker_track",
                        getChildrenModifierClass()
                    )}
                >
                    <div className="omnisearch_content_action-area_guest-picker_guest-type">
                        <div className="omnisearch_content_action-area_guest-picker_guest-type_title">
                            Adults
                        </div>
                    </div>
                    <div className="omnisearch_content_action-area_guest-picker_guest-control">
                        <button
                            className="omnisearch_content_action-area_guest-picker_guest-control_action"
                            type='button'
                            onClick={controlActionHandler(actions.sub, constants.guestTypes.ADULT)}
                            disabled={isControlDisabled(actions.sub.name, constants.guestTypes.ADULT)}
                        >
                            <CaretIcon />
                        </button>
                        <div className="omnisearch_content_action-area_guest-picker_guest-control_label">{value[constants.guestTypes.ADULT]}</div>
                        <button
                            className="omnisearch_content_action-area_guest-picker_guest-control_action"
                            type='button'
                            onClick={controlActionHandler(actions.add, constants.guestTypes.ADULT)}
                            disabled={isControlDisabled(actions.add.name, constants.guestTypes.ADULT)}
                        >
                            <CaretIcon />
                        </button>
                    </div>
                </div>
                <div
                    className={clsx(
                        'omnisearch_content_action-area_guest-picker_track',
                        'omnisearch_content_action-area_guest-picker_track--extended',
                        getChildrenModifierClass()
                    )}
                >
                    <div className="omnisearch_content_action-area_guest-picker_guest-type">
                        <div className="omnisearch_content_action-area_guest-picker_guest-type_title">
                            Children
                        </div>
                        <div className="omnisearch_content_action-area_guest-picker_guest-type_subtitle">
                             Ages 2-17
                        </div>
                    </div>
                    <div className="omnisearch_content_action-area_guest-picker_guest-control">
                        <button
                            className="omnisearch_content_action-area_guest-picker_guest-control_action"
                            type='button'
                            onClick={controlActionHandler(actions.sub, constants.guestTypes.CHILD)}
                            disabled={isControlDisabled(actions.sub.name, constants.guestTypes.CHILD)}
                        >
                            <CaretIcon />
                        </button>
                        <div className="omnisearch_content_action-area_guest-picker_guest-control_label">{value[constants.guestTypes.CHILD]}</div>
                        <button
                            className="omnisearch_content_action-area_guest-picker_guest-control_action"
                            type='button'
                            onClick={controlActionHandler(actions.add, constants.guestTypes.CHILD)}
                            disabled={isControlDisabled(actions.add.name, constants.guestTypes.CHILD)}
                        >
                            <CaretIcon />
                        </button>
                    </div>
                    {!disableChildAge &&
                        <AgeSelector
                            className={getChildrenModifierClass()}
                        />}
                </div>
                <div
                    className={clsx(
                        "omnisearch_content_action-area_guest-picker_track",
                        getChildrenModifierClass()
                    )}
                >
                    <div className="omnisearch_content_action-area_guest-picker_guest-type">
                        <div className="omnisearch_content_action-area_guest-picker_guest-type_title">
                            Infants
                        </div>
                        <div className="omnisearch_content_action-area_guest-picker_guest-type_subtitle">
                            Under age 2
                        </div>
                    </div>
                    <div className="omnisearch_content_action-area_guest-picker_guest-control">
                        <button
                            className="omnisearch_content_action-area_guest-picker_guest-control_action"
                            type='button'
                            onClick={controlActionHandler(actions.sub, constants.guestTypes.INFANT)}
                            disabled={isControlDisabled(actions.sub.name, constants.guestTypes.INFANT)}
                        >
                            <CaretIcon />
                        </button>
                        <div className="omnisearch_content_action-area_guest-picker_guest-control_label">{value[constants.guestTypes.INFANT]}</div>
                        <button
                            className="omnisearch_content_action-area_guest-picker_guest-control_action"
                            type='button'
                            onClick={controlActionHandler(actions.add, constants.guestTypes.INFANT)}
                            disabled={isControlDisabled(actions.add.name, constants.guestTypes.INFANT)}
                        >
                            <CaretIcon />
                        </button>
                    </div>
                </div>
                {!disableRooms &&
                    <div
                        className={clsx(
                            "omnisearch_content_action-area_guest-picker_track",
                            getChildrenModifierClass()
                        )}
                    >
                        <div className="omnisearch_content_action-area_guest-picker_guest-type">
                            <div className="omnisearch_content_action-area_guest-picker_guest-type_title">
                                Rooms
                            </div>
                        </div>
                        <div className="omnisearch_content_action-area_guest-picker_guest-control">
                            <button
                                className="omnisearch_content_action-area_guest-picker_guest-control_action"
                                type='button'
                                onClick={controlActionHandler(actions.sub, constants.guestTypes.ROOM)}
                                disabled={isControlDisabled(actions.sub.name, constants.guestTypes.ROOM)}
                            >
                                <CaretIcon />
                            </button>
                            <div className="omnisearch_content_action-area_guest-picker_guest-control_label">{value[constants.guestTypes.ROOM]}</div>
                            <button
                                className="omnisearch_content_action-area_guest-picker_guest-control_action"
                                type='button'
                                onClick={controlActionHandler(actions.add, constants.guestTypes.ROOM)}
                                disabled={isControlDisabled(actions.add.name, constants.guestTypes.ROOM)}
                            >
                                <CaretIcon />
                            </button>
                        </div>
                    </div>}
            </div>
            {searchActionAreaSlot}
        </>
    );
};

export default memo(GuestPicker);
