import React, { memo } from 'react';

const LYPCard2 = ({data}) => {
    return (
        <div className='cn__lyp__card2'>
            <div className='cn__lyp__card2__icon'>
                {data.icon}
            </div>
            <p className='cn__lyp__card2__title'>{data.title}</p>
            <p className='cn__lyp__card2__subtitle'>{data.subtitle}</p>
        </div>
    );
};

export default memo(LYPCard2);