import { memo, useEffect, useState } from 'react';
import clsx from 'clsx';
import Tooltip from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';
import ClickAwayListener from '@mui/material/ClickAwayListener';

import SearchIcon from '@/components/common/icons/Search';
import SearchCriteriaEntity from './SearchCriteriaEntity';

const SearchActionArea = ({
    className,
    onClick,
    children,
    disabled,
    isMobile,
    invalidEntity,
    resetInvalidEntity = () => {}
}) => {
    const [message, setMessage] = useState('');

    const onClose = () => resetInvalidEntity();

    const getValidationError = () => {
        if (invalidEntity instanceof SearchCriteriaEntity) {
            return invalidEntity.getValidationError()?.message;
        }

        return message;
    };

    useEffect(() => {
        if (invalidEntity) {
            setMessage(
                invalidEntity.getValidationError()?.message
            );
        }
    }, [invalidEntity]);

    return (
        <ClickAwayListener
            onClickAway={onClose}
        >
            <Tooltip
                arrow
                placement='top'
                PopperProps={{
                    disablePortal: true,
                    sx: {
                        '& .MuiTooltip-tooltip': {
                            bgcolor: 'var(--color-red)',
                            borderRadius: 2,
                            fontFamily: 'var(--primary-font)',

                            '& .MuiTooltip-arrow': {
                                color: 'var(--color-red)'
                            }
                        }
                    }
                }}
                TransitionComponent={Zoom}
                onClose={onClose}
                open={Boolean(invalidEntity)}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                title={getValidationError()}
            >
                <div
                    className={clsx(
                        'omnisearch_content_search-action-area',
                        className
                    )}
                >
                    <button
                        className={clsx(
                            'omnisearch_content_search-action-area_btn',
                            disabled && 'omnisearch_content_search-action-area_btn--disabled'
                        )}
                        test-id='search-action'
                        type='button'
                        disabled={disabled}
                        onClick={onClick}
                    >
                        {children || (
                            <>
                                <SearchIcon className="omnisearch_content_search-action-area_btn-icon" />
                                {isMobile && (
                                    <span className="omnisearch_content_search-action-area_btn-label">
                                        Search
                                    </span>
                                )}
                            </>
                        )}
                    </button>
                </div>
            </Tooltip>
        </ClickAwayListener>
    );
};

export default memo(SearchActionArea);
