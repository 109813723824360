import { useRouter } from "next/router";
import { useState, useEffect } from "react";

export default function useEmailConfPopup() {
    const router = useRouter();
    const [showConf, setShowConf] = useState(false);

    useEffect(() => {
        if (router.pathname === '/' && router.query?.verified === '1') {
            router.push(router.pathname, undefined, { shallow: true });
            setShowConf(true);
        }
    }, [router]);
    useEffect(() => {
        if (showConf) {
            const timeoutID = setTimeout(() => {
                setShowConf(false);
            }, 7000);
            return () => {
                setShowConf(false);
                clearTimeout(timeoutID);
            };
        }
    }, [showConf]);

    return { showConf, setShowConf };
}