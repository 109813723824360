import { config } from "@/utils/config";

const withEnvironmentConfig = Component =>
    props => Boolean(config.isCryptoEnabled) && (
        <Component
            {...props}
        />
    );

export default withEnvironmentConfig;
