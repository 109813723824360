import React, { memo } from 'react';

//Import components
import LYPMapCard from './Card';
import BedFilled from '@/components/common/icons/BedFilled';
import HouseFilled from '@/components/common/icons/HouseFilled';
import HotelFilled from '@/components/common/icons/HotelFilled';

const data = [
    {
        title: 'Chicago',
        amount: 'USD 3,260',
        position: {
            top:11.4,
            left:14.6
        },
        icon: <BedFilled/>
    },
    {
        title: 'New York City',
        amount: 'USD 3,520',
        position: {
            top:25,
            left:18.9
        },
        icon: <BedFilled/>
    },
    {
        title: 'San Francisco',
        amount: 'USD 4,000',
        position: {
            top:37.8,
            left:4.2
        },
        icon: <BedFilled/>
    },
    {
        title: 'São Paulo',
        amount: 'BRL 3,650',
        position: {
            top:75.2,
            left:25
        },
        icon: <HotelFilled className='lypmaphotel'/>
    },
    {
        title: 'Berlin',
        amount: 'EUR 1,600',
        position: {
            top:21,
            left:47.6
        },
        icon: <HouseFilled/>
    },
    {
        title: 'New Delhi',
        amount: 'INR 65,000',
        position: {
            top:44.5,
            left:46.5
        },
        icon: <HouseFilled/>
    },
    {
        title: 'Dubai',
        amount: 'AED 9,500',
        position: {
            top:57.9,
            left:52.1
        },
        icon: <HouseFilled/>
    },
    {
        title: 'Moscow',
        amount: 'RUB 64,600',
        position: {
            top:30.3,
            left:61
        },
        icon: <HotelFilled className='lypmaphotel'/>
    },
    {
        title: 'Bali',
        amount: 'IDR 3,000,000.00',
        position: {
            top:58.5,
            left:76
        },
        icon: <BedFilled/>
    }
];

const LYPMap = () => {
   
    return (
        <div className='cn__lyp__lyp7__map__wrapper'>
            <img src='/static/staticpages/lyp/lypmap.svg' alt='Cuddlynest property revenues map' className='cn__lyp__lyp7__map'/>
            {data.map((el,i) => {
                return <LYPMapCard data={el} key={i}/>
            })}
        </div>
    );
};

export default memo(LYPMap);