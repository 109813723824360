import React, { memo } from 'react';

const LYPMapCard = ({data}) => {
    return (
        <div className='cn__lyp__lyp7__map__card' style={{top:`${data.position.top}%`,left:`${data.position.left}%`}}>
            {data.icon}
            <div className='cn__lyp__lyp7__map__card__info'>
                <p className='cn__lyp__lyp7__map__card__info__title'>{data.title}</p>
                <p className='cn__lyp__lyp7__map__card__info__subtitle'>{data.amount}</p>
            </div>
        </div>
    );
};

export default memo(LYPMapCard);