import { memo, useEffect, useMemo, useRef, useState } from "react";
import Box from '@mui/material/Box';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import IconButton from '@mui/material/IconButton';
import InterestSlide from "./InterestSlide";
import useInterests from "./useInterests";
import SwiperCore, { Navigation, A11y, Scrollbar } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'

import InterestCard from "./InterestCard";

SwiperCore.use([Navigation, A11y, Scrollbar]);

const ImageSize = {
    SMALL: 'small',
    MEDIUM: 'medium',
    LARGE: 'large',
};

const InterestTiles = ({ length }) => {
    const { data, isLoading } = useInterests();

    // TODO: refactor into wrapper component
    const [swiper, setSwiper] = useState();
    const prevRef = useRef();
    const nextRef = useRef();

    useEffect(() => {
        if (swiper) {
            swiper.params.navigation.prevEl = prevRef.current;
            swiper.params.navigation.nextEl = nextRef.current;
            swiper.navigation.init();
            swiper.navigation.update();
        }
    }, [swiper]);

    const slides = useMemo(() => {
        if (isLoading) {
            return [Array.from(new Array(5)).fill({})];
        }

        return data;
    }, [data, isLoading]);

    const isPaginated = useMemo(() => slides.length > 4, [slides.length]);

    const imgSize = useMemo(() => {
        let size;
        switch (slides.length) {
            case 1:
                size = ImageSize.LARGE;
                break;
            case 2:
                size = ImageSize.MEDIUM;
                break;
            default:
                size = ImageSize.SMALL;
        }
        return size;
    }, [slides.length]);

    const sxArrowProp = {
        position: 'absolute',
        zIndex: 2,
        top: '50%',
        transform: 'translateY(-50%)',
        opacity: Number(isPaginated),
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
        transition: 'backgroundColor 0.2s',
        '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.9)',
        },
        '&.swiper-button-disabled': {
            backgroundColor: 'transparent',
            color: 'rgba(0, 0, 0, 0)',
            pointerEvents: 'none',
            cursor: 'default'
        }
    };

    const position = '8px';

    return (
        <Box
            sx={{
                mt: '10px',
                position: 'relative',
                '& .swiper-pagination': {
                    display: 'none'
                }
            }}
        >
            <IconButton
                ref={prevRef}
                className="swiper-button"
                sx={{
                    ...sxArrowProp,
                    left: position
                }}
            >
                <ChevronLeftIcon />
            </IconButton>
            <Swiper
                freeMode
                observer
                observeParents
                updateOnWindowResize
                scrollbar={{
                    hide: true,
                    draggable: true,
                }}
                navigation={{
                    prevEl: prevRef?.current,
                    nextEl: nextRef?.current
                }}
                spaceBetween={8}
                slidesPerView={isPaginated ? 'auto' : slides.length}
                pagination={{ clickable: true }}
                onSwiper={setSwiper}
            >
                {slides.map((slide, index) => (
                        <SwiperSlide
                            key={String(index)}
                        >
                            <InterestCard
                                key={String(index)}
                                isLoading={isLoading}
                                widthUnset={!isPaginated}
                                imgSize={imgSize}
                                {...slide}
                            />
                        </SwiperSlide>
                    ))}
            </Swiper>
            <IconButton
                ref={nextRef}
                className="swiper-button"
                sx={{
                    ...sxArrowProp,
                    right: position
                }}
            >
                <ChevronRightIcon />
            </IconButton>
        </Box>
    );
};

InterestTiles.defaultProps = {
    length: 8
};

export default memo(InterestTiles);
