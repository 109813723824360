import { useOmnisearchState } from "./contexts/OmnisearchState";
import { Entities } from "./enum";
import useInterests from "./Interests/useInterests";

export default function useValidationGate() {
    const {
        destination,
        dates,
        guests
    } = useOmnisearchState();

    const { isInterestsValid } = useInterests();

    const [destinationState] = destination;
    const [datesState] = dates;
    const [guestsState] = guests;

    const validateEntities = (
        destination = destinationState,
        date = datesState,
    ) => {
        const entitiesMap = new Map()
            .set(Entities.Destination, destination)
            .set(Entities.DateRange, date)

        for (const [_, entity] of Array.from(entitiesMap.entries())) {
            if (!entity.validate()) {
                return entity;
            }
        }
    };

    const isEntitiesValid = (
        destination,
        date,
    ) =>
        !validateEntities(
            destination,
            date,
        );

    const isInvalid = (destinationState, datesState, guestsState) =>
        /*!isInterestsValid() ||*/ !isEntitiesValid(
            destinationState,
            datesState,
        );

    return {
        validateEntities,
        isEntitiesValid,
        isInvalid
    };
};
