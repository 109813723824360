import React, { memo } from 'react';

//Import libraries
import { shallowEqual, useSelector } from 'react-redux';

//Import methods
import useEnhanceUrlSearchParams, { STRATEGIES } from '@/utils/customHooks/useEnhanceUrlSearchParams';
import { handleJSClick } from '@/containers/newHomePage/utils/link';
import analyticsLabelProvider from '@/static/analyticsLabelProvider';
import { EAType } from '@/static/contants';

const List = ({data, location, handleLogIn}) => {

    //Store
    const { data: authState } = useSelector(state => state.authReducer, shallowEqual);

    let renderItem = (
        <p className="cn__footer__top__options__option__list">
            <a href={data.url} target="_self" data-cn-ga-click={JSON.stringify({ el: analyticsLabelProvider.footer[EAType.CLICK](data.gaLabel) })}>
                {data.title}
            </a>
        </p>
    );

    const linkGeneratorFn = useEnhanceUrlSearchParams(STRATEGIES.CHECKIN_CHECKOUT_NEXT_WEEKEND);
    //User location

    /* if(data.title === "List your property"){
        const handleButtonClick = () => {
            if(authState){
                handleJSClick("/list_space")
            }else{
                handleLogIn(true);
            }
        }
        renderItem = (
            <p
                className="cn__footer__top__options__option__list"
                onClick={handleButtonClick}
                data-cn-ga-click={JSON.stringify({ el: analyticsLabelProvider.footer[EAType.CLICK](data.gaLabel) })}
            >
                {data.title}
            </p>
        )
    } */

    return renderItem;
}

export default memo(List);