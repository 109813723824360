import { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { object, string } from 'yup';
import clsx from "clsx";
import { config } from "@/src/utils/config";
import { Submit, PhoneInput } from "@/components/formComponents/MaterialFormInputs";
import { forgotPasswordAction, resendVerifyCodeAction } from "@/redux/actions/authActions";
import styles from './Authentication.module.scss';
import ErrorMsg from './Error';
import { PasswordEyeSVG } from './AuthSVG';
import Dots from './DotsAnimation';
import Final from './Final';
import {
    FORGOT_PASSWORD_FAIL,
    FORGOT_PASSWORD_LOAD,
    FORGOT_PASSWORD_SUCCESS,
    RESEND_VERIFY_CODE_SUCCESS,
    RESEND_VERIFY_CODE_FAIL,
    RESEND_VERIFY_CODE_LOAD,
} from "@/redux/types";
import useForm from './useForm';
import usePhoneInputDecorator from './usePhoneInputDecorator';
import PhoneVerification from './PhoneVerification';
import ReCAPTCHA from 'react-google-recaptcha';
import { constants } from '@/src/utils/constants';

const Forgot = ({ loginOption, setAuthOpen }) => {
    const phoneInputHelper = useRef(false);
    const [showPassword, setShowPassword] = useState(false);
    const isEmail = loginOption === 'email';
    const authStore = useSelector(state => state.authReducer);
    const dispatch = useDispatch();
    const [captchaCode, setCaptcaCode] = useState();
    const [submitCount, setSubmitCount] = useState(0);
    const [screen, setScreen] = useState('main');
    const captchaRef = useRef(null);
    const [backendErr, setBackendErr] = useState({
        phone: null,
        email: null,
        password: null
    });

    const initVals = {
        phone: '',
        email: '',
        password: ''
    };

    const loginSchema = object({
        password: string()
            .min(4, 'Must be at least 4 characters long')
            .max(20, 'Must not exceed 20 characters')
            .required('Required'),
        email: string()
            .email('Must be valid email address')
            .max(100, 'Must not exceed 100 characters')
            .required('Required'),
        phone: string()
            .required('Required'),
    });

    const myForm = useForm(initVals, loginSchema);

    const [
        phoneNumber,
        phoneInputKey,
        handleChangePhoneDecorated,
        handleBlurPhoneDecorated
    ] = usePhoneInputDecorator(myForm.handleChange, myForm.handleBlur);

    const onChangeReCaptcha = (e) => {
        setCaptcaCode(e)
    }
    const handleSubmit = async evt => {
        evt.preventDefault();
        setSubmitCount((count) => {
            return count + 1;
        });
        if (isEmail) {
            dispatch(forgotPasswordAction({ email: myForm.values.email, 'g-recaptcha-response': captchaCode }));
        } else {
            dispatch(resendVerifyCodeAction({
                country_calling_code: '+' + phoneNumber[0],
                phone: phoneNumber[1],
                'g-recaptcha-response': captchaCode
            }));
        }
    };

    const updateBackendError = (field) => {
        setBackendErr((prev) => {
            return {
                ...prev,
                [field]: authStore.message
            };
        });
    };

    useEffect(() => {
        if (submitCount > 0) {
            if (authStore.type === FORGOT_PASSWORD_SUCCESS) {
                setScreen('final');
            } else if (authStore.type === FORGOT_PASSWORD_FAIL) {
                updateBackendError('email');
            } else if (authStore.type === RESEND_VERIFY_CODE_SUCCESS && screen === 'main') {
                setScreen('verification');
            } else if (authStore.type === RESEND_VERIFY_CODE_FAIL && screen === 'main') {
                updateBackendError('phone');
            }
        }
    }, [authStore.type]);

    useEffect(() => {
        const timeoutID = setTimeout(() => {
            setBackendErr({
                phone: null,
                email: null,
                password: null
            });
        }, 4000);
        return () => {
            clearTimeout(timeoutID);
        };
    }, [backendErr.phone, backendErr.email, backendErr.password]);

    const isSubmitDisabled = () => {
        const token = !Boolean(captchaRef.current?.getValue());
        const values = { ...myForm.values };
        const errors = { ...myForm.errors };
        if (isEmail) {
            delete values.phone;
            delete errors.phone;
        } else {
            delete values.email;
            delete errors.email;
        }
        const haveEmpty = Object.values(values).some(value => !value);
        const haveError = Object.values(errors).some(error => error);
        return token && (haveEmpty || haveError);
    };

    useEffect(() => {
        if (phoneInputHelper.current) {
            document.querySelector('.phone-input-component input')?.focus();
        } else {
            phoneInputHelper.current = true;
        }
    }, [phoneInputKey]);

    return (
        <>

            {(screen === 'final') &&
                <Final
                    message={isEmail
                        ? authStore.message
                        : "A new password has been created for your account"}
                    linkText={'Ok, thanks!'}
                    setAuthOpen={setAuthOpen}
                />
            }

            {(screen === 'verification') && (<>
                <PhoneVerification
                    phoneNumber={phoneNumber}
                    setScreen={setScreen}
                    password={myForm.values.password}>
                    <div className={styles.auth_form_item}>
                        <input
                            id='password'
                            name='password'
                            type={showPassword ? 'text' : 'password'}
                            placeholder='Password'
                            className={clsx(styles.auth_form_input,
                                styles.auth_form_input_password)}
                            onChange={myForm.handleChange}
                            onBlur={myForm.handleBlur}
                            value={myForm.values.password}
                        />
                        <span
                            className={styles.auth_form_item__symbol}
                            onClick={() => setShowPassword(currState => !currState)}
                        >
                            <PasswordEyeSVG show={showPassword} />
                        </span>
                        {myForm.errors.password &&
                            <ErrorMsg>{myForm.errors.password}</ErrorMsg>}
                        {backendErr.password && (submitCount > 0) && <ErrorMsg>{backendErr.password}</ErrorMsg>}
                    </div>
                </PhoneVerification>
            </>)}

            {(screen === 'main') && (<>
                <div className={styles.auth_title}>
                    {isEmail
                        ? "Enter your email address and we’ll send over password reset instructions"
                        : "Enter your mobile number and we’ll send you verification code"}
                </div >
                <form className={styles.auth_form} onSubmit={handleSubmit}>
                    <ul className={styles.auth_form_ul}>
                        {isEmail
                            ? (
                                <li className={styles.auth_form_item}>
                                    <input
                                        id='email'
                                        name='email'
                                        type='email'
                                        placeholder='Email address'
                                        className={clsx(styles.auth_form_input)}
                                        onChange={myForm.handleChange}
                                        onBlur={myForm.handleBlur}
                                        value={myForm.values.email}
                                    />
                                    {myForm.errors.email &&
                                        <ErrorMsg>{myForm.errors.email}</ErrorMsg>}
                                    {backendErr.email && (submitCount > 0) && <ErrorMsg>{backendErr.email}</ErrorMsg>}
                                </li>
                            )
                            : (
                                <li className={styles.auth_form_item}>
                                    <PhoneInput
                                        containerClass={clsx(styles.auth_form_item__phone_container,
                                            (myForm.values.phone && styles.auth_form_item__phone_container_selected))}
                                        placeholder='country code+phone number'
                                        value={myForm.values.phone}
                                        onChange={handleChangePhoneDecorated}
                                        onBlur={handleBlurPhoneDecorated}
                                        key={phoneInputKey}
                                    />
                                    {myForm.errors.phone &&
                                        <ErrorMsg>{myForm.errors.phone}</ErrorMsg>}
                                    {backendErr.phone && (submitCount > 0) && <ErrorMsg>{backendErr.phone}</ErrorMsg>}
                                </li>
                            )
                        }
                         <ReCAPTCHA
                                sitekey={constants.dashboardResendTokenRecaptchaKey}
                                onChange={onChangeReCaptcha}
                                className={styles.recaptcha}
                                ref={captchaRef}
                            />
                        <li className={styles.auth_form_item}>
                            <Submit
                                type="submit"
                                className={styles.auth_form_submit}
                                disabled={isSubmitDisabled()}
                            >
                                {[FORGOT_PASSWORD_LOAD, RESEND_VERIFY_CODE_LOAD].includes(authStore.type)
                                    ? <Dots container={styles.loading} />
                                    : 'Continue'}
                            </Submit>
                        </li>
                    </ul>
                </form>
            </>)}

        </>
    );
}

export default Forgot;
