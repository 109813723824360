import { memo } from "react";

const CalculateProgress = ({ component: AbstractAvatarComponent, src, progress, ...restProps }) => {
    
    const calculatedProgress = progress * 100;

    return (
        <AbstractAvatarComponent
            {...restProps}
            src={src}
            progress={calculatedProgress}
        />
    );
};

export default memo(CalculateProgress);
