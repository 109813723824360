import { useState, useRef, useEffect } from "react"
import Login from "./login"
import Signup from "./signup"
import ResetPass from "./resetPass"
import { _clearInput } from "@/src/utils/globalValidations"

export const Auth = props => {
    const { current = 1 } = props;
    const [ active, setActive ] = useState(current);

    const modelOverlay = useRef(null);
    const wrapperRef = useRef(null);

    useEffect(() => {
        _clearInput('email');
        _clearInput('password');
        
        const timeOut1 = setTimeout(() => {
            modelOverlay.current.style.cssText = `opacity:1`;
        },0);
        const timeOut2 = setTimeout(() => {
            const model = document.querySelector('.model');
            wrapperRef.current.style.cssText = `transform: translate(0,0)`;
            model.style.cssText = `z-index: 101;`;
        },300);
        return () => {
            clearTimeout(timeOut1);
            clearTimeout(timeOut2);
        }
    },[]);


    const _toggleScreen = (screen = 1) => {
        setActive(screen);
    }
    const screenToShow = active === 1 ? (
    <Login
        toggle={props.toggle}
        toggleScreen={_toggleScreen}
    />)
    : active === 2 ? (
        <Signup
            toggle={props.toggle}
            toggleScreen={_toggleScreen}
        />
    ) : (
        <ResetPass
            toggle={props.toggle}
            toggleScreen={_toggleScreen}
        />
    );

    return (
        <div className='popup-parent'>
            <div className='popup-content-wrapper' ref={wrapperRef}>
                {screenToShow}
            </div>
            <div className="popup-layer overlay" ref={modelOverlay}></div>
        </div>
    );
}