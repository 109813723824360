import React, { memo } from 'react';

const LocationOutlined = ({ className }) => (
    <svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path
            d="M18 10.4C18 12.841 15.1384 16.4891 13.3676 18.5128C12.6347 19.3504 11.3653 19.3504 10.6324
                18.5128C8.86163 16.4891 6 12.841 6 10.4C6 6.86538 8.68629 4 12 4C15.3137 4 18 6.86538 18 10.4Z"
            stroke="#ACABB4"
            strokeWidth="2"
        />
        <circle
            cx="12"
            cy="10"
            r="2"
            stroke="#ACABB4"
            strokeWidth="2"
        />
    </svg>
);

export default memo(LocationOutlined);
