import { useEffect } from 'react';


export const useResizeVHeight = () => {
    useEffect(() => {
        let deviceHeight = 0;
        let inHeight = 0;
        let originalHeight = 0;
        const resHeight = () => {
            const varValue = parseInt(getComputedStyle(document.documentElement).getPropertyValue('--v-height'));
            if(varValue === 100){
                deviceHeight = window.screen.height;
                inHeight = window.innerHeight;
                originalHeight = inHeight;
                document.documentElement.style.setProperty('--v-height', window.innerHeight+'px');
            }
            if(window.innerWidth > 600){
                inHeight = window.innerHeight;
                document.documentElement.style.setProperty('--v-height', window.innerHeight+'px');
            }else{
                if(window.screen.height !== deviceHeight){
                    deviceHeight = window.screen.height;
                    inHeight = window.innerHeight;
                    document.documentElement.style.setProperty('--v-height', window.innerHeight+'px');
                }else{
                    if(window.innerHeight - inHeight > 200){
                        deviceHeight = window.screen.height;
                        inHeight = window.innerHeight;
                        document.documentElement.style.setProperty('--v-height', originalHeight+'px');
                    }
                }
            }
        }
        resHeight();
        window.addEventListener('resize',resHeight);
        return () => {
            window.removeEventListener('resize',resHeight);
        }
    },[])
}

export const useHomeHeroImage = (setImgUrl) => {
    useEffect(() => {
        const checkBackground = () => {
            const heroTitleSpan = document.querySelector('.cn__hp__hero__words__title span');
            let time = new Date().getHours();
            if(time < 6 || time > 18){
                heroTitleSpan.classList.add('cnhphrwrds--night');
                let checkDesk = setImgUrl === 'https://img.cuddlynest.com/images/assets/hero-night.jpg';
                let checkMob = setImgUrl === 'https://img.cuddlynest.com/images/assets/homepage/hero-night-mob-new.jpg';
                if(window.innerWidth > 600){
                    if(!checkDesk) setImgUrl('https://img.cuddlynest.com/images/assets/hero-night.jpg');
                }else{
                    if(!checkMob) setImgUrl('https://img.cuddlynest.com/images/assets/homepage/hero-night-mob-new.jpg');
                }
            }else{
                heroTitleSpan.classList.add('cnhphrwrds--day');
                let checkDesk = setImgUrl === 'https://img.cuddlynest.com/images/assets/homepage/Full_Day_desktop.png';
                let checkMob = setImgUrl === 'https://img.cuddlynest.com/images/assets/homepage/hero-day-mob-new.jpg';
                if(window.innerWidth > 600){
                    if(!checkDesk) setImgUrl('https://img.cuddlynest.com/images/assets/homepage/Full_Day_desktop.png');
                }else{
                    if(!checkMob) setImgUrl('https://img.cuddlynest.com/images/assets/homepage/hero-day-mob-new.jpg');
                }
            }
            // Motherfucking Christmas 2023 - 2024,
            // remove shit below uncomment above one this squirt will over ;)
            {/* Enable on christmas */}
            /*if(time < 6 || time > 18){
                heroTitleSpan.classList.add('cnhphrwrds--night');
                let checkDesk = setImgUrl === 'https://img.cuddlynest.com/images/assets/homepage/christmas/christmas-hero-image-night.jpg';
                let checkMob = setImgUrl === 'https://img.cuddlynest.com/images/assets/homepage/christmas/christmas-hero-image-night-mobile.jpg';
                if(window.innerWidth > 600){
                    if(!checkDesk) setImgUrl('https://img.cuddlynest.com/images/assets/homepage/christmas/christmas-hero-image-night.jpg');
                }else{
                    if(!checkMob) setImgUrl('https://img.cuddlynest.com/images/assets/homepage/christmas/christmas-hero-image-night-mobile.jpg');
                }
            }else{
                heroTitleSpan.classList.add('cnhphrwrds--day');
                let checkDesk = setImgUrl === 'https://img.cuddlynest.com/images/assets/homepage/christmas/christmas-hero-image.jpg';
                let checkMob = setImgUrl === 'https://img.cuddlynest.com/images/assets/homepage/christmas/christmas-hero-image-mobile.jpg';
                if(window.innerWidth > 600){
                    if(!checkDesk) setImgUrl('https://img.cuddlynest.com/images/assets/homepage/christmas/christmas-hero-image.jpg');
                }else{
                    if(!checkMob) setImgUrl('https://img.cuddlynest.com/images/assets/homepage/christmas/christmas-hero-image-mobile.jpg');
                }
            }*/
            // end Motherfucking Christmas 2023 - 2024
        }
        checkBackground();
        window.addEventListener('resize',checkBackground);
        return () => {
            window.removeEventListener('resize',checkBackground);
        }
    },[]);
}