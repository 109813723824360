import { useState, useEffect } from "react";
import { currentPositionPromise } from "../currentPosition";

export default function useCurrentPosition() {
    const [locationResult, setLocationResult] = useState(null);

    useEffect(() => {
        let isMounted = true;

        currentPositionPromise
            .then(locationResponse =>
                isMounted &&
                    setLocationResult(locationResponse.data?.result)
            );

        return () => {
            isMounted = false;
        };
    }, []);

    return locationResult;
};
