import HomePage, { SettingsProvider } from '@/containers/newHomePage';

//API request functions
import { getDealsAllTheWay, getMetaInfo, getPropertytypes, getSeoLinks } from '@/redux/services/global';

//Import components
import CookieBanner from '@/components/common/CookieBanner/CookieBanner';
import SeoMetaData from '@/components/common/seoMetaData';
import { useEffect } from 'react';
import Cookies from 'js-cookie';
import PasswordResetWrapper from '@/components/Authentication/PasswordReset/PasswordResetWrapper';

const HomePageWrapper = props => {
  const { seoInfo = {} } = props;



  useEffect(()=>{
    const params = (new URL(document.location)).searchParams;
    if(params.get('affiliate') && !Cookies.get('affiliate')) {
      Cookies.set('affiliate', params.get('affiliate'));
    }
  },[])
  return (
    <SettingsProvider>
      <SeoMetaData
        meta_data={seoInfo.meta_data}
        schema_breadcrumb={''}
        schema_context={''}
      />
      {Boolean(seoInfo.schema_data) && (
        <span dangerouslySetInnerHTML={{ __html: seoInfo.schema_data }} />
      )}
      <HomePage
        data={props}
      />
      <CookieBanner />
      {/* <UserLocationPopup /> */}
      <PasswordResetWrapper />
      {/* Start of cuddlynest Fresh Chat Widget script */}
      {/* https://cuddlynest.atlassian.net/browse/FE-2784 */}
      {/* {(process.env.CN_ENVIRONMENT !== 'production') && (
        // <script
        //   id="ze-snippet"
        //   async
        //   src="https://static.zdassets.com/ekr/snippet.js?key=b5326824-0d04-49fa-9b71-0dc19f095ace"
        // />
        <script
          dangerouslySetInnerHTML={{
            __html: `
            (function(){function initFreshChat() {
              if (typeof window === 'undefined') {
                return;
              }
              window.fcWidget.init({
                  token: "d67ed06d-3606-4ae0-a578-5649e9f0156b",
                  host: "https://cuddlynest.freshchat.com"
              });
            };
            function initialize(i,t){var e;i.getElementById(t)?
            initFreshChat():((e=i.createElement("script")).id=t,e.async=!0,
            e.src="https://cuddlynest.freshchat.com/js/widget.js",e.onload=initFreshChat,i.head.appendChild(e))}
            function initiateCall(){initialize(document,"Freshchat-js-sdk")}
            window.addEventListener?window.addEventListener("load",initiateCall,!1):
            window.attachEvent("load",initiateCall,!1);}());`
          }}
        />
      )} */}
      {/* End of cuddlynest Fresh Chat Widget script */}
    </SettingsProvider>
  );
};

export default HomePageWrapper;


// =============== API ===============

export async function getStaticProps() {
  try {
    //Call all homepage API's
    const [
      dealsAllTheWay,
      seoLinks,
      seoMetaInfo,
      propertyTypes
    ] = await Promise.all([
      { service: getDealsAllTheWay },
      { service: getSeoLinks },
      { service: getMetaInfo },
      { service: getPropertytypes }
    ].map(({ service, params = [] }) => service(...params).catch(() => ({})))
    );

    return {
      props: {
        deals: dealsAllTheWay.data,
        seo: seoLinks.data,
        seoInfo: seoMetaInfo.data,
        propertyTypes: propertyTypes.data
      }
    };

  } catch (err) {

    return { props: {} };

  }
}
