import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
    avatar: (props) => ({
        width: props.width,
        height: props.height
    }),
    progress: {
        position: 'absolute',
        color: 'var(--color-vibrant-pink)',
        border: '1px solid var(--color-light-grey)',
        borderRadius: '40px',
        padding:'1px'
    }
}));
