import React, { memo } from 'react';

const Dates = ({ className }) => (

    <svg className={className} width="16" height="17" viewBox="0 0 16 17">
        <path d="M5.81098 8.84714C5.81098 8.64929 5.65051 8.48901 5.4525 8.48901H4.20155C4.00377 8.48901 3.84326 8.64929 3.84326 8.84714V10.0979C3.84326 10.296 4.00377 10.4564 4.20155 10.4564H5.4525C5.65051 10.4564 5.81098 10.296 5.81098 10.0979V8.84714Z" fill="#64748B"/>
        <path d="M8.93725 8.84714C8.93725 8.64929 8.77674 8.48901 8.57913 8.48901H7.32801C7.13023 8.48901 6.96973 8.64929 6.96973 8.84714V10.0979C6.96973 10.296 7.13023 10.4564 7.32801 10.4564H8.57913C8.77674 10.4564 8.93725 10.296 8.93725 10.0979V8.84714Z" fill="#64748B"/>
        <path d="M12.0636 8.84714C12.0636 8.64929 11.9031 8.48901 11.7054 8.48901H10.4544C10.2564 8.48901 10.0959 8.64929 10.0959 8.84714V10.0979C10.0959 10.296 10.2564 10.4564 10.4544 10.4564H11.7054C11.9031 10.4564 12.0636 10.296 12.0636 10.0979V8.84714Z" fill="#64748B"/>
        <path d="M5.81098 11.9726C5.81098 11.7744 5.65051 11.6143 5.4525 11.6143H4.20155C4.00377 11.6143 3.84326 11.7744 3.84326 11.9726V13.2231C3.84326 13.4211 4.00377 13.5814 4.20155 13.5814H5.4525C5.65051 13.5814 5.81098 13.4211 5.81098 13.2231V11.9726Z" fill="#64748B"/>
        <path d="M8.93725 11.9726C8.93725 11.7744 8.77674 11.6143 8.57913 11.6143H7.32801C7.13023 11.6143 6.96973 11.7744 6.96973 11.9726V13.2231C6.96973 13.4211 7.13023 13.5814 7.32801 13.5814H8.57913C8.77674 13.5814 8.93725 13.4211 8.93725 13.2231V11.9726Z" fill="#64748B"/>
        <path d="M12.0636 11.9726C12.0636 11.7744 11.9031 11.6143 11.7055 11.6143H10.4544C10.2564 11.6143 10.0959 11.7744 10.0959 11.9726V13.2231C10.0959 13.4211 10.2564 13.5814 10.4544 13.5814H11.7055C11.9031 13.5814 12.0636 13.4211 12.0636 13.2231V11.9726Z" fill="#64748B"/>
        <path d="M14.1254 2.11453V4.02501C14.1254 4.88853 13.4249 5.58439 12.5615 5.58439H11.5749C10.7115 5.58439 10.0017 4.88853 10.0017 4.02501V2.10767H5.55498V4.02501C5.55498 4.88853 4.84521 5.58439 3.98188 5.58439H2.99513C2.13177 5.58439 1.43128 4.88853 1.43128 4.02501V2.11453C0.676698 2.13727 0.0566406 2.76166 0.0566406 3.52913V14.7255C0.0566406 15.5074 0.690384 16.1495 1.47227 16.1495H14.0844C14.8651 16.1495 15.5 15.506 15.5 14.7255V3.52913C15.5 2.76166 14.88 2.13727 14.1254 2.11453ZM13.6672 14.0283C13.6672 14.3662 13.3932 14.6403 13.0552 14.6403H2.47453C2.13649 14.6403 1.86252 14.3662 1.86252 14.0283V8.24493C1.86252 7.9069 2.13646 7.63277 2.47453 7.63277H13.0551C13.3932 7.63277 13.6671 7.9069 13.6671 8.24493L13.6672 14.0283Z" fill="#64748B"/>
        <path d="M3.16673 4.33774H4.14258C4.43878 4.33774 4.67894 4.09793 4.67894 3.80173V0.669741C4.67894 0.373512 4.43878 0.133545 4.14258 0.133545H3.16673C2.8705 0.133545 2.63037 0.373512 2.63037 0.669741V3.80173C2.63037 4.09793 2.8705 4.33774 3.16673 4.33774Z" fill="#64748B"/>
        <path d="M11.7375 4.33774H12.7133C13.0093 4.33774 13.2495 4.09793 13.2495 3.80173V0.669741C13.2495 0.373512 13.0094 0.133545 12.7133 0.133545H11.7375C11.4413 0.133545 11.2012 0.373512 11.2012 0.669741V3.80173C11.2012 4.09793 11.4413 4.33774 11.7375 4.33774Z" fill="#64748B"/>
    </svg>
);

export default memo(Dates);