import Conditional from "@/components/Conditional";
import PriceValue from "./PriceValue";

const OriginalPrice = ({ originalPriceUSD, priceUSD, currency }) => (
    <Conditional
        when={originalPriceUSD?.amount > priceUSD?.amount}
        then={(
            <PriceValue
                sx={{
                    textDecoration: 'line-through',
                    color: 'var(--color-vibrant-pink)',
                    fontWeight: 500,
                    fontSize: 16
                }}
                value={originalPriceUSD.amount}
                currency={currency}
            />
        )}
    />
);

export default OriginalPrice;
