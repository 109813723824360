import { memo } from "react";
import CircularProgress from "@mui/material/CircularProgress";


function GradientCircularProgress(props) {
    return (
        <>
            <svg width={0} height={0}>
                <defs>
                    <linearGradient id="cn_gradient" x1="0%" y1="0%" x2="0%" y2="100%">
                        <stop offset="0%" stopColor="#F35597" />
                        <stop offset="100%" stopColor="#7868C7" />
                    </linearGradient>
                </defs>
            </svg>
            <CircularProgress
                thickness={6}
                size={'32px'}
                sx={{
                    'svg circle': {
                        stroke: 'url(#cn_gradient)',
                        strokeLinecap: 'round',
                    },
                }}
                {...props}
            />
        </>
    );
}

export default memo(GradientCircularProgress);
