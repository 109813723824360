import { useCallback, useEffect, useMemo, useState } from "react";

export default function useAmenityIcons(properties) {
    const [amenityIcons, setAmenityIconsState] = useState(null);

    useEffect(() => {
        import('@/components/common/PropertyCard/Amenities/amenityIcons')
            .then(amenityIconsModule => {
                setAmenityIconsState(amenityIconsModule.default);
            })
            .catch(console.error);
    }, []);

    const getIconComponents = useCallback(function fn(subject) {
        if (!amenityIcons) {
            return [];
        }

        if (Array.isArray(subject)) {
            return subject.reduce((acc, amenity = {}) => [
                ...acc,
                {
                    ...amenity,
                    iconComponent: amenityIcons[amenity.name]?.icon,
                    iconName: amenityIcons[amenity.name]?.iconName
                }
            ], [])
            .filter(({ iconComponent }) => Boolean(iconComponent));
        }

        if (subject instanceof Object) {
            return Object.entries(subject).reduce((accCategories, [categoryName, categoryAmenities = []]) => ({
                ...accCategories,
                [categoryName]: fn(categoryAmenities)
            }), {});
        }

        return [];
    }, [amenityIcons]);

    return useMemo(() =>
        properties.map(({ featured_amenities, tiles_icons,...restProperies }) => {
            return {
                ...restProperies,
                amenities: getIconComponents(featured_amenities),
                facilities: getIconComponents(tiles_icons)
            };
        })
    , [properties, getIconComponents]);
}