import { Box } from "@mui/material";
import { memo } from "react";
import AgeInput from "./AgeInput";
import useGuestPicker from "./useGuestPicker";

const AgeSelector = ({ className }) => {
    const isActive = Boolean(className);

    const { state, onChildAgeChange } = useGuestPicker();

    return (
        <Box
            sx={{
                mt: (state.childCount && 2),
                display: 'grid',
                gridTemplateColumns: `repeat(${isActive ? 2 : 1}, 1fr)`,
                gridColumn: '1/span 3',
                gap: 1
            }}
        >
            {Array.from({ length: state.childCount })
                .map((_, index) => (
                    <AgeInput
                        key={String(index)}
                        index={index}
                        value={state.getChildAge(index)}
                        onChange={onChildAgeChange}
                    />
                ))}
        </Box>
    );
};

export default memo(AgeSelector);
