import { useRouter } from "next/router";
import PasswordReset from ".";

const PasswordResetWrapper = () => {
    const router = useRouter();
    let tokenParam = null;
    const path = router.asPath;
    const resetSuccess = (router.query['reset-password-success'] == 1);

    if (path?.includes('/?reset-password-token')) {
        tokenParam = path.split('=').pop();
    }

    if (!tokenParam && !resetSuccess) {
        return null;
    }

    return (
        <>
            {(tokenParam || resetSuccess) && <PasswordReset tokenParam={tokenParam} />}
        </>
    );
};

export default PasswordResetWrapper;
