import Button from "@material-ui/core/Button";
import clsx from "clsx";
import { memo } from "react";
import { useSubmitStyles } from './useStyles';

const Submit = ({ className, ...restProps }) => {
    const defaultClasses = useSubmitStyles();

    return (
        <Button
            {...restProps}
            className={clsx(defaultClasses.root, className)}
        />
    );
};

export default memo(Submit);
