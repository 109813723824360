import useLoginPrompt from '@/components/header/utils/useLoginPrompt';
import { TickMarkSVG } from "../AuthSVG";
import styles from './PasswordReset.module.scss';

const PasswordResetSuccess = ({ onClose }) => {
    const showLogin = useLoginPrompt();

    return (
        <>
            <div className={styles.card_title}>Reset Password</div>
            <div className={styles.content}>
                <div className={styles.tickmark}><TickMarkSVG /></div>
                <div className={styles.text}>{'You have successfully changed your password.'}</div>
                <div className={styles.link}>
                    Go to <a onClick={() => {
                        onClose();
                        showLogin[1]();
                    }}>sign in.</a>
                </div>
            </div>
        </>
    );
};

export default PasswordResetSuccess;
