import { Fragment, useState, useEffect, useCallback } from "react"
import { Modal } from "../common/modal";
import { ModalContainer } from "../common/modal/modalCotainer";
import { ModalBody } from "../common/modal/modalBody";
import { Button } from "../formComponents/button";
import { Input } from "../formComponents/input";
import { _validateEmail, _validatePassword, _setFocus } from "@/src/utils/globalValidations";
import { inValidEmail, emptyEmail, passwordCheck, invalidLoginDetails } from "@/static/errorMessages";
import { InputGroup } from "../formComponents/inputGroup";
import { FormHelpertext } from "../formComponents/formHelperText";
import { login } from "@/redux/services/authApi";
import { successNotificationActions, errorNotificationActions } from "@/redux/actions/globalActions";
import { connect } from "react-redux";
import { setupLogin } from "@/src/utils/authFunctions";
import { loginAction } from "@/redux/actions/authActions";
import {
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    GOOGLE_SIGNUP_SIGNIN_FAIL,
    FB_SIGNUP_SIGNIN_FAIL,
    GOOGLE_SIGNUP_SIGNIN_SUCCESS,
    FB_SIGNUP_SIGNIN_SUCCESS
} from "@/redux/types";
import FbSignupLogin from "./fbSignupLogin";
import GoogleSignupLogin from "./googleSignupLogin";
import { scroll_none } from "@/src/utils/dynamicClasses";
import { add_remove_class_to_body } from "@/src/utils/globalFunc";
import { useRouter } from "next/router";
import { config } from "@/src/utils/config";

const Login = props => {
    const [ email, setEmail ] = useState();
    const [ password, setPassword ] = useState();
    const [isRememberMe, setRememberMe] = useState(false);
    const [ errEmail, setErrEmail ] = useState(false);
    const [ errPass, setErrPass ] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const router = useRouter();
    const _checkFormValidity = () => {
        let isValid = true;
        if (!!email) {
            if (_validateEmail(email)) {
                setErrEmail();
            }else{
                _setFocus('email');
                isValid = false;
                setErrEmail(inValidEmail);
            }
        }else{
            _setFocus('email');
            isValid = false;
            setErrEmail(emptyEmail);
        }
        if (_validatePassword(password)) {
            setErrPass();
        }else{
            _setFocus('password');
            isValid = false;
            setErrPass(passwordCheck);
        }
        return isValid;
    }
    const _formSubmit = async e => {
        e.preventDefault();
        if (_checkFormValidity()) {
            setIsLoading(true);
            try {
                props.loginAction({
                    email: email,
                    password: password,
                    remember: Number(isRememberMe)
                });
            } catch (error) {
                setIsLoading(false);

                props.errorNotificationActions('Error', error.message)
            }
        }else{
            return false;
        }
    }

    useEffect(() => {
       
        if ([LOGIN_SUCCESS, GOOGLE_SIGNUP_SIGNIN_SUCCESS, FB_SIGNUP_SIGNIN_SUCCESS].includes(props.auth?.type)) {
            props.toggle();
            setIsLoading(false);
            if(router?.asPath?.includes("redirect_url")) {
                const queryRedirect = router?.asPath?.replace('?redirect_url=', '');
                window.location.href = `${config.current_url}${queryRedirect}`
            }
        }

        if([LOGIN_FAIL, GOOGLE_SIGNUP_SIGNIN_FAIL, FB_SIGNUP_SIGNIN_FAIL].includes(props.auth?.type))
        {
            if(props.auth.message.includes('email')){
                setErrEmail(props.auth.message.replace('email-',''));
                _setFocus('email');
            }else if(props.auth.message.includes('password')){
                setErrPass(props.auth.message.replace('password-',''));
                _setFocus('password');
            }
            setIsLoading(false);

        }
    }, [props.auth]);


    useEffect(()=>{
        if(errEmail)
        {
            setTimeout(()=>{
                setErrEmail(false)
            },4000)
        }

        if(errPass)
        {
            setTimeout(()=>{
                setErrPass(false)
            },4000)
        }

    },[errEmail, errPass]);


    const enterPressHandler = (e) => {
        let key = e.keyCode || e.which;
        if(key===13)
        {
            _formSubmit(e)
        }
    }

    const rememberMeHandler = useCallback(() => {
        setRememberMe(state => !state);
    }, []);

    return (
        <Modal className="login-popup" toggle={props.toggle}>
            <ModalContainer>
                <ModalBody>
                    <Fragment>
                        <div className="login-heading">Login</div>
                        <FbSignupLogin />
                        <GoogleSignupLogin />
                        <div className="middle-border-text">
                            <span>or, sign in with my email</span>
                        </div>
                        <InputGroup>
                            <Input
                                type="email"
                                className="primary-input"
                                value={email}
                                id="email"
                                onChange={e => setEmail(e.target.value)}
                                placeholder="Email address"
                                onKeyUp={enterPressHandler}
                            />
                            {
                                !!errEmail && (
                                    <FormHelpertext className="err">
                                        { errEmail }
                                    </FormHelpertext>
                                )
                            }
                        </InputGroup>
                        <InputGroup>
                            <Input
                                type="password"
                                className="primary-input"
                                value={password}
                                id="password"
                                onChange={e => setPassword(e.target.value)}
                                placeholder="Password"
                                onKeyUp={enterPressHandler}
                            />
                            {
                                !!errPass && (
                                        <FormHelpertext className="err">
                                            { errPass }
                                        </FormHelpertext>
                                )
                            }
                            {/*
                             * Commenting this section because we are showing the error messages in the useeffect area
                             * and we are now showing separate messages for email and password as veremchuk suggested in this task, CUD-1267 - sarmad
                            <FormHelpertext className="err">{props.auth && props.auth.type && props.auth.type.includes("FAIL") && props.auth.message}</FormHelpertext> */}
                        </InputGroup>
                        <div className="remember-checkbox">
                            <label className="cn_slct_box">
                                <input type="checkbox" checked={isRememberMe} onChange={rememberMeHandler} />
                                <span className="label">Remember me</span>
                                <span className="cn_checkmark"></span>
                            </label>
                        </div>
                        <Button type="button" className="btn-primary" onClick={_formSubmit} status={isLoading}>
                            Sign in
                        </Button>
                        <div className="forgot-password">
                            <a
                                className="model-ref"
                                href="javascript:void(0)"
                                onClick={e => props.toggleScreen(3)}
                                rel="#forgot-popup">
                                Forgot your password?
                            </a>
                        </div>
                        <div className="register-account">
                            Don’t have an account? <a
                                className="model-ref"
                                href="javascript:void(0)"
                                onClick={e => props.toggleScreen(2)}
                                rel="#register-popup">Register</a>
                        </div>
                        <div className="popup-mobile-back close" onClick={props.toggle}>
                            <img src="/static/img/left-arrow-black.svg" alt="left arrow" />
                            Back
                        </div>
                    </Fragment>
                </ModalBody>
            </ModalContainer>
        </Modal>
    )
}

const mapStateToProps = ({ authReducer }) => {
    return {
        auth: authReducer
    }
}
export default connect(mapStateToProps, { loginAction, successNotificationActions, errorNotificationActions })(Login)