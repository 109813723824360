import React, {memo, useEffect, useRef, useState} from 'react';

//Libraries
import { useRouter } from "next/router";
import LogoBirdsText from '@/components/AppHeader/Header/LogoBirdsText';
import clsx from 'clsx';

const CookieBanner = ({ className }) => {

        //Refs and Variables
        const router = useRouter();
        const cookieBanner = useRef(null);
        const consentPropertyName = 'cuddlynest_allconsent';
        const [_document, set_document] = useState(null); // set document state for next.js
        const [checkCookieStorage, setCheckCookieStorage] = useState(null); // Check if the cookie exist in order to render the component
        let cookieStorage, shouldShowBanner, saveToStorage, handleClick; // all methods declared prior setting the document
        let d = new Date();
        d.setTime(d.getTime() + (365*24*60*60*1000));
        const expires = "expires="+ d.toUTCString();

        //Methods
        if(_document !== null){

            // set cookies get and set
            cookieStorage = {
                getItem:(key) => {
                    const cookies = _document.cookie
                        .split(';')
                        .map(cookie => cookie.split('='))
                        .reduce((acc,[key,value]) => ({...acc, [key.trim()]:value}),{});
                    return cookies[key];
                },
                setItem:(key,value) => {
                    _document.cookie = `${key}=${value};path=/;${expires};secure=true`;
                }
            };

            // save the storageType
            const storageType = cookieStorage;

            // check if cookie exist
            shouldShowBanner = () => !storageType.getItem(consentPropertyName);

            // save the cookie function
            saveToStorage = (action) => storageType.setItem(consentPropertyName,action);

            // save the cookie when user clicks on sounds good or decline
            handleClick = (action) => {
                cookieBanner.current.classList.remove('cn__cookie-show');
                setTimeout(() => {
                    setCheckCookieStorage(false);
                },1000);
                saveToStorage(action);
            };
        }


        //Hooks

        // set the document
        useEffect(() => {
            set_document(document);
        },[]);

        // set the cookiestorage state
        useEffect(() => {
            if(_document !== null){
                setCheckCookieStorage(shouldShowBanner());
            }else{
                setCheckCookieStorage(false);
            }
        },[_document]);

        // check if the cookie exist or not
        useEffect(() => {
            let timeOut;
            let scrollEvent = () =>{
                if(checkCookieStorage){
                    if(window.innerWidth > 1136 && router.asPath === '/'){
                        if(cookieBanner.current) {
                            let cookie = cookieBanner.current.querySelector('.cn__cookie');
                            if(cookie) {
                                if ((window.innerHeight + window.pageYOffset) == window.innerHeight) {
                                    cookieBanner.current.style.cssText = 'bottom: 70px';
                                    cookie.style.cssText = 'box-shadow: unset; outline: unset';
                                }else{
                                    cookieBanner.current.style.cssText = 'bottom: 16px';
                                    cookie.style.cssText = 'box-shadow: 0px 6px 14px -6px rgba(24, 39, 75, 0.12); outline: 1px solid #DFE2E5;';
                                }
                            }
                        }
                    }
                }
            };
            if(checkCookieStorage){
                timeOut = setTimeout(() => {
                    cookieBanner.current.classList.add('cn__cookie-show');
                },500);
                document.addEventListener('scroll',scrollEvent);
            }
            return () => {
                clearTimeout(timeOut);
                document.removeEventListener('scroll',scrollEvent);
            }
        },[checkCookieStorage]);

        //Render
        const renderComponent = checkCookieStorage && (
            <div className={clsx('cn__cookie-wrapper hide', className)} ref={cookieBanner}>
                <div className='cn__cookie'>
                    <div className='cn__cookie__header'>
                        <LogoBirdsText />
                    </div>
                    <div>
                        <p className='cn__cookie--title'>Let’s Talk Cookies…</p>
                        <p className='cn__cookie--description'>
                            We use cookies and similar technologies to drastically improve your user experience through more personalised content and tailored ads.
                        </p>
                        <p className='cn__cookie--description--two'>
                            By clicking ‘Accept’, you’re agreeing to both this usage as well as our <a href='/privacy-policy' target="_blank">Privacy Policy.</a>  
                        </p>
                    </div>
                    <div className='cn__cookie__button-wrapper'>
                        <div onClick={() => handleClick(false)} className='cn__cookie--more'>
                            Decline
                        </div>
                        <div onClick={() => handleClick(true)} className='cn__cookie--accept'>Accept</div>
                    </div>
                </div>
            </div>
        );

        return renderComponent;
}
export default memo(CookieBanner);