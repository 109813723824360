import AppleSignin from 'react-apple-signin-auth';
import { useDispatch } from 'react-redux';
import { AppleSignupSigninAction } from '@/redux/actions/authActions';
import { errorNotificationActions } from '@/redux/actions/globalActions';
import { config } from '@/src/utils/config';
import styles from './Authentication.module.scss';
import { AppleSVG } from './AuthSVG';


export default function AppleAuth() {
    const dispatch = useDispatch();

    const successHandler = (response) => {
        const { user, authorization: { id_token } } = response;
        console.log('response => ', response);
        if (id_token) {
            dispatch(AppleSignupSigninAction({ token: id_token, user }));
        }
    };

    const errorHandler = (error) => {
        console.log('error => ', error);
        console.log(error);
    };

    return (
        <AppleSignin
            authOptions={{
                clientId: 'com.cuddlynest.authservice',
                scope: 'email name',
                // redirectURI: 'https://feature.cuddlynest.com/apple-login-redirect',
                redirectURI: `${config.current_url}/apple-login-redirect`,
                state: '',
                usePopup: true,
            }}
            onSuccess={successHandler}
            onError={errorHandler}
            // iconProp={{ style: { marginTop: '10px' } }}
            render={(renderProps) => (
                <div
                    id="apple"
                    onClick={renderProps.onClick}
                    className={styles.auth_login_options_item}
                >
                    <div className={styles.auth_login_options_item_inner}>
                        <AppleSVG />
                        <span className={styles.auth_login_options_item__text}>Apple</span>
                    </div>
                </div>
            )}
        />
    );
};
