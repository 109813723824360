
const MenuSymb = () => {
    return (
        <svg width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 8H21" stroke="black" strokeWidth="2" strokeLinecap="round" />
            <path d="M1 15H21" stroke="black" strokeWidth="2" strokeLinecap="round" />
            <path d="M1 1H21" stroke="black" strokeWidth="2" strokeLinecap="round" />
        </svg>
    );
};

export default MenuSymb;
