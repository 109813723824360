import React, { memo, useEffect } from 'react';
import clsx from 'clsx';
import InputGroupArea from './InputGroupArea';
import ActionArea from './ActionArea';
import analyticsLabelProvider from '@/static/analyticsLabelProvider';
import { EAType } from '@/static/contants';
import InterestsTrigger from './Interests/InterestsTrigger';
import UnavailabilityInterestsPopover from './Interests/UnavailabilityInterestsPopover';
import SelectionMismatchInterestsPopover from './Interests/SelectionMismatchInterestsPopover';

const Desktop = ({
    classNames,
    destination,
    dates,
    guests,
    // interests,
    step,
    // withRecentSearches,
    // attractionsNearbyData,
    // settings
}) => {
    const [stepState, setStepState] = step;

    useEffect(() => {
        setStepState();

        const handleClose = ({ target }) => {
            if (!classNames.some(className => Boolean(target.closest(`.${className}`)))) {
                setStepState();
            }
        };

        window.addEventListener('mousedown', handleClose);
        return () => {
            window.removeEventListener('mousedown', handleClose);
        };
    }, [setStepState, classNames]);

    return (
        <>
            <div
                className={clsx(
                    'omnisearch_content',
                    classNames,
                    stepState && `omnisearch_content_step-${stepState.toLowerCase()}`
                )}
                // gtm analytics
                data-cn-ga-click={JSON.stringify({
                    el: analyticsLabelProvider.omnisearch[EAType.CLICK]()
                })}
            >
                <InputGroupArea
                    destination={destination}
                    dates={dates}
                    guests={guests}
                    step={step}
                />
                <ActionArea
                    destination={destination}
                    dates={dates}
                    guests={guests}
                    step={step}
                // interests={interests}
                // withRecentSearches={withRecentSearches}
                // attractionsNearbyData={attractionsNearbyData}
                />
                <InterestsTrigger />
            </div>
            <UnavailabilityInterestsPopover />
            <SelectionMismatchInterestsPopover />
        </>
    );
};

Desktop.defaultProps = {
    classNames: ['omnisearch_content--desktop', 'js-portal-popover']
};

export default memo(Desktop);
