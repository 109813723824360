import { useRef, useEffect } from "react";
import { useOutsideClick } from "@/src/utils/customHooks/clickOutSide";
import {add_remove_class_to_body} from "@/utils/globalFunc"
import { scroll_none } from "@/utils/dynamicClasses"

export const Modal = props => {

    const wrapperRef = useRef(null);
    const isOutside = useOutsideClick(wrapperRef);
    if(!!isOutside && props?.toggle && typeof props?.toggle==='function'){ // if toggle is not passed then means we don't want to close popup on outside click

        props.toggle();
        // Here we remove the scroll_none class when modal get closed
        add_remove_class_to_body(scroll_none,true);
    }
    else // Here we add the scroll_none class when modal opens
        add_remove_class_to_body(scroll_none);

    useEffect(() => () =>{
        add_remove_class_to_body(scroll_none, true);
    }, []);

    return (
        <div className={`model ${props.className}`} id="popup">
            <div className="model-inner" ref={wrapperRef}>
                {props.children}
            </div>
        </div>
    )
}