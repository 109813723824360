import { useEffect, useState } from "react";
import { isMobile, isTablet, isDesktop } from "@/utils/commonScripts/commonMethods";
import { ObjectShallowEqualityCheck } from "../globalFunc";
import debounce from "lodash.debounce";


export default function useMyDeviceDetect() {
    const [devices, setDevices] = useState({
        isMobile: isMobile(),
        isTablet: isTablet(),
        isDesktop: isDesktop()
    });
    const resizeHandler = debounce(() => {
        const updDevices = {
            isMobile: isMobile(),
            isTablet: isTablet(),
            isDesktop: isDesktop()
        };
        if (!ObjectShallowEqualityCheck(updDevices, devices)) {
            setDevices(updDevices);
        }
    }, 200, { trailing: true });
    useEffect(() => {
        window.addEventListener('resize', resizeHandler);
        return () => {
            window.removeEventListener('resize', resizeHandler);
        };
    }, []);

    return devices;
};