import React, { memo } from 'react';

const Dates = ({ className }) => (
    <svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path
            d="M6 9C6 7.34315 7.34315 6 9 6H15C16.6569 6 18 7.34315 18 9V15C18 16.6569 16.6569 18 15
                18H9C7.34315 18 6 16.6569 6 15V9Z"
            stroke="#ACABB4"
            strokeWidth="2"
        />
        <path d="M6 10H18" stroke="#ACABB4" strokeWidth="2" />
        <rect x="8" y="3" width="2" height="3" rx="1" fill="#ACABB4" />
        <rect x="14" y="3" width="2" height="3" rx="1" fill="#ACABB4" />
    </svg>
);

export default memo(Dates);