import clsx from 'clsx';
import React, { memo } from 'react';

const LabelledInputWrapper = ({ className, label, children }) => (
    <div test-id='Dates' className={clsx('omnisearch_content_input-area_labelled-input-wrapper', className)}>
        {/* {label && <div className="omnisearch_content_input-area_labelled-input-wrapper_label">{label}</div>} */}
        {children}
    </div>
);

export default memo(LabelledInputWrapper);
