import { memo } from "react";
import Box from '@mui/material/Box';
import InfoPopover from "../InfoPopover";
import clsx from "clsx";

const InterestsPopover = ({
    anchorElementRef,
    className,
    offset,
    isOpen,
    icon,
    triangleStyles,
    children
}) => {
    const isOpenPolicy = () =>
        Boolean(anchorElementRef.current) &&
            typeof isOpen === 'function'
            ? isOpen()
            : isOpen;


    return isOpenPolicy() && (
        <InfoPopover
            className={clsx('omnisearch_activities-popover', className)}
            anchorElementRef={anchorElementRef}
            offset={offset}
        >
            <Box
                sx={{
                    borderRadius: '20px',
                    color: 'var(--color-white)',
                    border: '2px solid var(--color-pastel-red)',
                    display: 'grid',
                    gridTemplateColumns: 'repeat(2, max-content)',
                    backgroundColor: 'var(--color-pastel-red)'
                }}
            >
                <Box
                    sx={{
                        backgroundColor: 'inherit',
                        borderTopLeftRadius: 'inherit',
                        borderBottomLeftRadius: 'inherit',
                        width: '45px',
                        height: '40px',
                        display: 'grid',
                        placeContent: 'center',
                        position: 'relative',
                        '& svg path': {
                            fill: 'var(--color-white)'
                        },
                        ':after': {
                            content: '""',
                            position: 'absolute',
                            left: '50%',
                            transform: 'translateX(-50%)',
                            display: 'block',
                            width: 0,
                            height: 0,
                            borderStyle: 'solid',
                            ...triangleStyles
                        }
                    }}
                >
                    {icon}
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        color: 'var(--color-pastel-red)',
                        px: '15px',
                        fontFamily: 'var(--primary-font)',
                        fontWeight: 500,
                        fontSize: '10px',
                        lineHeight: '150%',
                        letterSpacing: '.01em',
                        backgroundColor: 'var(--color-white)',
                        borderTopRightRadius: 'inherit',
                        borderBottomRightRadius: 'inherit'
                    }}
                >
                    {children}
                </Box>
            </Box>
        </InfoPopover>
    );
};

InterestsPopover.defaultProps = {
    triangleStyles: {
        top: '100%',
        borderWidth: '13px 10px 0 10px',
        borderColor: 'var(--color-pastel-red) transparent transparent transparent'
    }
};

export default memo(InterestsPopover);
