import { useCallback } from "react";
import { useStore } from "react-redux";
import { PDFilterSchema } from "utils/CustomHooks/usePDCommonState";
import { getItem, removeItem, setItem } from "../localStorageService";

export default function useCompleteBooking(localStorageKey = 'completeBooking') {
    const store = useStore();

    const setData = useCallback(filter => {
        const {
            product_images: productImages,
            property_type: propertyType,
            product_title: propertyTitle,
            product_id: productId
        } = store.getState().product;

        setItem(localStorageKey, new CompleteBookingEntity({
            ...filter,
            productImage: productImages[0],
            propertyType,
            propertyTitle,
            productId,
            productUrl: location.pathname
        }));
    }, [
        store,
        localStorageKey
    ]);

    const getData = useCallback((key = localStorageKey) => getItem(key), [localStorageKey]);

    const removeData = useCallback((key = localStorageKey) => removeItem(key), [localStorageKey]);

    return {
        setData,
        getData,
        removeData
    };
}

class CompleteBookingEntity {
    lastViewedAt = Date.now();

    constructor({
        checkin,
        checkout,
        adults,
        children,
        infants,
        rooms,
        productImage,
        propertyType,
        propertyTitle,
        productId,
        productUrl
    }) {
        this[PDFilterSchema.checkin] = checkin;
        this[PDFilterSchema.checkout] = checkout;
        this[PDFilterSchema.adults] = adults;
        this[PDFilterSchema.children]= children;
        this[PDFilterSchema.infants] = infants;
        this[PDFilterSchema.rooms] = rooms;
        this.productImage = productImage;
        this.propertyType = propertyType;
        this.propertyTitle = propertyTitle;
        this.productId = productId;
        this.productUrl = productUrl;
    }
}
