import { useRef, useState, useEffect } from "react";
import { useOmnisearchState } from "../contexts/OmnisearchState";
import { ActivityErrorTypes } from "../enum";
import useInterests from "./useInterests";

export default function useInterestsPopover({
    anchorElementCssSelector = '',
    validationErrorType = ActivityErrorTypes.ActivitesNotAvailable
}) {
    const { validationError, getSelectedLocation } = useInterests();

    const { destination } = useOmnisearchState();
    const [destinationState] = destination;

    const [isOpen, setOpen] = useState(false);
    const [destinationLocation, setDestinationLocation] = useState('');

    const anchorElementRef = useRef(null);
    const destinationLocationRef = useRef('');

    useEffect(() => {
        destinationLocationRef.current = getSelectedLocation(destinationState)[0];
    }, [destinationState, getSelectedLocation]);

    useEffect(() => {
        let timeoutId;
        if (validationError?.context.errorType === validationErrorType) {
            anchorElementRef.current = document.querySelector(anchorElementCssSelector);

            setOpen(true);
            setDestinationLocation(destinationLocationRef.current);

            timeoutId = setTimeout(() => {
                setOpen(false);
                setDestinationLocation('');
            }, 5000);
        }

        return () => {
            clearTimeout(timeoutId);
        };
    }, [validationError, anchorElementCssSelector, validationErrorType]);

    return {
        isOpen,
        destinationLocation,
        anchorElementRef
    };
};
