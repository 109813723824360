import clsx from 'clsx';
import React, { memo } from 'react';
import SwiperCore, { Navigation, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

SwiperCore.use([Navigation, A11y]);

const SliderTrack = ({ className, title, children }) => (
    <div className={clsx('omnisearch_content_action-area_initial-step_slider-track', className)}>
        <div className="omnisearch_content_action-area_initial-step_slider-track_title">{title}</div>
        <Swiper
            spaceBetween={15}
            slidesPerView='auto'
            freeMode
            pagination={{ clickable: true }}
            {...(window.innerWidth > 860 ? {
                navigation: true
            } : {
                slidesOffsetAfter: 20,
                slidesOffsetBefore: 20
            })}
        >
            {React.Children.map(children, child => (
                <SwiperSlide>{child}</SwiperSlide>
            ))}
        </Swiper>
    </div>
);

export default memo(SliderTrack);
