import { useEffect, useRef } from "react";
import useApi from "../../useApi";

export default function useLocationsNearBy(withLocationsNearby = true) {
    const { getLocationsNearBy } = useApi();
    const getLocationsNearbyRef = useRef(getLocationsNearBy);

    useEffect(() => {
        if (withLocationsNearby) {
            getLocationsNearbyRef.current();
        }
    }, [withLocationsNearby]);
};
