import React, { memo, useCallback, useEffect, useState, useRef } from 'react';

//Libraries
import Link from 'next/link';
import SimpleBar from 'simplebar-react';

//Import Methods
import useBlogArticles from '@/containers/Static/useBlogArticles';
import useDeviceDetect from '@/src/utils/customHooks/useDeviceDetect';
import analyticsLabelProvider from '@/static/analyticsLabelProvider';
import { EAType } from '@/static/contants';
import { handleJSClick } from '../../utils/link';

//Components
import Heading from '../../components/Heading';
import Image from '../../components/Image';
import Button from '../../components/Button';

//Local Utils
const getFiveElements = (info) => {
    let infoReduced = info.reduce((acc,curr,index) => {
        if(index < 5){
            acc.push(curr)
        }
        return acc;
    },[]);
    return infoReduced
}

const Featured = ({ title, subTitle }) => {
    //Check Device
    const {isDesktop,isMobile} = useDeviceDetect();

    //Refs
    const menuRef = useRef(null);
    const sliderRef = useRef(null);

    //States
    const [destinationDeep,setDestinationDeep] = useState(null);
    const [activitiesAdventures, setActivitiesAdventures] = useState(null);
    const [staysYouLove,setStaysYouLove] = useState(null);
    const [cuisineCrawl,setCuisineCrawl] = useState(null);
    const [hancksAndTips, setHacksAndTips] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [mainData, setMainData] = useState(null);

    //Fetch
    const fetchDestinationDeep = useBlogArticles({baseUrl:'https://www.cuddlynest.com/blog/wp-json/wp/v2/posts?categories[]=425',imagesOnly:true});
    const fecthActivitiesAdventures = useBlogArticles({baseUrl:'https://www.cuddlynest.com/blog/wp-json/wp/v2/posts?categories[]=426',imagesOnly:true});
    const fecthStaysYouLove = useBlogArticles({baseUrl:'https://www.cuddlynest.com/blog/wp-json/wp/v2/posts?categories[]=427',imagesOnly:true});
    const fecthCuisineCrawl = useBlogArticles({baseUrl:'https://www.cuddlynest.com/blog/wp-json/wp/v2/posts?categories[]=428',imagesOnly:true});
    const fecthHacksAndTips = useBlogArticles({baseUrl:'https://www.cuddlynest.com/blog/wp-json/wp/v2/posts?categories[]=172',imagesOnly:true});

    useEffect(() => {
        fetchDestinationDeep()
            .then(({ posts: data }) =>  setDestinationDeep({ title:'Destination deep-dive', data }));
        fecthActivitiesAdventures()
            .then(({ posts: data }) =>  setActivitiesAdventures({ title:'Activities & adventure', data }));
        fecthStaysYouLove()
            .then(({ posts: data }) =>  setStaysYouLove({ title:'Stays you’ll love', data }));
        fecthCuisineCrawl()
            .then(({ posts: data }) =>  setCuisineCrawl({ title:'Cuisine crawl', data }));
        fecthHacksAndTips()
            .then(({ posts: data }) =>  setHacksAndTips({ title:'Hacks & Tips', data }));
    },[]);

    useEffect(() => {
        if(destinationDeep && activitiesAdventures && staysYouLove && cuisineCrawl && hancksAndTips){
            setIsLoading(false);
            setMainData(destinationDeep.data);
        }
    },[destinationDeep, activitiesAdventures, staysYouLove, cuisineCrawl, hancksAndTips]);

    //Render

    //Loading scene
    const renderLoading = (
        <div className="cn__hp__featured__loading">
            <div className="cn__hp__featured__loading__titles"/>
            <div className="cn__hp__featured__loading__articles">
                    <div className="cn__hp__featured__loading__articles__article"/>
                    <div className="cn__hp__featured__loading__articles__article"/>
                    <div className="cn__hp__featured__loading__articles__article"/>
                    <div className="cn__hp__featured__loading__articles__article"/>
                    <div className="cn__hp__featured__loading__articles__article"/>
            </div>
            <div className="cn__hp__featured__loading__buttons"/>
        </div>
    );


    //Data Loaded Scene

    //Methods
    const handleOptionClick = (e) => {
        const element = e.target;
        const classList = element.classList;
        if(!element.classList.contains('cnhpfeatured--active')){
            //Add active class
            const activeElement = document.querySelector('.cnhpfeatured--active');
            activeElement.classList.remove('cnhpfeatured--active');
            element.classList.add('cnhpfeatured--active');

            //Set Element to the first
            if(isDesktop){}else{
                //menu slider
                const menuWrapper = menuRef.current.querySelector('.simplebar-content-wrapper');
                const moveLeft = element.offsetLeft - (isMobile ? 20 : 30);
                menuWrapper.scrollTo(moveLeft, 0);
                //Cards slider
                const cardsWrapper = sliderRef.current.querySelector('.simplebar-content-wrapper');
                cardsWrapper.scrollTo(0, 0);
            }

            //Change state
            switch(classList[1]) {
                case 'cnhpfeatured--1':
                    setMainData(destinationDeep.data);
                    break;
                case 'cnhpfeatured--2':
                    setMainData(activitiesAdventures.data);
                    break;
                case 'cnhpfeatured--3':
                    setMainData(staysYouLove.data);
                    break;
                case 'cnhpfeatured--4':
                    setMainData(cuisineCrawl.data);
                    break;
                case 'cnhpfeatured--5':
                    setMainData(hancksAndTips.data);
                    break;
            }
        }
    }

    const onImageLoadError = useCallback(id => {
        setMainData(state => state.filter(article => article.id !== id));
    }, []);

    const renderArticle = useCallback(el => (
        <div className="cn__hp__featured__info__articles__article" key={el.id}>
            <Link href={el.url}>
                <a>
                    <div className="cn__hp__featured__info__articles__article__img">
                        <Image
                            src={isDesktop ? el.desktopImage : el.mobileImage}
                            alt={el.title}
                            onError={() => onImageLoadError(el.id)}
                        />
                    </div>
                    <h4 className="cn__hp__featured__info__articles__article__title" dangerouslySetInnerHTML={{ __html: el.title }}/>
                </a>
            </Link>
        </div>
    ), [onImageLoadError,isDesktop]);

    const articlesToRender = (mainData || []).slice(0, 5);

    const featuredTabBar = (
        <>
            <h3
                test-id='hp_destination_tab' className="cn__hp__featured__info__titles__title cnhpfeatured--1 cnhpfeatured--active"
                onClick={handleOptionClick}
                data-cn-ga-click={JSON.stringify({ el: analyticsLabelProvider.featuredThisWeek[EAType.CLICK]('DDD') })}
            >
                Destination deep-dive
            </h3>
            <h3
                test-id='hp_activities_tab' className="cn__hp__featured__info__titles__title cnhpfeatured--2"
                onClick={handleOptionClick}
                data-cn-ga-click={JSON.stringify({ el: analyticsLabelProvider.featuredThisWeek[EAType.CLICK]('A&D') })}
            >
                Activities & adventure
            </h3>
            <h3
                test-id='hp_stay_you_will_love_tab' className="cn__hp__featured__info__titles__title cnhpfeatured--3"
                onClick={handleOptionClick}
                data-cn-ga-click={JSON.stringify({ el: analyticsLabelProvider.featuredThisWeek[EAType.CLICK]('SYL') })}
            >
                Stays you’ll love
            </h3>
            <h3
                test-id='hp_cuisine_crawl_tab' className="cn__hp__featured__info__titles__title cnhpfeatured--4"
                onClick={handleOptionClick}
                data-cn-ga-click={JSON.stringify({ el: analyticsLabelProvider.featuredThisWeek[EAType.CLICK]('CC') })}
            >
                Cuisine crawl
            </h3>
            <h3
                test-id='hp_hackntip_tab' className="cn__hp__featured__info__titles__title cnhpfeatured--5"
                onClick={handleOptionClick}
                data-cn-ga-click={JSON.stringify({ el: analyticsLabelProvider.featuredThisWeek[EAType.CLICK]('H&T') })}

            >
                Hacks & Tips
            </h3>
        </>
    );

    const renderAll = !isLoading && (
        <div className="cn__hp__featured__info">
            <div className="cn__hp__featured__info__titles" ref={menuRef}>
                {!isDesktop ? (
                   <SimpleBar>
                        <div className="slidermobile__wrapper">
                            {featuredTabBar}
                            <div style={{width: isMobile ? 10 : 20, flexShrink:0, marginLeft: 10}}/>
                        </div>
                    </SimpleBar>
                ):(
                    featuredTabBar
                )}

            </div>
            <div className="cn__hp__featured__info__articles" ref={sliderRef}>
                {isDesktop ? (
                    articlesToRender.map(renderArticle)
                ):(
                    <SimpleBar>
                        <div className="slidermobile__wrapper">
                            {articlesToRender.map(renderArticle)}
                            <div style={{width: isMobile ? 10 : 20, flexShrink:0, marginLeft: 10}}/>
                        </div>
                    </SimpleBar>
                )}

            </div>
            <Button type="blog" title="Browse them all" click={() => handleJSClick("/blog")}/>
        </div>
    );

    const headingTitle = (<Heading
        align={isMobile ? "left" : "center"}
        tit={title || "Worthwhile Travel Reads"}
        sub={subTitle || "Curated articles by travelers, for travelers"} />);


    return (

        <section
            className="cn__hp__featured"
            data-cn-ga-observe={JSON.stringify({ el: analyticsLabelProvider.featuredThisWeek[EAType.SCROLL]() })}
        >
            {headingTitle}
            {isLoading ? renderLoading : renderAll}
        </section>
    )
};

export default memo(Featured);