import React, { memo } from 'react';

//Import Libraries
import clsx from 'clsx';

const Heading = ({align,tit,sub}) => {
    return (
        <div className={clsx("cn__hp__heading", align === "left" ? "cn__hp__heading--left" : "cn__hp__heading--center")}>
            <h2 className="cn__hp__heading__title">{tit}</h2>
            {sub !== "" && (
                <h3 className="cn__hp__heading__subtitle">{sub}</h3>
            )}
        </div>
    )
}

export default memo(Heading);