import { memo, useEffect, useRef } from "react";
import CloseIcon from '@mui/icons-material/Close';
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import ButtonBase from '@mui/material/ButtonBase';
import useInterests from "./useInterests";
import useProceedToSearch from "../useProceedToSearch";
import { useOmnisearchState } from "../contexts/OmnisearchState";
import StepEnum from '../enum';

const MotherfuckingButton = () => {
    const {
        onClose,
        isStateSelected,
        areBusinessTeamMotherfuckers,
        clearMotherfuckingState
    } = useInterests();

    const onProceedToSearch = useProceedToSearch();
    const { destination, dates, guests, step } = useOmnisearchState();
    const [stepState, setStepState] = step;
    const clearMotherfuckingStateRef = useRef(clearMotherfuckingState);

    const onSkip = () => {
        setStepState(StepEnum.GUESTS);
        clearMotherfuckingStateRef.current();
    };

    useEffect(() => () => {
        clearMotherfuckingStateRef.current();
    }, []);

    const CloseButton = (
        <IconButton
            onClick={onClose}
            sx={{
                position: 'absolute',
                right: 10,
                top: 10
            }}
        >
            <CloseIcon />
        </IconButton>
    );
        return !dates[0].startDate
            ? CloseButton
            : (
                <Box
                    component={ButtonBase}
                    sx={{
                        position: 'absolute',
                        right: 20,
                        top: 20,
                        color: 'var(--color-dark-grey)',
                        fontFamily: 'var(--primary-font)',
                        fontWeight: 600,
                        fontSize: '12px',
                        lineHeight: '120%',
                        border: '1px solid var(--color-dark-grey)',
                        borderRadius: '20px',
                        p: '3px 10px'
                    }}
                    onClick={onSkip}
                >
                    Skip
                </Box>
            );
};

export default memo(MotherfuckingButton);
