import React, { memo } from 'react';

const PayLetterStickers = ({ className }) => (
    <svg className={className} width="11" height="9" viewBox="0 0 11 9" fill="none">
        <mask id="path-1-inside-1_3658_10324" fill="white">
        <path fillRule="evenodd" clipRule="evenodd" d="M3 0C1.42733 0 0.137306 1.21012 0.0102684 2.75H10.9897C10.8627 1.21012 9.57267 0 8 0H3ZM11 4.125H0V5.25C0 6.90685 1.34315 8.25 3 8.25H8C9.65685 8.25 11 6.90685 11 5.25V4.125Z"/>
        </mask>
        <path fillRule="evenodd" clipRule="evenodd" d="M3 0C1.42733 0 0.137306 1.21012 0.0102684 2.75H10.9897C10.8627 1.21012 9.57267 0 8 0H3ZM11 4.125H0V5.25C0 6.90685 1.34315 8.25 3 8.25H8C9.65685 8.25 11 6.90685 11 5.25V4.125Z" fill="#1E2A3B"/>
        <path d="M0.0102684 2.75L-1.98296 2.58556L-2.16152 4.75H0.0102684V2.75ZM10.9897 2.75V4.75H13.1615L12.983 2.58556L10.9897 2.75ZM11 4.125H13V2.125H11V4.125ZM0 4.125V2.125H-2V4.125H0ZM2.0035 2.91444C2.04567 2.40322 2.47678 2 3 2V-2C0.377891 -2 -1.77106 0.0170108 -1.98296 2.58556L2.0035 2.91444ZM0.0102684 4.75H10.9897V0.75H0.0102684V4.75ZM8 2C8.52322 2 8.95433 2.40322 8.9965 2.91444L12.983 2.58556C12.7711 0.0170109 10.6221 -2 8 -2V2ZM3 2H8V-2H3V2ZM11 2.125H0V6.125H11V2.125ZM2 5.25V4.125H-2V5.25H2ZM3 6.25C2.44772 6.25 2 5.80228 2 5.25H-2C-2 8.01142 0.238576 10.25 3 10.25V6.25ZM8 6.25H3V10.25H8V6.25ZM9 5.25C9 5.80228 8.55228 6.25 8 6.25V10.25C10.7614 10.25 13 8.01142 13 5.25H9ZM9 4.125V5.25H13V4.125H9Z" fill="#1E2A3B" mask="url(#path-1-inside-1_3658_10324)"/>
    </svg>
);

export default memo(PayLetterStickers);