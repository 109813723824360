import React, { memo, useCallback, useEffect, useRef, useState } from "react";

// Libraries
import clsx from "clsx";
import Cookies from "js-cookie";

// Import methods
import { constants } from "@/utils/constants";
import useLoginPrompt from "../header/utils/useLoginPrompt";

// Import components
import CloseIcon from "../common/icons/Close";
import { concentCookieName } from "@/src/utils/customHooks/useGetUserLocation";

const LoginCTA = ({
  displayTimeout,
  cookieKey,
  cookieValue,
  cookieSettings,
  closingTimeout
}) => {
  // Vars
  const [showLogIn, handleShowLogin] = useLoginPrompt();

  // States
  const [isShowCSSClass, setShowCssClass] = useState(false);

  // Refs
  const vidibilityRef = useRef(false);

  // State with dependency
  const [visibility, setVisibilityState] = useState(true);

  // Methods

  // --- close animation
  const hideCTA = useCallback(() => {
    setShowCssClass(false);
    setVisibilityState(false)
    Cookies.set(cookieKey, cookieValue, cookieSettings);
  }, [cookieKey, cookieValue, cookieSettings]);


  useEffect(() => {
      let closingTime;
        if (!Cookies.get(concentCookieName) && isShowCSSClass === false) {
            const intervalID = setInterval(() => {
                if (Cookies.get(concentCookieName) === constants.trueString || Cookies.get(concentCookieName) === constants.falseString ) {
                  setShowCssClass(true)
                  clearInterval(intervalID)
                  closingTime = setTimeout(()=>{
                    hideCTA();
                  },6000)
                }
            }, 500);
            return () => {
              clearInterval(intervalID)
              clearTimeout(closingTime)
            };
        } 
    }, []);

  // --- close icon animation
  const handleSignClick = useCallback(() => {
    handleShowLogin('signup');
    hideCTA();
  }, [handleShowLogin, hideCTA]);

  if (!isShowCSSClass || !visibility) {
    return null;
  }

  return (
    showLogIn && (
      <div className="cn__CTA__login__wrapper">
        <div
          className={clsx(
            "cn__CTA__login",
            isShowCSSClass && "cn__CTA__login--show"
          )}
        >
          <div className="cn__CTA__login__close" onClick={hideCTA}>
            <CloseIcon />
          </div>
          <div className="cn__CTA__login__header">
            <div className="cn__CTA__login__header__icon">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
               
              >
                <path
                  d="M20 12V22H4V12"
                  stroke="#F35597"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M22 7H2V12H22V7Z"
                  stroke="#F35597"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M12 22V7"
                  stroke="#F35597"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M12 7H7.5C6.83696 7 6.20107 6.73661 5.73223 6.26777C5.26339 5.79893 5 5.16304 5 4.5C5 3.83696 5.26339 3.20107 5.73223 2.73223C6.20107 2.26339 6.83696 2 7.5 2C11 2 12 7 12 7Z"
                  stroke="#F35597"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M12 7H16.5C17.163 7 17.7989 6.73661 18.2678 6.26777C18.7366 5.79893 19 5.16304 19 4.5C19 3.83696 18.7366 3.20107 18.2678 2.73223C17.7989 2.26339 17.163 2 16.5 2C13 2 12 7 12 7Z"
                  stroke="#F35597"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
            <p className="cn__CTA__login__header__title">
                  Sign up & stay inspired
            </p>
          </div>
          <p className="cn__CTA__login__info">
                 Join the CuddlyNest community for travel newsletters and incentives.
          </p>
          <button onClick={handleSignClick}>Sign up</button>
        </div>
      </div>
    )
  );
};

LoginCTA.defaultProps = {
  displayTimeout: 2000,
  closingTimeout: 6000,
  cookieKey: "cn-login-popup",
  cookieValue: 1,
  cookieSettings: { expires: 7, secure: true },
};

export default memo(LoginCTA);
