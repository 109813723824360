import { memo } from "react";

const BooleanTrigger = ({
    getPredicate,
    truthySlot = null,
    falsySlot = null
}) => (
    getPredicate()
        ? truthySlot
        : falsySlot
);

BooleanTrigger.defaultProps = {
    getPredicate: () => false,
    truthySlot: null,
    falsySlot: null
};

export default memo(BooleanTrigger);
