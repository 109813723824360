import React, { memo, useEffect, useRef } from "react";

// Libraries
import Link from "next/link";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";

// Import methods
import analyticsLabelProvider from "@/static/analyticsLabelProvider";
import { EAType } from "@/static/contants";
import { secsAnim } from "../animations";

const LearMore = ({ state }) => {
  // Refs
  const sec3Ref = useRef(null);
  const referenceRef = useRef(null);
  const reference2Ref = useRef(null);
  const el1Ref = useRef(null);
  const el2Ref = useRef(null);
  const el3Ref = useRef(null);
  const el4Ref = useRef(null);
  const el5Ref = useRef(null);

  const prevProps = useRef();

  // Methods

  const resetTriggers = () => {
    const triggers = ScrollTrigger.getAll();
    if (triggers.length > 0) {
      triggers.forEach((trigger) => {
        const name = trigger.vars.id;
        if (name === "learn1" || name === "learn2") {
          trigger.kill();
        }
      });
    }
  };

  const callAnimation = () => {
    resetTriggers();

    const animData = {
      trigger: sec3Ref.current,
      reference1: referenceRef.current,
      reference2: reference2Ref.current,
      el1: el1Ref.current,
      el2: el2Ref.current,
      el3: el3Ref.current,
      el4: el4Ref.current,
      el5: el5Ref.current,
    };

    secsAnim(animData);
  };

  useEffect(() => {
    if (prevProps.current !== state) {
      callAnimation();
    }
    prevProps.current = state;
    window.addEventListener("load", callAnimation);
    return () => {
      window.removeEventListener("load", callAnimation);
    };
  }, [state]);

  return (
    <section
      className="cn__hp__learnmore"
      ref={sec3Ref}
      data-cn-ga-observe={JSON.stringify({
        el: analyticsLabelProvider.about[EAType.SCROLL](),
      })}
    >
      <div className="cn__hp__learnmore__reference" ref={referenceRef}>
        <div
          className="cn__hp__learnmore__reference__wrapper"
          ref={reference2Ref}
        >
          <p className="cn__hp__learnmore__reference__title">
            CuddlyNest is the fastest-growing accommodation booking platform
            built by travelers, for travelers.
          </p>
          <div className="cn__hp__learnmore__reference__subtitle-wrapper">
            <p className="cn__hp__learnmore__reference__subtitle">
              <Link href="/about-us">
                <a
                  data-cn-ga-click={JSON.stringify({
                    el: analyticsLabelProvider.about[EAType.CLICK](),
                  })}
                >
                  Learn more
                </a>
              </Link>
            </p>
          </div>
        </div>
      </div>
      <div className="cn__hp__learnmore__images">
        <div
          className="cn__hp__learnmore__images__info"
          style={{ marginTop: 55 }}
          ref={el2Ref}
        >
          <p className="cn__hp__learnmore__images__info__title">3,698,933</p>
          <p className="cn__hp__learnmore__images__info__subtitle">listings</p>
        </div>
        <div className="cn__hp__learnmore__images__info" ref={el3Ref}>
          <p className="cn__hp__learnmore__images__info__title">80,000</p>
          <p className="cn__hp__learnmore__images__info__subtitle">cities</p>
        </div>
        <div className="cn__hp__learnmore__images__info" ref={el4Ref}>
          <p className="cn__hp__learnmore__images__info__title">188+</p>
          <p className="cn__hp__learnmore__images__info__subtitle">
            countries & territories
          </p>
        </div>
        <div className="cn__hp__learnmore__images__info" ref={el5Ref}>
          <p className="cn__hp__learnmore__images__info__title">32+</p>
          <p className="cn__hp__learnmore__images__info__subtitle">
            property types
          </p>
        </div>
      </div>
    </section>
  );
};

export default memo(LearMore);
