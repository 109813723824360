import { useCallback, useEffect, useRef } from "react";
import { useOmnisearchState } from "./contexts/OmnisearchState";
import { useSettings } from "./contexts/Settings";
import StepEnum, { ActivityErrorTypes } from "./enum";
import useInterests from "./Interests/useInterests";

const activitiesValidationErrorSeen = Symbol('activitiesValidationErrorSeen');

export default function useDestinationTransition(Component) {
    const { fetchActivities, validationError } = useInterests();
    const { destination, step } = useOmnisearchState();
    const { getIsMobile } = useSettings();

    const validationErrorRef = useRef();
    const componentRef = useRef(Component);

    const [, setStep] = step;
    const [, setDestination] = destination;

    useEffect(() => {
        if (!validationError) {
            componentRef.current[activitiesValidationErrorSeen] = false;
        }

        validationErrorRef.current = validationError;
    }, [validationError]);

    return useCallback(async destinationModel => {
        setDestination(destinationModel);

        await fetchActivities(destinationModel);

        // Say 'thank you' for business team
        if (getIsMobile() ||
            (validationErrorRef.current?.context.errorType !== ActivityErrorTypes.ActivitiesOutsideSelectedRange) ||
            componentRef.current[activitiesValidationErrorSeen]) {
            setStep(StepEnum.DATES);
            return;
        }

        setStep();
        componentRef.current[activitiesValidationErrorSeen] = true;
    }, [getIsMobile, setStep, fetchActivities]);
};
