import { useCallback } from "react";
import moment from 'moment';
import useDateFormatter from "@/containers/homePage/Omnisearch/useDateFormatter";
import useGenerateLink from "./useGenerateLink";
import { getWeekend } from "../globalFunc";
import { config } from "../config";
import { constants } from "../constants";
import { sortByTypes } from "@/containers/searchResults/enum";
import General from "@/components/map/helpers/General";
import useUserLocation from '@/utils/customHooks/useUserLocation';

export const STRATEGIES = {
    CHECKIN_CHECKOUT_NEXT_WEEKEND: 'CHECKIN_CHECKOUT_NEXT_WEEKEND',
    CHECKIN_CHECKOUT_NEXT_WEEKEND_OR_DEADLINE: 'CHECKIN_CHECKOUT_NEXT_WEEKEND_OR_DEADLINE',
};

/**
 * Adds or replaces existing url query params
 * @param {Array<[name: string, value: string, strategy: 'append' | 'set']> | Function} searchParamsOrStrategy
 */
export default function useEnhanceUrlSearchParams(strategy, sort, deadline) {
    const userLocation = useUserLocation();
    const linkGeneratorFn = useGenerateLink();
    const dateFormater = useDateFormatter();

    const getBaseUrl = useCallback(({ url, geoSeoTag }) => {
        if (geoSeoTag) {
            return `${config.current_url}${constants.geo_page_route}/${geoSeoTag}`
        }
        return url || `${config.current_url}${constants.search_results_route}`
    }, []);

    const getQueryParams = useCallback(({ strategy: searchParamsStrategy = strategy, queryParams, ...options }) => {
        const getQueryParams = (searchQueryParams = queryParams) =>
            (Array.isArray(searchQueryParams) ? searchQueryParams : [])
                .map(([key, value]) => {
                    const queryModifiers = {
                        location: value => {
                            let val;
                            if (Array.isArray(value)) {
                                val = value;
                            }else if (typeof value === 'string') {
                                val = value.split(',');
                            }

                            return typeof val !== 'undefined' ? General.getGeolocationString(val, ', ') : value;
                        }
                    };

                    return [key, queryModifiers[key]?.(value) ?? value];
                });

        const queryParamsStrategies = new Map()
            .set(STRATEGIES.CHECKIN_CHECKOUT_NEXT_WEEKEND, ({ queryParams, geoSeoTag }) => {
                const [checkin, checkout] = getWeekend(1).map(date => dateFormater(date));
                return !geoSeoTag ? options?.url?.includes('checkin', 'checkout') ? 
                [
                    ['sort',  sort || sortByTypes.RECOMMENDED],
                    ...getQueryParams(queryParams),
                    ['user_country', userLocation?.country]
                ]: 
                [
                    ['sort',  sort || sortByTypes.RECOMMENDED],
                    ...getQueryParams(queryParams),
                    ['checkin', checkin],
                    ['checkout', checkout],
                    ['user_country', userLocation?.country]
                ] : [];
            })
            .set(STRATEGIES.CHECKIN_CHECKOUT_NEXT_WEEKEND_OR_DEADLINE, ({ queryParams, geoSeoTag }) => {
                let [checkin, checkout] = getWeekend(1).map(date => dateFormater(date));
                const deadlineDay = moment(deadline);
                if (deadlineDay.isValid() &&
                    moment(checkin).isAfter(deadlineDay) &&
                    moment().isBefore(deadlineDay)
                ) {
                    checkin = deadlineDay;
                    checkout = deadlineDay.clone().add(1, 'd');
                    checkin = dateFormater(checkin);
                    checkout = dateFormater(checkout);
                }
                return !geoSeoTag ? [
                    ['sort',  sort || sortByTypes.RECOMMENDED],
                    ...getQueryParams(queryParams),
                    ['checkin', checkin],
                    ['checkout', checkout],
                    ['user_country', userLocation?.country]
                ] : [];
            });

        const handler = queryParamsStrategies.get(searchParamsStrategy) ?? searchParamsStrategy;

        return typeof handler === 'function'
            ? handler({ strategy: searchParamsStrategy, queryParams, ...options })
            : getQueryParams()
    }, [strategy, dateFormater, userLocation?.country]);

    // { url, searchParamsOrStrategy, geoSeoTag, queryParams, ...options }
    return useCallback(args => linkGeneratorFn({
        baseUrl: getBaseUrl(args),
        queryParams: getQueryParams(args)
    }), [
        linkGeneratorFn,
        getQueryParams,
        getBaseUrl
    ]);
};
