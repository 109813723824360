import React, { memo, useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import CheckMarkIcon from '@/components/common/icons/CheckMark';
import clsx from 'clsx';

const Checkbox = ({ className, children, id, disabled, boxClass, markClass, textClass, ...restProps }) => {
    const { checked } = restProps;
    const [isChecked, setChecked] = useState(checked);

    useEffect(() => {
        setChecked(checked);
    }, [checked]);

    return (
        <div className={clsx(
            'checkbox', {
                'checkbox--checked': isChecked,
                'checkbox--disabled': disabled
            },
            className
        )}>
            <input
                {...restProps}
                id={id}
                disabled={disabled}
                className="checkbox_input"
                type="checkbox"
            />
            <label htmlFor={id} className={clsx("checkbox_label", boxClass)}>
                <span className={clsx("checkbox_checkmark", markClass)}>
                    <CheckMarkIcon />
                </span>
                <span className={clsx("checkbox_label-text", textClass)}>
                    {children}
                </span>
            </label>
        </div>
    );
};

Checkbox.defaultProps = {
    id: '$chkbox$'
};

Checkbox.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.string,
        PropTypes.array
    ]).isRequired,
    id: PropTypes.string,
    className: PropTypes.string
};

export default memo(Checkbox);
