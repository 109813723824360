import clsx from 'clsx';
import { memo } from 'react';

const LosNotificationContent = ({ className, text }) => {
    return (
        <div className={clsx('los-notification', className)}>
            <div className='los-notification-container'>
                <div className='los-notification-arrow-up'></div>
                <div className='los-notification-symbol'>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <rect x="4" y="5" width="12" height="12" rx="3" stroke="white" strokeWidth="2" />
                        <rect x="4" y="5" width="12" height="3" rx="1" fill="white" />
                        <rect x="6" y="2" width="2" height="3" rx="1" fill="white" />
                        <rect x="12" y="2" width="2" height="3" rx="1" fill="white" />
                        <path d="M22 16C22 18.7614 19.7614 21 17 21C14.2386 21 12 18.7614 12 16C12 13.2386 14.2386 11 17 11C19.7614 11 22 13.2386 22 16Z" fill="#EA526F" stroke="white" strokeWidth="2" />
                        <rect x="15.5859" y="13.1719" width="6" height="2" rx="1" transform="rotate(45 15.5859 13.1719)" fill="white" />
                        <rect x="14.1714" y="17.4141" width="6" height="2" rx="1" transform="rotate(-45 14.1714 17.4141)" fill="white" />
                    </svg>
                </div>
                <div className='los-notification-text'>
                    {text}
                </div>
            </div>
        </div>
    );
};

export default memo(LosNotificationContent);
