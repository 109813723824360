
export const ArrowSymb = () => {
    return (
        <svg width="7" height="14" viewBox="0 0 7 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 13L6 7L1 1" stroke="#ACABB4" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
};

export const CurrencySymb = () => {
    return (
        <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_4255_451)">
                <path d="M2.95204 11.2803L1.70209 10.6554C1.54834 10.5798 1.36023 10.641 1.28272 10.7954L0.657754 12.0453C0.580882 12.1997 0.643369 12.3872 0.797758 12.4647C0.843371 12.4872 0.890889 12.4978 0.937762 12.4978C1.05213 12.4978 1.16275 12.4347 1.21713 12.3253L1.70211 11.3547L2.67271 11.8397C2.82771 11.916 3.01521 11.8547 3.09208 11.6997C3.16892 11.5453 3.1064 11.3578 2.95204 11.2803Z" fill="#64748B" />
                <path d="M4.68955 13.7487C3.08462 13.7437 2.05842 12.3094 1.87342 10.8957C1.8503 10.7238 1.68279 10.6013 1.52281 10.6263C1.35093 10.6488 1.23093 10.8057 1.25282 10.9769C1.4728 12.66 2.72337 14.3674 4.68642 14.3736C4.68703 14.3736 4.68703 14.3736 4.68768 14.3736C4.86017 14.3736 5.00017 14.2336 5.00079 14.0618C5.0014 13.8899 4.8614 13.7493 4.68955 13.7487Z" fill="#64748B" />
                <path d="M14.2016 2.53352C14.0472 2.45852 13.8598 2.51913 13.7822 2.67352L13.2972 3.64412L12.3266 3.15913C12.1723 3.08352 11.9848 3.14475 11.9073 3.29913C11.8304 3.45349 11.8929 3.64099 12.0473 3.7185L13.2972 4.34347C13.3429 4.36597 13.3904 4.3766 13.4372 4.3766C13.5516 4.3766 13.6622 4.31347 13.7166 4.20285L14.3416 2.95289C14.4185 2.7985 14.356 2.61101 14.2016 2.53352Z" fill="#64748B" />
                <path d="M13.7472 4.01833C13.5272 2.33526 12.2767 0.627851 10.3136 0.621582C10.313 0.621582 10.313 0.621582 10.3124 0.621582C10.1405 0.621582 10.0005 0.761586 9.99988 0.933434C9.99926 1.10531 10.1392 1.24593 10.3111 1.24655C11.916 1.25156 12.9422 2.68587 13.1272 4.09956C13.1479 4.25706 13.2822 4.37143 13.4366 4.37143C13.451 4.37143 13.4641 4.37081 13.4772 4.36894C13.6491 4.34644 13.7691 4.18956 13.7472 4.01833Z" fill="#64748B" />
                <path d="M8.125 8.43604C8.125 8.88039 8.67186 9.26414 9.37496 9.35349V7.51855C8.67186 7.60797 8.125 7.99168 8.125 8.43604Z" fill="#64748B" />
                <path d="M9.99988 10.02V11.855C10.7036 11.7656 11.2498 11.3812 11.2498 10.9375C11.2498 10.4931 10.703 10.1094 9.99988 10.02Z" fill="#64748B" />
                <path d="M9.68739 4.37671C6.75815 4.37671 4.37512 6.75974 4.37512 9.68898C4.37512 12.6182 6.75815 15.0013 9.68739 15.0013C12.6166 15.0013 14.9997 12.6182 14.9997 9.68898C14.9997 6.75974 12.6167 4.37671 9.68739 4.37671ZM11.8748 10.9389C11.8748 11.7383 11.0717 12.3733 9.99989 12.4795V12.8139C9.99989 12.9864 9.85989 13.1264 9.68739 13.1264C9.5149 13.1264 9.3749 12.9864 9.3749 12.8139V12.4795C8.30306 12.3733 7.49997 11.7383 7.49997 10.9389C7.49997 10.7664 7.63998 10.6264 7.81247 10.6264C7.98496 10.6264 8.12497 10.7664 8.12497 10.9389C8.12497 11.3833 8.67183 11.767 9.37493 11.8564V9.97959C8.30309 9.87334 7.5 9.23838 7.5 8.43902C7.5 7.63966 8.30309 7.00471 9.37493 6.89845V6.5641C9.37493 6.39161 9.51493 6.2516 9.68742 6.2516C9.85992 6.2516 9.99992 6.39161 9.99992 6.5641V6.89845C11.0718 7.00471 11.8748 7.63966 11.8748 8.43902C11.8748 8.61152 11.7348 8.75152 11.5623 8.75152C11.3899 8.75152 11.2498 8.61152 11.2498 8.43902C11.2498 7.99467 10.703 7.61092 9.99989 7.52157V9.39837C11.0723 9.50462 11.8748 10.1396 11.8748 10.9389Z" fill="#64748B" />
                <path d="M9.28371 3.77047C8.85623 1.62368 6.95881 0 4.68767 0C2.10277 0 0.000366211 2.1024 0.000366211 4.68731C0.000366211 6.95845 1.62343 8.85588 3.77083 9.28336C3.81896 8.57777 3.99333 7.90717 4.26643 7.29032C4.24205 7.34718 4.21205 7.40032 4.18894 7.45718C3.21149 7.29969 2.50025 6.69034 2.50025 5.93724V5.62477H2.18775C2.01526 5.62477 1.87526 5.48477 1.87526 5.31227C1.87526 5.13978 2.01526 4.99978 2.18775 4.99978H2.50025V4.37481H2.18775C2.01526 4.37481 1.87526 4.23481 1.87526 4.06231C1.87526 3.88982 2.01526 3.74985 2.18775 3.74985H2.50025V3.43735C2.50025 2.56114 3.46082 1.87492 4.68767 1.87492C5.91451 1.87492 6.87508 2.56114 6.87508 3.43735C6.87508 3.60984 6.73508 3.74985 6.56259 3.74985C6.39009 3.74985 6.25009 3.60984 6.25009 3.43735C6.25009 2.92925 5.53449 2.49989 4.68767 2.49989C3.84085 2.49989 3.12524 2.92925 3.12524 3.43735V3.74985H5.31266C5.48515 3.74985 5.62516 3.88985 5.62516 4.06234C5.62516 4.23484 5.48515 4.37484 5.31266 4.37484H3.12524V4.9998H5.31266C5.48515 4.9998 5.62516 5.13981 5.62516 5.3123C5.62516 5.34043 5.6164 5.3648 5.60954 5.39105C6.58195 4.46794 7.86252 3.86671 9.28371 3.77047Z" fill="#64748B" />
                <path d="M5.39129 5.60742C5.36566 5.61366 5.34067 5.62304 5.31254 5.62304H3.12512V5.93553C3.12512 6.39864 3.72073 6.79176 4.46757 6.85925C4.42444 6.938 4.39195 7.02299 4.3532 7.10425C4.62131 6.55177 4.97318 6.04803 5.39129 5.60742Z" fill="#64748B" />
            </g>
            <defs>
                <clipPath id="clip0_4255_451">
                    <rect width="15" height="15" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

export const ListsSymb = () => {
    return (
        <svg  width="16" height="15" viewBox="0 0 20 18"  fill="#64748B" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.48866 2.31323L10 2.86016L10.5113 2.31323C12.5223 0.162257 15.762 0.162257 17.7729 2.31323C19.809 4.49107 19.809 8.0403 17.7729 10.2181L11.9739 16.4208C10.878 17.5931 9.12197 17.5931 8.02606 16.4208L2.22706 10.2181C0.190978 8.0403 0.190978 4.49107 2.22706 2.31323C4.23804 0.162257 7.47769 0.162257 9.48866 2.31323Z" strokeWidth="1.4"/>
        </svg>
    );
};

export const LypSymb = () => {
    return (
        <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.7314 6.73293L13.3066 4.30805V1.50691C13.3066 1.00042 12.8961 0.589954 12.3889 0.589954C11.8828 0.589954 11.4724 1.00042 11.4724 1.50691V2.47392L9.66688 0.66834C8.77421 -0.22384 7.22217 -0.222257 6.33154 0.669955L0.268439 6.73293C-0.0894796 7.09156 -0.0894796 7.67184 0.268439 8.02992C0.626518 8.38845 1.20793 8.38845 1.56588 8.02992L7.6284 1.96679C7.8259 1.77032 8.174 1.77032 8.37047 1.96621L14.434 8.02992C14.6138 8.2092 14.8482 8.29838 15.0826 8.29838C15.3174 8.29838 15.5522 8.2091 15.7315 8.02992C16.0895 7.67187 16.0895 7.09159 15.7314 6.73293Z" fill="#64748B" />
            <path d="M8.31848 3.70723C8.14233 3.53118 7.85712 3.53118 7.68148 3.70723L2.34836 9.03877C2.26417 9.12294 2.21643 9.23785 2.21643 9.35777V13.2464C2.21643 14.1589 2.9563 14.8987 3.86876 14.8987H6.50919V10.8096H9.49019V14.8987H12.1307C13.0431 14.8987 13.783 14.1589 13.783 13.2464V9.35777C13.783 9.23785 13.7356 9.12294 13.651 9.03877L8.31848 3.70723Z" fill="#64748B" />
        </svg>
    );
};

export const FaqSymb = () => {
    return (
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14 9.47458C14 7.73729 13.0033 6.18681 11.5106 5.43262C11.4643 8.76364 8.76364 11.4643 5.43262 11.5106C6.18681 13.0033 7.73729 14 9.47458 14C10.2891 14 11.0812 13.783 11.7772 13.3708L13.9802 13.9802L13.3708 11.7772C13.783 11.0812 14 10.2891 14 9.47458Z" fill="#64748B" />
            <path d="M10.6914 5.3457C10.6914 2.39803 8.29338 0 5.3457 0C2.39803 0 0 2.39803 0 5.3457C0 6.30637 0.255707 7.24171 0.741272 8.06288L0.0196533 10.6716L2.62852 9.95013C3.44969 10.4357 4.38504 10.6914 5.3457 10.6914C8.29338 10.6914 10.6914 8.29338 10.6914 5.3457ZM4.52539 4.10156H3.70508C3.70508 3.19687 4.44101 2.46094 5.3457 2.46094C6.2504 2.46094 6.98633 3.19687 6.98633 4.10156C6.98633 4.56075 6.79193 5.00209 6.4528 5.31227L5.75586 5.95015V6.58984H4.93555V5.58891L5.89899 4.70708C6.07117 4.54953 6.16602 4.33452 6.16602 4.10156C6.16602 3.64922 5.79805 3.28125 5.3457 3.28125C4.89336 3.28125 4.52539 3.64922 4.52539 4.10156ZM4.93555 7.41016H5.75586V8.23047H4.93555V7.41016Z" fill="#64748B" />
        </svg>
    );
};

export const ContactUsSymb = () => {
    return (
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_4255_606)">
                <path d="M8.75 11.0833C8.428 11.0833 8.16667 11.3447 8.16667 11.6667V12.25C8.16667 12.572 7.90533 12.8333 7.58333 12.8333H1.75C1.428 12.8333 1.16667 12.572 1.16667 12.25V1.75C1.16667 1.428 1.428 1.16667 1.75 1.16667H2.04167C2.366 1.16667 2.62908 1.4315 2.625 1.757C2.62092 2.07667 2.88458 2.33333 3.20425 2.33333H5.83917C6.44817 2.33333 6.71183 2.07667 6.70833 1.757C6.70425 1.4315 6.96733 1.16667 7.29167 1.16667H7.58333C7.90533 1.16667 8.16667 1.428 8.16667 1.75V2.33333C8.16667 2.65533 8.428 2.91667 8.75 2.91667C9.072 2.91667 9.33333 2.65533 9.33333 2.33333V1.75C9.33333 0.783417 8.54992 0 7.58333 0H1.75C0.783417 0 0 0.783417 0 1.75V12.25C0 13.2166 0.783417 14 1.75 14H7.58333C8.54992 14 9.33333 13.2166 9.33333 12.25V11.6667C9.33333 11.3447 9.072 11.0833 8.75 11.0833Z" fill="#64748B" />
                <path d="M14 5.24943C14 4.60601 13.4768 4.08276 12.8334 4.08276H7.00004C6.35662 4.08276 5.83337 4.60601 5.83337 5.24943V8.74943C5.83337 9.39285 6.35662 9.9161 7.00004 9.9161H12.8334C13.4768 9.9161 14 9.39285 14 8.74943V5.24943ZM12.8334 6.4161L10.1034 7.68776C10.045 7.71693 9.98087 7.7286 9.91671 7.7286C9.85254 7.7286 9.78837 7.71693 9.73004 7.68776L7.00004 6.4161V5.44776L9.91671 6.80693L12.8334 5.44776V6.4161Z" fill="#64748B" />
            </g>
            <defs>
                <clipPath id="clip0_4255_606">
                    <rect width="14" height="14" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

export const PrivacySymb = () => {
    return (
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_4255_1150)">
                <path d="M8.3125 0H1.60417C0.7175 0 0 0.7175 0 1.60417V10.6458C0 11.5325 0.7175 12.25 1.60417 12.25H6.35833C5.86833 11.4742 5.54167 10.5583 5.54167 9.50833V8.085C5.45417 8.1375 5.355 8.16667 5.25 8.16667H2.33333C2.0125 8.16667 1.75 7.90417 1.75 7.58333C1.75 7.2625 2.0125 7 2.33333 7H5.25C5.36083 7 5.46 7.02917 5.5475 7.08167C5.57083 6.58583 5.80417 6.13083 6.17167 5.83333H2.33333C2.0125 5.83333 1.75 5.57083 1.75 5.25C1.75 4.92917 2.0125 4.66667 2.33333 4.66667H7.58333C7.82833 4.66667 8.03833 4.81833 8.12583 5.03417L9.78833 4.46833C9.82917 4.45083 9.87 4.43917 9.91667 4.43333V1.60417C9.91667 0.7175 9.19917 0 8.3125 0V0ZM4.66667 3.5H2.33333C2.0125 3.5 1.75 3.2375 1.75 2.91667C1.75 2.59583 2.0125 2.33333 2.33333 2.33333H4.66667C4.9875 2.33333 5.25 2.59583 5.25 2.91667C5.25 3.2375 4.9875 3.5 4.66667 3.5Z" fill="#64748B" />
                <path d="M13.6045 6.61391L10.542 5.57208C10.4201 5.53066 10.2877 5.53066 10.1658 5.57208L7.10326 6.61391C6.86701 6.69441 6.70776 6.91666 6.70776 7.16633V9.51016C6.70776 12.4659 9.98552 13.8939 10.1249 13.9534C10.1984 13.9843 10.2766 13.9995 10.3542 13.9995C10.4318 13.9995 10.5099 13.9843 10.5829 13.9528C10.7223 13.8933 14 12.4659 14 9.51016V7.16633C14 6.91608 13.8414 6.69441 13.6045 6.61391ZM12.425 8.80783L10.675 11.1412C10.5735 11.2765 10.4189 11.3611 10.2498 11.3727C10.2358 11.3739 10.2218 11.3745 10.2084 11.3745C10.0538 11.3745 9.9056 11.3138 9.79594 11.2036L8.62927 10.0369C8.40118 9.80883 8.40118 9.44016 8.62927 9.21208C8.85735 8.98399 9.22602 8.98399 9.4541 9.21208L10.1454 9.90333L11.4917 8.10783C11.6848 7.85058 12.0505 7.79749 12.3078 7.99116C12.5662 8.18424 12.6187 8.54999 12.425 8.80783Z" fill="#64748B" />
            </g>
            <defs>
                <clipPath id="clip0_4255_1150">
                    <rect width="14" height="14" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

export const TermsSymb = () => {
    return (
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_4255_727)">
                <path d="M8.3125 0H1.60417C0.7175 0 0 0.7175 0 1.60417V10.6458C0 11.5325 0.7175 12.25 1.60417 12.25H5.90917L6.0375 11.5325C6.09583 11.2058 6.2475 10.9142 6.48083 10.675L9.91667 7.245V1.60417C9.91667 0.7175 9.19917 0 8.3125 0V0ZM2.33333 2.33333H4.66667C4.9875 2.33333 5.25 2.59583 5.25 2.91667C5.25 3.2375 4.9875 3.5 4.66667 3.5H2.33333C2.0125 3.5 1.75 3.2375 1.75 2.91667C1.75 2.59583 2.0125 2.33333 2.33333 2.33333ZM5.25 8.16667H2.33333C2.0125 8.16667 1.75 7.90417 1.75 7.58333C1.75 7.2625 2.0125 7 2.33333 7H5.25C5.57083 7 5.83333 7.2625 5.83333 7.58333C5.83333 7.90417 5.57083 8.16667 5.25 8.16667ZM7.58333 5.83333H2.33333C2.0125 5.83333 1.75 5.57083 1.75 5.25C1.75 4.92917 2.0125 4.66667 2.33333 4.66667H7.58333C7.90417 4.66667 8.16667 4.92917 8.16667 5.25C8.16667 5.57083 7.90417 5.83333 7.58333 5.83333Z" fill="#64748B" />
                <path d="M7.308 13.9991C7.19308 13.9991 7.08108 13.9536 6.99883 13.8707C6.89792 13.7698 6.85242 13.6263 6.8775 13.4852L7.18667 11.7322C7.20183 11.6442 7.245 11.5625 7.308 11.4989L11.6392 7.16826C12.1712 6.6351 12.6939 6.77918 12.9797 7.06501L13.7013 7.7866C14.0997 8.18443 14.0997 8.83192 13.7013 9.23034L9.37007 13.5616C9.30707 13.6252 9.22541 13.6677 9.13674 13.6829L7.38383 13.9921C7.35875 13.9967 7.33308 13.9991 7.308 13.9991ZM9.06091 13.2524H9.06674H9.06091Z" fill="#64748B" />
            </g>
            <defs>
                <clipPath id="clip0_4255_727">
                    <rect width="14" height="14" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

export const MyAccountSymb = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8ZM10 5C10 6.28866 9.10457 7.33333 8 7.33333C6.89543 7.33333 6 6.28866 6 5C6 3.71134 6.89543 2.66667 8 2.66667C9.10457 2.66667 10 3.71134 10 5ZM4.64727 9.95617C4.23313 10.2118 4 10.6696 4 11.1563C4 11.9905 4.67622 12.6667 5.51037 12.6667H10.4896C11.3238 12.6667 12 11.9905 12 11.1563C12 10.6696 11.7669 10.2118 11.3527 9.95617C10.5304 9.4485 9.08739 8.66667 8 8.66667C6.9126 8.66667 5.46963 9.4485 4.64727 9.95617Z" fill="#64748B" />
        </svg>
    );
};




export const BookingRequest = () => {
    return (
        <svg width="24" height="24" viewBox="3 0 16 18">
            <path d="M7.28846 17.885H9.24819C8.33993 16.787 7.84398 15.4061 7.84615 13.9811C7.84615 11.9065 8.87565 10.0717 10.4528 8.96191H4.5V15.0965C4.5 15.8361 4.79378 16.5453 5.31672 17.0683C5.83966 17.5912 6.54892 17.885 7.28846 17.885Z" fill="#64748B"/>
<path d="M17.8846 7.84615H4.5V7.28846C4.5 6.54892 4.79378 5.83966 5.31672 5.31672C5.83966 4.79378 6.54892 4.5 7.28846 4.5H15.0962C15.8357 4.5 16.545 4.79378 17.0679 5.31672C17.5908 5.83966 17.8846 6.54892 17.8846 7.28846V7.84615Z" fill="#64748B"/>
<circle cx="14" cy="14" r="5" fill="#64748B"/>
<path d="M14.04 11.352C14.568 11.352 14.9947 11.5013 15.32 11.8C15.6453 12.0987 15.808 12.4987 15.808 13C15.808 13.56 15.6347 13.976 15.288 14.248C14.9413 14.5147 14.472 14.648 13.88 14.648L13.856 15.408H13.224L13.192 14.144H13.424C13.952 14.144 14.3653 14.064 14.664 13.904C14.9627 13.744 15.112 13.4427 15.112 13C15.112 12.68 15.016 12.4267 14.824 12.24C14.632 12.0533 14.3733 11.96 14.048 11.96C13.7173 11.96 13.456 12.0507 13.264 12.232C13.0773 12.408 12.984 12.6507 12.984 12.96H12.296C12.296 12.64 12.368 12.36 12.512 12.12C12.656 11.8747 12.8587 11.6853 13.12 11.552C13.3867 11.4187 13.6933 11.352 14.04 11.352ZM13.536 17.048C13.3973 17.048 13.28 17 13.184 16.904C13.088 16.808 13.04 16.6907 13.04 16.552C13.04 16.4133 13.088 16.296 13.184 16.2C13.28 16.104 13.3973 16.056 13.536 16.056C13.6693 16.056 13.7813 16.104 13.872 16.2C13.968 16.296 14.016 16.4133 14.016 16.552C14.016 16.6907 13.968 16.808 13.872 16.904C13.7813 17 13.6693 17.048 13.536 17.048Z" fill="#fff"/>
        </svg>
    );
};


export const Earnings = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 17" fill="none">
            <circle cx="8.5" cy="8.5" r="8" fill="#64748B"/>
<path d="M11.3782 10.088C11.3782 10.504 11.2702 10.896 11.0542 11.264C10.8462 11.624 10.5342 11.924 10.1182 12.164C9.71022 12.396 9.22622 12.532 8.66622 12.572V13.544H7.89822V12.56C7.09822 12.488 6.45422 12.248 5.96622 11.84C5.47822 11.424 5.22622 10.864 5.21022 10.16H7.01022C7.05822 10.736 7.35422 11.084 7.89822 11.204V8.912C7.32222 8.768 6.85822 8.624 6.50622 8.48C6.15422 8.336 5.85022 8.104 5.59422 7.784C5.33822 7.464 5.21022 7.028 5.21022 6.476C5.21022 5.78 5.45822 5.212 5.95422 4.772C6.45822 4.332 7.10622 4.08 7.89822 4.016V3.044H8.66622V4.016C9.43422 4.08 10.0462 4.312 10.5022 4.712C10.9662 5.112 11.2262 5.664 11.2822 6.368H9.47022C9.44622 6.136 9.36222 5.936 9.21822 5.768C9.08222 5.592 8.89822 5.468 8.66622 5.396V7.664C9.26622 7.816 9.73822 7.964 10.0822 8.108C10.4342 8.244 10.7382 8.472 10.9942 8.792C11.2502 9.104 11.3782 9.536 11.3782 10.088ZM6.96222 6.392C6.96222 6.656 7.04222 6.872 7.20222 7.04C7.36222 7.2 7.59422 7.332 7.89822 7.436V5.36C7.61022 5.4 7.38222 5.508 7.21422 5.684C7.04622 5.86 6.96222 6.096 6.96222 6.392ZM8.66622 11.228C8.97022 11.172 9.20622 11.048 9.37422 10.856C9.55022 10.664 9.63822 10.432 9.63822 10.16C9.63822 9.896 9.55422 9.684 9.38622 9.524C9.21822 9.364 8.97822 9.232 8.66622 9.128V11.228Z" fill="white"/>
        </svg>
    );
};


export const MyBookingsSymb = () => {
    return (
        <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.00129 1H9.57945C9.69271 1 9.80134 1.04499 9.88143 1.12509C9.96152 1.20518 10.0065 1.3138 10.0065 1.42707V2.85774H4.57422V1.42707C4.57422 1.3138 4.61921 1.20518 4.6993 1.12509C4.7794 1.04499 4.88802 1 5.00129 1Z" stroke="#64748B" strokeWidth="1.20433" />
            <path fillRule="evenodd" clipRule="evenodd" d="M0.427067 2.85742H14.153C14.3889 2.85742 14.5801 3.04863 14.5801 3.28449V13.1754C14.5801 13.4112 14.3889 13.6024 14.153 13.6024H0.427067C0.191205 13.6024 0 13.4112 0 13.1754V3.28449C0 3.04863 0.191205 2.85742 0.427067 2.85742ZM2.2124 10.9857C1.87984 10.9857 1.61024 11.2553 1.61024 11.5879C1.61024 11.9205 1.87984 12.1901 2.2124 12.1901H12.3681C12.7006 12.1901 12.9702 11.9205 12.9702 11.5879C12.9702 11.2553 12.7006 10.9857 12.3681 10.9857H2.2124Z" fill="#64748B" />
        </svg>
    );
};

export const InboxSymb = () => {
    return (
        <svg width="17" height="12" viewBox="0 0 17 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.3749 9.91248H2.9877C2.19531 9.91248 1.43538 9.58815 0.875076 9.01084C0.314774 8.43352 0 7.65052 0 6.83407V3.07841C0 2.26197 0.314774 1.47896 0.875076 0.901646C1.43538 0.324332 2.19531 0 2.9877 0H14.0123C14.8047 0 15.5646 0.324332 16.1249 0.901646C16.6852 1.47896 17 2.26197 17 3.07841V11.503C16.9972 11.5953 16.9703 11.6851 16.9221 11.7629C16.8739 11.8408 16.8062 11.9037 16.7261 11.9452C16.6461 11.9868 16.5566 12.0052 16.4672 11.9987C16.3778 11.9922 16.2918 11.961 16.2182 11.9083L13.3749 9.91248Z" fill="#64748B" />
        </svg>
    );
};

export const LogoutSymb = () => {
    return (
        <>
            <svg width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M8 0H2C0.895431 0 0 0.895431 0 2V14C0 15.1046 0.895431 16 2 16H8C9.10457 16 10 15.1046 10 14V9H6C5.44772 9 5 8.55229 5 8C5 7.44772 5.44772 7 6 7H10V2C10 0.895431 9.10457 0 8 0Z" fill="#64748B" />
            </svg>
            <svg width="8" height="10" viewBox="0 0 8 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 6V4H2L5 4.5V5.5L2 6H0Z" fill="#64748B" />
                <path d="M6.4739 5.50235C6.76668 5.19487 6.76075 4.70997 6.46053 4.40975L2.75766 0.706883C2.36714 0.316359 1.73398 0.316359 1.34345 0.706883C0.952927 1.09741 0.952927 1.73057 1.34345 2.1211L3.05651 3.83415C3.09293 3.87057 3.13211 3.90412 3.17369 3.93451L5.4459 5.59455C5.76311 5.8263 6.20299 5.78684 6.4739 5.50235Z" fill="#64748B" />
                <path d="M6.4485 4.49778C6.74129 4.80526 6.73536 5.29015 6.43514 5.59038L2.73227 9.29324C2.34175 9.68376 1.70858 9.68376 1.31806 9.29324C0.927536 8.90271 0.927536 8.26955 1.31806 7.87903L3.03112 6.16597C3.06754 6.12955 3.10671 6.096 3.1483 6.06561L5.42051 4.40557C5.73772 4.17383 6.1776 4.21328 6.4485 4.49778Z" fill="#64748B" />
            </svg>
        </>
    );
};
