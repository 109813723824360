// This is all we need ;)
// https://s3.amazonaws.com/rand-imageprocessed.cuddlynest.com/images/listings/2021/8/26/1463742/1/z6efcc2alpq0l9mjaz5upips8zbpoq.jpg
const retryHelper = args => {
    const {
        timeout,
        counterRef,
        timerRef,
        image: {
            width,
            height,
            src,
            onLoad,
            onError
        }
    } = args;
    const { current: retryCount } = counterRef;

    if (retryCount <= 0) {
        clearTimeout(timerRef.current);
        return false;
    }

    counterRef.current -= 1;

    timerRef.current = setTimeout(() => {
        const image = new Image(width, height);
        image.src = src;

        image.onload = onLoad;
        image.onerror = err => {
            const shouldRetry = retryHelper({
                ...args,
                timerRef
            });
            if (!shouldRetry) {
                onError(err);
            }
        };
    }, timeout);

    return true;
};

export default retryHelper;
