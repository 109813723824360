import { recommendedWiredKeys } from "@/containers/searchResults/DropDownCollection/DropDownCmps/Recommended/useRecommended";
import useEnhanceUrlSearchParams, {
  STRATEGIES,
} from "@/utils/customHooks/useEnhanceUrlSearchParams";

// eslint-disable-next-line import/prefer-default-export
export const transformData = (arr, userLocation) => {
  const linkGeneratorFn = useEnhanceUrlSearchParams(
    STRATEGIES.CHECKIN_CHECKOUT_NEXT_WEEKEND
  );

  const data = arr.map(({ amenities, ...restProps }) => {
    if (restProps.hasOwnProperty("amenity_id")) {
      const location = [restProps.city, restProps.state, restProps.country];
      // Free cancellation
      if (restProps.amenity_name === "Free Cancellation") {
        return {
          ...restProps,
          url: linkGeneratorFn({
            queryParams: [
              ["location", location],
              ["popular[]", recommendedWiredKeys.FREE_CANCELLATION],
            ],
          }),
        };
      }

      // Breakfast included
      if (restProps.amenity_name === "Breakfast") {
        return {
          ...restProps,
          url: linkGeneratorFn({
            queryParams: [
              ["location", location],
              ["popular[]", recommendedWiredKeys.BREAKFAST_INCLUDED],
            ],
          }),
        };
      }
      // With amenity
      return {
        ...restProps,
        url: linkGeneratorFn({
          queryParams: [
            ["location", location],
            ["listvalue[]", restProps.amenity_name],
          ],
        }),
      };
    }

    // If not amenity
    if (restProps.url) {
      return {
        ...restProps,
      };
    }


    // Price
    if (restProps.title.startsWith("Stays under")) {
      return {
        ...restProps,
        url: linkGeneratorFn({
          queryParams: [
            ["location", userLocation],
            ["pricemin", 0],
            ["pricemax", restProps.amount],
            ["currency", restProps.currency],
          ],
        }),
      };
    }

    // Free cancellation
    if (restProps.title === "Free cancellation") {
      return {
        ...restProps,
        url: linkGeneratorFn({
          queryParams: [
            ["location", userLocation],
            ["popular[]", recommendedWiredKeys.FREE_CANCELLATION],
          ],
        }),
      };
    }

    // Breakfast included
    if (restProps.title === "With breakfast") {
      return {
        ...restProps,
        url: linkGeneratorFn({
          queryParams: [
            ["location", userLocation],
            ["popular[]", recommendedWiredKeys.BREAKFAST_INCLUDED],
          ],
        }),
      };
    }

    // With amenity
    const amenityQueryArgs = amenities.reduce(
      (acc, amenity) => [...acc, ["listvalue[]", amenity]],
      []
    );

    return {
      ...restProps,
      url: linkGeneratorFn({
        queryParams: [["location", userLocation], ...amenityQueryArgs],
      }),
    };
  });

  return data;
};
